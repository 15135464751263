import { DeviceUptimeClock } from "utils/DeviceUptimeClock";
import { TimeUtils } from "utils/TimeUtils";

/*
  2020-12-05 / Ivan / the concept for known server (world) UTC time is simple:
  1. we rely on 'device uptime' helper which measures the 'hardware time'
  2. when we receive the first response from server, we record local 'device uptime' and 'server time'
  3. at any given point in the future, we can estimate the current 'server time' based on the current 'device uptime'
*/

export class ServerClock {
  private static _initDeviceUptimeMillis = -1;
  private static _initServerUtcStampMillis = -1;
  // 2022-01-04 / Ivan / at the moment device_uptime_millis does not handle some tiny steps
  // let's patch it temporary via new Date().getTime()
  private static _initDeviceUtcStampMillis = -1;
  private static _latestKnownServerUtcStampMillis = -1;

  public static get latestKnownServerUtcStampMillis(): number {
    return ServerClock._latestKnownServerUtcStampMillis;
  }

  public static get isInitialized(): boolean {
    return ServerClock._initServerUtcStampMillis > 0;
  }

  // 2019-12-21 / Ivan / this method is called upon successfully login - only once
  // as a side effect, we are not aware of server time (i.e., we don't know how bad is the local time) before user is logged
  public static init(serverUtcStampMillis: number, startUptimeMillis: number) {
    // should never happen
    if (ServerClock._initServerUtcStampMillis > 0) {
      throw new Error(
        "[init] (ServerClock.init__server_utc_stamp_millis > 0) [" +
          ServerClock._initServerUtcStampMillis +
          "]",
      );
    }

    const nowUptimeMillis = DeviceUptimeClock.nativeDeviceUptimeMillis();
    const nowDeviceUtcStampMillis = new Date().getTime();

    // 2020-06-09 / Ivan / also install last known server time
    ServerClock._latestKnownServerUtcStampMillis = serverUtcStampMillis;

    ServerClock._initServerUtcStampMillis = serverUtcStampMillis;
    ServerClock._initDeviceUptimeMillis =
      (nowUptimeMillis - startUptimeMillis) / 2 + startUptimeMillis;

    // 2022-01-04 / Ivan / at the moment device_uptime_millis does not handle some extremely small steps
    // trying to detect and patch such situations via on Date().getTime()
    //    ServerClock.init__device_utc_stamp_millis = start_device_time_millis + (ServerClock.init__device_uptime_millis - start_uptime_millis);
    ServerClock._initDeviceUtcStampMillis =
      nowDeviceUtcStampMillis -
      (nowUptimeMillis - ServerClock._initDeviceUptimeMillis);
  }

  // 2020-06-09 / Ivan / quick hack - also keep last known server time
  public static installLatestKnownServerUtcStampMillis(
    serverUtcStampMillis: number,
  ): void {
    ServerClock._latestKnownServerUtcStampMillis = serverUtcStampMillis;
  }

  // returns (recalculated) real world time - stamp in UTC in millis
  public static serverStampMillis(exitIfUnknown = true) {
    const lastDeviceUptimeMillis = DeviceUptimeClock.lastDeviceUptimeMillis;
    const initServerUtcStampMillis = ServerClock._initServerUtcStampMillis;
    const initDeviceUptimeMillis = ServerClock._initDeviceUptimeMillis;
    const initDeviceUtcStampMillis = ServerClock._initDeviceUtcStampMillis;

    // 2020-12-05 / Ivan / fallback scenario - we don't have enough info to calculate server time
    if (
      lastDeviceUptimeMillis === -1 ||
      initServerUtcStampMillis === -1 ||
      initDeviceUptimeMillis === -1
    ) {
      if (exitIfUnknown) {
        return -1;
      }

      return TimeUtils.now().valueOf();
    }

    const nativeUptimeDelta = lastDeviceUptimeMillis - initDeviceUptimeMillis;
    const nowDeviceUtcStampMillis = TimeUtils.now().valueOf();
    const jsUptimeDelta = nowDeviceUtcStampMillis - initDeviceUtcStampMillis;

    // 2022-01-04 / Ivan / at the moment native device_uptime_millis does not handle some tiny steps
    // lets try to fix it via conventional JS date/time
    let uptimeDelta = nativeUptimeDelta;
    if (jsUptimeDelta > uptimeDelta && jsUptimeDelta < uptimeDelta + 500) {
      uptimeDelta = jsUptimeDelta;
    }

    return initServerUtcStampMillis + uptimeDelta;
  }
}
