import { PublicCoreApi } from "server/core/server/CoreApi";
import { MemoryStorage } from "utils/MemoryStorage";

// 2020-11-10 / Ivan / separate implementation for public feed refresh
export class SportsBookPrematchFeedMonitor {
  public static last_poll_data_start_stamp: number = 0; // public - to prevent typescript warning
  private static poll_data_in_progress: boolean;
  private static last_poll_data_end_stamp: number = 0;
  private static last_poll_data_was_error: boolean = false;

  public static onTick() {
    if (!SportsBookPrematchFeedMonitor.should_poll_now()) {
      return;
    }
    SportsBookPrematchFeedMonitor.do_single_poll();
  }

  private static should_poll_now(): boolean {
    if (!MemoryStorage.enableBackgroundRefresh) {
      return false;
    }

    if (SportsBookPrematchFeedMonitor.poll_data_in_progress) {
      return false;
    }

    // 2020-06-10 / Ivan / for the time being if we check for delta > x or < x - will be enough ?
    // 2019-12-06 / Ivan / we need to use something like OS uptime / realtime - if user changes his device time, this should not affect the logic

    // 2021-02-01 / Ivan / switch to server control for poll intervals
    let interval;
    if (SportsBookPrematchFeedMonitor.last_poll_data_was_error) {
      interval =
        MemoryStorage.config__20032__poll_interval_public_prematch_after_error;
    } else {
      interval =
        MemoryStorage.config__20031__poll_interval_public_prematch_after_ok;
    }
    // 2020-10-28 / Ivan / this wont work well if user changes the settings of the device clock
    const now: number = new Date().getTime();
    if (
      now - SportsBookPrematchFeedMonitor.last_poll_data_end_stamp <
      interval
    ) {
      return false;
    }

    return true;
  }

  private static async do_single_poll() {
    SportsBookPrematchFeedMonitor.poll_data_in_progress = true;
    SportsBookPrematchFeedMonitor.last_poll_data_start_stamp =
      new Date().getTime();

    const { response } = await PublicCoreApi.safeBackgroundPrematchFeedUpdate();

    let isError = true;
    const now = new Date().getTime();

    if (response) {
      // consider non-available check_immediatelly_for_more as error ?
      isError = false;
    }

    SportsBookPrematchFeedMonitor.last_poll_data_end_stamp = now; // new Date().getTime();
    SportsBookPrematchFeedMonitor.last_poll_data_was_error = isError;
    SportsBookPrematchFeedMonitor.poll_data_in_progress = false;
  }
}
