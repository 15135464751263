import { FliffException } from "server/legacyCore/FliffException";
import { TAnyAlias } from "src/types";
import { AppUtils } from "utils/AppUtils";
import { LogUploader } from "utils/Logger/LoggUploader";
import { TimeUtils } from "utils/TimeUtils";

// Small heads up: we are aware that @sentry/react-native has API for capturing messages + errors.
// In order to not be Fully dependent on lib implementation – we've decided to use our own implementation of common | exception logger.

class Logger {
  public init(): void {
    LogUploader.init();
  }

  // 2019-12-08 / Ivan / args are temporary ignored
  // need to find out the proper way to format them or just don't use additional args
  public logMessage(message: string): void {
    this._commonLog(`[${this._strTime()}] [log] ${message}`);
  }

  public warnMessage(message: string): void {
    this._commonLog(`[${this._strTime()}] [warn] ** ${message}`);
  }

  public warnHandledAny(message: string, input: unknown): void {
    this.warnAny(`[Handled] ${message}`, input);
  }

  public warnUnhandledAny(message: string, input: unknown) {
    this.warnAny(`[Unhandled] ${message}`, input);
  }

  public warnAny(message: string, input: TAnyAlias): void {
    // 2019-12-08 / Ivan / try to handle all situations where error is any object
    let errorName = "unknown";
    let errorMessage = "unknown";
    let errorStack = "unknown";
    let errorStringify = "unknown";
    let errorResponse = "unknown"; // will capture additional info from axios errors

    try {
      if (typeof input === "undefined") {
        errorName = "(error is undefined)";
      } else if (input === null) {
        // null == undefined
        errorName = "(error == null)";
      } else {
        errorName = "" + input.name;
        if (input instanceof FliffException) {
          errorName +=
            "[" +
            input.error_code +
            " / " +
            input.error_message +
            " / " +
            input.debug_error_source +
            "]";
        }
        errorMessage = "" + input.message;
        errorStack = "" + input.stack;
        errorResponse = input.response ? AppUtils.dump(input.response) : "none";
        errorStringify = JSON.stringify(input);
      }

      this._commonLog(
        "[" +
          this._strTime() +
          "] [warnx] ** " +
          message +
          " [exception name: " +
          errorName +
          ", message: " +
          errorMessage +
          ", errorResponse: " +
          errorResponse +
          "] [JSON.stringify: " +
          errorStringify +
          "], stack: " +
          errorStack,
      );
    } catch (error: TAnyAlias) {
      // example: 'Converting circular structure to JSON
      this._commonLog(
        "[" +
          this._strTime() +
          "] [warnx] warnx - internalerror while building log info",
      );
      this._commonLog(
        "[" +
          this._strTime() +
          "] [warnx] ** " +
          message +
          " [JSON.stringify: " +
          errorStringify +
          "] name: " +
          errorName +
          ", message: " +
          errorMessage +
          ", stack: " +
          errorStack,
      );
      this._commonLog(
        "[" +
          this._strTime() +
          "] [warnx] warnx - internalerror.name: " +
          error.name,
      );
      this._commonLog(
        "[" +
          this._strTime() +
          "] [warnx] warnx - internalerror.message: " +
          error.message,
      );
      this._commonLog(
        "[" +
          this._strTime() +
          "] [warnx] warnx - internalerror.stack: " +
          error.stack,
      );
    }
  }

  // 2019-12-08 / Ivan / args are only used for console log, not uploaded to server
  private _commonLog(message: string, ...args: unknown[]): void {
    // for some reason react native console does not like the full format, just strip the date prefix
    console.log(message, ...args);
    LogUploader.logg(message);
  }

  private _strTime(): string {
    return TimeUtils.now().format("YYYY-MM-DD HH:mm:ss");
  }
}

export default new Logger();
