import {
  IEndpoint,
  IOnEndpointConnectedCallback,
  IOnIncomingMessageCallBack,
} from "../types";
import { FliffWebSocket } from "./FliffWebSocket";
import { EventEmitter } from "events";
import pull from "lodash/pull";

class FliffSocketBase
  extends EventEmitter
  implements IOnEndpointConnectedCallback {
  protected callback: IOnIncomingMessageCallBack;

  protected endpoints: IEndpoint[] = [];
  protected enableDebugLog = false;

  constructor(callback: IOnIncomingMessageCallBack) {
    super();
    this.callback = callback;

    this.attachEndpoint = this.attachEndpoint.bind(this);
    this.endpointTerminated = this.endpointTerminated.bind(this);
    this.xrecv = this.xrecv.bind(this);
    this.hiccuped = this.hiccuped.bind(this);
    this.reconnecting = this.reconnecting.bind(this);
  }

  public getMessagesOnConnect(_endpoint: IEndpoint): string[] {
    return [];
  }

  connect(address: string) {
    const endpoint = new FliffWebSocket(address, this);
    endpoint.setEnableDebugLog(this.enableDebugLog);
    endpoint.on("attach", this.attachEndpoint);
    endpoint.on("terminated", this.endpointTerminated);
    endpoint.on("message", this.xrecv);
    endpoint.on("hiccuped", this.hiccuped);
    // 2021-12-28 / Ivan / add extra notification
    endpoint.on("reconnecting", this.reconnecting);

    this.endpoints.push(endpoint);
  }

  disconnect(address: string) {
    const endpoint = this.endpoints.find(({ address: eAddress }) => {
      const omitParamsAddress = eAddress.split("?")[0];
      return omitParamsAddress === address;
    });

    if (endpoint) {
      this._removeEndpointListeners(endpoint);
    }
  }

  close() {
    this.endpoints.forEach(this._removeEndpointListeners);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected attachEndpoint(_endpoint: IEndpoint) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected endpointTerminated(_endpoint: IEndpoint) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected hiccuped(_endpoint: IEndpoint) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected reconnecting(_endpoint: IEndpoint) {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected xrecv(_websocket: FliffWebSocket, _message: string) {}

  private _removeEndpointListeners(endpoint: IEndpoint): void {
    endpoint.removeListener("attach", this.attachEndpoint);
    endpoint.removeListener("terminated", this.endpointTerminated);
    endpoint.removeListener("message", this.xrecv);
    endpoint.removeListener("hiccuped", this.hiccuped);
    // 2021-12-28 / Ivan / add extra notification
    endpoint.removeListener("reconnecting", this.reconnecting);
    endpoint.close();
    pull(this.endpoints, endpoint);
    this.endpointTerminated(endpoint);
  }
}

export default FliffSocketBase;
