import { IProps as IOptionSelectProps } from "../Select";
import React from "react";
import Inputs from "components/atoms_new/shared/Inputs";
import { TUSAStatesValues } from "src/types";
import { AppUtils } from "utils/AppUtils";

type TProps = Omit<
  IOptionSelectProps<TUSAStatesValues>,
  "data" | "placeholder"
>;

const USAStateSelect = (props: TProps) => {
  const usaStatesArr = AppUtils.getConvertedToSelectedUSAStates();
  return <Inputs.Select {...props} placeholder={"State"} data={usaStatesArr} />;
};
export default React.memo(USAStateSelect);
