import { CoreInjectProtocolSlotsAction } from "reduxLocal/core/core.actions";
import { IFCMProtocolResponseSlots } from "server/core/data/objects";
import {
  IFliffProtocolResponse,
  IFliffResponse,
} from "server/sharedCore/data/objects";
import { IInternalProtocolResponseProcessor } from "server/sharedCore/interfaces";

export class InternalProtocolResponseProcessorImpl
  implements
    IInternalProtocolResponseProcessor<
      IFCMProtocolResponseSlots,
      IFliffResponse
    > {
  public processProtocolResponseSlots<Response extends IFliffResponse>(
    protocolResponse: IFliffProtocolResponse<
      IFCMProtocolResponseSlots,
      Response
    >,
  ): void {
    const slots = protocolResponse.x_slots;

    if (!slots) {
      return;
    }

    CoreInjectProtocolSlotsAction.dispatchInjectProtocolSlots(slots);
  }
}
