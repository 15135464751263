import { IProps } from "./types";
import "./styles.scss";
import Icon from "components/atoms/common/Icon";

const ModalHeader = ({ onGoBack, title, onClose }: IProps) => {
  return (
    <div className="modal-header">
      {!!onGoBack && (
        <button
          className="modal-header__button modal-header__back-button"
          onClick={onGoBack}>
          <Icon name="angle-left" className="modal-header__button-arrow-icon" />
        </button>
      )}
      <h2 className="modal-header__title">{title}</h2>
      {!!onClose && (
        <button
          className="modal-header__button modal-header__close-button"
          onClick={onClose}>
          <Icon name="close" className="modal-header__button-icon" />
        </button>
      )}
    </div>
  );
};
export default ModalHeader;
