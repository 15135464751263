import classNames from "classnames";
import React, { useState } from "react";
import ValidationStateMark from "../ValidationStateMark";
import "./styles.scss";
import { IProps } from "./types";
import InputMask, { InputState } from "react-input-mask";

const DateInput = ({
  value,
  onChangeText,
  validationState,
  className,
  ...props
}: IProps) => {
  const [internalValue, setInternalValue] = useState(value);

  const formatChars = {
    m: "[0-1]",
    M: "[0-9]",
    d: "[0-3]",
    D: "[0-9]",
    y: "[1-2]",
    Y: "[0-9]",
  };

  const beforeMaskedValueChange = (newState: InputState) => {
    const dateParts = newState.value.split("/");
    const monthPart = dateParts[0] || "";
    const dayPart = dateParts[1] || "";
    const yearPart = dateParts[2] || "";

    // Conditional mask for the 2nd digit of month based on the first digit
    if (monthPart.startsWith("1")) {
      formatChars["M"] = "[0-2]"; // To block 13, 15, etc.
    } else {
      formatChars["M"] = "[1-9]"; // To allow 05, 08, etc - but blocking 00.
    }

    // Conditional mask for day
    if (!yearPart.includes("_") && !monthPart.includes("_")) {
      // Find last day of the month
      const endOfMonth = new Date(`${yearPart}-${monthPart}-01`);
      endOfMonth.setMonth(parseInt(monthPart, 10));
      endOfMonth.setDate(0);
      const lastDayOfMonth = endOfMonth.getDate().toString();

      // Set [0-x] dynamically for the first digit based on the last day
      formatChars["d"] = `[0-${lastDayOfMonth[0]}]`;

      if (dayPart.startsWith(lastDayOfMonth[0])) {
        formatChars["D"] = `[0-${lastDayOfMonth[1]}]`; // Limit month's last digit based on last day
      } else if (dayPart.startsWith("0")) {
        formatChars["D"] = "[1-9]"; // To block 00.
      } else {
        formatChars["D"] = "[0-9]"; // To allow days to start with 1 Eg: 10, 12, 15, etc.
      }
    }

    return { value: newState.value, selection: newState.selection };
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    if (newValue.indexOf("_") === -1) {
      onChangeText(newValue);
    }
  };

  const handleBlur = () => {
    onChangeText(internalValue);
  };

  const isInvalidState = validationState.state === 0;

  const inputContainerClasses = classNames("date-container", className, {
    "date-container--invalid": isInvalidState,
  });

  return (
    <div className={inputContainerClasses}>
      <InputMask
        mask="mM/dD/yYYY"
        value={internalValue}
        onChange={handleChange}
        onBlur={handleBlur}
        formatChars={formatChars}
        beforeMaskedValueChange={beforeMaskedValueChange}
        className="date-container__input"
        placeholder="MM/DD/YYYY"
        {...props}
      />
      <ValidationStateMark validationState={validationState} />
    </div>
  );
};

export default DateInput;
