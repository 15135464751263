import { createContext, useContext } from "react";

import { ISportEventsContext } from "./sportEventsContext.props";

const sportEventsContext = createContext<ISportEventsContext>({
  events: [],
  selectChannel: () => ({}),
  selectedChannel: -1,
  channels: [],
  isInPlayChannelSelected: false,
  selectLiveChannel: () => ({}),
  selectedLiveChannel: -1,
  liveChannels: [],
  catchallChannel: null,
  allChannels: [],
  totalLiveGames: 0,
  gamesCountByChannelId: {},
  allSportsChannels: [],
  allEvents: [],
  lastSelectedChannelId: -1,
});

export const useSportEvents = (): ISportEventsContext =>
  useContext<ISportEventsContext>(sportEventsContext);

export default sportEventsContext;
