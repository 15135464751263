import { FliffException } from "server/legacyCore/FliffException";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
import { IFliffMessage } from "server/sharedCore/data/objects";
import * as Adapters from "server/social/server/impl/serverAdapters";
import { TAnyAlias } from "src/types";

export class MessageDecoder {
  public static decodeMessage(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IFliffMessage {
    const objectClassName = ValidationUtils.assert_non_empty_string(
      jsonData.__object_class_name,
      debugInfo + ".__object_class_name",
    );
    const nextDebugInfo = debugInfo + "." + objectClassName;

    if (objectClassName === "FSM__Modify_Relationship__Response") {
      return Adapters.AdapterFSMModifyRelationshipResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Activity_Feed_Data__Response") {
      return Adapters.AdapterFSMGetActivityFeedDataResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_User_Profile__Response") {
      return Adapters.AdapterFSMGetUserProfileResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Update_User_Profile_V2__Response") {
      return Adapters.AdapterFSMUpdateUserProfileResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Search_User__Response") {
      return Adapters.AdapterFSMSearchUserResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FSM__Get_Users_For_Relationship__Response") {
      return Adapters.AdapterFSMGetUsersForRelationshipResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Leaderboard__Response") {
      return Adapters.AdapterFSMGetLeaderboardResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Activity_Feed_Meta__Response") {
      return Adapters.AdapterFSMGetActivityFeedMetaResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_User_Badges__Response") {
      return Adapters.AdapterFSMGetUserBadgesResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Player_Level_Leaderboard_V2__Response") {
      return Adapters.AdapterFSMGetPlayerLevelLeaderboardResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Suggested_Follows__Response") {
      return Adapters.AdapterFSMGetSuggestedFollowersResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Copy_Pick__Response") {
      return Adapters.AdapterFSMCopyPickResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Copy_Pick_Users__Response") {
      return Adapters.AdapterFSMGetCopyPickUsersResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Event_Stats_Detail__Response") {
      return Adapters.AdapterFSMGetEventStatsDetailResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }
    if (objectClassName === "FSM__Get_Top_Event_Stats__Response") {
      return Adapters.AdapterFSMGetTopEventStatsResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Copy_Pick_Count__Response") {
      return Adapters.AdapterFSMGetCopyPickCountResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unknown object_class_name [" + objectClassName + "]",
    );
  }
}
