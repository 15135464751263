import RegularTextInput from "./atoms/RegularTextInput";
import SelectList from "./atoms/Select";
import RegularTextSkipValidations from "./atoms/SkipValidations";
import TextArea from "./atoms/TextArea";
import USAStateSelect from "./atoms/StateSelect";
import DatePick from "./atoms/DatePick";
import Phone from "./atoms/Phone";
import Code from "./atoms/Code";
import CouponCodeText from "./atoms/CouponCode";
import LabelInput from "./atoms/Label";
import DateInput from "./atoms/DateInput";

const Inputs = {
  TextArea: TextArea,
  RegularText: RegularTextInput,
  Select: SelectList,
  StateSelect: USAStateSelect,
  SkipValidations: RegularTextSkipValidations,
  DatePick,
  DateInput: DateInput,
  Phone: Phone,
  Code: Code,
  CouponCode: CouponCodeText,
  Label: LabelInput,
};

export default Inputs;
