import { ReactComponent as LoadingCircle } from "assets/img/green_circle.svg";
import "./styles.scss";
import { IProps } from "./types";
import classNames from "classnames";

const LoadingSpinner = ({ size, className }: IProps) => {
  const loadingSpinnerClasses = classNames("loading-spinner", className, {
    [`loading-spinner--${size}`]: size,
  });
  return (
    <div className={loadingSpinnerClasses}>
      <LoadingCircle />
    </div>
  );
};

export default LoadingSpinner;
