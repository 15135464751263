import RCTCalendar from "react-calendar";
import CalendarArrow from "components/atoms/common/Calendar/atoms/CalendarArrow";
import "./styles.scss";
import { IProps } from "./types";

const Calendar = ({ onDateChange, value, detail, ...props }: IProps) => (
  <RCTCalendar
    maxDate={new Date()}
    minDetail={detail || "month"}
    calendarType={"US"}
    prevLabel={<CalendarArrow direction={"left"} />}
    nextLabel={<CalendarArrow direction={"right"} />}
    next2Label={null}
    prev2Label={null}
    maxDetail={"month"}
    onChange={onDateChange}
    value={value}
    tileClassName={"calendar-tile"}
    className={"custom-calendar"}
    {...props}
  />
);

export default Calendar;
