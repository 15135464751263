import { initialState } from "./appUI.initialState";
import * as Actions from "./appUI.actions";

import { TAction } from "./types";

const appUIReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case Actions.AppUISetAppUiMode.type:
      return {
        ...state,
        appUiMode: action.payload,
      };
    case Actions.AppUIShowModalProgressIndicator.type:
      return {
        ...state,
        isModalProgressIndicatorVisible: true,
        modalProgressIndicatorMode: action.payload,
      };
    case Actions.AppUIHideModalProgressIndicator.type:
      return {
        ...state,
        isModalProgressIndicatorVisible: false,
      };
    case Actions.AppUIShowModalDialogAction.type:
      return {
        ...state,
        isModalDialogVisible: true,
        modalDialogMessage: action.payload.message,
        modalDialogTitle: action.payload.title,
        modalDialogAutoExitOnOk: action.payload.autoExitOnOk,
        onOkCallback: action.payload.onOkCallback,
      };
    case Actions.AppUIHideModalDialogAction.type:
      return {
        ...state,
        isModalDialogVisible: false,
        modalDialogMessage: "",
        modalDialogTitle: "",
        modalDialogAutoExitOnOk: false,
        onOkCallback: () => {},
      };
    default:
      return state;
  }
};

export default appUIReducer;
