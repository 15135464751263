import { useState } from "react";
import phone from "phone";

import { DevConstants } from "src/DevConstants";

interface IUsePhoneNumberReturnType {
  isValid: boolean;
  formattedPhoneNumber: string | null;
  rawPhoneNumber: string;
  handlePhoneNumberChange: (value: string) => void;
}

const usePhoneNumberVerification = (
  initialValue: string | (() => string),
): IUsePhoneNumberReturnType => {
  const [phoneNumber, setPhoneNumber] = useState<string>(initialValue);
  const {
    phoneNumber: formattedPhoneNumber,
    isValid: isValidPhoneNumber,
    countryIso3,
  } = phone(phoneNumber);

  const isValid = DevConstants.isAnyNumberEnterEnabled
    ? (countryIso3 === "USA" ||
        countryIso3 === "CAN" ||
        countryIso3 === "BGR" ||
        countryIso3 === "UKR") &&
      isValidPhoneNumber
    : (countryIso3 === "USA" || countryIso3 === "CAN") && isValidPhoneNumber;

  return {
    isValid,
    formattedPhoneNumber,
    handlePhoneNumberChange: setPhoneNumber,
    rawPhoneNumber: phoneNumber,
  };
};

export default usePhoneNumberVerification;
