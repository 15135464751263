import colors from "assets/styles/design-tokens/colors.module.scss";
import "./styles.scss";
import { IProps } from "./types";

const ButtonOutline = ({
  labelColor = colors.neutralBlueColor,
  borderColor = colors.neutralBlueColor,
  disabled,
  label,
  onClick,
  containerStyle,
  labelStyle,
  children,
}: IProps) => (
  <div
    onClick={onClick}
    className={`fliff-button-outline-container ${
      disabled ? "fliff-button-outline-disabled" : ""
    }`}
    style={{
      ...containerStyle,
      borderColor: borderColor,
    }}>
    <span
      className="fliff-button-outline-label"
      style={{
        ...labelStyle,
        color: labelColor,
      }}>
      {label}
    </span>
    {children}
  </div>
);

export default ButtonOutline;
