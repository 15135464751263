import { TWithdrawProviders } from "src/types";
import { IValidationReturnType } from "utils/FormValidationUtils";
import { staticGetState } from "reduxLocal/store";
import CoreSelectors from "reduxLocal/core/core.selectors";
import { TimeUtils } from "utils/TimeUtils";
import dayjs from "dayjs";

export const isRequired = (
  value: string,
  errorPrefix?: string,
): IValidationReturnType => {
  const isValid = value ? !!value.trim() : false;
  const state = isValid ? 1 : 0;
  return {
    value,
    isValid,
    validationError: isValid
      ? ""
      : `${errorPrefix || "This field"} is required.`,
    state,
  };
};

export const isLengthRequired = (
  value: string,
  minValue: number,
  errorPrefix?: string,
): IValidationReturnType => {
  const isValid = value.trim().length === minValue;
  const state = isValid ? 1 : 0;
  return {
    value,
    isValid,
    validationError: isValid
      ? ""
      : `${errorPrefix || "This field"} must be ${minValue} digits.`,
    state,
  };
};

export const isAdult = (dateTime: string): IValidationReturnType => {
  const age = TimeUtils.now().diff(dateTime, "years");

  if (age < 18) {
    return {
      value: dateTime,
      isValid: false,
      validationError:
        "You must be at least 18 years of age in order to proceed.",
      state: 0,
    };
  }
  return { isValid: true, value: dateTime, validationError: "", state: 1 };
};

export const isDateTimeValid = (dateTime: string): IValidationReturnType => {
  const isValid = dayjs(dateTime).isValid();
  const age = TimeUtils.now().diff(dateTime, "years");
  const isOverMaxAge = age > 120;

  const state = isValid && !isOverMaxAge ? 1 : 0;
  console.log("isDateTimeValid -> state", state);

  return {
    value: dateTime,
    isValid: state === 1,
    validationError: state ? "" : "Invalid date.",
    state,
  };
};

export const isBetweenLength = (
  value: string,
  min: number,
  max: number,
  errorPrefix?: string,
): IValidationReturnType => {
  const isValid = value.length >= min && value.length <= max;
  const state = isValid ? 1 : 0;
  return {
    value,
    isValid,
    validationError: isValid
      ? ""
      : `${
          errorPrefix || "This field"
        } must be between ${min} and ${max} characters.`,
    state,
  };
};

export const usernameSearchPattern = /^[a-zA-Z0-9_\-–]{0,32}$/;

export const isValidUsernamePattern = (value: string) =>
  validateStringPattern(
    usernameSearchPattern,
    value,
    "This field can contain '_', '-', numbers and letters only.",
  );

const validateStringPattern = (
  pattern: RegExp,
  value: string,
  errorPrefix?: string,
): IValidationReturnType => {
  const isTrimmed = !value.startsWith(" ") && !value.endsWith(" ");
  const isValid = isTrimmed && pattern.test(value.replace(/ /g, ""));
  const state = isValid ? 1 : 0;
  return {
    value,
    isValid,
    validationError: isValid
      ? ""
      : errorPrefix || "This field can contain letters only.",
    state,
  };
};

export const containsLettersNumbersSpecial = (
  value: string,
  errorPrefix?: string,
): IValidationReturnType => {
  const lettersNumbersSpecialRegex = /^[a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]+$/;
  const isTrimmed = !value.startsWith(" ") && !value.endsWith(" ");
  const isValid =
    isTrimmed && lettersNumbersSpecialRegex.test(value.replace(/ /g, ""));
  const state = isValid ? 1 : 0;
  return {
    value,
    isValid,
    validationError: isValid
      ? ""
      : `${
          errorPrefix || "This field"
        } may consist of letters, numbers or standard QUERTY keyboard characters.`,
    state,
  };
};

export const isValidEmail = (value: string): IValidationReturnType => {
  const fluentEmail =
    // eslint-disable-next-line no-control-regex
    /^((([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-zA-Z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/g;
  const emailRegex = new RegExp(fluentEmail);
  const isTrimmed = !value.startsWith(" ") && !value.endsWith(" ");
  const isValid = isTrimmed && emailRegex.test(value);
  const state = isValid ? 1 : 0;
  return {
    value,
    isValid,
    validationError: isValid ? "" : "Invalid email address.",
    state,
  };
};

export const compareNewPassword = (
  oldPassword: string,
  newPassword: string,
): IValidationReturnType => {
  const isValid = oldPassword !== newPassword;
  const state = isValid ? 1 : 0;
  return {
    value: newPassword,
    isValid,
    validationError: isValid
      ? ""
      : "New password must be different than the old one.",
    state,
  };
};

export const compareRepeatPassword = (
  newPassword: string,
  repeatPassword: string,
): IValidationReturnType => {
  const isValid = newPassword === repeatPassword;
  const state = isValid ? 1 : 0;
  return {
    value: repeatPassword,
    isValid,
    validationError: isValid
      ? ""
      : "Repeat password must be exactly like the new one.",
    state,
  };
};

export const withdrawAmountBiggerBalance = (
  amount: number,
  balance: number,
): IValidationReturnType => {
  const isValid = balance >= amount;
  const state = isValid ? 1 : 0;
  return {
    value: `${amount}`,
    isValid,
    validationError: isValid
      ? ""
      : "You don't have enough cash to prize redemption",
    state,
  };
};

export const isWithdrawAmountInProviderRange = (
  amount: number,
  provider: TWithdrawProviders,
): IValidationReturnType => {
  if (provider) {
    const state = staticGetState();
    const rangesByProvider =
      CoreSelectors.Withdrawal.withdrawalLimitsConfig(state);

    const { max, min } = rangesByProvider[provider];
    if (amount > max || amount < min) {
      return {
        isValid: false,
        value: `${amount}`,
        validationError: `Minimum $${min}, Maximum $${max}`,
        state: 0,
      };
    }
  }
  return { isValid: true, value: `${amount}`, validationError: "", state: 1 };
};

export const alphaNumericalPattern = /[^a-z0-9]/gi;

export const isStringAlphaNumerical = (value: string): boolean =>
  alphaNumericalPattern.test(value);

export const isStringNumerical = (value: string): IValidationReturnType => {
  const newValue = value.trim();
  const isValid = !numericalPattern.test(newValue);
  const state = isValid ? 1 : 0;

  return {
    isValid,
    state,
    value: newValue,
    validationError: isValid ? "" : "Field can contain numbers only",
  };
};

export const alphaNumericReplacement = (value: string): string =>
  value.replace(alphaNumericalPattern, "");

const numericalPattern = /[^0-9]/gm;

export const numericalReplacement = (input: string): string => {
  if (typeof input !== "string") {
    return "";
  }
  return input.replace(numericalPattern, "");
};
