import axios, { AxiosInstance } from "axios";
import { FliffException } from "server/legacyCore/FliffException";
import {
  IFliffProtocolRequest,
  IFliffProtocolResponse,
  IFliffRequest,
  IFliffResponse,
} from "server/sharedCore/data/objects";
import { AdapterFliffProtocolResponse } from "server/sharedCore/data/serverAdapters";
import { IInternalNetworkConnector } from "server/sharedCore/interfaces";
import { IFSMProtocolResponseSlots } from "server/social/data/objects";
import { MessageDecoder } from "server/social/server/impl/MessageDecoder";
import { AdapterFSMProtocolResponseSlots } from "server/social/server/impl/serverAdapters";
import { AppUtils } from "utils/AppUtils";
import { AppProfileUtils } from "utils/AppProfileUtils";
import { TimeUtils } from "utils/TimeUtils";

export class InternalNetworkConnectorImpl
  implements
    IInternalNetworkConnector<
      IFSMProtocolResponseSlots,
      IFliffRequest,
      IFliffResponse
    > {
  private static _CachedAxiosInstance: AxiosInstance;
  private readonly _retryErrorCode = 40791 as const;
  private _requestCounter = 0;

  private static get _axiosInstance() {
    if (InternalNetworkConnectorImpl._CachedAxiosInstance) {
      return InternalNetworkConnectorImpl._CachedAxiosInstance;
    }

    InternalNetworkConnectorImpl._CachedAxiosInstance = axios.create({
      baseURL: AppProfileUtils.socialServerBaseUrl,
      timeout: 20000,
    });

    return InternalNetworkConnectorImpl._CachedAxiosInstance;
  }

  public async sendProtocolRequest<
    Request extends IFliffRequest,
    ProtocolRequest extends IFliffProtocolRequest<Request>,
    Response extends IFliffResponse,
  >(
    inputRequest: ProtocolRequest,
  ): Promise<IFliffProtocolResponse<IFSMProtocolResponseSlots, Response>> {
    this._incrementRequestCounter();
    const request = {
      ...inputRequest,
      header: { ...inputRequest.header, conn_id: this._requestCounter },
    };
    const serializedRequestData = JSON.stringify(request);

    if (AppProfileUtils.isSocialNasty) {
      await this._injectNastyMode();
    }
    if (AppProfileUtils.injectedDelayToAllServers !== 0) {
      await this._injectNetworkDelay(AppProfileUtils.injectedDelayToAllServers);
    }

    const rawResp = await InternalNetworkConnectorImpl._axiosInstance.request({
      headers: { "Content-Type": "application/json" },
      url: "/mobile_api",
      method: "POST",
      data: serializedRequestData,
      transformResponse: [],
      params: request.header,
    });

    const response = AdapterFliffProtocolResponse.decode<
      IFSMProtocolResponseSlots,
      Response
    >(
      JSON.parse(rawResp.data),
      "protocol_response",
      AdapterFSMProtocolResponseSlots.decode,
      MessageDecoder.decodeMessage,
    );
    const error = response.result.error;
    if (error) {
      const incidentTagLength = error.incidentTag?.length || 0;
      if (error.errorCode === this._retryErrorCode && incidentTagLength > 0) {
        const nextRequest = {
          ...request,
          header: { ...request.header, incident_tag: error.incidentTag },
        };

        return await this.sendProtocolRequest(nextRequest);
      }
    }

    return response;
  }

  private _incrementRequestCounter() {
    this._requestCounter += 1;
  }

  private async _injectNetworkDelay(delayInMs: number): Promise<void> {
    await AppUtils.sleep(delayInMs);
  }

  private async _injectNastyMode(): Promise<void> {
    const injectNetworkDelayInMillis = TimeUtils.fromSecondsToMills(
      AppUtils.randomInt(0, 10),
    );
    const injectNetworkError = AppUtils.randomInt(0, 5) > 3;

    if (injectNetworkDelayInMillis > 0) {
      await AppUtils.sleep(injectNetworkDelayInMillis / 2);
    }

    if (injectNetworkError) {
      throw new FliffException(
        FliffException.ERROR_1009__DUMMY_NETWORK_ERROR,
        "[Social Nasty Mode Exception]",
      );
    }

    if (injectNetworkDelayInMillis > 0) {
      await AppUtils.sleep(injectNetworkDelayInMillis / 2);
    }
  }
}
