export class BaseUIAmountsFormatters {
  private static readonly _thousandSeparatorsPattern = /(-?\d+)(\d{3})/;
  private static readonly _Instance = new BaseUIAmountsFormatters();
  protected readonly shortenAmountLookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  private readonly _shortAmountPattern = /\.0+$|(\.[0-9]*[1-9])0+$/;

  public static withThousandsSeparatorsOneThousandLimit = (
    res: string,
  ): string => {
    const isOverOneThousand = parseInt(res) >= 1000;
    let newRes = isOverOneThousand
      ? String(parseInt("" + parseInt(res) / 1000))
      : res;
    const replacementValue = isOverOneThousand ? "$1.$2" : "$1,$2";

    while (this._thousandSeparatorsPattern.test(newRes)) {
      newRes = newRes.replace(
        this._thousandSeparatorsPattern,
        replacementValue,
      );
    }
    return isOverOneThousand ? `${newRes}K` : newRes;
  };

  public static withThousandsSeparators = (res: string): string => {
    return BaseUIAmountsFormatters._Instance.withThousandSeparatorsMillionLimited(
      res,
    );
  };

  /*
    format dollar amount
    input: 1231212321
    output: 12,312,123 (.21 not included)
*/
  protected amountInCentsToRoundedDollarAmountAsString(
    amountInCents: number,
  ): string {
    const res = Number(amountInCents / 100).toFixed(0);
    return this.withThousandSeparatorsMillionLimited(res);
  }

  protected prepareCentsInDollarsImpl = (amountInCents: number): number => {
    return Math.round((amountInCents / 100 + Number.EPSILON) * 100) / 100;
  };

  protected withThousandSeparatorsMillionLimited = (res: string): string => {
    const isOverTenMil = parseInt(res) >= 10000000;
    const isOverBillion = parseInt(res) >= 1000000000;

    const separatedValue = this._getSplitValue(
      res,
      isOverTenMil || isOverBillion,
      isOverBillion ? 1000000 : 1000,
    );

    return isOverTenMil
      ? `${separatedValue}${isOverBillion ? "B" : "M"}`
      : separatedValue;
  };

  protected withThousandSeparators = (res: string): string => {
    return this._getSplitValue(res, false);
  };

  protected getShorteningAmountPattern = (amount: number) =>
    this.shortenAmountLookup
      .slice()
      .reverse()
      .find(item => amount >= item.value);

  protected replaceShortenAmountPattern = (
    stringAmount: string,
    symbol: string,
  ) => stringAmount.replace(this._shortAmountPattern, "$1") + symbol;

  private _getSplitValue = (
    res: string,
    isOverLimit: boolean,
    divider = 1000,
  ): string => {
    let newRes = isOverLimit
      ? String(parseInt("" + parseInt(res) / divider))
      : res;
    const replacementValue = isOverLimit ? "$1.$2" : "$1,$2";

    while (BaseUIAmountsFormatters._thousandSeparatorsPattern.test(newRes)) {
      newRes = newRes.replace(
        BaseUIAmountsFormatters._thousandSeparatorsPattern,
        replacementValue,
      );
    }

    return newRes;
  };
}
