import { createContext, useContext } from "react";

import { IMakePaymentContextProps } from "./MakePaymentContext.props";

const MakePaymentContext = createContext<IMakePaymentContextProps>({
  handleSelectBankAccount(): () => void {
    return () => ({});
  },
  handleDeleteBankAccount: () => () => {
    return {};
  },

  handlePaymentTypeSubmit: () => ({}),
  resetContextDetails: () => ({}),
  selectedBankAccount: null,
  paymentURL: null,
});

export const useMakePayment = (): IMakePaymentContextProps =>
  useContext<IMakePaymentContextProps>(MakePaymentContext);

export default MakePaymentContext;
