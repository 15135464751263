export class MemoryStorage {
  // 2021-03-28 / Ivan / enable support for more frequent polling during 'poll for purchase progress'
  public static local_poll_interval_profile_during_monitor_purchase_progress = 0;

  // 2021-01-02 / Ivan / add support for server controlled polling intervals
  public static config__20021__poll_interval_profile_after_ok = 10000;
  public static config__20022__poll_interval_profile_after_error = 20000;
  public static config__20031__poll_interval_public_prematch_after_ok = 30000;
  public static config__20032__poll_interval_public_prematch_after_error = 3000;
  public static config__20041__poll_interval_public_inplay_after_ok = 1000;
  public static config__20042__poll_interval_public_inplay_after_error = 3000;
  // 2021-08-08 / Ivan / introduce basic protection from double click - allow to ignore requests if blocking operation is in progress
  public static is_blocking_operation_in_progress = false;
  // 2020-06-11 / Ivan / quick and ugly hack in order to enable background refresh only after user opens homeScreen
  public static enableBackgroundRefresh = false;
  public static enableSportsBookMonitor = false;

  // 2020-12-29 / Ivan / experimental solution - lets try to separate static runtime in-memory data in its own class
  public static mem_cache__id_51201: number = 0;
  public static mem_cache__id_51202: number = 0;
  public static mem_cache__id_51203: number = 0;
  public static mem_cache__id_51204: number = 0;
  public static mem_cache__id_51205: number = 0;
  public static mem_cache__id_51206: number = 0;
  public static mem_cache__id_51207: number = 0;
  public static mem_cache__id_51221: number = 0;
  public static mem_cache__id_51231: number = 0;
  public static mem_cache__id_51232: number = 0;
  public static mem_cache__id_51241: number = 0;
  public static mem_cache__id_51250 = 0;
  public static mem_cache__id_51251 = 0;
  public static mem_cache__id_51252 = 0;
  public static mem_cache__id_51253 = 0;
  public static mem_cache__id_51254 = 0;
  public static mem_cache__id_51255 = 0;
  private static _focusedChannelId = 0;
  private static _focusedConflictFkey = "";
  private static _focusedPlayerFkey = "";

  public static get focusedChannelId(): number {
    return MemoryStorage._focusedChannelId;
  }
  public static get focusedConflictFkey(): string {
    return MemoryStorage._focusedConflictFkey;
  }

  public static get focusedPlayerFkey(): string {
    return MemoryStorage._focusedPlayerFkey;
  }

  public static setFocusedPlayerFkey(playerFkey: string): void {
    MemoryStorage._focusedPlayerFkey = playerFkey;
  }

  public static setFocusedChannelId(channelId: number): void {
    MemoryStorage._focusedChannelId = channelId;
  }
  public static setFocusedConflictFkey(conflictFkey: string): void {
    MemoryStorage._focusedConflictFkey = conflictFkey;
  }

  public static resetFocusedConflictFkey(): void {
    MemoryStorage._focusedConflictFkey = "";
  }
}
