import { useState, useRef, useEffect } from "react";
import Tippy from "@tippyjs/react";

import "./styles.scss";
import ExclamationMark from "components/atoms/common/ExclamationMark";
import { IProps } from "./types";

const Tooltip = ({ message }: IProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isVisible, setVisibility] = useState(false);

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setVisibility(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("touchend", handleClickOutside, {
        passive: true,
      });
    } else {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [isVisible]);

  const toggleVisibility = () => {
    setVisibility(prevState => !prevState);
  };

  return (
    <div className="input-tooltip-container" ref={tooltipRef}>
      <Tippy content={message}>
        <>
          {isVisible && (
            <div className={`input-tooltip-container__message`}>{message}</div>
          )}
          <div onClick={toggleVisibility}>
            <ExclamationMark />
          </div>
        </>
      </Tippy>
    </div>
  );
};

export default Tooltip;
