import { Dispatch, SetStateAction, useState } from "react";
import { IValidationReturnType } from "utils/FormValidationUtils";

const useTextInput = <T extends string = string>(
  inputValue: T,
  appliedValidation: () => IValidationReturnType<T>,
): [
  IValidationReturnType<T>,
  (input: T) => void,
  () => IValidationReturnType<T>,
  Dispatch<SetStateAction<IValidationReturnType<T>>>,
] => {
  const [validationState, setValidationState] = useState<
    IValidationReturnType<T>
  >({
    value: inputValue,
    isValid: true,
    validationError: "",
    state: -1,
  });

  const handleInputChange = (input: T): void =>
    setValidationState(prev => ({ ...prev, value: input }));

  const handleInputBlur = (): IValidationReturnType<T> => {
    const appliedValidationResponse = appliedValidation();
    setValidationState(appliedValidationResponse);

    return appliedValidationResponse;
  };

  return [
    validationState,
    handleInputChange,
    handleInputBlur,
    setValidationState,
  ];
};

export default useTextInput;
