import { useEffect } from "react";
import { APP_PROFILE_PROD } from "utils/AppProfileUtils";

export const VerifiedOriginsEnum = {
  DEV_PUBLIC: "https://dev.public.getfliff.com",
  PROD: APP_PROFILE_PROD.base_url,
  CURRENT_ENV: window.location.origin,
} as const;

const VERIFIED_ORIGINS = [
  VerifiedOriginsEnum.DEV_PUBLIC,
  VerifiedOriginsEnum.PROD,
] as const;

const usePostMessage = <T>(
  onMessageSuccess: (data: T) => void,
  expectedOrigin: string,
) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent<T>) => {
      if (VERIFIED_ORIGINS.indexOf(event.origin) === -1) {
        return;
      }
      if (event.origin !== expectedOrigin) {
        return;
      }
      onMessageSuccess(event.data);
    };
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [onMessageSuccess, expectedOrigin]);
};

export default usePostMessage;
