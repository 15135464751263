import { FliffException } from "server/legacyCore/FliffException";

import { ErrorHelper } from "server/legacyCore/ErrorHelper";
import {
  IFliffProtocolResponse,
  IFliffRequest,
  IFliffResponse,
  TFliffResult,
} from "server/sharedCore/data/objects";
import { IFSMProtocolResponseSlots } from "server/social/data/objects";
import {
  IInternalNetworkConnector,
  IInternalProtocolRequestBuilder,
  IInternalProtocolResponseProcessor,
} from "server/sharedCore/interfaces";
import { TAnyAlias } from "src/types";

// wrapper over basic primitives, exposes functionality for normal usage
export class SocialApiCoreImpl {
  private _ProtocolRequestBuilder: IInternalProtocolRequestBuilder<IFliffRequest>;
  private _ResponseProcessor: IInternalProtocolResponseProcessor<
    IFSMProtocolResponseSlots,
    IFliffResponse
  >;
  private _NetworkConnector: IInternalNetworkConnector<
    IFSMProtocolResponseSlots,
    IFliffRequest,
    IFliffResponse
  >;

  constructor(
    protocolRequestBuilder: IInternalProtocolRequestBuilder<IFliffRequest>,
    responseProcessor: IInternalProtocolResponseProcessor<
      IFSMProtocolResponseSlots,
      IFliffResponse
    >,
    networkConnector: IInternalNetworkConnector<
      IFSMProtocolResponseSlots,
      IFliffRequest,
      IFliffResponse
    >,
  ) {
    this._ProtocolRequestBuilder = protocolRequestBuilder;
    this._ResponseProcessor = responseProcessor;
    this._NetworkConnector = networkConnector;
  }

  // basic api call - accepts 'request message', return valid 'response message' or throws FliffException
  public executeRequest = async <
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(
    request: Request,
  ): Promise<Response> => {
    const protocolResponse = await this._executeProtocolRequestImpl<
      Request,
      Response
    >(request, null);

    const result = protocolResponse.result;

    if (result.error) {
      throw new FliffException(
        result.error.errorCode,
        result.error.errorMessage,
      );
    }

    return result.response;
  };

  // wraps 'response message' or FliffException into FSM__Result
  public safeExecuteRequest = async <
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(
    request: Request,
  ): Promise<TFliffResult<Response>> => {
    try {
      const protocolResponse = await this._executeProtocolRequestImpl<
        Request,
        Response
      >(request, null);
      return protocolResponse.result;
    } catch (error) {
      // build error holder for any exception
      const ex = this.fliffExceptionFromAnyError(error);
      return {
        error: {
          errorCode: ex.error_code,
          errorMessage: ex.error_message,
          errorSource: ex.debug_error_source,
          incidentTag: "",
        },
        response: null,
      };
    }
  };

  public fliffExceptionFromAnyError = (error: TAnyAlias): FliffException => {
    if (error instanceof FliffException) {
      return error;
    }

    console.log("unexpected exception - error:", error);

    // should not happen normally
    // may want to add some additional info
    return new FliffException(
      FliffException.ERROR_9999__INTERNAL_ERROR,
      "INTERNAL_ERROR - unexpected exception",
    );
  };

  // main internal logic: build meta, execute, import slots
  private _executeProtocolRequestImpl = async <
    Request extends IFliffRequest,
    Response extends IFliffResponse,
  >(
    request: Request,
    xRequests: IFliffRequest[] | null,
  ): Promise<IFliffProtocolResponse<IFSMProtocolResponseSlots, Response>> => {
    try {
      const protocolRequest = this._ProtocolRequestBuilder.buildProtocolRequest(
        request,
        xRequests,
      );
      const protocolResponse = await this._NetworkConnector.sendProtocolRequest(
        protocolRequest,
      );
      if (protocolResponse.x_slots !== null) {
        this._ResponseProcessor.processProtocolResponseSlots(protocolResponse);
      }
      return protocolResponse as IFliffProtocolResponse<
        IFSMProtocolResponseSlots,
        Response
      >;
    } catch (error) {
      // 2023-04-27 / Ivan / introduce standard conversion to FliffException for all network calls
      console.log("error:", error);
      const exerror = ErrorHelper.apiErrorToDDException(error);
      console.log("exerror:", exerror);
      exerror.debug_error_source = "error in server_social/ApiBase";
      throw exerror;
    }
  };
}
