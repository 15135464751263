import { forwardRef, useState } from "react";

import "./styles.scss";

import SkipValidations, {
  IProps,
} from "components/atoms_new/shared/Inputs/atoms/SkipValidations";

import { alphaNumericReplacement } from "utils/validators";
import LinkifiedText from "components/atoms_new/shared/LinkifiedText";

const CouponCodeText = forwardRef<
  HTMLInputElement,
  Omit<IProps, "placeholder">
>((props, ref) => {
  const [isInputVisible, setIsInputVisible] = useState(false);

  const handleTextChange = (value: string) => {
    if (props.onChangeText) {
      props.onChangeText(alphaNumericReplacement(value));
      return;
    }
    console.warn("onChangeText is missing.");
  };
  const handleShowPress = () => {
    setIsInputVisible(true);
  };

  if (!isInputVisible) {
    return (
      <div className="referrer-code">
        <span className="referrer-code__text">
          <LinkifiedText
            text={"Have a Referrer Code? <link> Enter</link>"}
            handlers={[handleShowPress]}
          />
        </span>
      </div>
    );
  }

  return (
    <div className={"referrer-code"}>
      <p className="referrer-code__text">Enter Referrer Code:</p>
      <SkipValidations
        ref={ref}
        {...props}
        autoCapitalize={"none"}
        onChangeText={handleTextChange}
        maxLength={20}
        placeholder={"Referrer Code (Optional)"}
        autoFocus
      />
    </div>
  );
});

CouponCodeText.displayName = "CouponCodeText";

export default CouponCodeText;
