export const USA_STATES = [
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Hawaii",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Missouri",
  "Montana",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
] as const;

const RESTRICTED_USA_STATES = [
  "Alabama",
  "Georgia",
  "Minnesota",
  "Mississippi",
  "South Carolina",
  "Nevada",
  "Idaho",
  "Hawaii",
  "Tennessee",
]
  .slice()
  .sort((a, b) => +(a > b) || -(a < b));

const DISABLED_STATES = ["Washington"];

export const ALL_UNAVAILABLE_STATES = [
  ...RESTRICTED_USA_STATES,
  ...DISABLED_STATES,
]
  .slice()
  .sort((a, b) => +(a > b) || -(a < b));
