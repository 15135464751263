import { IProps } from "./types";
import "./styles.scss";
import PromoCoins from "assets/img/promo/promo-coins.png";
import PromoCash from "assets/img/promo/promo-cash.png";
import ChestIcon from "assets/img/coinsBundles/coin_bundles__bag_skin_106.png";
import ShadowImg from "assets/img/rpm/shadow.png";
import PromoBackground from "assets/json/promoBackground.json";
import Lottie from "lottie-react";
import classNames from "classnames";

const SignUpPromoInfo = ({ isImageShown, className }: IProps) => {
  const classes = classNames("sign-up-promo", className);
  return (
    <div className={classes}>
      <Lottie
        autoplay
        loop
        animationData={PromoBackground}
        className="sign-up-promo__lottie"
      />
      <div className="sign-up-promo__content-wrapper">
        <h3 className="sign-up-promo__title">YOU’VE UNLOCKED A 5X BONUS</h3>
        <p className="sign-up-promo__promo-text">
          You are set to receive a
          <span className="sign-up-promo__5x">{" 5X "}</span>
          <span className="sign-up-promo__coins-text">
            {" Fliff Coin bonus "}
            <img src={PromoCoins} alt="promo-coins" />
          </span>
          {" and "}
          <span className="sign-up-promo__cash-text">
            {"FREE Fliff Cash "}
            <img src={PromoCash} alt="promo-cash" />
          </span>
          with your first purchase.
        </p>
      </div>

      {isImageShown && (
        <div className="sign-up-promo__animated-chest">
          <img
            src={ChestIcon}
            alt="animated-chest"
            className="sign-up-promo__chest-img"
          />
          <img
            className="sign-up-promo__shadow"
            src={ShadowImg}
            alt="chest-shadow"
          />
        </div>
      )}
    </div>
  );
};

export default SignUpPromoInfo;
