import {
  IFliffRequest,
  IFliffResponse,
  TFliffResult,
} from "server/sharedCore/data/objects";

import AppConfig from "utils/AppConfig";
import { FliffException } from "server/legacyCore/FliffException";
import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalDialogAction,
  AppUIShowModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";

export default class ApiUIHandler {
  protected safeBlockingTransparentNoErrorModalProgressBarRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<TFliffResult<InputResponse>>) {
    return async function (
      request: InputRequest,
    ): Promise<TFliffResult<InputResponse>> {
      try {
        AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
          AppConfig.modalProgressBarTransparentMode,
        );
        const result = await input(request);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return result;
      } catch (error) {
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        if (!(error instanceof FliffException)) {
          console.warn(
            "unexpected error caught ApiUIHandler.blockingDefaultProgressBarRequest",
            "error.message",
          );
        }
        // Expected to have here only instances of FliffException.
        throw error;
      }
    };
  }

  protected blockingDefaultProgressBarRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<InputResponse>) {
    return async function (request: InputRequest): Promise<InputResponse> {
      try {
        AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
          AppConfig.modalProgressBarDefaultMode,
        );
        const result = await input(request);
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        return result;
      } catch (error) {
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
        AppUIShowModalDialogAction.dispatchShowErrorDialogForException(
          error,
          request.__object_class_name,
        );
        if (!(error instanceof FliffException)) {
          console.warn(
            "unexpected error caught ApiUIHandler.blockingDefaultProgressBarRequest",
            "error.message",
          );
        }
        // Expected to have here only instances of FliffException.
        throw error;
      }
    };
  }

  protected safeBlockingDefaultProgressBarRequest<
    InputRequest extends IFliffRequest,
    InputResponse extends IFliffResponse,
  >(input: (request: InputRequest) => Promise<TFliffResult<InputResponse>>) {
    return async function (
      request: InputRequest,
    ): Promise<TFliffResult<InputResponse>> {
      AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
        AppConfig.modalProgressBarDefaultMode,
      );
      const result = await input(request);
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
      if (result.error) {
        AppUIShowModalDialogAction.dispatchShowErrorDialogForException(
          new FliffException(
            result.error.errorCode,
            result.error.errorMessage,
            result.error.errorSource,
          ),
          request.__object_class_name,
        );
      }
      return result;
    };
  }
}
