import { PublicCoreApi } from "server/core/server/CoreApi";
import { LogUploaderPersistentStorage } from "utils/Logger/LogUploaderStorage";

export class LogUploader {
  private static readonly _UploaderStorage = new LogUploaderPersistentStorage();
  private static _isFlushingLog = false;
  private static _scheduledReFlushLogTimeout: NodeJS.Timeout | number = 0;

  public static async init() {
    this._UploaderStorage.init();
    await LogUploader._flushLog();
  }

  public static logg(message: string): void {
    LogUploader._UploaderStorage.append(message);
    // got new message - start flushing
    LogUploader._flushLog();
  }

  /*
  flushLog will try to upload some messages
  in case of success
  * it will remove them from queue
  * it will continue working on the queue
  in case of error
  * it will set a timeout and try to flush again later
  */

  private static async _flushLog() {
    // we are already flushing previous logSnippet
    if (LogUploader._isFlushingLog) {
      return;
    }

    if (LogUploader._scheduledReFlushLogTimeout !== 0) {
      clearTimeout(LogUploader._scheduledReFlushLogTimeout);
      LogUploader._scheduledReFlushLogTimeout = 0;
    }

    LogUploader._isFlushingLog = true;

    const gotError = await LogUploader._flushLogLoop();

    LogUploader._isFlushingLog = false;

    if (gotError) {
      // in case of error, schedule another flush log after 20 seconds
      LogUploader._scheduledReFlushLogTimeout = setTimeout(_ => {
        LogUploader._flushLog();
      }, 20000);
    }
  }

  private static async _flushLogLoop(): Promise<boolean> {
    // try to upload until we have zero pending messages or upload error
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const [count, logSnippet] =
        LogUploader._UploaderStorage.getNextSnippetForUpload();

      // nothing to upload
      if (count === 0) {
        return false;
      }

      const gotError = await LogUploader._doSingleUpload(logSnippet);

      // got an error - exit the loop
      if (gotError) {
        return true;
      }

      // upload ok - remove the uploaded messages
      LogUploader._UploaderStorage.removeOldest(count);
    }
  }

  private static async _doSingleUpload(logSnippet: string): Promise<boolean> {
    const { error } = await PublicCoreApi.safeBackgroundUploadMobileLog(
      logSnippet,
    );

    return !!error;
  }
}
