// In general, League === Channel. Used such separation to distinguish minor/major CHANNEL leagues.
// (i.e. Basketball channel contains NBA league). ATM used only inside All Sports Menu screen.

import { ChannelSkins, ChannelType } from "server/legacyCore/data/constants";
import {
  Data__SB_Channel,
  Data__SB_SportEvent,
} from "server/legacyCore/data/objects";
import { ISportsMenuItem, IStringMap, TSportsMenuItemsMap } from "../types";

import { PersistentStorage } from "utils/PersistentStorage";
import colors from "assets/styles/design-tokens/colors.module.scss";
import Icon from "components/atoms/common/Icon";

class ChannelUtils {
  private readonly _oneDayStamp = 8.64e7 as const;
  private readonly _ChannelsSkinsMap = {
    [ChannelSkins.SB_CHANNEL_ICON_7300_HOME]: {
      name: "Home",
      iconName: "home",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7301_BASKETBALL]: {
      name: "Basketball",
      iconName: "basketball",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7302_FOOTBALL]: {
      name: "Football",
      iconName: "nflball",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7303_HOCKEY]: {
      name: "Hockey",
      iconName: "nhlball",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7304_SOCCER]: {
      name: "Soccer",
      iconName: "soccer",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7305_BASEBALL]: {
      name: "Baseball",
      iconName: "baseball",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7306_MMA]: {
      name: "MMA",
      iconName: "mma",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7307_TENNIS]: {
      name: "Tennis",
      iconName: "tennis",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7308_BOXING]: {
      name: "Boxing",
      iconName: "boxing",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7341_OLYMPICS]: {
      name: "Olympics",
      iconName: "olympics",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7309_ESPORTS]: {
      name: "eSports",
      iconName: "gamepad",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7310_GOLF]: {
      name: "Golf",
      iconName: "golf",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7389_LIVE]: {
      name: "Live",
      iconName: "live",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7390_OTHER_1]: {
      name: "Other",
      iconName: "gamepad",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7386_SUPER_BOWL]: {
      name: "Super Bowl",
      iconName: "superbowl",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7391_MARCH_MADNESS]: {
      name: "March Madness",
      iconName: "basketball",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7387_BOOSTED_POKER]: {
      name: "Poker",
      iconName: "poker",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7392_POKER]: {
      name: "Poker",
      iconName: "poker",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7388_BOOSTED]: {
      name: "Boosted",
      iconName: "rocket",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7311_HORSE_RACING]: {
      name: "Horse Racing",
      iconName: "horseracing",
    },
    [ChannelSkins.SB_CHANNEL_ICON_7312_LACROSSE]: {
      name: "Lacrosse",
      iconName: "lacrosse",
    },
    [-1]: {
      name: "Unknown",
      iconName: "basketball",
    },
  } as const;

  private readonly _channelNamesWithSummaryTab = [
    "NFL",
    "MLB",
    "NCAAF",
    "NCAAB",
    "NHL",
    "NBA",
  ];

  public getAllSportsPreMatchRecentLeagues = (
    channels: Data__SB_Channel[],
    hiddenLeaguesStampsMap: IStringMap<number>,
  ) => {
    const now = Date.now();

    return channels.filter(channel => {
      const lastLeagueStamp = hiddenLeaguesStampsMap[channel.id];

      if (lastLeagueStamp === 0) {
        return true;
      }
      if (lastLeagueStamp === -1) {
        return false;
      }

      return now - lastLeagueStamp < this._oneDayStamp;
    });
  };

  public extractMajorLeaguesEventsCount = (
    leagueConfig: ISportsMenuItem,
  ): number[] =>
    leagueConfig.data.reduce<number[]>((acc, curr, index) => {
      if (curr.all_sports_level === 1) {
        acc.push(leagueConfig.eventsCountPerLeague[index]);
      }
      return acc;
    }, []);

  public getMajorLeaguesCount = (
    majorLeagues: Data__SB_Channel[],
    { data, eventsCountPerLeague }: ISportsMenuItem,
  ): number =>
    majorLeagues.reduce<number>((acc, curr) => {
      const count =
        eventsCountPerLeague[data.findIndex(channel => channel.id === curr.id)];
      if (count) {
        acc += count;
      }

      return acc;
    }, 0);

  public extractMinorLeagues = (
    channels: Data__SB_Channel[],
  ): Data__SB_Channel[] => channels.filter(this.isLeagueMinor);

  public extractMajorLeagues = (channels: Data__SB_Channel[]) =>
    channels.filter(this.isLeagueMajor);

  public isLeagueMajor = (channel: Data__SB_Channel): boolean =>
    channel.all_sports_level === 1;

  public isLeagueMinor = (channel: Data__SB_Channel): boolean =>
    channel.all_sports_level === 2;

  public sortChannelByDashPos = (
    channels: Data__SB_Channel[],
  ): Data__SB_Channel[] => {
    const copy = [...channels];
    copy.sort((a, b) => a.dash_pos - b.dash_pos);
    return copy;
  };

  public sortChannelsByAllSportsPos = (
    channels: Data__SB_Channel[],
    mode: "asc" | "desc",
  ): Data__SB_Channel[] => {
    const copy = [...channels];
    copy.sort((a, b) => {
      if (mode === "asc") {
        return this._allSportsChannelsSortCallback(a, b);
      }
      return this._allSportsChannelsSortCallback(b, a);
    });
    return copy;
  };

  public sortLiveChannels = (
    channels: Data__SB_Channel[],
  ): Data__SB_Channel[] => {
    const copy = [...channels];
    return copy.sort((a, b) => b.inplay_pos - a.inplay_pos);
  };

  public getChannelById = (
    channels: Data__SB_Channel[],
    id: number | null | undefined,
  ): Data__SB_Channel | null => {
    if (!id) {
      return null;
    }
    const channel = channels.find(({ id: channelId }) => channelId === id);
    if (channel) {
      return channel;
    }
    return null;
  };

  public shouldChannelIncludeSummary = (
    channels: Data__SB_Channel[],
    channelId: number,
  ): boolean => {
    const channel = this.getChannelById(channels, channelId);

    if (!channel) {
      return false;
    }

    return this._channelNamesWithSummaryTab.includes(channel.visualName);
  };

  public toPreMatchLeagues = (
    events: Data__SB_SportEvent[],
    channels: Data__SB_Channel[],
  ): ISportsMenuItem[] => {
    const config = this._prepareLeaguesToPreMatchConfig(events, channels);
    return Object.values(config);
  };

  public areChannelIdsIdentical(
    channels1: Data__SB_Channel[],
    channels2: Data__SB_Channel[],
  ): boolean {
    if (channels1.length !== channels2.length) {
      return false;
    }

    const channels1Ids = channels1.map(channel => channel.id);
    const channels2Ids = channels2.map(channel => channel.id);
    return channels1Ids.every(channelId => ~channels2Ids.indexOf(channelId));
  }

  public getIsHomeFirst = (
    channel: Data__SB_Channel | undefined | null,
  ): boolean => {
    if (!channel) {
      return false;
    }
    switch (channel.type) {
      case ChannelType.CONST_990_NONE:
        return false;
      case ChannelType.CONST_991_12_HOME_FIRST:
        return true;
      case ChannelType.CONST_992_12_AWAY_FIRST:
        return false;
      case ChannelType.CONST_993_1_X_2_HOME_FIRST:
        return true;
      case ChannelType.CONST_994_1_X_2_AWAY_FIRST:
        return false;
      default:
        return false;
    }
  };

  public getValidChannelSkinId = (skinId: string) => {
    const channelSkinId: ChannelSkins = +skinId;
    if (!channelSkinId || !this._ChannelsSkinsMap[channelSkinId]) {
      return null;
    }
    return channelSkinId;
  };

  public getChannelGroupNameBySkinId = (
    skinId: ChannelSkins | undefined,
  ): string => this._ChannelsSkinsMap[skinId || -1]?.name || "Unknown";

  public extractChannelIcon(
    skinId: number | undefined,
    inputColor: string = colors.wh,
    size = 13,
  ) {
    const channelSkinId: ChannelSkins = +(skinId || -1);
    const iconName =
      this._ChannelsSkinsMap[channelSkinId]?.iconName || "basketball";

    return (
      <Icon styles={{ fontSize: size, color: inputColor }} name={iconName} />
    );
  }

  public initPremMatchChannels = (
    events: Data__SB_SportEvent[],
    channels: Data__SB_Channel[],
  ) => {
    PersistentStorage.syncAllSportsLeaguesMap(
      this._toPreMatchLeagues(events, channels),
    );

    const allSportsHiddenLeaguesMap =
      PersistentStorage.getAllSportsLeaguesMap()!;

    const filteredChannels = this.getAllSportsPreMatchRecentLeagues(
      channels,
      allSportsHiddenLeaguesMap,
    );

    return this._toPreMatchLeagues(events, filteredChannels);
  };

  public isChannelFromAllSports = (
    allChannels: Data__SB_Channel[],
    feedChannels: Data__SB_Channel[],
    id: number,
  ): boolean => {
    const channelFromAllSports = this.getChannelById(allChannels, id);
    const isChannelExistsInFeedChannel = this.getChannelById(feedChannels, id);

    return !!channelFromAllSports && !isChannelExistsInFeedChannel;
  };

  public getEventTeams = <
    Event extends Pick<Data__SB_SportEvent, "awayTeamName" | "homeTeamName">,
  >(
    event: Event | null,
    channel: Data__SB_Channel | null,
  ) => {
    if (!event) {
      return "Missing event";
    }

    const isHomeFirst = this.getIsHomeFirst(channel);

    return isHomeFirst
      ? `${event.homeTeamName} vs ${event.awayTeamName}`
      : `${event.awayTeamName} vs ${event.homeTeamName}`;
  };

  private _toPreMatchLeagues = (
    events: Data__SB_SportEvent[],
    channels: Data__SB_Channel[],
  ): ISportsMenuItem[] => {
    const config = this._prepareLeaguesToPreMatchConfig(events, channels);
    return Object.values(config);
  };

  private _prepareLeaguesToPreMatchConfig = (
    events: Data__SB_SportEvent[],
    channels: Data__SB_Channel[],
  ): TSportsMenuItemsMap =>
    channels.reduce<TSportsMenuItemsMap>((acc, curr) => {
      const currChannel = acc[curr.skin_id];
      const eventsCountPerChannel = this._getGamesCountForChannel(
        events,
        curr.id,
      );
      if (!currChannel) {
        const initArray = [eventsCountPerChannel];
        acc[curr.skin_id] = {
          data: [curr],
          skinId: curr.skin_id,
          eventsCountPerLeague: initArray,
          totalCount: this._extractTotalCountChannelLeagues(initArray),
        };
        return acc;
      }
      currChannel.data.push(curr);
      currChannel.eventsCountPerLeague.push(eventsCountPerChannel);
      currChannel.totalCount = this._extractTotalCountChannelLeagues(
        currChannel.eventsCountPerLeague,
      );
      return acc;
    }, {});

  private _getGamesCountForChannel = (
    events: Data__SB_SportEvent[],
    channelId: ChannelSkins,
  ): number => events.filter(event => event.channelId === channelId).length;

  private _extractTotalCountChannelLeagues = (values: number[]): number =>
    values.reduce((acc, curr) => (acc += curr), 0);

  private _allSportsChannelsSortCallback = (
    a1: Data__SB_Channel,
    a2: Data__SB_Channel,
  ) => a1.allsports_pos - a2.allsports_pos;
}

export default new ChannelUtils();
