import { staticGetState } from "reduxLocal/store";
import { FliffException } from "server/legacyCore/FliffException";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
import { AdapterHelper } from "server/legacyCore/data/server_adapters";
import {
  validate_TPickCurrency,
  validate_TPickSelectionState,
  validate_TPickType,
} from "server/legacyCore/data/server_enum_helpers";
import { IFliffError, IFliffMessage } from "server/sharedCore/data/objects";
import * as I from "server/social/data/objects";
import {
  validate_SocialAccessLevel,
  validate_SocialPrivacyLevel,
} from "server/social/server/impl/server_enum_helpers";
import { TAnyAlias } from "src/types";
import { AppUtils } from "utils/AppUtils";

export class AdapterFSMDataMediaLink {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataMediaLink {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      link_text: ValidationUtils.assert_non_empty_string(
        jsonData.link_text,
        debugInfo + ".link_text",
      ),
      link_url: ValidationUtils.assert_non_empty_string(
        jsonData.link_url,
        debugInfo + ".link_url",
      ),
      media_logo_url: ValidationUtils.assert_non_empty_string(
        jsonData.media_logo_url,
        debugInfo + ".media_logo_url",
      ),
    };
  }
}

export class AdapterFSMDataPlayerLevelLeaderboardItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataPlayerLevelLeaderboardItem {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assert_non_empty_string(
        jsonData.username,
        debugInfo + ".username",
      ),
      social_amount_won: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.social_amount_won,
        debugInfo + ".social_amount_won",
      ),
      rank: ValidationUtils.assert_any_integer(
        jsonData.rank,
        debugInfo + ".rank",
      ),
      relation: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      upgrading: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.upgrading,
        debugInfo + ".upgrading",
      ),
    };
  }
}

class AdapterPlayerLevelDescription {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevelDescription {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      icon_url: ValidationUtils.assert_non_empty_string(
        jsonData.icon_url,
        debugInfo + ".icon_url",
      ),
      title: ValidationUtils.assert_non_empty_string(
        jsonData.title,
        debugInfo + ".title",
      ),
    };
  }
}

class AdapterUserProfileBadgeBannerMinimized {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IBadgeBannerMinimized {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      text: ValidationUtils.assert_non_empty_string(
        jsonData.text,
        debugInfo + ".text",
      ),
      title: ValidationUtils.assert_non_empty_string(
        jsonData.title,
        debugInfo + ".title",
      ),
    };
  }
}

class AdapterPlayerLevelsBanner {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevelsBanner {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      arena_code: ValidationUtils.assert_non_empty_string(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      maximized_banners: AdapterHelper.decode_array(
        AdapterPlayerLevelDescription.decode,
        jsonData.maximized_banners,
        debugInfo + ".maximized_banners",
      ),
      maximized_text: ValidationUtils.assert_non_empty_string(
        jsonData.maximized_text,
        debugInfo + ".maximized_text",
      ),
      maximized_title: ValidationUtils.assert_non_empty_string(
        jsonData.maximized_title,
        debugInfo + ".maximized_title",
      ),
      minimized_banner: AdapterUserProfileBadgeBannerMinimized.decode(
        jsonData.minimized_banner,
        debugInfo + ".minimized_banner",
      ),
    };
  }
}

export class AdapterFSMGetPlayerLevelLeaderboardResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetPlayerLevelLeaderboardResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Player_Level_Leaderboard_V2__Response",
      arena_code: ValidationUtils.assert_non_empty_string(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      top_users:
        AdapterHelper.decode_array<I.IFSMDataPlayerLevelLeaderboardItem>(
          AdapterFSMDataPlayerLevelLeaderboardItem.decode,
          jsonData.top_users,
          debugInfo + ".top_users",
        ),
      middle_users:
        AdapterHelper.decode_array<I.IFSMDataPlayerLevelLeaderboardItem>(
          AdapterFSMDataPlayerLevelLeaderboardItem.decode,
          jsonData.middle_users,
          debugInfo + ".middle_users",
        ),
      bottom_users:
        AdapterHelper.decode_array<I.IFSMDataPlayerLevelLeaderboardItem>(
          AdapterFSMDataPlayerLevelLeaderboardItem.decode,
          jsonData.bottom_users,
          debugInfo + ".bottom_users",
        ),
      period_meta: AdapterFSMLeaderboardReport.decode(
        jsonData.period_meta,
        debugInfo + ".period_meta",
      ),
      profile: jsonData.profile
        ? AdapterFSMDataUserInfo.decode(
            jsonData.profile,
            debugInfo + ".profile",
          )
        : null,
      current_user: jsonData.current_user
        ? AdapterFSMDataPlayerLevelLeaderboardItem.decode(
            jsonData.current_user,
            debugInfo + ".current_user",
          )
        : null,
      player_level_leaderboard_banner: AdapterPlayerLevelsBanner.decode(
        jsonData.player_level_leaderboard_banner,
        debugInfo + ".player_level_leaderboard_banner",
      ),
    };
  }
}

class AdapterPlayerLevel {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IPlayerLevel {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      level: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.level,
        debugInfo + ".level",
      ),
      name: ValidationUtils.assert_non_empty_string(
        jsonData.name,
        debugInfo + ".name",
      ),
      icon: ValidationUtils.assert_non_empty_string(
        jsonData.icon,
        debugInfo + ".icon",
      ),
    };
  }
}

class AdapterPlayerLevels {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevels {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      previous: jsonData.previous
        ? AdapterPlayerLevel.decode(jsonData.previous, debugInfo + ".previous")
        : null,
      current: AdapterPlayerLevel.decode(
        jsonData.current,
        debugInfo + ".current",
      ),
      next: jsonData.next
        ? AdapterPlayerLevel.decode(jsonData.next, debugInfo + ".next")
        : null,
    };
  }
}

class AdapterPlayerLevelBanner {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayerLevelBanner {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      icon: ValidationUtils.assert_non_empty_string(
        jsonData.icon,
        debugInfo + ".icon",
      ),
      text: ValidationUtils.assert_non_empty_string(
        jsonData.text,
        debugInfo + ".text",
      ),
    };
  }
}

export class AdapterFSMDataUserInfo {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataUserInfo {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      first_name: ValidationUtils.assert_optional_string(
        jsonData.first_name,
        debugInfo + ".first_name",
      ),
      last_name: ValidationUtils.assert_optional_string(
        jsonData.last_name,
        debugInfo + ".last_name",
      ),
      privacy_level: validate_SocialPrivacyLevel(
        jsonData.privacy_level,
        debugInfo + ".privacy_level",
      ),
      user_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assert_non_empty_string(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assert_optional_string(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_avatar_full_body: ValidationUtils.assert_optional_string(
        jsonData.rpm_avatar_full_body,
        debugInfo + ".rpm_avatar_full_body",
      ),
      rpm_bgcolor: ValidationUtils.assert_optional_string(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      count_followers: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.count_followers,
        debugInfo + ".count_followers",
      ),
      count_following: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.count_following,
        debugInfo + ".count_following",
      ),
      count_friends: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.count_friends,
        debugInfo + ".count_friends",
      ),
      relation: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      access_level: validate_SocialAccessLevel(
        jsonData.access_level,
        debugInfo + ".access_level",
      ),
      bio: ValidationUtils.assert_optional_string(
        jsonData.bio,
        debugInfo + ".bio",
      ),
      user_type: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.user_type,
        debugInfo + ".user_type",
      ),
      media_links: AdapterHelper.decode_array<I.IFSMDataMediaLink>(
        AdapterFSMDataMediaLink.decode,
        jsonData.media_links,
        debugInfo + ".media_links",
      ),
      total_picks: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.total_picks,
        debugInfo + ".total_picks",
      ),
      high_stakes_picks: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.high_stakes_picks,
        debugInfo + ".high_stakes_picks",
      ),
      global_rank: ValidationUtils.assert_any_integer(
        jsonData.global_rank,
        debugInfo + ".global_rank",
      ),
      banner: jsonData.banner
        ? AdapterPlayerLevelBanner.decode(
            jsonData.banner,
            debugInfo + ".banner",
          )
        : null,
      player_levels: AdapterPlayerLevels.decode(
        jsonData.player_levels,
        debugInfo + ".player_levels",
      ),
    };
  }
}

export class AdapterFSMStatsResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IStatsResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      social_amount_won: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.social_amount_won,
        debugInfo + ".social_amount_won",
      ),
      global_rank: ValidationUtils.assert_any_integer(
        jsonData.global_rank,
        debugInfo + ".global_rank",
      ),
      regional_rank: ValidationUtils.assert_any_integer(
        jsonData.regional_rank,
        debugInfo + ".regional_rank",
      ),
      week_no: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.week_no,
        debugInfo + ".week_no",
      ),
      social_count_picks_high_stake:
        ValidationUtils.assert_positive_integer_or_zero(
          jsonData.social_count_picks_high_stake,
          debugInfo + ".social_count_picks_high_stake",
        ),
      social_count_picks_lost: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.social_count_picks_lost,
        debugInfo + ".social_count_picks_lost",
      ),
      social_count_picks_total: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.social_count_picks_total,
        debugInfo + ".social_count_picks_total",
      ),
      user_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
    };
  }
}

class AdapterUserProfileStatsResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["stats_response"] {
    ValidationUtils.assert_non_null_data(
      jsonData,
      debugInfo + ".stats_response",
    );

    return {
      stats_week_0: AdapterFSMStatsResponse.decode(
        jsonData.stats_week_0,
        debugInfo + ".stats_week_0",
      ),
      stats_week_m1: AdapterFSMStatsResponse.decode(
        jsonData.stats_week_m1,
        debugInfo + ".stats_week_m1",
      ),
    };
  }
}

class AdapterUserProfileFeedResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["feed_response"] {
    ValidationUtils.assert_non_null_data(
      jsonData,
      debugInfo + ".feed_response",
    );

    return {
      data: AdapterHelper.decode_array<I.IFSMDataActivityFeedItem>(
        AdapterFSMFSMobileDataActivityFeedItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
      merge_code: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.merge_code,
        debugInfo + ".merge_code",
      ),
      arena_code: ValidationUtils.assert_non_empty_string(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
    };
  }
}

class AdapterUserProfileFollowingResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["following"] {
    if (!jsonData) {
      return { users: [] };
    }
    return {
      users: AdapterHelper.null_or_decode_array<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

class AdapterUserProfileFollowersResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["followers"] {
    if (!jsonData) {
      return { users: [] };
    }

    return {
      users: AdapterHelper.null_or_decode_array<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

class AdapterUserProfileSharedConnectionsResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse["shared_connections"] {
    if (!jsonData) {
      return { top_users: [], count_total: 0 };
    }

    return {
      top_users: AdapterHelper.decode_array<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.top_users,
        debugInfo + ".top_users",
      ),
      count_total: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.count_total,
        debugInfo + ".count_total",
      ),
    };
  }
}

class AdapterUserProfileBadgeBannerMaximized {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IBadgeBannerMaximized {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      icon_url: ValidationUtils.assert_non_empty_string(
        jsonData.icon_url,
        debugInfo + ".icon_url",
      ),
      text: ValidationUtils.assert_non_empty_string(
        jsonData.text,
        debugInfo + ".text",
      ),
      title: ValidationUtils.assert_non_empty_string(
        jsonData.title,
        debugInfo + ".title",
      ),
    };
  }
}

class AdapterUserProfileBadgeBanner {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IBadgeBanner {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      arena_code: ValidationUtils.assert_non_empty_string(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      maximized_banners: AdapterHelper.decode_array(
        AdapterUserProfileBadgeBannerMaximized.decode,
        jsonData.maximized_banners,
        debugInfo + ".maximized_banners",
      ),
      maximized_text: ValidationUtils.assert_non_empty_string(
        jsonData.maximized_text,
        debugInfo + ".maximized_text",
      ),
      maximized_title: ValidationUtils.assert_non_empty_string(
        jsonData.maximized_title,
        debugInfo + ".maximized_title",
      ),
    };
  }
}

export class AdapterFSMBadgeResponse {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IFSMBadge {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      code: ValidationUtils.assert_non_empty_string(
        jsonData.code,
        debugInfo + ".code",
      ),
      count: ValidationUtils.assert_positive_integer(
        jsonData.count,
        debugInfo + ".count",
      ),
      created_timestamp_utc: ValidationUtils.assert_positive_integer_or_null(
        jsonData.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      description: ValidationUtils.assert_optional_string(
        jsonData.description,
        debugInfo + ".description",
      ),
      name: ValidationUtils.assert_optional_string(
        jsonData.name,
        debugInfo + ".name",
      ),
      text: ValidationUtils.assert_optional_string(
        jsonData.text,
        debugInfo + ".text",
      ),
      image_url: ValidationUtils.assert_non_empty_string(
        jsonData.image_url,
        debugInfo + "image_url",
      ),
      weight: ValidationUtils.assert_any_integer(
        jsonData.weight,
        debugInfo + "weight",
      ),
      short: ValidationUtils.assert_non_empty_string(
        jsonData.short,
        debugInfo + ".short",
      ),
      arena_code: ValidationUtils.assert_non_empty_string(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      details: ValidationUtils.assert_optional_string(
        jsonData.details,
        debugInfo + ".details",
      ),
    };
  }
}

export class AdapterFSMGetUserProfileResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserProfileResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_User_Profile__Response",
      profile: AdapterFSMDataUserInfo.decode(
        jsonData.profile,
        debugInfo + ".profile",
      ),
      stats_response: AdapterUserProfileStatsResponse.decode(
        jsonData.stats_response,
        debugInfo + ".stats_response",
      ),
      feed_response: AdapterUserProfileFeedResponse.decode(
        jsonData.feed_response,
        debugInfo + ".feed_response",
      ),
      following: AdapterUserProfileFollowingResponse.decode(
        jsonData.following,
        debugInfo + ".following",
      ),
      followers: AdapterUserProfileFollowersResponse.decode(
        jsonData.followers,
        debugInfo + ".followers",
      ),
      shared_connections: AdapterUserProfileSharedConnectionsResponse.decode(
        jsonData.shared_connections,
        debugInfo + ".shared_connections",
      ),
      badges: AdapterHelper.decode_array<I.IFSMBadge>(
        AdapterFSMBadgeResponse.decode,
        jsonData.badges,
        debugInfo + ".badges",
      ),
      badge_banner_data: AdapterHelper.decode_array(
        AdapterUserProfileBadgeBanner.decode,
        jsonData.badge_banner_data,
        debugInfo + ".badge_banner_data",
      ),
    };
  }
}

export class AdapterFSMModifyRelationshipResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMModifyRelationshipResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      observer_profile: AdapterFSMDataUserInfo.decode(
        jsonData.observer_profile,
        debugInfo + ".observer_profile",
      ),
      anchor_profile: AdapterFSMDataUserInfo.decode(
        jsonData.anchor_profile,
        debugInfo + ".anchor_profile",
      ),
      // this is actually transport detail, not a member
      __object_class_name: ValidationUtils.assert_non_empty_string(
        jsonData.__object_class_name,
        debugInfo + ".__object_class_name",
      ),
    };
  }
}

export class AdapterFSMPickSelection {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataUserPickSelectionInfo {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    if (!jsonData.proposal_fkey) {
      jsonData.proposal_fkey = "unknown_proposal_fkey";
    }
    if (!jsonData.conflict_fkey) {
      jsonData.conflict_fkey = "unknown_conflict_fkey";
    }
    if (!jsonData.market_fkey) {
      jsonData.market_fkey = "unknown_market_fkey";
    }
    if (!jsonData.channel_name) {
      jsonData.channel_name = "";
    }
    const state = staticGetState();
    const eventByFkey = AppUtils.getEventByConflictKey(
      state.sportsBook.events,
      jsonData.conflict_fkey,
    );

    return {
      settled_timestamp_utc: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validate_TPickSelectionState(jsonData.state, debugInfo + ".state"),
      channel_id: ValidationUtils.assert_positive_integer(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      event_start_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      coeff: ValidationUtils.assert_any_integer(
        jsonData.coeff,
        debugInfo + ".coeff",
      ),
      is_conflict_in_play: !!eventByFkey?.isLive,
      in_play_state_desc: eventByFkey?.live_state_desc || "",
      is_copy_available: false,
      proposal_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      conflict_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      id: ValidationUtils.assert_positive_integer(
        jsonData.id || 1,
        debugInfo + ".id",
      ),
      market_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.market_fkey,
        debugInfo + ".market_fkey",
      ),
      // 2020-10-25 / Ivan / moving to new set of texts
      t_141_selection_name: ValidationUtils.assert_non_empty_string(
        jsonData.selection_name,
        debugInfo + ".t_141_selection_name",
      ),
      t_142_selection_param_1: ValidationUtils.assert_optional_string(
        jsonData.selection_param_1,
        debugInfo + ".t_142_selection_param_1",
      ),
      t_131_market_name: ValidationUtils.assert_non_empty_string(
        jsonData.market_name,
        debugInfo + ".t_131_market_name",
      ),
      t_132_market_note: ValidationUtils.assert_optional_string(
        jsonData.market_note,
        debugInfo + ".t_132_market_note",
      ),
      t_121_event_info: ValidationUtils.assert_non_empty_string(
        jsonData.event_info,
        debugInfo + ".t_121_event_info",
      ),
      channel_name: ValidationUtils.assert_optional_string(
        jsonData.channel_name,
        debugInfo + ".channel_name",
      ),
    };
  }
}

export class AdapterFSMFSMobileDataActivityFeedItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataActivityFeedItem {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);
    return {
      pick_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.pick_fkey,
        debugInfo + ".pick_fkey",
      ),
      user_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      created_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsonData.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      settled_timestamp_utc: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validate_TPickSelectionState(jsonData.state, debugInfo + ".state"),
      type: validate_TPickType(jsonData.type, debugInfo + ".type"),
      currency: validate_TPickCurrency(
        jsonData.currency,
        debugInfo + ".currency",
      ),
      risk_amount: ValidationUtils.assert_positive_integer(
        jsonData.risk_amount,
        debugInfo + ".risk_amount",
      ),
      payout_amount: ValidationUtils.assert_any_integer(
        jsonData.payout_amount,
        debugInfo + ".payout_amount",
      ),
      selections: AdapterHelper.decode_array<I.IFSMDataUserPickSelectionInfo>(
        AdapterFSMPickSelection.decode,
        jsonData.selections,
        debugInfo + ".selections",
      ),
      coeff: ValidationUtils.assert_any_integer(
        jsonData.us_coeff,
        debugInfo + ".us_coeff",
      ),
      username: ValidationUtils.assert_optional_string(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assert_optional_string(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_bgcolor: ValidationUtils.assert_optional_string(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      relation: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      stake_category: ValidationUtils.assert_positive_integer(
        jsonData.stake_category,
        debugInfo + ".stake_category",
      ),
      player_level_icon: ValidationUtils.assert_optional_string(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
      copy_count: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.copy_count,
        debugInfo + ".copy_count",
      ),
    };
  }
}

export class AdapterFSMUpdateUserProfileResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMUpdateUserProfileResponse {
    return {
      __object_class_name: "FSM__Update_User_Profile_V2__Response",
      profile: AdapterFSMDataUserInfo.decode(jsonData.profile, debugInfo),
    };
  }
}

export class AdapterFSMSearchUserInfo {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMSearchUserInfo {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assert_non_empty_string(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assert_optional_string(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_bgcolor: ValidationUtils.assert_optional_string(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      count_followers: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.count_followers,
        debugInfo + ".count_followers",
      ),
      count_following: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.count_following,
        debugInfo + ".count_following",
      ),
      relation: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      access_level: validate_SocialAccessLevel(
        jsonData.access_level,
        debugInfo + ".access_level",
      ),
      player_level_icon: ValidationUtils.assert_optional_string(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
      user_type: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.user_type,
        debugInfo + ".user_type",
      ),
    };
  }
}

export class AdapterFSMSearchUserResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMSearchUserResponse {
    return {
      __object_class_name: "FSM__Search_User__Response",
      users: AdapterHelper.decode_array<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

export class AdapterFSMGetUsersForRelationshipResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUsersForRelationshipResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Users_For_Relationship__Response",
      users: AdapterHelper.decode_array<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

export class AdapterFSMLeaderboardReport {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMLeaderboardPeriodMeta {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      period_start_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
        jsonData.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_visual_start_date: ValidationUtils.assert_non_empty_string(
        jsonData.period_visual_start_date,
        debugInfo + ".period_visual_start_date",
      ),
      period_visual_end_date: ValidationUtils.assert_non_empty_string(
        jsonData.period_visual_end_date,
        debugInfo + ".period_visual_end_date",
      ),
      report_anchor_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
        jsonData.report_anchor_stamp_seconds_utc,
        debugInfo + ".report_anchor_stamp_seconds_utc",
      ),
      report_anchor_offset: ValidationUtils.assert_any_integer(
        jsonData.report_anchor_offset,
        debugInfo + ".report_anchor_offset",
      ),
      has_prev: ValidationUtils.assert_boolean(
        jsonData.has_prev,
        debugInfo + ".has_prev",
      ),
      has_next: ValidationUtils.assert_boolean(
        jsonData.has_next,
        debugInfo + ".has_next",
      ),
    };
  }
}

export class AdapterFSMDataLeaderboardItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataLeaderboardItem {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assert_non_empty_string(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assert_optional_string(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_avatar_full_body: ValidationUtils.assert_optional_string(
        jsonData.rpm_avatar_full_body,
        debugInfo + ".rpm_avatar_full_body",
      ),
      rpm_bgcolor: ValidationUtils.assert_optional_string(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      social_amount_won: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.social_amount_won,
        debugInfo + ".count_followers",
      ),
      rank: ValidationUtils.assert_any_integer(
        jsonData.rank,
        debugInfo + ".rank",
      ),
      relation: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      badge_icon_url: ValidationUtils.assert_optional_string(
        jsonData.badge_icon_url,
        debugInfo + ".badge_icon_url",
      ),
      player_level_icon: ValidationUtils.assert_non_empty_string(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
    };
  }
}

export class AdapterFSMLeaderboardBanner {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMLeaderboardBanner {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      arena_code: ValidationUtils.assert_non_empty_string(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      maximized_banners: AdapterHelper.decode_array(
        AdapterUserProfileBadgeBannerMaximized.decode,
        jsonData.maximized_banners,
        debugInfo + ".maximized_banners",
      ),
      maximized_text: ValidationUtils.assert_non_empty_string(
        jsonData.maximized_text,
        debugInfo + ".maximized_text",
      ),
      maximized_title: ValidationUtils.assert_non_empty_string(
        jsonData.maximized_title,
        debugInfo + ".maximized_title",
      ),
      minimized_banner: AdapterUserProfileBadgeBannerMaximized.decode(
        jsonData.minimized_banner,
        debugInfo + ".minimized_banner",
      ),
    };
  }
}

export class AdapterFSMGetLeaderboardResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetLeaderboardResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Leaderboard__Response",
      arena_code: ValidationUtils.assert_non_empty_string(
        jsonData.arena_code,
        debugInfo + ".arena_code",
      ),
      data: AdapterHelper.decode_array<I.IFSMDataLeaderboardItem>(
        AdapterFSMDataLeaderboardItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
      period_meta: AdapterFSMLeaderboardReport.decode(
        jsonData.period_meta,
        debugInfo + ".period_meta",
      ),
      profile: jsonData.profile
        ? AdapterFSMDataUserInfo.decode(
            jsonData.profile,
            debugInfo + ".profile",
          )
        : null,
      current_user: jsonData.current_user
        ? AdapterFSMDataLeaderboardItem.decode(
            jsonData.current_user,
            debugInfo + ".current_user",
          )
        : null,
      leaderboard_banner: jsonData.leaderboard_banner
        ? AdapterFSMLeaderboardBanner.decode(
            jsonData.leaderboard_banner,
            debugInfo + ".leaderboard_banner",
          )
        : null,
    };
  }
}

export class AdapterFSMGetActivityFeedMetaResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetActivityFeedMetaResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Activity_Feed_Meta__Response",
      newest_feed_key: ValidationUtils.assert_optional_string(
        jsonData.newest_feed_key,
        debugInfo,
      ),
    };
  }
}

export class AdapterFSMGetActivityFeedDataResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetActivityFeedDataResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Activity_Feed_Data__Response",
      data: AdapterHelper.decode_array<I.IFSMDataActivityFeedItem>(
        AdapterFSMFSMobileDataActivityFeedItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
      merge_code: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.merge_code,
        debugInfo + ".merge_code",
      ),
    };
  }
}

export class AdapterFSMGetUserBadgesResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserBadgesResponse {
    return {
      __object_class_name: "FSM__Get_User_Badges__Response",
      badges: AdapterHelper.decode_array<I.IFSMBadge>(
        AdapterFSMBadgeResponse.decode,
        jsonData.badges,
        debugInfo + ".badges",
      ),
    };
  }
}

export class SocialProtocolUtils {
  public static decodeMessage(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IFliffMessage {
    const objectClassName = ValidationUtils.assert_non_empty_string(
      jsonData.__object_class_name,
      debugInfo + ".__object_class_name",
    );
    const nextDebugInfo = debugInfo + "." + objectClassName;

    if (objectClassName === "FSM__Modify_Relationship__Response") {
      return AdapterFSMModifyRelationshipResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Activity_Feed_Data__Response") {
      return AdapterFSMGetActivityFeedDataResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_User_Profile__Response") {
      return AdapterFSMGetUserProfileResponse.decode(jsonData, nextDebugInfo);
    }

    if (objectClassName === "FSM__Update_User_Profile__Response") {
      return AdapterFSMUpdateUserProfileResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Search_User__Response") {
      return AdapterFSMSearchUserResponse.decode(jsonData, nextDebugInfo);
    }
    if (objectClassName === "FSM__Get_Users_For_Relationship__Response") {
      return AdapterFSMGetUsersForRelationshipResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Leaderboard__Response") {
      return AdapterFSMGetLeaderboardResponse.decode(jsonData, nextDebugInfo);
    }

    if (objectClassName === "FSM__Get_Activity_Feed_Meta__Response") {
      return AdapterFSMGetActivityFeedMetaResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_User_Badges__Response") {
      return AdapterFSMGetUserBadgesResponse.decode(jsonData, nextDebugInfo);
    }

    if (objectClassName === "FSM__Get_Player_Level_Leaderboard_V2__Response") {
      return AdapterFSMGetPlayerLevelLeaderboardResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    if (objectClassName === "FSM__Get_Suggested_Follows__Response") {
      return AdapterFSMGetSuggestedFollowersResponse.decode(
        jsonData,
        nextDebugInfo,
      );
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unknown object_class_name [" + objectClassName + "]",
    );
  }
}

export class AdapterFSMError {
  public static decode(jsonData: TAnyAlias, debugInfo: string): IFliffError {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      errorCode: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.error_code,
        debugInfo + ".error_code",
      ),
      errorMessage: ValidationUtils.assert_non_empty_string(
        jsonData.error_message,
        debugInfo + ".error_message",
      ),
      errorSource: null,
      incidentTag: ValidationUtils.assert_optional_string(
        jsonData.incident_tag,
        debugInfo + ".incident_tag",
      ),
    };
  }
}

export class AdapterFSMProtocolResponseHeader {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetUserBadgesResponse {
    return {
      __object_class_name: "FSM__Get_User_Badges__Response",
      badges: AdapterHelper.decode_array<I.IFSMBadge>(
        AdapterFSMBadgeResponse.decode,
        jsonData.badges,
        debugInfo + ".badges",
      ),
    };
  }
}

export class AdapterFSMDataConfig {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataConfig {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      obj_version: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.obj_version,
        debugInfo + ".obj_version",
      ),
    };
  }
}

export class AdapterFSMDataLoggedUserProfile {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMDataLoggedUserProfile {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      obj_version: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.obj_version,
        debugInfo + ".obj_version",
      ),
      user_id: ValidationUtils.assert_non_empty_string(
        jsonData.user_id,
        debugInfo + ".user_id",
      ),
      fliff_token: ValidationUtils.assert_non_empty_string(
        jsonData.fliff_token,
        debugInfo + ".fliff_token",
      ),
      username: ValidationUtils.assert_non_empty_string(
        jsonData.username,
        debugInfo + ".username",
      ),
    };
  }
}

export class AdapterFSMProtocolResponseSlots {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMProtocolResponseSlots {
    return {
      config: AdapterHelper.null_or_decode<I.IFSMDataConfig>(
        AdapterFSMDataConfig.decode,
        jsonData.config,
        debugInfo + ".config",
      ),
      user_profile: AdapterHelper.null_or_decode<I.IFSMDataLoggedUserProfile>(
        AdapterFSMDataLoggedUserProfile.decode,
        jsonData.user_profile,
        debugInfo + ".user_profile",
      ),
    };
  }
}

export class AdapterFSMGetSuggestedFollowersResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetSuggestedFollowsResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Suggested_Follows__Response",
      users: AdapterHelper.decode_array(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
    };
  }
}

export class AdapterFSMEventStatsGroupLine {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsGroupLine {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      line_category: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.line_category,
        debugInfo + ".line_category",
      ),
      line_header: ValidationUtils.assert_optional_string(
        jsonData.line_header,
        debugInfo + ".line_header",
      ),
      option_name_1: ValidationUtils.assert_optional_string(
        jsonData.option_name_1,
        debugInfo + ".option_name_1",
      ),
      option_name_2: ValidationUtils.assert_optional_string(
        jsonData.option_name_2,
        debugInfo + ".option_name_2",
      ),
      option_value_1: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.option_value_1,
        debugInfo + ".option_value_1",
      ),
      option_value_2: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.option_value_2,
        debugInfo + ".option_value_2",
      ),
      skin: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.skin,
        debugInfo + ".skin",
      ),
    };
  }
}

export class AdapterFSMEventStatsGroup {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsGroup {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      group_category: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.group_category,
        debugInfo + ".group_category",
      ),
      group_header: ValidationUtils.assert_optional_string(
        jsonData.group_header,
        debugInfo + ".group_header",
      ),
      lines: AdapterHelper.decode_array(
        AdapterFSMEventStatsGroupLine.decode,
        jsonData.lines,
        debugInfo + ".lines",
      ),
    };
  }
}

export class AdapterFSMEventStatsFriendsAndFollowsProposal {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMFriendsAndFollowsProposal {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      user_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.user_fkey,
        debugInfo + ".user_fkey",
      ),
      username: ValidationUtils.assert_non_empty_string(
        jsonData.username,
        debugInfo + ".username",
      ),
      rpm_avatar_half_body: ValidationUtils.assert_optional_string(
        jsonData.rpm_avatar_half_body,
        debugInfo + ".rpm_avatar_half_body",
      ),
      rpm_bgcolor: ValidationUtils.assert_optional_string(
        jsonData.rpm_bgcolor,
        debugInfo + ".rpm_bgcolor",
      ),
      player_level_icon: ValidationUtils.assert_non_empty_string(
        jsonData.player_level_icon,
        debugInfo + ".player_level_icon",
      ),
      event_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      market_name: ValidationUtils.assert_non_empty_string(
        jsonData.market_name,
        debugInfo + ".market_name",
      ),
      proposal_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      relation: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.relation,
        debugInfo + ".relation",
      ),
      selection_name: ValidationUtils.assert_non_empty_string(
        jsonData.selection_name,
        debugInfo + ".selection_name",
      ),
      submission_stamp_millis_utc: ValidationUtils.assert_any_integer(
        jsonData.submission_stamp_millis_utc,
        debugInfo + ".submission_stamp_millis_utc",
      ),
    };
  }
}

export class AdapterFSMPopularParlayProposal {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMPopularParlayProposal {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      event_info: ValidationUtils.assert_non_empty_string(
        jsonData.event_info,
        debugInfo + ".event_info",
      ),
      event_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      event_start_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      market_name: ValidationUtils.assert_non_empty_string(
        jsonData.market_name,
        debugInfo + ".market_name",
      ),
      market_note: ValidationUtils.assert_optional_string(
        jsonData.market_note,
        debugInfo + ".market_note",
      ),
      channel_id: ValidationUtils.assert_any_integer(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      proposal_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      selection_name: ValidationUtils.assert_non_empty_string(
        jsonData.selection_name,
        debugInfo + ".selection_name",
      ),
      channel_name: ValidationUtils.assert_non_empty_string(
        jsonData.channel_name,
        debugInfo + ".channel_name",
      ),
      sport_id: ValidationUtils.assert_positive_integer(
        jsonData.sport_id,
        debugInfo + ".sport_id",
      ),
    };
  }
}

export class AdapterFSMPopularParlay {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMPopularParlay {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      pick_type: validate_TPickType(
        jsonData.pick_type,
        debugInfo + ".pick_type",
      ),
      total_social_coins_risk_amount: ValidationUtils.assert_positive_integer(
        jsonData.total_social_coins_risk_amount,
        debugInfo + ".total_social_coins_risk_amount",
      ),
      total_social_picks: ValidationUtils.assert_positive_integer(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
      us_coeff: ValidationUtils.assert_any_integer(
        jsonData.us_coeff,
        debugInfo + ".us_coeff",
      ),
      proposals: AdapterHelper.decode_array(
        AdapterFSMPopularParlayProposal.decode,
        jsonData.proposals,
        debugInfo + ".proposals",
      ),
    };
  }
}

export class AdapterFSMEventStatsInfo {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsInfo {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      away_team: ValidationUtils.assert_optional_string(
        jsonData.away_team,
        debugInfo + ".away_team",
      ),
      channel_id: ValidationUtils.assert_any_integer(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      channel_name: ValidationUtils.assert_optional_string(
        jsonData.channel_name,
        debugInfo + ".channel_name",
      ),
      event_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      event_info: ValidationUtils.assert_optional_string(
        jsonData.event_info,
        debugInfo + ".event_info",
      ),
      event_start_timestamp_utc: ValidationUtils.assert_any_integer(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      home_team: ValidationUtils.assert_optional_string(
        jsonData.home_team,
        debugInfo + ".home_team",
      ),
      is_home_visually_first: ValidationUtils.assert_boolean(
        jsonData.is_home_visually_first,
        debugInfo + ".is_home_visually_first",
      ),
    };
  }
}

export class AdapterFSMEventDetailsStats {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventDetailsStats {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);
    return {
      total_social_picks: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
      total_social_coins_risk_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsonData.total_social_coins_risk_amount,
          debugInfo + ".total_social_coins_risk_amount",
        ),
      groups: AdapterHelper.decode_array(
        AdapterFSMEventStatsGroup.decode,
        jsonData.groups,
        debugInfo + ".groups",
      ),
      info: AdapterFSMEventStatsInfo.decode(jsonData.info, debugInfo + ".info"),
    };
  }
}

export class AdapterFSMEventStats {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStats {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);
    return {
      total_social_picks: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
      total_social_coins_risk_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsonData.total_social_coins_risk_amount,
          debugInfo + ".total_social_coins_risk_amount",
        ),
      info: AdapterFSMEventStatsInfo.decode(jsonData.info, debugInfo + ".info"),
    };
  }
}

export class AdapterFSMEventStatsProposal {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsProposal {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      event_info: ValidationUtils.assert_non_empty_string(
        jsonData.event_info,
        debugInfo + ".event_info",
      ),
      event_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.event_fkey,
        debugInfo + ".event_fkey",
      ),
      event_start_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      market_name: ValidationUtils.assert_non_empty_string(
        jsonData.market_name,
        debugInfo + ".market_name",
      ),
      channel_id: ValidationUtils.assert_any_integer(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      proposal_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      selection_name: ValidationUtils.assert_non_empty_string(
        jsonData.selection_name,
        debugInfo + ".selection_name",
      ),
      total_social_coins_risk_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsonData.total_social_coins_risk_amount,
          debugInfo + ".total_social_coins_risk_amount",
        ),
      total_social_picks: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.total_social_picks,
        debugInfo + ".total_social_picks",
      ),
    };
  }
}

export class AdapterFSMEventStatsProposalsGroup {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMEventStatsProposalsGroup {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      group_category: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.group_category,
        debugInfo + ".group_category",
      ),
      group_header: ValidationUtils.assert_optional_string(
        jsonData.group_header,
        debugInfo + ".group_header",
      ),
      proposals: AdapterHelper.decode_array(
        AdapterFSMEventStatsProposal.decode,
        jsonData.proposals,
        debugInfo + ".proposals",
      ),
    };
  }
}

export class AdapterFSMGetTopEventStatsResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetTopEventStatsResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Top_Event_Stats__Response",
      stats_events: AdapterHelper.decode_array(
        AdapterFSMEventStats.decode,
        jsonData.stats_events,
        debugInfo + ".stats_events",
      ),
      proposals_groups: AdapterHelper.decode_array(
        AdapterFSMEventStatsProposalsGroup.decode,
        jsonData.proposals_groups,
        debugInfo + ".proposals_groups",
      ),
      popular_parlays: AdapterHelper.decode_array(
        AdapterFSMPopularParlay.decode,
        jsonData.popular_parlays,
        debugInfo + ".popular_parlays",
      ),
    };
  }
}

export class AdapterFSMGetEventStatsDetailResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetEventStatsDetailResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Event_Stats_Detail__Response",
      stats_event: AdapterFSMEventDetailsStats.decode(
        jsonData.stats_event,
        debugInfo + ".stats_event",
      ),
      proposals_groups: AdapterHelper.decode_array(
        AdapterFSMEventStatsProposalsGroup.decode,
        jsonData.proposals_groups,
        debugInfo + ".proposals_groups",
      ),
      friends_and_follows_proposals: AdapterHelper.decode_array(
        AdapterFSMEventStatsFriendsAndFollowsProposal.decode,
        jsonData.friends_and_follows_proposals,
        debugInfo + ".friends_and_follows_proposals",
      ),
    };
  }
}

export class AdapterFSMCopyPickResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMCopyPickResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Copy_Pick__Response",
    };
  }
}

export class AdapterFSMGetCopyPickUsersResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetCopyPickUsersResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Copy_Pick_Users__Response",
      users: AdapterHelper.decode_array<I.IFSMSearchUserInfo>(
        AdapterFSMSearchUserInfo.decode,
        jsonData.users,
        debugInfo + ".users",
      ),
      hidden_copy_count: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.hidden_copy_count,
        debugInfo + ".hidden_copy_count",
      ),
    };
  }
}

export class AdapterFSMGetCopyPickCountResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMGetCopyPickCountResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FSM__Get_Copy_Pick_Count__Response",
      picks: AdapterHelper.decode_string_number_map(
        jsonData.picks,
        debugInfo + ".picks",
      ),
    };
  }
}
