import * as H from "history";
import { IFCMPublicFeedSyncResponse } from "server/core/data/objects";
import { PublicCoreApi } from "server/core/server/CoreApi";
import { HeraldBackend } from "server/herald/HeraldBackend";
import {
  Data__SB_Channel,
  Data__SB_SportEvent,
} from "server/legacyCore/data/objects";
import { TFliffResult } from "server/sharedCore/data/objects";
import { MemoryStorage } from "utils/MemoryStorage";

export class FeedFocusUtils {
  public static unsubscribeFromFocusedEvent() {
    const prevFocusedConflictFkey = MemoryStorage.focusedConflictFkey;
    HeraldBackend.unsubscribeForConflictFkeyFromFeed(prevFocusedConflictFkey);
    MemoryStorage.resetFocusedConflictFkey();
  }

  public static resetFocusedEvent = async (
    event: Data__SB_SportEvent,
  ): Promise<TFliffResult<IFCMPublicFeedSyncResponse>> => {
    // 2022-01-12 / Ivan / initial integration with zmq on conflict level
    const prevEventFkey = MemoryStorage.focusedConflictFkey;

    // 2022-01-20 / Ivan / if new event - change subscription
    if (prevEventFkey !== event.conflict_fkey) {
      if (prevEventFkey !== "") {
        HeraldBackend.unsubscribeForConflictFkeyFromFeed(prevEventFkey);
      }
      if (
        !HeraldBackend.activeSubscriptionsTopics.includes(
          HeraldBackend.toConflictFkeyTopic(event.conflict_fkey),
        )
      ) {
        HeraldBackend.subscribeForConflictFkey(event.conflict_fkey);
      }
    }

    // 2021-10-14 / Ivan / update the focus
    MemoryStorage.setFocusedConflictFkey(event.conflict_fkey);

    return await PublicCoreApi.safeBackgroundLoadEventMarkets();
  };

  public static handleMoreMarketsPress(
    eventConflictFkey: string,
    history: H.History,
    initialTabIndex?: number,
  ) {
    history.push(`/markets/${eventConflictFkey}`, {
      initialTabIndex,
    });
  }

  public static async handleSelectChannelFromAllSports(
    inputChannelId: Data__SB_Channel["id"],
    selectChannel: (channelId: number, fromAllSports?: boolean) => void,
  ) {
    const isSuccess = await FeedFocusUtils._switchToChannelImpl(inputChannelId);
    if (!isSuccess) {
      return;
    }

    selectChannel(inputChannelId, true);
  }

  // 2022-01-20 / Ivan / separate actual implementation to simplify code
  private static async _switchToChannelImpl(
    channelId: Data__SB_Channel["id"],
  ): Promise<boolean> {
    const prevChannelId = MemoryStorage.focusedChannelId;

    if (prevChannelId === channelId) {
      return true;
    }

    MemoryStorage.setFocusedChannelId(channelId);
    HeraldBackend.subscribeForChannelId(channelId);

    const { error } =
      await PublicCoreApi.safeBlockingSyncPublicDataOnChannelSelect();
    if (error) {
      MemoryStorage.setFocusedChannelId(prevChannelId);
      HeraldBackend.unsubscribeForChannelId(channelId);
      return false;
    }

    if (prevChannelId > 0) {
      HeraldBackend.unsubscribeForChannelId(prevChannelId);
    }

    return true;
  }
}
