import { combineReducers } from "redux";

import appUIReducer from "./appUI/appUI.reducer";
import appReducer from "./app/app.reducer";
import coreReducer from "./core/core.reducer";
import socialReducer from "./social/social.reducer";

const rootReducer = combineReducers({
  coreAppUI: appUIReducer,
  app: appReducer,
  sportsBook: coreReducer,
  social: socialReducer,
});

export type TGlobalAppState = ReturnType<typeof rootReducer>;

export default rootReducer;
