import { TGlobalAppState } from "reduxLocal/rootReducer";
import { createSelector } from "reselect";

class AppSelectors {
  public get isAuthenticated() {
    return createSelector(this._accessor, ({ authenticated }) => authenticated);
  }

  private _accessor({ app }: TGlobalAppState): TGlobalAppState["app"] {
    return app;
  }
}

export default new AppSelectors();
