import { forwardRef } from "react";

import RegularTextInput, {
  IProps as IRegularTextInputProps,
} from "../RegularTextInput";

export interface IProps
  extends Omit<IRegularTextInputProps, "validationState"> {
  value: string;
}

const RegularTextSkipValidations = forwardRef<HTMLInputElement, IProps>(
  ({ value, ...props }, ref) => (
    <RegularTextInput
      ref={ref}
      {...props}
      validationState={{ isValid: true, validationError: "", state: -1, value }}
    />
  ),
);

RegularTextSkipValidations.displayName = "RegularTextSkipValidations";

export default RegularTextSkipValidations;
