import { TRestrictionCodes } from "server/core/data/objects";
import { FliffException } from "server/legacyCore/FliffException";
import { TAnyAlias } from "src/types";
import AppUIModesManager from "utils/AppUIModesManager";
import Logger from "utils/Logger";

import { MemoryStorage } from "utils/MemoryStorage";
import { staticDispatch } from "reduxLocal/store";

class AppUIShowModalDialogAction {
  public static readonly type = "@type/SHOW_MODAL_DIALOG";
  public static readonly payload: {
    message: string;
    title: string;
    autoExitOnOk: boolean;
    onOkCallback?: () => void;
  };
  public readonly type: typeof AppUIShowModalDialogAction.type;
  public readonly payload: typeof AppUIShowModalDialogAction.payload;

  public static dispatchShowErrorDialog(title: string, message: string): void {
    staticDispatch(this._action({ message, title, autoExitOnOk: false }));
  }

  public static dispatchShowErrorDialogForException(
    error: TAnyAlias,
    errorSourceDebugInfo: string,
  ): void {
    const [title, message] = FliffException.getVisualErrorTextsForException(
      error,
      errorSourceDebugInfo,
    );
    // 2019-12-22 / Ivan / added support for 'auto logout on close'
    const autoExitOnOk =
      (!!error.error_code &&
        error.error_code >=
          FliffException.ERROR_1900__SESSION_EXPIRED__BASE_ERROR_CODE &&
        error.error_code <
          FliffException.ERROR_1900__SESSION_EXPIRED__BASE_ERROR_CODE + 100) ||
      error.error_code ===
        FliffException.ERROR_9997__PERMISSION_PHYSICAL_LOCATION_FAILURE ||
      error.error_code ===
        FliffException.ERROR_9996__COORDINATES_PHYSICAL_LOCATION_FAILURE ||
      error.error_code ===
        FliffException.ERROR_9995__GOOGLE_API_GEOCODE_PHYSICAL_LOCATION_FAILURE;

    staticDispatch(this._action({ message, title, autoExitOnOk }));
  }

  public static dispatchShowInfoDialog(
    title: string,
    message: string,
    onOkCallback?: () => void,
  ): void {
    staticDispatch(
      this._action({ message, title, autoExitOnOk: false, onOkCallback }),
    );
  }

  public static dispatchShowRestrictionInfoDialog(onOkPress?: () => void) {
    if (!AppUIModesManager.restrictionReason) {
      Logger.warnMessage(
        "Attempt to show AppUIModesManager.restrictionReason with null",
      );
      return;
    }
    this.dispatchShowInfoDialog(
      "Restriction Applied",
      AppUIModesManager.restrictionReason,
      onOkPress,
    );
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

class AppUIHideModalDialogAction {
  public static readonly type = "@type/HIDE_MODAL_DIALOG" as const;
  public readonly type: typeof AppUIHideModalDialogAction.type;

  public static dispatchHideModalDialog() {
    // 2021-08-08 / Ivan / introduce basic protection from double click - allow to ignore requests if blocking operation is in progress
    // just reset it every time when modal error dialog is closed
    MemoryStorage.is_blocking_operation_in_progress = false;

    staticDispatch(this._action());
  }

  private static _action() {
    return { type: this.type };
  }
}

class AppUIShowModalProgressIndicator {
  public static readonly type =
    "@type/SHOW_LOADING_MODAL_PROGRESS_INDICATOR" as const;
  public static readonly payload: number;
  public readonly type: typeof AppUIShowModalProgressIndicator.type;
  public readonly payload: number;

  public static dispatchShowModalProgressIndicator(transparentMillis: number) {
    staticDispatch(this._action(transparentMillis));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

class AppUIHideModalProgressIndicator {
  public static readonly type =
    "@type/HIDE_LOADING_MODAL_PROGRESS_INDICATOR" as const;
  public readonly type: typeof AppUIHideModalProgressIndicator.type;

  public static dispatchHideModalProgressIndicator() {
    staticDispatch(this._action());
  }

  private static _action() {
    return { type: this.type };
  }
}

class AppUISetAppUiMode {
  public static readonly type = "@type/SET_APP_UI_MODE" as const;
  public static readonly payload: TRestrictionCodes | undefined;
  public readonly type: typeof AppUISetAppUiMode.type;
  public readonly payload: TRestrictionCodes | undefined;

  public static dispatchSetAppUiMode(appUiMode: TRestrictionCodes | undefined) {
    staticDispatch(this._action(appUiMode));
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export {
  AppUIShowModalDialogAction,
  AppUIHideModalDialogAction,
  AppUIShowModalProgressIndicator,
  AppUIHideModalProgressIndicator,
  AppUISetAppUiMode,
};
