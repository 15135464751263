import React from "react";
import WarningSubtitle from "components/atoms_new/shared/WarningSubtitle";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import "./styles.scss";
import Button from "components/atoms_new/desktop/Button";
import SocialButtons from "components/atoms_new/desktop/SocialButtons";
import TextLink from "components/atoms_new/desktop/TextLink";
import { IModalControls } from "components/atoms_new/desktop/Modals/AuthenticationModal/types";

const SignInModal = React.forwardRef<HTMLDivElement, IModalControls>(
  ({ onClose, openNext, openPrevious }, forwardedRef) => {
    const handleSignInWithPhoneNumberClick = () => {
      openNext("signInPhone", { mode: 2 });
    };

    const handleSignInWithEmail = () => {
      openNext("signInEmail");
    };

    const handleForgotPassword = () => {
      openNext("forgotPassword");
    };

    return (
      <div className="sign-in-modal" ref={forwardedRef}>
        <ModalHeader title={"Sign In"} onClose={onClose} />

        <Button
          onClick={handleSignInWithPhoneNumberClick}
          label={"Sign In with Phone Number"}
          className="sign-in-modal__phone-number-button"
          autoFocus
        />

        <WarningSubtitle
          subtitle={
            "If you have verified your phone number with Fliff, you will be texted a one-time code you can use to sign in."
          }
          className="sign-in-modal__warning-subtitle"
        />

        <SocialButtons
          onClose={onClose}
          openNext={openNext}
          openPrevious={openPrevious}
        />
        <Button onClick={handleSignInWithEmail} label={"Sign In with Email"} />
        <div className={"sign-in-modal__footer"}>
          <TextLink label={"Forgot Password"} onClick={handleForgotPassword} />
        </div>
      </div>
    );
  },
);

SignInModal.displayName = "SignInModal";

export default SignInModal;
