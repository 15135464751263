import { PublicChallengeType } from "server/legacyCore/data/constants";
import { FliffException } from "server/legacyCore/FliffException";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
// 2020-05-27 / Ivan / trying alternative approach, where client PJO objects are the reference point and protocol only decodes them
import {
  Data__SB_BetGroup,
  Data__SB_BetMarketVisualColumn,
  Data__SB_BetProposal,
  Data__SB_Channel,
  Data__SB_Config,
  Data__SB_Conflict_Class_Def,
  Data__SB_Conlict_Market_Class_Def,
  Data__SB_FeedDiffItem_BetMarket_Available,
  Data__SB_FeedDiffItem_BetMarket_Deactivated,
  Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def,
  Data__SB_IAP_PaymentsToFinish,
  IDataSBIAPTransaction,
  Data__SB_Initiated_Persona_Verification_Response,
  Data__SB_Initiated_Withdrawal_Response,
  Data__SB_Logro_v2_All_Daily_Challenges_Completed,
  Data__SB_Permissions_And_Common_Data,
  Data__SB_Pick,
  Data__SB_Pick_Badge,
  Data__SB_Pick_PeriodReport,
  Data__SB_Pick_Selection,
  Data__SB_Points_Leaderboard_Badge,
  Data__SB_Points_Transaction_PeriodReport,
  Data__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE,
  Data__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT,
  Data__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT,
  Data__SB_Public_Challenges_Report,
  Data__SB_Social_Picks_Stats,
  Data__SB_SportEvent,
  Data__SB_User_Personal_Info,
  Data__SB_UserProfile,
  Data__SB_UserProfile_Attribute,
  Data__SB_v2_Place_Picks_Operation_Status,
  Data__SB_v3_Straight_Pick_Selection_Limit_Def,
  Data__SB_v3_User_Profile_Summary,
  Data__SB_v3_User_Weekly_Stats,
  Data__SB_X_Delta_Profile,
  Data__SB_XP_ExchangeOffer,
  Data__Slide_All_Gift_Card_Brands,
  Data__Slide_Gift_Card_Brand,
  Data__Slide_Gift_Card_Redemption_Method,
  Data__Slide_User_Gift_Card,
  IArenaDef,
  IPlayThroughBundle,
  IPlayThroughBundleBonus,
  IPlayThroughData,
  TBetMarket,
  TDataSBPublicChallengesReportItem,
} from "server/legacyCore/data/objects";
import { IStringMap, TAnyAlias, TUSAStatesValues } from "src/types";

import { IFCMTransaction } from "server/core/data/objects";
import { AdapterFCMTransaction } from "server/core/server/impl/serverAdapters";
import { Data__SB_Unfinished_Order } from "server/sharedCore/data/objects";
import {
  validate_PublicChallengeType,
  validate_TBetOrBetGroupStatus,
  validate_TChannelType,
  validate_TConflictType,
  validate_TConflictType_or_null,
  validate_TGiftCardRedemptionConfigType,
  validate_TIdentityVerificationStatus,
  validate_TMarketOfferSelectionChangeType,
  validate_TPickCurrency,
  validate_TPickSelectionState,
  validate_TPickType,
  validate_TPickType_or_null,
  validate_TPlacePicksOperationStatus,
  validate_TRawPeriodType,
  validate_TServerUIMode,
  validate_TUnfinishedOrderState,
  validate_TUser_Account_Details_Status,
  validateBundleBonusSkin,
  validateBundleOfferSkin,
  validateHeraldProtocolVersion,
  validatePlacePickPlayThroughMode,
} from "./server_enum_helpers";

export type TDatObjectDecoder<T, K extends object = object> = (
  jsondata: K,
  debugInfo: string,
  index: number,
) => T;

export type TDataObjectDecoder2<T> = (
  jsondata: T,
  debugInfo: string,
  index: number,
  param2: string,
) => T;

export type TDataObjectDecoder3<T> = (
  jsondata: T,
  debugInfo: string,
  index: number,
  param2: string,
  param3: string,
) => T;

export type TDataObjectDecoder<T> = (
  jsondata: T,
  debugInfo: string,
  param2: string,
  param3: string,
  param4: string,
) => T;

export type TDataObjectDecoder4n<T> = (
  jsondata: T,
  debugInfo: string,
  param2: string,
  param3: string,
  param4: number,
) => T;

export class AdapterHelper {
  public static null_or_decode<T>(
    decoder: TDatObjectDecoder<T>,
    jsondata: T,
    debugInfo: string,
    index: number = -1,
  ): T | null {
    if (jsondata === undefined || jsondata === null) {
      return null;
    }

    return decoder(jsondata, debugInfo, index);
  }

  public static decode_array<T, K extends object = object>(
    decoder: TDatObjectDecoder<T, K>,
    jsondata: object,
    debugInfo: string,
  ): Array<T> {
    const rawarray: K[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) =>
      decoder(rawData, debugInfo + "[" + index + "]", index),
    ) as Array<T>;
  }

  public static decode_array_2<T>(
    decoder: TDataObjectDecoder2<T>,
    jsondata: T,
    debugInfo: string,
    param2: string,
  ): Array<T> {
    const rawarray: T[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) =>
      decoder(rawData, debugInfo + "[" + index + "]", index, param2),
    ) as Array<T>;
  }

  public static decode_array_3<T>(
    decoder: TDataObjectDecoder3<T>,
    jsondata: T,
    debugInfo: string,
    param2: string,
    param3: string,
  ): Array<T> {
    const rawarray: T[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) =>
      decoder(rawData, debugInfo + "[" + index + "]", index, param2, param3),
    ) as Array<T>;
  }

  public static decode_array_4<T>(
    decoder: TDataObjectDecoder<T>,
    jsondata: T,
    debugInfo: string,
    param2: string,
    param3: string,
    param4: string,
  ): Array<T> {
    const rawarray: T[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) =>
      decoder(rawData, debugInfo + "[" + index + "]", param2, param3, param4),
    ) as Array<T>;
  }

  public static decode_array_4n<T>(
    decoder: TDataObjectDecoder4n<T>,
    jsondata: T,
    debugInfo: string,
    param2: string,
    param3: string,
    param4: number,
  ): Array<T> {
    const rawarray: T[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) =>
      decoder(rawData, debugInfo + "[" + index + "]", param2, param3, param4),
    ) as Array<T>;
  }

  public static null_or_decode_array<T>(
    decoder: TDatObjectDecoder<T>,
    jsondata: object,
    debugInfo: string,
  ): Array<T> | null {
    if (jsondata === undefined || jsondata === null) {
      return null;
    }

    return AdapterHelper.decode_array<T>(decoder, jsondata, debugInfo);
  }

  public static decode_array_of_integers(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): number[] {
    const rawarray: TAnyAlias[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) =>
      ValidationUtils.assert_any_integer(
        rawData,
        debugInfo + "[" + index + "]",
      ),
    ) as number[];
  }

  public static decode_array_of_strings(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): string[] {
    const rawarray: TAnyAlias[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) =>
      ValidationUtils.assert_non_empty_string(
        rawData,
        debugInfo + "[" + index + "]",
      ),
    ) as string[];
  }

  public static decode_array_with_typed_items<T extends { type: string }>(
    decoders: { [key in T["type"]]: TDatObjectDecoder<T, T> },
    jsondata: object,
    debugInfo: string,
  ): Array<T> {
    const rawarray: T[] = ValidationUtils.assert_non_null_array(
      jsondata,
      debugInfo,
    );

    return rawarray.map((rawData, index) => {
      const decoder = decoders[rawData.type as T["type"]];
      if (!decoder) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "decode_array_with_typed_items - unknown item type [" +
            rawData.type +
            "] at index [" +
            index +
            "] for [" +
            debugInfo +
            "]",
        );
      }
      return decoder(rawData, debugInfo + "[" + index + "]", index);
    }) as Array<T>;
  }

  public static decode_string_number_map(
    jsondata: object,
    debugInfo: string,
  ): IStringMap<number> {
    const rawObject: IStringMap<number> = ValidationUtils.assert_non_null_data(
      jsondata,
      debugInfo,
    );

    return Object.entries(rawObject).reduce<IStringMap<number>>(
      (acc, [key, value]) => {
        acc[key] = ValidationUtils.assert_positive_float_or_zero(
          value,
          debugInfo + "[" + key + "]",
        );
        return acc;
      },
      {},
    );
  }
}

class AdapterSBPlayThroughBundleBonus {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IPlayThroughBundleBonus {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      amount_fliff_cash: ValidationUtils.assert_positive_integer(
        jsonData.amount_fliff_cash,
        debugInfo + ".amount_fliff_cash",
      ),
      bundle_code: ValidationUtils.assert_non_empty_string(
        jsonData.bundle_code,
        debugInfo + ".bundle_code",
      ),
      multiplier_in_percents: ValidationUtils.assert_positive_integer(
        jsonData.multiplier_in_percents,
        debugInfo + ".multiplier_in_percents",
      ),
      level_2_skin: validateBundleBonusSkin(
        jsonData.level_2_skin,
        debugInfo + ".level_2_skin",
      ),
      serialized_goods: ValidationUtils.assert_optional_string(
        jsonData.serialized_goods,
        debugInfo + ".serialized_goods",
      ),
    };
  }
}

class AdapterSBPlayThroughBundle {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IPlayThroughBundle {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      amount_social_coins: ValidationUtils.assert_any_integer(
        jsonData.amount_social_coins,
        debugInfo + ".amount_social_coins",
      ),
      level_1_skin: validateBundleOfferSkin(
        jsonData.level_1_skin,
        debugInfo + ".level_1_skin",
      ),
      level_2_data: AdapterHelper.null_or_decode_array(
        AdapterSBPlayThroughBundleBonus.decode,
        jsonData.level_2_data,
        debugInfo + ".level_2_data",
      ),
      price_in_cents: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.price_in_cents,
        debugInfo + ".price_in_cents",
      ),
      v_bonus_banner: ValidationUtils.assert_optional_string(
        jsonData.v_bonus_banner,
        debugInfo + ".v_bonus_banner",
      ),
      amount_fliff_cash: ValidationUtils.assert_any_integer(
        jsonData.amount_fliff_cash,
        debugInfo + ".amount_fliff_cash",
      ),
      bundle_code: ValidationUtils.assert_non_empty_string(
        jsonData.bundle_code,
        debugInfo + ".bundle_code",
      ),
      serialized_goods: ValidationUtils.assert_optional_string(
        jsonData.serialized_goods,
        debugInfo + ".serialized_goods",
      ),
      min_next_obtain_stamp_sec: ValidationUtils.assert_optional_integer(
        jsonData.min_next_obtain_stamp_sec,
        debugInfo + ".min_next_obtain_stamp_sec",
      ),
    };
  }
}

export class Adapter__SB_Channel {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Channel {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      skin_id: ValidationUtils.assert_positive_integer(
        jsondata.skin_id,
        debugInfo + ".skin_id",
      ),
      inplay_pos: ValidationUtils.assert_any_integer(
        jsondata.inplay_pos,
        debugInfo + ".inplay_pos",
      ),
      // negative is used for 'filter only' channels
      id: ValidationUtils.assert_any_integer(jsondata.id, debugInfo + ".id"),
      dash_pos: ValidationUtils.assert_any_integer(
        jsondata.dash_pos,
        debugInfo + ".dash_pos",
      ),
      allsports_pos: ValidationUtils.assert_any_integer(
        jsondata.allsports_pos,
        debugInfo + ".allsports_pos",
      ),
      visualName: ValidationUtils.assert_non_empty_string(
        jsondata.name_type_1,
        debugInfo + ".name_type_1",
      ),
      name_type_2: ValidationUtils.assert_non_empty_string(
        jsondata.name_type_2,
        debugInfo + ".name_type_2",
      ),
      long_name: ValidationUtils.assert_non_empty_string(
        jsondata.name_type_3,
        debugInfo + ".name_type_3",
      ),
      type: validate_TChannelType(jsondata.type, debugInfo + ".type"),

      fc_limit_code: ValidationUtils.assert_any_integer(
        jsondata.fc_limit_code,
        debugInfo + ".fc_limit_code",
      ),
      // 2021-06-06 / Ivan / introduce convept for two levels for all sports screen
      all_sports_level: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.all_sports_level,
        debugInfo + ".all_sports_level",
      ),
    };
  }
}

export class Adapter__SB_v2_Place_Picks_Operation_Status {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_v2_Place_Picks_Operation_Status {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      status: validate_TPlacePicksOperationStatus(
        jsondata.status,
        debugInfo + ".status",
      ),
      required_resubmit_delay_millis:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.required_resubmit_delay_millis,
          debugInfo + ".required_resubmit_delay_millis",
        ),
      required_resubmit_after_timestamp_millis:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.required_resubmit_after_timestamp_millis,
          debugInfo + ".required_resubmit_after_timestamp_millis",
        ),
      required_confirmed_server_quote_coeff: ValidationUtils.assert_any_integer(
        jsondata.required_confirmed_server_quote_coeff,
        debugInfo + ".required_confirmed_server_quote_coeff",
      ),
      picks: AdapterHelper.decode_array<Data__SB_Pick>(
        Adapter__SB_Pick.decode,
        jsondata.picks,
        debugInfo + ".picks",
      ),
    };
  }
}

export class Adapter__SB_Arena_Def {
  public static decode(jsondata: TAnyAlias, debugInfo: string): IArenaDef {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      arena_code: ValidationUtils.assert_non_empty_string(
        jsondata.arena_code,
        debugInfo + ".arena_code",
      ),
      arena_name: ValidationUtils.assert_non_empty_string(
        jsondata.arena_name,
        debugInfo + ".arena_name",
      ),
      arena_icon_url: ValidationUtils.assert_non_empty_string(
        jsondata.arena_icon_url,
        debugInfo + ".arena_icon_url",
      ),
      arena_icon_v2_url: ValidationUtils.assert_optional_string(
        jsondata.arena_icon_v2_url,
        debugInfo + ".arena_icon_v2_url",
      ),
    };
  }
}

class AdapterSBPlayThroughData {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IPlayThroughData {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      data: AdapterHelper.decode_array(
        AdapterSBPlayThroughBundle.decode,
        jsonData.data,
        debugInfo + ".play_through_bundles",
      ),
    };
  }
}

export class Adapter__SB_Permissions_And_Common_Data {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Permissions_And_Common_Data {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);
    const conflict_pcats = AdapterHelper.decode_array_of_integers(
      jsondata.conflict_pcats,
      debugInfo + ".conflict_pcats",
    );

    // 2022-01-15 / Ivan / introduce concept for default + per user permissions
    return {
      ui_mode: validate_TServerUIMode(jsondata.ui_mode, debugInfo + ".ui_mode"),
      enable_gift_cards: ValidationUtils.assert_boolean(
        jsondata.enable_gift_cards,
        debugInfo + ".enable_gift_cards",
      ),
      conflict_pcats: new Set(conflict_pcats),
      herald_endpoint: ValidationUtils.assert_optional_string(
        jsondata.herald_endpoint,
        debugInfo + ".herald_endpoint",
      ),
      herald_protocol_version: validateHeraldProtocolVersion(
        jsondata.herald_protocol_version,
        debugInfo + ".herald_protocol_version",
      ),
      // 2021-12-23 / Ivan / pass last known ip address from server
      caller_ip_address: ValidationUtils.assert_optional_string(
        jsondata.caller_ip_address,
        debugInfo + ".caller_ip_address",
      ),
      // 2022-12-05 / Ivan / new server side config texts
      cashier_social_banner_skin:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.cashier_social_banner_skin,
          debugInfo + ".cashier_social_banner_skin",
        ),
      cashier_social_banner_title: ValidationUtils.assert_optional_string(
        jsondata.cashier_social_banner_title,
        debugInfo + ".cashier_social_banner_title",
      ),
      cashier_social_banner_text: ValidationUtils.assert_optional_string(
        jsondata.cashier_social_banner_text,
        debugInfo + ".cashier_social_banner_text",
      ),

      cashier_cash_banner_skin: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.cashier_cash_banner_skin,
        debugInfo + ".cashier_cash_banner_skin",
      ),
      cashier_cash_banner_title: ValidationUtils.assert_optional_string(
        jsondata.cashier_cash_banner_title,
        debugInfo + ".cashier_cash_banner_title",
      ),
      cashier_cash_banner_text: ValidationUtils.assert_optional_string(
        jsondata.cashier_cash_banner_text,
        debugInfo + ".cashier_cash_banner_text",
      ),

      // 2022-12-22 / Ivan / added concept for global banner
      global_banner_image_url: ValidationUtils.assert_optional_string(
        jsondata.global_banner_image_url,
        debugInfo + ".global_banner_image_url",
      ),
      global_banner_click_url: ValidationUtils.assert_optional_string(
        jsondata.global_banner_click_url,
        debugInfo + ".global_banner_click_url",
      ),

      claim_v8_free_available_after_stamp_millis:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.claim_v8_free_available_after_stamp_millis,
          debugInfo + ".claim_v8_free_available_after_stamp_millis",
        ),
      active_feed_arenas: AdapterHelper.decode_array<IArenaDef>(
        Adapter__SB_Arena_Def.decode,
        jsondata.active_feed_arenas,
        debugInfo + ".active_feed_arenas",
      ),
      leaderboard_arenas: AdapterHelper.decode_array<IArenaDef>(
        Adapter__SB_Arena_Def.decode,
        jsondata.leaderboard_arenas,
        debugInfo + ".leaderboard_arenas",
      ),
      leaderboard_disabled_dialog_title: ValidationUtils.assert_optional_string(
        jsondata.leaderboard_disabled_dialog_title,
        debugInfo + ".leaderboard_disabled_dialog_title",
      ),
      cashier_allow_fc_mode: ValidationUtils.assert_boolean(
        jsondata.cashier_allow_fc_mode,
        debugInfo + ".cashier_allow_fc_mode",
      ),
      cashier_social_banner_url: ValidationUtils.assert_optional_string(
        jsondata.cashier_social_banner_url,
        debugInfo + ".cashier_social_banner_url",
      ),
      cashier_cash_banner_url: ValidationUtils.assert_optional_string(
        jsondata.cashier_cash_banner_url,
        debugInfo + ".cashier_cash_banner_url",
      ),
      config__personalized__20032: ValidationUtils.assert_any_integer(
        jsondata.config__personalized__20032,
        debugInfo + ".config__personalized__20032",
      ),
      config__personalized__20031: ValidationUtils.assert_any_integer(
        jsondata.config__personalized__20031,
        debugInfo + ".config__personalized__20031",
      ),
      config__personalized__20022: ValidationUtils.assert_any_integer(
        jsondata.config__personalized__20022,
        debugInfo + ".config__personalized__20022",
      ),
      config__personalized__20021: ValidationUtils.assert_any_integer(
        jsondata.config__personalized__20021,
        debugInfo + ".config__personalized__20021",
      ),
      config__personalized__20041: ValidationUtils.assert_any_integer(
        jsondata.config__personalized__20041,
        debugInfo + ".config__personalized__20041",
      ),
      config__personalized__20042: ValidationUtils.assert_any_integer(
        jsondata.config__personalized__20042,
        debugInfo + ".config__personalized__20042",
      ),
      core_sever_private_endpoint: ValidationUtils.assert_optional_string(
        jsondata.core_sever_private_endpoint,
        debugInfo + ".core_sever_private_endpoint",
      ),
      core_sever_public_endpoint: ValidationUtils.assert_optional_string(
        jsondata.core_sever_public_endpoint,
        debugInfo + ".core_sever_public_endpoint",
      ),
      proposals_feed_endpoint: ValidationUtils.assert_optional_string(
        jsondata.proposals_feed_endpoint,
        debugInfo + ".proposals_feed_endpoint",
      ),
      we_are_under_construction_skin: ValidationUtils.assert_any_integer(
        jsondata.we_are_under_construction_skin,
        debugInfo + ".we_are_under_construction_skin",
      ),
      leaderboard_disabled: ValidationUtils.assert_boolean(
        jsondata.leaderboard_disabled,
        debugInfo + ".leaderboard_disabled",
      ),
      leaderboard_disabled_dialog_text: ValidationUtils.assert_optional_string(
        jsondata.leaderboard_disabled_dialog_text,
        debugInfo + ".leaderboard_disabled_dialog_text",
      ),
      play_through_data: AdapterSBPlayThroughData.decode(
        jsondata.play_through_data,
        debugInfo + ".play_through_data",
      ),
      affiliate_report_url: ValidationUtils.assert_optional_string(
        jsondata.affiliate_report_url,
        debugInfo + ".affiliate_report_url",
      ),
    };
  }
}

export class Adapter__SB_XP_ExchangeOffer {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_XP_ExchangeOffer {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      offer_id: ValidationUtils.assert_positive_integer(
        jsondata.offer_id,
        debugInfo + ".offer_id",
      ),
      xp_amount: ValidationUtils.assert_positive_integer(
        jsondata.xp_amount,
        debugInfo + ".xp_amount",
      ),
      rc_amount: ValidationUtils.assert_positive_integer(
        jsondata.rc_amount,
        debugInfo + ".rc_amount",
      ),
      visual_banner: ValidationUtils.assert_optional_string(
        jsondata.visual_banner,
        debugInfo + ".visual_banner",
      ),
    };
  }
}

export class Adapter__SB_Config {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Config {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      obj_version: ValidationUtils.assert_positive_integer(
        jsondata.obj_version,
        debugInfo + ".obj_version",
      ),
      reward_cash_per_referral_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.reward_cash_per_referral_in_cents,
          debugInfo + ".reward_cash_per_referral_in_cents",
        ),
      min_user_profile_id_which_requires_kyc_before_place_pick:
        ValidationUtils.assert_any_integer(
          jsondata.min_user_profile_id_which_requires_kyc_before_place_pick,
          debugInfo +
            ".min_user_profile_id_which_requires_kyc_before_place_pick",
        ),
      min_amount_for_withdraw_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.min_amount_for_withdraw_in_cents,
          debugInfo + ".min_amount_for_withdraw_in_cents",
        ),
      max_number_of_selections_total:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.max_number_of_selections_total,
          debugInfo + ".max_number_of_selections_total",
        ),
      max_number_of_selections_in_straight_mode:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.max_number_of_selections_in_straight_mode,
          debugInfo + ".max_number_of_selections_in_straight_mode",
        ),
      max_number_of_selections_in_parlay_mode:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.max_number_of_selections_in_parlay_mode,
          debugInfo + ".max_number_of_selections_in_parlay_mode",
        ),
      max_number_of_selections_in_sgp_mode:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.max_number_of_selections_in_sgp_mode,
          debugInfo + ".max_number_of_selections_in_sgp_mode",
        ),

      fliff_cash_straight__min_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.fliff_cash_straight__min_pick_amount_in_cents,
          debugInfo + ".fliff_cash_straight__min_pick_amount_in_cents",
        ),
      fliff_cash_straight__max_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.fliff_cash_straight__max_pick_amount_in_cents,
          debugInfo + ".fliff_cash_straight__max_pick_amount_in_cents",
        ),
      fliff_cash_straight__max_payout_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.fliff_cash_straight__max_payout_in_cents,
          debugInfo + ".fliff_cash_straight__max_payout_in_cents",
        ),

      fliff_cash_parlay__min_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.fliff_cash_parlay__min_pick_amount_in_cents,
          debugInfo + ".fliff_cash_parlay__min_pick_amount_in_cents",
        ),
      fliff_cash_parlay__max_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.fliff_cash_parlay__max_pick_amount_in_cents,
          debugInfo + ".fliff_cash_parlay__max_pick_amount_in_cents",
        ),
      fliff_cash_parlay__max_payout_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.fliff_cash_parlay__max_payout_in_cents,
          debugInfo + ".fliff_cash_parlay__max_payout_in_cents",
        ),

      // 2020-12-21 / Ivan / moving to v2 currencies
      credits_straight__min_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.credits_straight__min_pick_amount_in_cents,
          debugInfo + ".credits_straight__min_pick_amount_in_cents",
        ),
      credits_straight__max_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.credits_straight__max_pick_amount_in_cents,
          debugInfo + ".credits_straight__max_pick_amount_in_cents",
        ),
      credits_straight__max_payout_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.credits_straight__max_payout_in_cents,
          debugInfo + ".credits_straight__max_payout_in_cents",
        ),

      credits_parlay__min_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.credits_parlay__min_pick_amount_in_cents,
          debugInfo + ".credits_parlay__min_pick_amount_in_cents",
        ),
      credits_parlay__max_pick_amount_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.credits_parlay__max_pick_amount_in_cents,
          debugInfo + ".credits_parlay__max_pick_amount_in_cents",
        ),
      credits_parlay__max_payout_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.credits_parlay__max_payout_in_cents,
          debugInfo + ".credits_parlay__max_payout_in_cents",
        ),

      // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
      xp_bonus_progress__amount_required_for_bonus:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.xp_bonus_progress__amount_required_for_bonus,
          debugInfo + ".xp_bonus_progress__amount_required_for_bonus",
        ),
      xp_bonus_progress__amount_bonus:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.xp_bonus_progress__amount_bonus,
          debugInfo + ".xp_bonus_progress__amount_bonus",
        ),
      //      xp_bonus_initial_purchases__max_amount_social_coins:
      //        ValidationUtils.assert_positive_integer_or_zero(
      //          jsondata.xp_bonus_initial_purchases__max_amount_social_coins,
      //          debugInfo + ".xp_bonus_initial_purchases__max_amount_social_coins",
      //        ),
      //      xp_bonus_initial_purchases__max_amount_fliff_cash:
      //        ValidationUtils.assert_positive_integer_or_zero(
      //          jsondata.xp_bonus_initial_purchases__max_amount_fliff_cash,
      //          debugInfo + ".xp_bonus_initial_purchases__max_amount_fliff_cash",
      //        ),
      xp_bonus_referral__max_amount_per_child:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.xp_bonus_referral__max_amount_per_child,
          debugInfo + ".xp_bonus_referral__max_amount_per_child",
        ),
      xp_bonus_referral__percent_from_child_purchase:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.xp_bonus_referral__percent_from_child_purchase,
          debugInfo + ".xp_bonus_referral__percent_from_child_purchase",
        ),

      //      iap_promo_tokens_convert__amount_consume_tokens:
      //        ValidationUtils.assert_positive_integer_or_zero(
      //          jsondata.iap_promo_tokens_convert__amount_consume_tokens,
      //          debugInfo + ".iap_promo_tokens_convert__amount_consume_tokens",
      //        ),
      //      iap_promo_tokens_convert__amount_gift_rewards_cash:
      //        ValidationUtils.assert_positive_integer_or_zero(
      //          jsondata.iap_promo_tokens_convert__amount_gift_rewards_cash,
      //          debugInfo + ".iap_promo_tokens_convert__amount_gift_rewards_cash",
      //        ),

      // 2022-09-30 / Ivan / moved to per-user config
      //      coin_bundle_offers: AdapterHelper.decode_array<Data__SB_CoinsBundleOffer>(
      //        Adapter__SB_CoinsBundleOffer.decode,
      //        jsondata.coin_bundles,
      //        debugInfo + ".coin_bundles",
      //      ),

      channels: AdapterHelper.decode_array<Data__SB_Channel>(
        Adapter__SB_Channel.decode,
        jsondata.channels,
        debugInfo + ".channels",
      ),
      //2021-06-25 / Ivan / no longer used
      //      user_levels: AdapterHelper.decode_array<Data__SB_UserLevelDef>(
      //        Adapter__SB_UserLevelDef.decode,
      //        jsondata.user_v2_levels,
      //        debugInfo + ".user_v2_levels",
      //      ),
      //      user_v2_goat_sublevel_points: ValidationUtils.assert_positive_integer(
      //        jsondata.user_v2_goat_sublevel_points,
      //        debugInfo + ".user_v2_goat_sublevel_points",
      //      ),
      //      user_v2_goat_sublevel_rc_bonus: ValidationUtils.assert_positive_integer(
      //        jsondata.user_v2_goat_sublevel_rc_bonus,
      //        debugInfo + ".user_v2_goat_sublevel_rc_bonus",
      //      ),
      // 2021-06-25 / Ivan / switch to XP
      //      v2_points_for_complete_single_challenge: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.v2_points_for_complete_single_challenge,
      //        debugInfo + ".v2_points_for_complete_single_challenge",
      //      ),
      //      v2_points_for_complete_all_daily_challenges: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.v2_points_for_complete_all_daily_challenges,
      //        debugInfo + ".v2_points_for_complete_all_daily_challenges",
      //      ),
      v3_xpoints_for_complete_single_challenge:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.v3_xpoints_for_complete_single_challenge,
          debugInfo + ".v3_xpoints_for_complete_single_challenge",
        ),
      v3_xpoints_for_complete_all_daily_challenges:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.v3_xpoints_for_complete_all_daily_challenges,
          debugInfo + ".v3_xpoints_for_complete_all_daily_challenges",
        ),

      error_codes_without_modal_error: AdapterHelper.decode_array_of_integers(
        jsondata.error_codes_without_modal_error,
        debugInfo + ".error_codes_without_modal_error",
      ),

      // 2022-04-27 / Ivan / add support for SGP quote fatal error
      error_codes_for_fatal_sqp_quote_error:
        AdapterHelper.decode_array_of_integers(
          jsondata.error_codes_for_fatal_sqp_quote_error,
          debugInfo + ".error_codes_for_fatal_sqp_quote_error",
        ),
      // 2022-05-15 / Ivan / add support for enable/disabe in ticket SGP errors
      error_codes_for_hide_in_ticket_sqp_error:
        AdapterHelper.decode_array_of_integers(
          jsondata.error_codes_for_hide_in_ticket_sqp_error,
          debugInfo + ".error_codes_for_hide_in_ticket_sqp_error",
        ),
      onboarding_ad_amount_bonus: ValidationUtils.assert_positive_integer(
        jsondata.onboarding_ad_amount_bonus,
        debugInfo + ".onboarding_ad_amount_bonus",
      ),
      onboarding_ad_amount_purchase: ValidationUtils.assert_positive_integer(
        jsondata.onboarding_ad_amount_purchase,
        debugInfo + ".onboarding_ad_amount_purchase",
      ),
      // 2021-03-29 / Ivan / temp hack - protocol is in percent, convert to promiles
      max_cashback_in_promiles_for_slide:
        10 *
        ValidationUtils.assert_positive_integer(
          jsondata.max_cashback_percent_for_slide,
          debugInfo + ".max_cashback_percent_for_slide",
        ),
      // 2021-05-19 / Ivan / introduce per-event configuration for 'More Markets' screen layout
      conflict_classes: AdapterHelper.decode_array<Data__SB_Conflict_Class_Def>(
        Adapter__SB_Conflict_Class_Def.decode,
        jsondata.conflict_classes,
        debugInfo + ".conflict_classes",
      ),
      //      default_ui_mode: validate_TServerUIMode(
      //        jsondata.default_ui_mode,
      //        debugInfo + ".default_ui_mode",
      //      ),
      // 2021-12-23 / Ivan / introducing zmq support

      // 2021-12-16 / Ivan / moving to user profile
      /*
      enable_gift_cards: ValidationUtils.assert_boolean(
        jsondata.enable_gift_cards,
        debugInfo + ".enable_gift_cards",
      ),
*/
      default_pdata: Adapter__SB_Permissions_And_Common_Data.decode(
        jsondata.default_pdata,
        debugInfo + ".default_pdata",
      ),
      // 2022-01-31 / Ivan / introduce support for persona v2
      amount_which_requires_level_2_verification:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.amount_which_requires_level_2_verification,
          debugInfo + ".amount_which_requires_level_2_verification",
        ),

      // 2022-02-10 / Ivan / introduce server control on config/promo messages for referral program
      referral_bonus__percent_from_child_purchase:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.referral_bonus__percent_from_child_purchase,
          debugInfo + ".referral_bonus__percent_from_child_purchase",
        ),
      referral_bonus__text_1: ValidationUtils.assert_optional_string(
        jsondata.referral_bonus__text_1,
        debugInfo + ".referral_bonus__text_1",
      ),
      referral_bonus__text_2: ValidationUtils.assert_optional_string(
        jsondata.referral_bonus__text_2,
        debugInfo + ".referral_bonus__text_2",
      ),
      server_config__text_11: ValidationUtils.assert_optional_string(
        jsondata.server_config__text_11,
        debugInfo + ".server_config__text_11",
      ),
      server_config__text_21: ValidationUtils.assert_optional_string(
        jsondata.server_config__text_21,
        debugInfo + ".server_config__text_21",
      ),
      // 2022-12-05 / Ivan / new server side config texts
      server_config__text_31: ValidationUtils.assert_optional_string(
        jsondata.server_config__text_31,
        debugInfo + ".server_config__text_31",
      ),
      server_config__text_32: ValidationUtils.assert_optional_string(
        jsondata.server_config__text_32,
        debugInfo + ".server_config__text_32",
      ),
      server_config__text_33: ValidationUtils.assert_optional_string(
        jsondata.server_config__text_33,
        debugInfo + ".server_config__text_33",
      ),
      server_config__text_41: ValidationUtils.assert_optional_string(
        jsondata.server_config__text_41,
        debugInfo + ".server_config__text_41",
      ),

      // 2022-12-20 / Ivan / add global flag which controls how users can register / login
      // 101 => disable sign-in via email
      auth_mode: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.auth_mode,
        debugInfo + ".auth_mode",
      ),

      rpm_status_check_url: ValidationUtils.assert_non_empty_string(
        jsondata.rpm_status_check_url,
        debugInfo + ".rpm_status_check_url",
      ),
      rpm_status_check_substring: ValidationUtils.assert_non_empty_string(
        jsondata.rpm_status_check_substring,
        debugInfo + ".rpm_status_check_substring",
      ),
    };
  }
}

export class Adapter__SB_Social_Picks_Stats {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Social_Picks_Stats {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      s_picks_count: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_picks_count,
        debugInfo + ".s_picks_count",
      ),
      s_5005_v2_tokens: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_5005_v2_tokens,
        debugInfo + ".s_5005_v2_tokens",
      ),
      // 2021-06-25 / Ivan / moving to xpoints stats
      //
      //      s_5006_v2_points: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.s_5006_v2_points,
      //        debugInfo + ".s_5006_v2_points",
      //      ),
      s_5007_v2_credits: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_5007_v2_credits,
        debugInfo + ".s_5007_v2_credits",
      ),
      // 2021-06-25 / Ivan / moving to xpoints stats
      s_5009_v3_xpoints: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_5009_v3_xpoints,
        debugInfo + ".s_5009_v3_xpoints",
      ),
      // 2021-07-11 / Ivan / introduce new user profile stats
      s_6011_best_win: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_6011_best_win,
        debugInfo + ".s_6011_best_win",
      ),
      s_6021_skill_coeff: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_6021_skill_coeff,
        debugInfo + ".s_6021_skill_coeff",
      ),
    };
  }
}

// 2021-04-16 / Ivan / introduce user attributes
export class Adapter__SB_UserProfile_Attribute {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_UserProfile_Attribute {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      code: ValidationUtils.assert_any_integer(
        jsondata.code,
        debugInfo + ".code",
      ),
      value: ValidationUtils.assert_optional_string(
        jsondata.value,
        debugInfo + ".value",
      ),
      state: ValidationUtils.assert_any_integer(
        jsondata.state,
        debugInfo + ".state",
      ),
      min_claim_stamp_seconds_utc:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.min_claim_stamp_seconds_utc,
          debugInfo + ".min_claim_stamp_seconds_utc",
        ),
    };
  }
}

class AdapterPickPlayThoroughPod {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Pick["play_through_data"]["pods"][number] {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      risk_amount: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.risk_amount,
        debugInfo + ".risk_amount",
      ),
      pod_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.pod_fkey,
        debugInfo + ".pod_fkey",
      ),
      v_info: ValidationUtils.assert_non_empty_string(
        jsonData.v_info,
        debugInfo + ".v_info",
      ),
    };
  }
}

class AdapterPickPlayThroughData {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Pick["play_through_data"] {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      pods: AdapterHelper.decode_array(
        AdapterPickPlayThoroughPod.decode,
        jsonData.pods,
        debugInfo + ".jsonData",
      ),
    };
  }
}

export class Adapter__SB_UserProfile {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_UserProfile {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    // 2021-01-31 / Ivan / introduce qtokens
    const r_5005_v2_tokens = ValidationUtils.assert_any_integer(
      jsondata.r_5005_v2_tokens,
      debugInfo + ".r_5005_v2_tokens",
    );

    const r_5008_v2_qtokens = ValidationUtils.assert_any_integer(
      jsondata.r_5008_v2_qtokens,
      debugInfo + ".r_5008_v2_qtokens",
    );

    // 2021-01-31 / Ivan / mobile app considers both types of tokens as identical with one exception (see below)
    const amount_v2_tokens = r_5005_v2_tokens + r_5008_v2_qtokens;

    // 2021-07-02 / Ivan / restore old scale (1000), rename field
    // 2021-01-31 / Ivan / only real tokens can give points
    const amount_max_v3_xpoints_for_place_pick = r_5005_v2_tokens / 1000;

    /*
    console.log('');
    console.log('');
    console.log('== jsondata.payment_tokens ==');
    console.log(jsondata.payment_tokens);
    console.log('');
    console.log('');
*/

    return {
      r_5011_v5_playable_fliff_cash: ValidationUtils.assert_any_integer(
        jsondata.r_5011_v5_playable_fliff_cash,
        debugInfo + ".r_5011_v5_playable_fliff_cash",
      ),
      r_5013_v5_redeemable_fliff_cash: ValidationUtils.assert_any_integer(
        jsondata.r_5013_v5_redeemable_fliff_cash,
        debugInfo + ".r_5013_v5_redeemable_fliff_cash",
      ),
      amount_v2_tokens,
      amount_max_v3_xpoints_for_place_pick:
        amount_max_v3_xpoints_for_place_pick,

      obj_version: ValidationUtils.assert_positive_integer(
        jsondata.obj_version,
        debugInfo + ".obj_version",
      ),
      // 2021-06-26 / Ivan / no longer needed
      // 2020-12-18 / Ivan / introduce v2 resources
      //      amount_v2_points: ValidationUtils.assert_any_integer(
      //        jsondata.r_5006_v2_points,
      //        debugInfo + ".r_5006_v2_points",
      //      ),
      // 2021-07-09 / Ivan / remove support for d_5007_v2_credits
      //      amount_v2_credits: ValidationUtils.assert_any_integer(
      //        jsondata.r_5007_v2_credits,
      //        debugInfo + ".r_5007_v2_credits",
      //      ),
      // 2021-06-19 / Ivan / introduce XP
      amount_v3_xpoints: ValidationUtils.assert_any_integer(
        jsondata.r_5009_v3_xpoints,
        debugInfo + ".r_5009_v3_xpoints",
      ),
      // 2020-05-30 / Ivan / from now on - assume that all resources may have negative values -
      // for example after annulated transaction
      // UI will have deal with this
      // 2021-02-12 / Ivan / we no longer support gold coins
      //      amount_gold_coins: ValidationUtils.assert_any_integer(
      //        jsondata.r_5001_gold_coins,
      //        debugInfo + ".r_5001_gold_coins",
      //      ),
      amount_rewards_cash_in_cents: ValidationUtils.assert_any_integer(
        jsondata.r_5004_rewards_cash,
        debugInfo + ".r_5004_rewards_cash",
      ),
      // 2022-06-13 / Ivan / switching from RC to XP, tracking XP progress
      amount_xp_bonus_progress_tokens: ValidationUtils.assert_any_integer(
        jsondata.r_5010_v4_xp_bonus_progress_tokens,
        debugInfo + ".r_5010_v4_xp_bonus_progress_tokens",
      ),
      //      amount_iap_promo_tokens: ValidationUtils.assert_any_integer(
      //        jsondata.r_5021_iap_promo_tokens,
      //        debugInfo + ".r_5021_iap_promo_tokens",
      //      ),
      //free_bundle_available_after_timestamp_utc:
      // ValidationUtils.assert_positive_integer_or_zero(
      //  jsondata.free_bundle_available_after_timestamp_utc,
      //  debugInfo + ".free_bundle_available_after_timestamp_utc",
      //        ),
      //      free_v2_current_bundle_id: ValidationUtils.assert_positive_integer(
      //        jsondata.free_v2_current_bundle_id,
      //        debugInfo + ".free_v2_current_bundle_id",
      //      ),
      sb_affiliate_hash: ValidationUtils.assert_non_empty_string(
        jsondata.sb_affiliate_hash,
        debugInfo + ".sb_affiliate_hash",
      ),
      open_iap_purchases_count: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.open_iap_purchases_count,
        debugInfo + ".open_iap_purchases_count",
      ),
      // 2021-03-25 / Ivan / add monitoring for open orders
      open_orders_count: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.open_orders_count,
        debugInfo + ".open_orders_count",
      ),
      // 2020-12-27 / Ivan / moving to v2 mechanics
      // 2021-06-30 / Ivan / no longer used
      //      user_v2_points_level: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.user_v2_points_level,
      //        debugInfo + ".user_v2_points_level",
      //      ),
      may_display_rate_us_nag: ValidationUtils.assert_boolean(
        jsondata.may_display_rate_us_nag,
        debugInfo + ".may_display_rate_us_nag",
      ),
      may_display_rate_us_reason: ValidationUtils.assert_optional_string(
        jsondata.may_display_rate_us_reason,
        debugInfo + ".may_display_rate_us_reason",
      ),
      days_left_until_withdraw_tax_level_1:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.days_left_until_withdraw_tax_level_1,
          debugInfo + ".days_left_until_withdraw_tax_level_1",
        ),
      fc_limits:
        AdapterHelper.decode_array<Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def>(
          Adapter__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def.decode,
          jsondata.fc_limits,
          debugInfo + ".fc_limits",
        ),
      v3_limits:
        AdapterHelper.decode_array<Data__SB_v3_Straight_Pick_Selection_Limit_Def>(
          Adapter__SB_v3_Straight_Pick_Selection_Limit_Def.decode,
          jsondata.v3_limits,
          debugInfo + ".v3_limits",
        ),
      v2_withdraw_limits_allowed_amount: ValidationUtils.assert_any_integer(
        jsondata.v2_withdraw_limits_allowed_amount,
        debugInfo + ".v2_withdraw_limits_allowed_amount",
      ),
      v2_withdraw_limits_allowed_fliff_cash_amount:
        ValidationUtils.assert_any_integer(
          jsondata.v2_withdraw_limits_allowed_fliff_cash_amount,
          debugInfo + ".v2_withdraw_limits_allowed_fliff_cash_amount",
        ),
      v2_withdraw_limits_allowed_rewards_cash_amount:
        ValidationUtils.assert_any_integer(
          jsondata.v2_withdraw_limits_allowed_rewards_cash_amount,
          debugInfo + ".v2_withdraw_limits_allowed_rewards_cash_amount",
        ),
      //      v2_withdraw_limits_progress: ValidationUtils.assert_any_integer(
      //        jsondata.v2_withdraw_limits_progress,
      //        debugInfo + ".v2_withdraw_limits_progress",
      //      ),
      //      v2_withdraw_limits_max: ValidationUtils.assert_any_integer(
      //        jsondata.v2_withdraw_limits_max,
      //        debugInfo + ".v2_withdraw_limits_max",
      //      ),
      //      v2_withdraw_v_limit_min: ValidationUtils.assert_non_empty_string(
      //        jsondata.v2_withdraw_v_limit_min,
      //        debugInfo + ".v2_withdraw_v_limit_min",
      //      ),
      //      v2_withdraw_v_limit_max: ValidationUtils.assert_non_empty_string(
      //        jsondata.v2_withdraw_v_limit_max,
      //        debugInfo + ".v2_withdraw_v_limit_max",
      //      ),
      last_withdraw_visual_date: ValidationUtils.assert_optional_string(
        jsondata.last_withdraw_visual_date,
        debugInfo + ".last_withdraw_visual_date",
      ),
      next_withdraw_min_visual_date: ValidationUtils.assert_optional_string(
        jsondata.next_withdraw_min_visual_date,
        debugInfo + ".next_withdraw_min_visual_date",
      ),
      next_withdraw_days_left: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.next_withdraw_days_left,
        debugInfo + ".next_withdraw_days_left",
      ),
      this_week_stats: Adapter__SB_Social_Picks_Stats.decode(
        jsondata.this_week_stats,
        debugInfo + ".this_week_stats",
      ),
      prev_week_stats: Adapter__SB_Social_Picks_Stats.decode(
        jsondata.prev_week_stats,
        debugInfo + ".prev_week_stats",
      ),
      allowed_to_submit_orders: ValidationUtils.assert_boolean(
        jsondata.allowed_to_submit_orders,
        debugInfo + ".allowed_to_submit_orders",
      ),
      // 2021-04-16 / Ivan / introduce user attributes
      attributes: AdapterHelper.decode_array<Data__SB_UserProfile_Attribute>(
        Adapter__SB_UserProfile_Attribute.decode,
        jsondata.attributes,
        debugInfo + ".attributes",
      ),
      // 2021-08-16 / Ivan / introduce referrer info
      referrer_id: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.referrer_id,
        debugInfo + ".referrer_id",
      ),
      referrer_username: ValidationUtils.assert_optional_string(
        jsondata.referrer_username,
        debugInfo + ".referrer_username",
      ),
      own_bonus_code: ValidationUtils.assert_optional_string(
        jsondata.own_bonus_code,
        debugInfo + ".own_bonus_code",
      ),
      is_user_id_verified: ValidationUtils.assert_boolean(
        jsondata.is_user_id_verified,
        debugInfo + ".is_user_id_verified",
      ),
      // 2021-12-17 / Ivan / move survey config to server
      //      enable_survey: ValidationUtils.assert_boolean(
      //        jsondata.enable_survey,
      //        debugInfo + ".enable_survey",
      //      ),
      survey_title: ValidationUtils.assert_optional_string(
        jsondata.survey_title,
        debugInfo + ".survey_title",
      ),
      survey_logo_url: ValidationUtils.assert_optional_string(
        jsondata.survey_logo_url,
        debugInfo + ".survey_logo_url",
      ),
      survey_description: ValidationUtils.assert_optional_string(
        jsondata.survey_description,
        debugInfo + ".survey_description",
      ),
      survey_button_label: ValidationUtils.assert_optional_string(
        jsondata.survey_button_label,
        debugInfo + ".survey_button_label",
      ),
      survey_url: ValidationUtils.assert_optional_string(
        jsondata.survey_url,
        debugInfo + ".survey_url",
      ),
      survey_webview_title: ValidationUtils.assert_optional_string(
        jsondata.survey_webview_title,
        debugInfo + ".survey_webview_title",
      ),

      d_51071_withdraw__locked_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51071_withdraw__locked_amount,
          debugInfo + ".d_51071_withdraw__locked_amount",
        ),
      d_51072_withdraw__locked_count:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51072_withdraw__locked_count,
          debugInfo + ".d_51072_withdraw__locked_count",
        ),
      // 2022-01-31 / Ivan / introduce support for persona v2
      d_51073_withdraw__total_approved_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51073_withdraw__total_approved_amount,
          debugInfo + ".d_51073_withdraw__total_approved_amount",
        ),
      // 2022-02-04 / Ivan / enforce enum
      identity_verification_status: validate_TIdentityVerificationStatus(
        jsondata.identity_verification_status,
        debugInfo + ".identity_verification_status",
      ),
      //      identity_verification_status:
      //        ValidationUtils.assert_positive_integer_or_zero(
      //          jsondata.identity_verification_status,
      //          debugInfo + ".identity_verification_status",
      //        ),
      // 2021-10-27 / Ivan / add protocol support for pending picks
      d_51001_tokens_locked_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51001_tokens_locked_amount,
          debugInfo + ".d_51001_tokens_locked_amount",
        ),
      d_51002_tokens_active_picks_count:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51002_tokens_active_picks_count,
          debugInfo + ".d_51002_tokens_active_picks_count",
        ),
      d_51003_fliff_cash_locked_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51003_fliff_cash_locked_amount,
          debugInfo + ".d_51003_fliff_cash_locked_amount",
        ),
      d_51004_fliff_cash_active_picks_count:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51004_fliff_cash_active_picks_count,
          debugInfo + ".d_51004_fliff_cash_active_picks_count",
        ),
      //      personalized_ui_mode: validate_TServerUIMode(
      //        jsondata.personalized_ui_mode,
      //        debugInfo + ".personalized_ui_mode",
      //      ),
      // 2021-12-04 / Ivan / introduce delay per user
      pick_delay_acceptance_timeout_millis:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.pick_delay_acceptance_timeout_millis,
          debugInfo + ".pick_delay_acceptance_timeout_millis",
        ),
      withdraw_7303__CASH_APP__max_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_7303__CASH_APP__max_amount,
          debugInfo + ".withdraw_7303__CASH_APP__max_amount",
        ),
      withdraw_7304__SKRILL__max_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_7304__SKRILL__max_amount,
          debugInfo + ".withdraw_7304__SKRILL__max_amount",
        ),
      withdraw_7305__BITCOIN__max_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_7305__BITCOIN__max_amount,
          debugInfo + ".withdraw_7305__BITCOIN__max_amount",
        ),
      withdraw_7306__MAZOOMA_VERIFIED__max_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_7306__MAZOOMA_VERIFIED__max_amount,
          debugInfo + ".withdraw_7306__MAZOOMA_VERIFIED__max_amount",
        ),
      withdraw_7307__MAZOOMA_INSTANT__max_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_7307__MAZOOMA_INSTANT__max_amount,
          debugInfo + ".withdraw_7307__MAZOOMA_INSTANT__max_amount",
        ),
      withdraw_7308__FISERV_VERIFIED__max_amount:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_7308__FISERV_VERIFIED__max_amount,
          debugInfo + ".withdraw_7308__FISERV_VERIFIED__max_amount",
        ),
      withdraw_min_days_between_withdraws:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_min_days_between_withdraws,
          debugInfo + ".withdraw_min_days_between_withdraws",
        ),
      withdraw_max_days_to_process_withdraw:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_max_days_to_process_withdraw,
          debugInfo + ".withdraw_max_days_to_process_withdraw",
        ),
      withdraw_tax_level_2_in_percents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.withdraw_tax_level_2_in_percents,
          debugInfo + ".withdraw_tax_level_2_in_percents",
        ),
      // 2022-01-15 / Ivan / moved to SB_Permissions_And_Common_Data
      //      enable_gift_cards: ValidationUtils.assert_boolean(
      //        jsondata.enable_gift_cards,
      //        debugInfo + ".enable_gift_cards",
      //      ),
      // 2020-12-24 / Ivan / adding user_id - we'd like to deprecate Data_UserAccount
      user_id: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.user_id,
        debugInfo + ".user_id",
      ),

      personal_info: Adapter__SB_User_Personal_Info.decode(
        jsondata.personal_info,
        debugInfo + ".personal_info",
      ),
      // 2022-01-31 / Ivan / lets try to get rid of ServerObj_FliffApp_UserAccount
      avatar_id: ValidationUtils.assert_non_empty_string(
        jsondata.avatar_id,
        debugInfo + ".avatar_id",
      ),
      // 2022-02-11 / Ivan / real username may be missing during 'social login' + 'complete profile'
      // for the time being, server will return 'profile_not_completed', this value is not supposed to be displayed anywhere
      username: ValidationUtils.assert_non_empty_string(
        jsondata.username,
        debugInfo + ".username",
      ),
      // 2022-08-05 / Ivan / introduce server side control over available methods for bundle purchase
      avalable_payment_vendors: AdapterHelper.decode_array_of_integers(
        jsondata.avalable_payment_vendors,
        debugInfo + ".avalable_payment_vendors",
      ),
      // 2022-10-01 / Ivan / provide information which mechanics was used when account was created
      account_created_mechanics_version:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.account_created_mechanics_version,
          debugInfo + ".account_created_mechanics_version",
        ),
      // 2022-10-26 / Ivan / added some stats in order to help mobile app to track first pick/purchase
      s_1_total_picks: ValidationUtils.assert_any_integer(
        jsondata.s_1_total_picks,
        debugInfo + ".s_1_total_picks",
      ),
      s_3_total_money_in_purchase_count: ValidationUtils.assert_any_integer(
        jsondata.s_3_total_money_in_purchase_count,
        debugInfo + ".s_3_total_money_in_purchase_count",
      ),
      // 2022-12-20 / Ivan / introduce server side control over account details verification
      account_details_status: validate_TUser_Account_Details_Status(
        jsondata.account_details_status,
        debugInfo + ".account_details_status",
      ),
      place_pick_play_through_mode: validatePlacePickPlayThroughMode(
        jsondata.place_pick_play_through_mode,
        debugInfo + ".place_pick_play_through_mode",
      ),
    };
  }
}

export class Adapter__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FliffCash_Place_Straight_Pick_Selection_Limit_Def {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      limit_code: ValidationUtils.assert_any_integer(
        jsondata.limit_code,
        debugInfo + ".limit_code",
      ),
      max_amount_in_cents: ValidationUtils.assert_any_integer(
        jsondata.max_amount_in_cents,
        debugInfo + ".max_amount_in_cents",
      ),
    };
  }
}

export class Adapter__SB_v3_Straight_Pick_Selection_Limit_Def {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_v3_Straight_Pick_Selection_Limit_Def {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      currency: validate_TPickCurrency(
        jsondata.currency,
        debugInfo + ".currency",
      ),
      is_inplay: ValidationUtils.assert_boolean(
        jsondata.is_inplay,
        debugInfo + ".is_inplay",
      ),
      limit_code: ValidationUtils.assert_any_integer(
        jsondata.limit_code,
        debugInfo + ".limit_code",
      ),
      max_amount: ValidationUtils.assert_any_integer(
        jsondata.max_amount,
        debugInfo + ".max_amount",
      ),
    };
  }
}

export class Adapter__SB_Points_Transaction_PeriodReport {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Points_Transaction_PeriodReport {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      period_request_id: ValidationUtils.assert_non_empty_string(
        jsondata.period_request_id,
        debugInfo + ".period_request_id",
      ),
      period_start_stamp_seconds_utc: ValidationUtils.assert_any_integer(
        jsondata.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_end_stamp_seconds_utc: ValidationUtils.assert_any_integer(
        jsondata.period_end_stamp_seconds_utc,
        debugInfo + ".period_end_stamp_seconds_utc",
      ),
      data: AdapterHelper.decode_array<IFCMTransaction>(
        AdapterFCMTransaction.decode,
        jsondata.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class Adapter__SB_Pick_Selection {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Pick_Selection {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    if (!jsondata.proposal_fkey) {
      jsondata.proposal_fkey = "unknown";
    }
    if (!jsondata.channel_name) {
      jsondata.channel_name = "";
    }

    return {
      id: ValidationUtils.assert_positive_integer(
        jsondata.id,
        debugInfo + ".id",
      ),
      is_conflict_in_play: ValidationUtils.assert_boolean(
        jsondata.is_conflict_in_play,
        debugInfo + ".is_conflict_in_play",
      ),
      in_play_state_desc: ValidationUtils.assert_optional_string(
        jsondata.in_play_state_desc,
        debugInfo + ".in_play_state_desc",
      ),
      settled_timestamp_utc: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validate_TPickSelectionState(jsondata.state, debugInfo + ".state"),
      channel_id: ValidationUtils.assert_positive_integer(
        jsondata.channel_id,
        debugInfo + ".channel_id",
      ),
      event_start_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsondata.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),

      coeff: ValidationUtils.assert_any_integer(
        jsondata.coeff,
        debugInfo + ".coeff",
      ),

      // 2020-10-25 / Ivan / moving to new set of texts
      t_141_selection_name: ValidationUtils.assert_non_empty_string(
        jsondata.t_141_selection_name,
        debugInfo + ".t_141_selection_name",
      ),
      t_142_selection_param_1: ValidationUtils.assert_optional_string(
        jsondata.t_142_selection_param_1,
        debugInfo + ".t_142_selection_param_1",
      ),
      t_131_market_name: ValidationUtils.assert_non_empty_string(
        jsondata.t_131_market_name,
        debugInfo + ".t_131_market_name",
      ),
      t_132_market_note: ValidationUtils.assert_optional_string(
        jsondata.t_132_market_note,
        debugInfo + ".t_132_market_note",
      ),
      t_121_event_info: ValidationUtils.assert_non_empty_string(
        jsondata.t_121_event_info,
        debugInfo + ".t_121_event_info",
      ),
      // 2021-07-06 / Ivan / introduce support for activity feed
      conflict_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      market_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.market_fkey,
        debugInfo + ".market_fkey",
      ),
      proposal_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      is_copy_available: ValidationUtils.assert_boolean(
        jsondata.is_copy_available,
        debugInfo + ".is_copy_available",
      ),
      channel_name: ValidationUtils.assert_optional_string(
        jsondata.channel_name,
        debugInfo + ".channel_name",
      ),
    };
  }
}

export class Adapter__SB_Pick {
  public static decode(jsondata: TAnyAlias, debugInfo: string): Data__SB_Pick {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    // 2021-07-06 / Ivan / those attributes exist only in activity feed response
    if (!jsondata.user_id) {
      jsondata.user_id = 0;
    }
    if (!jsondata.username) {
      jsondata.username = "";
    }
    if (!jsondata.avatar_id) {
      jsondata.avatar_id = "";
    }
    if (!jsondata.play_through_data) {
      jsondata.play_through_data = { pods: [] };
    }

    return {
      id: ValidationUtils.assert_positive_integer(
        jsondata.id,
        debugInfo + ".id",
      ),
      created_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsondata.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      settled_timestamp_utc: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.settled_timestamp_utc,
        debugInfo + ".settled_timestamp_utc",
      ),
      state: validate_TPickSelectionState(jsondata.state, debugInfo + ".state"),
      type: validate_TPickType(jsondata.type, debugInfo + ".type"),
      currency: validate_TPickCurrency(
        jsondata.currency,
        debugInfo + ".currency",
      ),
      risk_amount: ValidationUtils.assert_positive_integer(
        jsondata.risk_amount,
        debugInfo + ".risk_amount",
      ),
      payout_amount: ValidationUtils.assert_any_integer(
        jsondata.payout_amount,
        debugInfo + ".payout_amount",
      ),
      coeff: ValidationUtils.assert_any_integer(
        jsondata.coeff,
        debugInfo + ".coeff",
      ),
      selections: AdapterHelper.decode_array<Data__SB_Pick_Selection>(
        Adapter__SB_Pick_Selection.decode,
        jsondata.selections,
        debugInfo + ".selections",
      ),
      userId: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.user_id,
        debugInfo + ".user_id",
      ),
      username: ValidationUtils.assert_optional_string(
        jsondata.username,
        debugInfo + ".username",
      ),
      avatar_id: ValidationUtils.assert_optional_string(
        jsondata.avatar_id,
        debugInfo + ".avatar_id",
      ),
      stake_category: ValidationUtils.assert_positive_integer(
        jsondata.stake_category,
        debugInfo + ".stake_category",
      ),
      copy_count: ValidationUtils.assert_optional_integer(
        jsondata.copy_count,
        debugInfo + ".copy_count",
      ),
      play_through_data: AdapterPickPlayThroughData.decode(
        jsondata.play_through_data,
        debugInfo + ".play_through_data",
      ),
    };
  }
}

export class Adapter__SB_Pick_PeriodReport {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Pick_PeriodReport {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      period_request_id: ValidationUtils.assert_non_empty_string(
        jsondata.period_request_id,
        debugInfo + ".period_request_id",
      ),
      period_start_stamp_seconds_utc: ValidationUtils.assert_any_integer(
        jsondata.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_end_stamp_seconds_utc: ValidationUtils.assert_any_integer(
        jsondata.period_end_stamp_seconds_utc,
        debugInfo + ".period_end_stamp_seconds_utc",
      ),
      data: AdapterHelper.decode_array<Data__SB_Pick>(
        Adapter__SB_Pick.decode,
        jsondata.data,
        debugInfo + ".data",
      ),
      //      pending_picks_stats: Adapter__SB_Social_Picks_Stats.decode(
      //        jsondata.pending_picks_stats,
      //        debugInfo + ".pending_picks_stats",
      //      ),
      v3_profile_summary: Adapter__SB_v3_User_Profile_Summary.decode(
        jsondata.v3_profile_summary,
        debugInfo + ".v3_profile_summary",
      ),
      // 2021-07-13 / Ivan / introduce support for 'other user profile/stats'
      other_user_id: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.other_user_id,
        debugInfo + ".other_user_id",
      ),
    };
  }
}

export class Adapter__SB_SportEvent {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_SportEvent {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    const conflict_fkey = ValidationUtils.assert_non_empty_string(
      jsondata.conflict_fkey,
      debugInfo + ".conflict_fkey",
    );

    // 2020-11-15 / Ivan / temp hack, need more formal attribute
    const isLive = conflict_fkey.endsWith("inplay");

    // console.log("isLive:", isLive, ", conflict_fkey:", conflict_fkey)

    const filter_ids = AdapterHelper.decode_array_of_integers(
      jsondata.filter_ids,
      debugInfo + ".filter_ids",
    );

    // 2022-01-20 / Ivan / introduce display mode per event - will be used to fine tune the game card
    let displayMode: number = 0;
    // ugly magic constant for BOOSTED_OFFERS_CHANNELS_FOR_ROOKIES
    if (filter_ids.includes(-801)) {
      displayMode = 1;
    }

    return {
      // 2020-10-17 / Ivan / moving to new fkey concept, supplied by server
      conflict_fkey: conflict_fkey,
      //      conflict_fkey: ValidationUtils.assert_non_empty_string(
      //        jsondata.conflict_fkey,
      //        debugInfo + ".conflict_fkey",
      //      ),
      //      x_id: ValidationUtils.assert_positive_integer(
      //        jsondata.id,
      //        debugInfo + ".id",
      //      ),
      channelId: ValidationUtils.assert_positive_integer(
        jsondata.channel_id,
        debugInfo + ".channel_id",
      ),
      event_start_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsondata.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      homeTeamName: ValidationUtils.assert_non_empty_string(
        jsondata.home_team_name,
        debugInfo + ".home_team_name",
      ),
      awayTeamName: ValidationUtils.assert_non_empty_string(
        jsondata.away_team_name,
        debugInfo + ".away_team_name",
      ),
      isLive: isLive,
      // 2020-08-18
      live_state_desc: ValidationUtils.assert_optional_string(
        jsondata.live_state_desc,
        debugInfo + ".live_state_desc",
      ),
      live_score_home: ValidationUtils.assert_any_integer(
        jsondata.live_home_score,
        debugInfo + ".live_home_score",
      ),
      live_score_away: ValidationUtils.assert_any_integer(
        jsondata.live_away_score,
        debugInfo + ".live_away_score",
      ),
      live_x_score_home: ValidationUtils.assert_optional_string(
        jsondata.live_x_score_home,
        debugInfo + ".live_x_score_home",
      ),
      live_x_score_away: ValidationUtils.assert_optional_string(
        jsondata.live_x_score_away,
        debugInfo + ".live_x_score_away",
      ),
      event_note: ValidationUtils.assert_optional_string(
        jsondata.event_note,
        debugInfo + ".event_note",
      ),
      event_watch_url: ValidationUtils.assert_optional_string(
        jsondata.event_watch_url,
        debugInfo + ".event_watch_url",
      ),
      filter_ids: filter_ids,
      displayMode: displayMode,
      // 2020-11-12 / Ivan / attach estimated markets count (new model) to conflict
      estimated_markets_count: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.estimated_markets_count,
        debugInfo + ".estimated_markets_count",
      ),
      expect_live_betting: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.expect_live_betting,
        debugInfo + ".expect_live_betting",
      ),
      live_status: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.live_status,
        debugInfo + ".live_status",
      ),
      conflict_class_code: ValidationUtils.assert_positive_integer(
        jsondata.conflict_class_code,
        debugInfo + ".conflict_class_code",
      ),
      // 2022-01-15 / Ivan / introduce permission categories
      // mobile app will display this event only if user has appropriate permissions
      pcats: AdapterHelper.decode_array_of_integers(
        jsondata.pcats,
        debugInfo + ".pcats",
      ),
      // 2022-10-01 / Ivan / introduce sort order weight
      weight: ValidationUtils.assert_positive_integer(
        jsondata.weight,
        debugInfo + ".weight",
      ),
    };
  }
}

export class Adapter__SB_IAP_OwnTransaction {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): IDataSBIAPTransaction {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      own_transaction_id: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.own_transaction_id,
        debugInfo + ".own_transaction_id",
      ),
      own_invoice_id: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.own_invoice_id,
        debugInfo + ".own_invoice_id",
      ),
      iap_productId: ValidationUtils.assert_non_empty_string(
        jsondata.iap_productId,
        debugInfo + ".iap_productId",
      ),
      iap_transactionDate: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.iap_transactionDate,
        debugInfo + ".iap_transactionDate",
      ),
      iap_transactionId: ValidationUtils.assert_non_empty_string(
        jsondata.iap_transactionId,
        debugInfo + ".iap_transactionId",
      ),
      iap_android_purchaseToken: ValidationUtils.assert_optional_string(
        jsondata.iap_android_purchaseToken,
        debugInfo + ".iap_android_purchaseToken",
      ),
      level_1_skin: validateBundleOfferSkin(
        jsondata.level_1_skin,
        debugInfo + ".level_1_skin",
      ),
      amount_social_coins: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_social_coins,
        debugInfo + ".amount_social_coins",
      ),
      price_in_cents: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.price_in_cents,
        debugInfo + ".price_in_cents",
      ),
    };
  }
}

export class Adapter__SB_IAP_PaymentsToFinish {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_IAP_PaymentsToFinish {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      data: AdapterHelper.decode_array<IDataSBIAPTransaction>(
        Adapter__SB_IAP_OwnTransaction.decode,
        jsondata.data,
        debugInfo + ".data",
      ),
    };
  }
}

// export class Adapter__SB_GoldCoins_Leaderboard_Item {
//   public static decode(
//     jsondata: TAnyAlias,
//     debugInfo: string,
//
//   ): Data__SB_GoldCoins_Leaderboard_Item {
//     ValidationUtils.assert_non_null_data(jsondata, debugInfo);
//
//     return {
//       user_id: ValidationUtils.assert_positive_integer(
//         jsondata.user_id,
//         debugInfo + ".user_id",
//       ),
//       user_name: ValidationUtils.assert_non_empty_string(
//         jsondata.user_name,
//         debugInfo + ".user_name",
//       ),
//       position: ValidationUtils.assert_positive_integer(
//         jsondata.position,
//         debugInfo + ".position",
//       ),
//       amount_gold_coins: ValidationUtils.assert_positive_integer(
//         jsondata.amount_gold_coins,
//         debugInfo + ".amount_gold_coins",
//       ),
//     };
//   }
// }

// export class Adapter__SB_GoldCoins_Leaderboard_Report {
//   public static decode(
//     jsondata: TAnyAlias,
//     debugInfo: string,
//
//   ): Data__SB_GoldCoins_Leaderboard_Report {
//     ValidationUtils.assert_non_null_data(jsondata, debugInfo);
//
//     return {
//       period_type: validate_TRawPeriodType(
//         jsondata.period_type,
//         debugInfo + ".period_type",
//       ),
//       period_start_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
//         jsondata.period_start_stamp_seconds_utc,
//         debugInfo + ".period_start_stamp_seconds_utc",
//       ),
//       period_visual_start_date: ValidationUtils.assert_non_empty_string(
//         jsondata.period_visual_start_date,
//         debugInfo + ".period_visual_start_date",
//       ),
//       period_visual_end_date: ValidationUtils.assert_non_empty_string(
//         jsondata.period_visual_end_date,
//         debugInfo + ".period_visual_end_date",
//       ),
//       report_anchor_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
//         jsondata.report_anchor_stamp_seconds_utc,
//         debugInfo + ".report_anchor_stamp_seconds_utc",
//       ),
//       report_anchor_offset: ValidationUtils.assert_any_integer(
//         jsondata.report_anchor_offset,
//         debugInfo + ".report_anchor_offset",
//       ),
//       has_prev: ValidationUtils.assert_boolean(
//         jsondata.has_prev,
//         debugInfo + ".has_prev",
//       ),
//       has_next: ValidationUtils.assert_boolean(
//         jsondata.has_next,
//         debugInfo + ".has_next",
//       ),
//
//       data: AdapterHelper.decode_array<Data__SB_GoldCoins_Leaderboard_Item>(
//         Adapter__SB_GoldCoins_Leaderboard_Item.decode,
//         jsondata.data,
//         debugInfo + ".data",
//       ),
//     };
//   }
// }

// 2020-07-12 / Ivan / moving to v3 skill report
// export class Adapter__SB_Points_Leaderboard_Item {
//   public static decode(
//     jsondata: TAnyAlias,
//     debugInfo: string,
//
//   ): Data__SB_Points_Leaderboard_Item {
//     ValidationUtils.assert_non_null_data(jsondata, debugInfo);
//
//     return {
//       user_id: ValidationUtils.assert_positive_integer(
//         jsondata.user_id,
//         debugInfo + ".user_id",
//       ),
//       user_name: ValidationUtils.assert_non_empty_string(
//         jsondata.user_name,
//         debugInfo + ".user_name",
//       ),
//       position: ValidationUtils.assert_positive_integer(
//         jsondata.position,
//         debugInfo + ".position",
//       ),
//       amount_v2_credits: ValidationUtils.assert_positive_integer(
//         jsondata.amount_v2_credits,
//         debugInfo + ".amount_v2_credits",
//       ),
//       // 2021-02-13 / Ivan / may be zero for 'record for this user' - which is outside of top 50
//       amount_v2_bonus_tokens: ValidationUtils.assert_positive_integer_or_zero(
//         //      amount_v2_bonus_tokens: ValidationUtils.assert_positive_integer(
//         jsondata.amount_v2_bonus_tokens,
//         debugInfo + ".amount_v2_bonus_tokens",
//       ),
//     };
//   }
// }

// export class Adapter__SB_Points_Leaderboard_Report {
//   public static decode(
//     jsondata: TAnyAlias,
//     debugInfo: string,
//
//   ): Data__SB_Points_Leaderboard_Report {
//     ValidationUtils.assert_non_null_data(jsondata, debugInfo);
//
//     return {
//       period_type: validate_TRawPeriodType(
//         jsondata.period_type,
//         debugInfo + ".period_type",
//       ),
//       period_start_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
//         jsondata.period_start_stamp_seconds_utc,
//         debugInfo + ".period_start_stamp_seconds_utc",
//       ),
//       period_visual_start_date: ValidationUtils.assert_non_empty_string(
//         jsondata.period_visual_start_date,
//         debugInfo + ".period_visual_start_date",
//       ),
//       period_visual_end_date: ValidationUtils.assert_non_empty_string(
//         jsondata.period_visual_end_date,
//         debugInfo + ".period_visual_end_date",
//       ),
//       report_anchor_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
//         jsondata.report_anchor_stamp_seconds_utc,
//         debugInfo + ".report_anchor_stamp_seconds_utc",
//       ),
//       report_anchor_offset: ValidationUtils.assert_any_integer(
//         jsondata.report_anchor_offset,
//         debugInfo + ".report_anchor_offset",
//       ),
//       has_prev: ValidationUtils.assert_boolean(
//         jsondata.has_prev,
//         debugInfo + ".has_prev",
//       ),
//       has_next: ValidationUtils.assert_boolean(
//         jsondata.has_next,
//         debugInfo + ".has_next",
//       ),
//
//       data: AdapterHelper.decode_array<Data__SB_Points_Leaderboard_Item>(
//         Adapter__SB_Points_Leaderboard_Item.decode,
//         jsondata.data,
//         debugInfo + ".data",
//       ),
//     };
//   }
// }

export class Adapter__SB_Pick_Badge {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Pick_Badge {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      id: ValidationUtils.assert_positive_integer(
        jsondata.id,
        debugInfo + ".id",
      ),
      pick: Adapter__SB_Pick.decode(jsondata.pick, debugInfo + ".pick"),
    };
  }
}

export class Adapter__SB_Points_Leaderboard_Badge {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Points_Leaderboard_Badge {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    // 2020-12-29 / Ivan / temp hack / old leaderboard serves gold coins, new one serves v2 credits
    // temporary decode both
    // 2021-02-12 / Ivan / we no longer support gold coins
    //    if (!jsondata.amount_gold_coins) {
    //      jsondata.amount_gold_coins = 0;
    //    }
    //    if (!jsondata.amount_v2_credits) {
    //      jsondata.amount_v2_credits = 0;
    //    }

    return {
      id: ValidationUtils.assert_positive_integer(
        jsondata.id,
        debugInfo + ".id",
      ),
      amount_v2_credits: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_v2_credits,
        debugInfo + ".amount_v2_credits",
      ),
      amount_v2_bonus_tokens: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_v2_bonus_tokens,
        debugInfo + ".amount_v2_bonus_tokens",
      ),
      position: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.position,
        debugInfo + ".position",
      ),
      period_type: validate_TRawPeriodType(
        jsondata.period_type,
        debugInfo + ".period_type",
      ),
      period_start_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsondata.period_start_timestamp_utc,
        debugInfo + ".period_start_timestamp_utc",
      ),
      start_date: ValidationUtils.assert_non_empty_string(
        jsondata.start_date,
        debugInfo + ".start_date",
      ),
      end_date: ValidationUtils.assert_non_empty_string(
        jsondata.end_date,
        debugInfo + ".end_date",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenges_Report_Item {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): TDataSBPublicChallengesReportItem {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      id: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.id,
        debugInfo + ".id",
      ),
      position: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.position,
        debugInfo + ".position",
      ),
      type: validate_PublicChallengeType(jsondata.type, debugInfo + ".type"),
      is_completed: ValidationUtils.assert_boolean(
        jsondata.is_completed,
        debugInfo + ".is_completed",
      ),
      details: Adapter__SB_Public_Challenge_Details.decode(
        jsondata.details,
        debugInfo + ".details",
      ),
    } as TDataSBPublicChallengesReportItem;
  }
}

export class Adapter__SB_Public_Challenges_Report {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenges_Report {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      period_type: validate_TRawPeriodType(
        jsondata.period_type,
        debugInfo + ".period_type",
      ),
      period_start_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
        jsondata.period_start_stamp_seconds_utc,
        debugInfo + ".period_start_stamp_seconds_utc",
      ),
      period_visual_start_date: ValidationUtils.assert_non_empty_string(
        jsondata.period_visual_start_date,
        debugInfo + ".period_visual_start_date",
      ),
      period_visual_end_date: ValidationUtils.assert_non_empty_string(
        jsondata.period_visual_end_date,
        debugInfo + ".period_visual_end_date",
      ),
      report_anchor_stamp_seconds_utc: ValidationUtils.assert_positive_integer(
        jsondata.report_anchor_stamp_seconds_utc,
        debugInfo + ".report_anchor_stamp_seconds_utc",
      ),
      report_anchor_offset: ValidationUtils.assert_any_integer(
        jsondata.report_anchor_offset,
        debugInfo + ".report_anchor_offset",
      ),
      has_prev: ValidationUtils.assert_boolean(
        jsondata.has_prev,
        debugInfo + ".has_prev",
      ),
      has_next: ValidationUtils.assert_boolean(
        jsondata.has_next,
        debugInfo + ".has_next",
      ),
      is_today: ValidationUtils.assert_boolean(
        jsondata.is_today,
        debugInfo + ".is_today",
      ),
      // 2020-12-27 / Ivan / no longer used in v2 mechanics
      //      daily_bonus_amount: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.daily_bonus_amount,
      //        debugInfo + ".daily_bonus_amount",
      //      ),
      //      estimated_daily_bonus_amount: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.estimated_daily_bonus_amount,
      //        debugInfo + ".estimated_daily_bonus_amount",
      //      ),
      error_message: ValidationUtils.assert_optional_string(
        jsondata.error_message,
        debugInfo + ".error_message",
      ),
      data: AdapterHelper.decode_array<TDataSBPublicChallengesReportItem>(
        Adapter__SB_Public_Challenges_Report_Item.decode,
        jsondata.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenge_Details {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): TDataSBPublicChallengesReportItem["details"] {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    const challenge_type: PublicChallengeType = validate_PublicChallengeType(
      jsondata.challenge_type,
      debugInfo + ".challenge_type",
    );

    if (challenge_type === PublicChallengeType.CONST_17001_PLACE_PICK_TYPE) {
      return Adapter__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE.decode(
        jsondata,
        debugInfo,
      );
    }
    if (
      challenge_type ===
      PublicChallengeType.CONST_17002_PLACE_PICK_SELECTION_NO_CONFLICT
    ) {
      return Adapter__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT.decode(
        jsondata,
        debugInfo,
      );
    }

    if (
      challenge_type ===
      PublicChallengeType.CONST_17003_PLACE_PICK_SELECTION_FOR_CONFLICT
    ) {
      return Adapter__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT.decode(
        jsondata,
        debugInfo,
      );
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected challenge_type type [" + challenge_type + "]",
    );
  }
}

export class Adapter__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenge_Details__17001__PLACE_PICK_TYPE["details"] {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      challenge_type: validate_PublicChallengeType(
        jsondata.challenge_type,
        debugInfo + ".challenge_type",
      ),
      pick_type: validate_TPickType_or_null(
        jsondata.pick_type,
        debugInfo + ".pick_type",
      ),
      min_selections_count: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.min_selections_count,
        debugInfo + ".min_selections_count",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenge_Details__17002__PLACE_PICK_SELECTION_NO_CONFLICT["details"] {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      challenge_type: validate_PublicChallengeType(
        jsondata.challenge_type,
        debugInfo + ".challenge_type",
      ),
      conflict_type: validate_TConflictType_or_null(
        jsondata.conflict_type,
        debugInfo + ".conflict_type",
      ),
    };
  }
}

export class Adapter__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Public_Challenge_Details__17003__PLACE_PICK_SELECTION_FOR_CONFLICT["details"] {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      challenge_type: validate_PublicChallengeType(
        jsondata.challenge_type,
        debugInfo + ".challenge_type",
      ),
      conflict_type: validate_TConflictType(
        jsondata.conflict_type,
        debugInfo + ".conflict_type",
      ),
      conflict_id: ValidationUtils.assert_positive_integer(
        jsondata.conflict_id,
        debugInfo + ".conflict_id",
      ),
      channel_id: ValidationUtils.assert_positive_integer(
        jsondata.channel_id,
        debugInfo + ".channel_id",
      ),
      home_team_name: ValidationUtils.assert_non_empty_string(
        jsondata.home_team_name,
        debugInfo + ".home_team_name",
      ),
      away_team_name: ValidationUtils.assert_non_empty_string(
        jsondata.away_team_name,
        debugInfo + ".away_team_name",
      ),
      related_conflict_fkeys: AdapterHelper.decode_array_of_strings(
        jsondata.related_conflict_fkeys,
        debugInfo + ".related_conflict_fkeys",
      ),
    };
  }
}

export class Adapter__SB_BetProposal {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
    conflict_fkey: string,
    default_grouping_tag: string,
    revision_id: number,
  ): Data__SB_BetProposal {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    const global_parlay_set_check__fkey = conflict_fkey;

    // 2020-10-26 / Ivan / provide default grouping tag (used for radio-button-like-checks), allow child to override it
    let grouping_tag = ValidationUtils.assert_optional_string(
      jsondata.grouping_tag,
      debugInfo + ".grouping_tag",
    );

    if (grouping_tag === "") {
      grouping_tag = default_grouping_tag;
    }

    const global_radio_set_check__fkey = conflict_fkey + "__" + grouping_tag;

    // 2020-12-17 / Ivan / those params exist (optionally) only for type: CONST__7801__BOOSTED_OFFER, we may want better check
    if (!jsondata.t_201_sponsor_name) {
      jsondata.t_201_sponsor_name = "";
    }
    if (!jsondata.t_202_sponsor_logo_url) {
      jsondata.t_202_sponsor_logo_url = "";
    }
    if (!jsondata.parlays_not_allowed) {
      jsondata.parlays_not_allowed = 0;
    }
    if (!jsondata.t_301_user_profile_fkey) {
      jsondata.t_301_user_profile_fkey = "";
    }
    if (!jsondata.t_302_boosted_note) {
      jsondata.t_302_boosted_note = "";
    }
    if (!jsondata.t_303_related_conflict_id) {
      jsondata.t_303_related_conflict_id = 0;
    }

    const sgp_mode = ValidationUtils.assert_any_integer(
      jsondata.sgp_mode,
      debugInfo + ".sgp_mode",
    );

    const is_usable_for_sgp: boolean = sgp_mode > 0;

    return {
      // switching from id + hash to single identifier - proposal_fkey
      proposal_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.proposal_fkey,
        debugInfo + ".proposal_fkey",
      ),
      //2021-07-08 / Ivan / this field is now compulsory
      //      t_121_event_info: ValidationUtils.assert_optional_string(
      t_121_event_info: ValidationUtils.assert_non_empty_string(
        jsondata.t_121_event_info,
        debugInfo + ".t_121_event_info",
      ),
      //2021-07-08 / Ivan / this field is now compulsory
      //      t_131_market_name: ValidationUtils.assert_optional_string(
      t_131_market_name: ValidationUtils.assert_non_empty_string(
        jsondata.t_131_market_name,
        debugInfo + ".t_131_market_name",
      ),
      t_132_market_note: ValidationUtils.assert_optional_string(
        jsondata.t_132_market_note,
        debugInfo + ".t_132_market_note",
      ),
      t_141_selection_name: ValidationUtils.assert_non_empty_string(
        jsondata.t_141_selection_name,
        debugInfo + ".t_141_selection_name",
      ),
      t_142_selection_param_1: ValidationUtils.assert_optional_string(
        jsondata.t_142_selection_param_1,
        debugInfo + ".t_142_selection_param_1",
      ),
      t_151_cell_text_1: ValidationUtils.assert_optional_string(
        jsondata.t_151_cell_text_1,
        debugInfo + ".t_151_cell_text_1",
      ),
      t_201_sponsor_name: ValidationUtils.assert_optional_string(
        jsondata.t_201_sponsor_name,
        debugInfo + ".t_201_sponsor_name",
      ),
      t_202_sponsor_logo_url: ValidationUtils.assert_optional_string(
        jsondata.t_202_sponsor_logo_url,
        debugInfo + ".t_202_sponsor_logo_url",
      ),
      t_303_related_conflict_id: ValidationUtils.assert_optional_integer(
        jsondata.t_303_related_conflict_id,
        debugInfo + ".t_303_related_conflict_id",
      ),
      status: validate_TBetOrBetGroupStatus(
        jsondata.status,
        debugInfo + ".status",
      ),
      type: ValidationUtils.assert_positive_integer(
        jsondata.type,
        debugInfo + ".type",
      ),
      // 2020-11-06 / Ivan / no longer used
      //      pos: ValidationUtils.assert_positive_integer_or_zero(
      //        jsondata.pos,
      //        debugInfo + ".pos",
      //      ),
      coeff: ValidationUtils.assert_any_integer(
        jsondata.coeff,
        debugInfo + ".coeff",
      ),

      coeff_updated_utc: ValidationUtils.assert_positive_integer(
        jsondata.coeff_updated_utc,
        debugInfo + ".coeff_updated_utc",
      ),

      prev_coeff: ValidationUtils.assert_any_integer(
        jsondata.prev_coeff,
        debugInfo + ".prev_coeff",
      ),

      prev_coeff_updated_utc: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.prev_coeff_updated_utc,
        debugInfo + ".prev_coeff_updated_utc",
      ),

      change_type: validate_TMarketOfferSelectionChangeType(
        jsondata.change_type,
        debugInfo + ".change_type",
      ),

      leadership_flag: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.leadership_flag,
        debugInfo + ".leadership_flag",
      ),
      market_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.market_fkey,
        debugInfo + ".market_fkey",
      ),
      market_proposal_type: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.market_proposal_type,
        debugInfo + ".market_proposal_type",
      ),

      // 2021-04-28 / Ivan / quick hack - allow server to enforce that this selection cannot participate in parlays
      parlays_not_allowed: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.parlays_not_allowed,
        debugInfo + ".parlays_not_allowed",
      ),
      // 2021-09-12 / Ivan / additional param to control parlay checks - see Trello 1599
      parlay_calibrate: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.parlay_calibrate,
        debugInfo + ".parlay_calibrate",
      ),

      global_radio_set_check__fkey: global_radio_set_check__fkey,
      global_parlay_set_check__fkey: global_parlay_set_check__fkey,

      revision_code: "default",
      revision_id: revision_id,
      is_usable_for_sgp: is_usable_for_sgp,

      t_301_user_profile_fkey: ValidationUtils.assert_optional_string(
        jsondata.t_301_user_profile_fkey,
        debugInfo + ".t_301_user_profile_fkey",
      ),

      t_302_boosted_note: ValidationUtils.assert_optional_string(
        jsondata.t_302_boosted_note,
        debugInfo + ".t_302_boosted_note",
      ),

      group_fkey: ValidationUtils.assert_optional_string(
        jsondata.group_fkey,
        debugInfo + ".group_fkey",
      ),
      player_fkey: ValidationUtils.assert_optional_string(
        jsondata.player_fkey,
        debugInfo + ".player_fkey",
      ),
    };
  }
}

export class Adapter__SB_BetGroup {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
    conflict_fkey: string,
    market_fkey: string,
    revision_id: number,
  ): Data__SB_BetGroup {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    // 2020-10-26 / Ivan / provide default grouping tag (used for radio-button-like-checks), allow child to override it
    const group_tag = ValidationUtils.assert_optional_string(
      jsondata.group_tag,
      debugInfo + ".group_tag",
    );

    const default_grouping_tag: string = market_fkey + "__" + group_tag;

    return {
      group_tag: ValidationUtils.assert_optional_string(
        jsondata.group_tag,
        debugInfo + ".group_tag",
      ),
      leadership_flag: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.leadership_flag,
        debugInfo + ".leadership_flag",
      ),
      row_header_name: ValidationUtils.assert_optional_string(
        jsondata.visual_name,
        debugInfo + ".visual_name",
      ),
      player_fkey: ValidationUtils.assert_optional_string(
        jsondata.player_fkey,
        debugInfo + ".player_fkey",
      ),
      proposals: AdapterHelper.decode_array_4n<Data__SB_BetProposal>(
        Adapter__SB_BetProposal.decode,
        jsondata.proposals,
        debugInfo + ".proposals",
        conflict_fkey,
        default_grouping_tag,
        revision_id,
      ),
    };
  }
}

export class Adapter__SB_BetMarketVisualColumn {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_BetMarketVisualColumn {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      proposal_type: ValidationUtils.assert_positive_integer(
        jsondata.proposal_type,
        debugInfo + ".proposal_type",
      ),
      column_header_name: ValidationUtils.assert_optional_string(
        jsondata.visual_name,
        debugInfo + ".visual_name",
      ),
    };
  }
}

export class Adapter__SB_FeedDiffItem_BetMarket_Deactivated {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiffItem_BetMarket_Deactivated {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      subfeed_code: ValidationUtils.assert_any_integer(
        jsondata.subfeed_code,
        debugInfo + ".subfeed_code",
      ),
      revision_id: ValidationUtils.assert_any_integer(
        jsondata.revision_id,
        debugInfo + ".revision_id",
      ),
      item_type: ValidationUtils.assert_any_integer(
        jsondata.item_type,
        debugInfo + ".item_type",
      ),
      deactivate_reason: ValidationUtils.assert_any_integer(
        jsondata.deactivate_reason,
        debugInfo + ".deactivate_reason",
      ) as -1,
      // 2020-11-12 / Ivan / estimated_markets_count is now passed directly as property of the event
      //      estimated_shelf_markets_count: ValidationUtils.assert_any_integer(
      //        jsondata.estimated_shelf_markets_count,
      //        debugInfo + ".estimated_shelf_markets_count",
      //      ),
      conflict_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      market_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.market_fkey,
        debugInfo + ".market_fkey",
      ),
    };
  }
}

export class Adapter__SB_FeedDiffItem_BetMarket_Available {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiffItem_BetMarket_Available {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    const temp_conflict_fkey = ValidationUtils.assert_non_empty_string(
      jsondata.conflict_fkey,
      debugInfo + ".conflict_fkey",
    );

    const temp_market_fkey = ValidationUtils.assert_non_empty_string(
      jsondata.market_fkey,
      debugInfo + ".market_fkey",
    );

    const revision_id = ValidationUtils.assert_any_integer(
      jsondata.revision_id,
      debugInfo + ".revision_id",
    );

    const sgp_mode = ValidationUtils.assert_any_integer(
      jsondata.sgp_mode,
      debugInfo + ".sgp_mode",
    );

    const market_class_codes = AdapterHelper.decode_array_of_integers(
      jsondata.market_class_codes,
      debugInfo + ".market_class_codes",
    );

    const is_usable_for_sgp = sgp_mode > 0;

    return {
      revision_id,
      item_type: ValidationUtils.assert_any_integer(
        jsondata.item_type,
        debugInfo + ".item_type",
      ),
      deactivate_reason: ValidationUtils.assert_any_integer(
        jsondata.deactivate_reason,
        debugInfo + ".deactivate_reason",
      ) as 0,
      conflict_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.conflict_fkey,
        debugInfo + ".conflict_fkey",
      ),
      market_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.market_fkey,
        debugInfo + ".market_fkey",
      ),
      xmarket_fkey: ValidationUtils.assert_non_empty_string(
        jsondata.xmarket_fkey,
        debugInfo + ".xmarket_fkey",
      ),
      weight: ValidationUtils.assert_any_integer(
        jsondata.weight,
        debugInfo + ".weight",
      ),
      visual_name: ValidationUtils.assert_non_empty_string(
        jsondata.visual_name,
        debugInfo + ".visual_name",
      ),
      note: ValidationUtils.assert_optional_string(
        jsondata.note,
        debugInfo + ".note",
      ),
      status: validate_TBetOrBetGroupStatus(
        jsondata.status,
        debugInfo + ".status",
      ),
      visual_columns:
        AdapterHelper.decode_array<Data__SB_BetMarketVisualColumn>(
          Adapter__SB_BetMarketVisualColumn.decode,
          jsondata.headers,
          debugInfo + ".headers",
        ),
      groups: AdapterHelper.decode_array_4n<Data__SB_BetGroup>(
        Adapter__SB_BetGroup.decode,
        jsondata.groups,
        debugInfo + ".groups",
        temp_conflict_fkey,
        temp_market_fkey,
        revision_id,
      ),
      market_class_codes: new Set(market_class_codes),
      subfeed_code: ValidationUtils.assert_any_integer(
        jsondata.subfeed_code,
        debugInfo + ".subfeed_code",
      ),
      is_usable_for_sgp,
      player_fkey: ValidationUtils.assert_optional_string(
        jsondata.player_fkey,
        debugInfo + ".player_fkey",
      ),
    };
  }
}

export class Adapter__SB_FeedDiffItem_Abstract {
  public static decode(jsondata: TAnyAlias, debugInfo: string): TBetMarket {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    const item_type: number = ValidationUtils.assert_positive_integer(
      jsondata.item_type,
      debugInfo + ".item_type",
    );
    const deactivate_reason: number = ValidationUtils.assert_any_integer(
      jsondata.deactivate_reason,
      debugInfo + ".deactivate_reason",
    ) as 0 | -1;

    if (item_type === 101 && deactivate_reason !== 0) {
      return Adapter__SB_FeedDiffItem_BetMarket_Deactivated.decode(
        jsondata,
        debugInfo,
      );
    }
    if (item_type === 101 && deactivate_reason === 0) {
      return Adapter__SB_FeedDiffItem_BetMarket_Available.decode(
        jsondata,
        debugInfo,
      );
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected item_type [" + item_type + "]",
    );
  }
}

export class Adapter__SB_Logro_v2_All_Daily_Challenges_Completed {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Logro_v2_All_Daily_Challenges_Completed {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      id: ValidationUtils.assert_positive_integer(
        jsondata.id,
        debugInfo + ".id",
      ),
      type: ValidationUtils.assert_positive_integer(
        jsondata.type,
        debugInfo + ".type",
      ),
      total_amount_v3_xpoints: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.total_amount_v3_xpoints,
        debugInfo + ".total_amount_v3_xpoints",
      ),
    };
  }
}

export class Adapter__SB_X_Delta_Profile {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_X_Delta_Profile {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      // 2021-02-12 / Ivan / introduce default vs per-user permissions
      user_pdata: Adapter__SB_Permissions_And_Common_Data.decode(
        jsondata.user_pdata,
        debugInfo + ".user_pdata",
      ),

      d_51202_last_v2_weekly_leaderboard_bonus:
        AdapterHelper.null_or_decode<Data__SB_Points_Leaderboard_Badge>(
          Adapter__SB_Points_Leaderboard_Badge.decode,
          jsondata.d_51202_last_v2_weekly_leaderboard_bonus,
          debugInfo + ".d_51202_last_v2_weekly_leaderboard_bonus",
        ),
      d_51203_last_v2_weekly_leaderboard_thropy:
        AdapterHelper.null_or_decode<Data__SB_Points_Leaderboard_Badge>(
          Adapter__SB_Points_Leaderboard_Badge.decode,
          jsondata.d_51203_last_v2_weekly_leaderboard_thropy,
          debugInfo + ".d_51203_last_v2_weekly_leaderboard_thropy",
        ),
      d_51204_last_v2_won_number_of_picks_badge:
        AdapterHelper.null_or_decode<Data__SB_Pick_Badge>(
          Adapter__SB_Pick_Badge.decode,
          jsondata.d_51204_last_v2_won_number_of_picks_badge,
          debugInfo + ".d_51204_last_v2_won_number_of_picks_badge",
        ),
      d_51207_last_v4_xpoints_bonus:
        AdapterHelper.null_or_decode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsondata.d_51207_last_v4_xpoints_bonus,
          debugInfo + ".d_51207_last_v4_xpoints_bonus",
        ),
      d_51206_last_v2_completed_daily_challenge:
        AdapterHelper.null_or_decode<Data__SB_Logro_v2_All_Daily_Challenges_Completed>(
          Adapter__SB_Logro_v2_All_Daily_Challenges_Completed.decode,
          jsondata.d_51206_last_v2_completed_daily_challenge,
          debugInfo + ".d_51206_last_v2_completed_daily_challenge",
        ),
      d_51221_last_v2_transaction_id:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51221_last_v2_transaction_id,
          debugInfo + ".d_51221_last_v2_transaction_id",
        ),
      d_51231_last_v3_purchase_error_order:
        AdapterHelper.null_or_decode<Data__SB_Unfinished_Order>(
          Adapter__SB_Unfinished_Order.decode,
          jsondata.d_51231_last_v3_purchase_error_order,
          debugInfo + ".d_51231_last_v3_purchase_error_order",
        ),
      d_51232_last_v3_purchase_success_transaction:
        AdapterHelper.null_or_decode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsondata.d_51232_last_v3_purchase_success_transaction,
          debugInfo + ".d_51232_last_v3_purchase_success_transaction",
        ),
      d_51241_last_v3_order_updates_tracker:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.d_51241_last_v3_order_updates_tracker,
          debugInfo + ".d_51241_last_v3_order_updates_tracker",
        ),
      d_51250_last_transaction_4115_mail_in_entry:
        AdapterHelper.null_or_decode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsondata.d_51250_last_transaction__4115_mail_in_entry,
          debugInfo + ".d_51250_last_transaction__4115_mail_in_entry",
        ),
      d_51251_last_transaction_4116_pending_fc_credit:
        AdapterHelper.null_or_decode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsondata.d_51251_last_transaction__4116_pending_fc_credit,
          debugInfo + ".d_51251_last_transaction__4116_pending_fc_credit",
        ),
      d_51252_last_transaction_4117_cleared_fc_credit:
        AdapterHelper.null_or_decode<IFCMTransaction>(
          AdapterFCMTransaction.decode,
          jsondata.d_51252_last_transaction__4117_cleared_fc_credit,
          debugInfo + ".d_51252_last_transaction__4117_cleared_fc_credit",
        ),
    };
  }
}

export class Adapter__Slide_Gift_Card_Redemption_Method {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__Slide_Gift_Card_Redemption_Method {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      m_info: ValidationUtils.assert_optional_string(
        jsondata.m_info,
        debugInfo + ".m_info",
      ),
    };
  }
}

export class Adapter__Slide_Gift_Card_Brand {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__Slide_Gift_Card_Brand {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    // 2021-03-28 / Ivan / quick hack for gift card branding info, may be better to fix it on server side
    if (jsondata.is_popular === undefined) {
      jsondata.is_popular = false;
    }
    // 2021-03-28 / Ivan / quick hack for gift card branding info, may be better to fix it on server side
    if (jsondata.cashback_in_promiles === undefined) {
      jsondata.cashback_in_promiles = 40;
    }

    return {
      b_uuid: ValidationUtils.assert_non_empty_string(
        jsondata.b_uuid,
        debugInfo + ".b_uuid",
      ),
      b_name: ValidationUtils.assert_non_empty_string(
        jsondata.b_name,
        debugInfo + ".b_name",
      ),
      b_icon_url: ValidationUtils.assert_non_empty_string(
        jsondata.b_icon_url,
        debugInfo + ".b_icon_url",
      ),
      b_legal_terms: ValidationUtils.assert_optional_string(
        jsondata.b_legal_terms,
        debugInfo + ".b_legal_terms",
      ),
      b_description: ValidationUtils.assert_optional_string(
        jsondata.b_description,
        debugInfo + ".b_description",
      ),
      v_category: ValidationUtils.assert_optional_string(
        jsondata.v_category,
        debugInfo + ".v_category",
      ),
      v_pos: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.v_pos,
        debugInfo + ".v_pos",
      ),
      cvc_denominations: AdapterHelper.decode_array_of_integers(
        jsondata.cvc_denominations,
        debugInfo + ".cvc_denominations",
      ),
      cvc_min_value: ValidationUtils.assert_positive_integer(
        jsondata.cvc_min_value,
        debugInfo + ".cvc_min_value",
      ),
      cvc_max_value: ValidationUtils.assert_positive_integer(
        jsondata.cvc_max_value,
        debugInfo + ".cvc_max_value",
      ),
      cvc_increment: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.cvc_increment,
        debugInfo + ".cvc_increment",
      ),
      rc_kind: validate_TGiftCardRedemptionConfigType(
        jsondata.rc_kind,
        debugInfo + ".rc_kind",
      ),
      rc_disclaimer: ValidationUtils.assert_optional_string(
        jsondata.rc_disclaimer,
        debugInfo + ".rc_disclaimer",
      ),
      rc_methods:
        AdapterHelper.decode_array<Data__Slide_Gift_Card_Redemption_Method>(
          Adapter__Slide_Gift_Card_Redemption_Method.decode,
          jsondata.rc_methods,
          debugInfo + ".rc_methods",
        ),
      is_popular: ValidationUtils.assert_boolean(
        jsondata.is_popular,
        debugInfo + ".is_popular",
      ),
      cashback_in_promiles: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.cashback_in_promiles,
        debugInfo + ".cashback_in_promiles",
      ),
    };
  }
}

export class Adapter__Slide_User_Gift_Card {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__Slide_User_Gift_Card {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      amount_denomination: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_denomination,
        debugInfo + ".amount_denomination",
      ),
      amount_paid_via_credit_card:
        ValidationUtils.assert_positive_integer_or_zero(
          jsondata.amount_paid_via_credit_card,
          debugInfo + ".amount_paid_via_credit_card",
        ),
      amount_paid_via_points: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_paid_via_points,
        debugInfo + ".amount_paid_via_points",
      ),

      brand_name: ValidationUtils.assert_non_empty_string(
        jsondata.brand_name,
        debugInfo + ".brand_name",
      ),
      card_barcode_kind: ValidationUtils.assert_non_empty_string(
        jsondata.card_barcode_kind,
        debugInfo + ".card_barcode_kind",
      ),
      card_barcode_value: ValidationUtils.assert_optional_string(
        jsondata.card_barcode_value,
        debugInfo + ".card_barcode_value",
      ),
      card_csc: ValidationUtils.assert_optional_string(
        jsondata.card_csc,
        debugInfo + ".card_csc",
      ),
      card_number: ValidationUtils.assert_non_empty_string(
        jsondata.card_number,
        debugInfo + ".card_number",
      ),
      amount_cashback: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_cashback,
        debugInfo + ".amount_cashback",
      ),
      amount_balance: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_balance,
        debugInfo + ".amount_balance",
      ),
      credit_card_number: ValidationUtils.assert_optional_string(
        jsondata.credit_card_number,
        debugInfo + ".credit_card_number",
      ),
      brand: Adapter__Slide_Gift_Card_Brand.decode(
        jsondata.brand,
        debugInfo + ".brand",
      ),
    };
  }
}

export class Adapter__SB_Unfinished_Order {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Unfinished_Order {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      id: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.id,
        debugInfo + ".id",
      ),
      created_stamp_sec_utc: ValidationUtils.assert_positive_integer(
        jsondata.created_stamp_sec_utc,
        debugInfo + ".created_stamp_sec_utc",
      ),
      updated_stamp_sec_utc: ValidationUtils.assert_positive_integer(
        jsondata.updated_stamp_sec_utc,
        debugInfo + ".updated_stamp_sec_utc",
      ),
      amount_denomination: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.amount_denomination,
        debugInfo + ".amount_denomination",
      ),
      state_category: validate_TUnfinishedOrderState(
        jsondata.state_category,
        debugInfo + ".state_category",
      ),
      state_code: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.state_code,
        debugInfo + ".state_code",
      ),
      brand: Adapter__Slide_Gift_Card_Brand.decode(
        jsondata.brand,
        debugInfo + ".brand",
      ),
    };
  }
}

export class Adapter__Slide_All_Gift_Card_Brands {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__Slide_All_Gift_Card_Brands {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      brands: AdapterHelper.decode_array<Data__Slide_Gift_Card_Brand>(
        Adapter__Slide_Gift_Card_Brand.decode,
        jsondata.brands,
        debugInfo + ".brands",
      ),
    };
  }
}

export class Adapter__SB_Conlict_Market_Class_Def {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Conlict_Market_Class_Def {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      code: ValidationUtils.assert_positive_integer(
        jsondata.code,
        debugInfo + ".code",
      ),
      tab_pos: ValidationUtils.assert_any_integer(
        jsondata.tab_pos,
        debugInfo + ".tab_pos",
      ),
      tab_name: ValidationUtils.assert_non_empty_string(
        jsondata.tab_name,
        debugInfo + ".tab_name",
      ),
    };
  }
}

export class Adapter__SB_Conflict_Class_Def {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Conflict_Class_Def {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      code: ValidationUtils.assert_positive_integer(
        jsondata.code,
        debugInfo + ".code",
      ),
      market_classes:
        AdapterHelper.decode_array<Data__SB_Conlict_Market_Class_Def>(
          Adapter__SB_Conlict_Market_Class_Def.decode,
          jsondata.market_classes,
          debugInfo + ".market_classes",
        ),
    };
  }
}

export class Adapter__SB_v3_User_Weekly_Stats {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_v3_User_Weekly_Stats {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      s_picks_count: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_picks_count,
        debugInfo + ".s_picks_count",
      ),
      s_6011_best_win: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_6011_best_win,
        debugInfo + ".s_6011_best_win",
      ),
      s_6021_skill_coeff: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_6021_skill_coeff,
        debugInfo + ".s_6021_skill_coeff",
      ),
      s_global_rank: ValidationUtils.assert_positive_integer_or_zero(
        jsondata.s_global_rank,
        debugInfo + ".s_global_rank",
      ),
    };
  }
}

export class Adapter__SB_v3_User_Profile_Summary {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_v3_User_Profile_Summary {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      user_id: ValidationUtils.assert_positive_integer(
        jsondata.user_id,
        debugInfo + ".user_id",
      ),
      user_name: ValidationUtils.assert_non_empty_string(
        jsondata.user_name,
        debugInfo + ".user_name",
      ),
      avatar_id: ValidationUtils.assert_non_empty_string(
        jsondata.avatar_id,
        debugInfo + ".avatar_id",
      ),
      this_week_stats: Adapter__SB_v3_User_Weekly_Stats.decode(
        jsondata.this_week_stats,
        debugInfo + ".this_week_stats",
      ),
      prev_week_stats: Adapter__SB_v3_User_Weekly_Stats.decode(
        jsondata.prev_week_stats,
        debugInfo + ".prev_week_stats",
      ),
    };
  }
}

export class Adapter__SB_Initiated_Withdrawal_Response {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Initiated_Withdrawal_Response {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      verification_url: ValidationUtils.assert_optional_string(
        jsondata.verification_url,
        debugInfo + ".verification_url",
      ),
    };
  }
}

export class Adapter__SB_User_Personal_Info {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_User_Personal_Info {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      first_name: ValidationUtils.assert_optional_string(
        jsondata.first_name,
        debugInfo + ".first_name",
      ),
      last_name: ValidationUtils.assert_optional_string(
        jsondata.last_name,
        debugInfo + ".last_name",
      ),
      date_of_birth: ValidationUtils.assert_optional_string(
        jsondata.date_of_birth,
        debugInfo + ".date_of_birth",
      ),
      country_code: ValidationUtils.assert_optional_string(
        jsondata.country_code,
        debugInfo + ".country_code",
      ),
      country_state: ValidationUtils.assert_optional_string(
        jsondata.country_state,
        debugInfo + ".country_state",
      ) as TUSAStatesValues,
      zip_code: ValidationUtils.assert_optional_string(
        jsondata.zip_code,
        debugInfo + ".zip_code",
      ),
      city: ValidationUtils.assert_optional_string(
        jsondata.city,
        debugInfo + ".city",
      ),
      address_1: ValidationUtils.assert_optional_string(
        jsondata.address_1,
        debugInfo + ".address_1",
      ),
      address_2: ValidationUtils.assert_optional_string(
        jsondata.address_2,
        debugInfo + ".address_2",
      ),
    };
  }
}

export class Adapter__SB_Initiated_Persona_Verification_Response {
  public static decode(
    jsondata: TAnyAlias,
    debugInfo: string,
  ): Data__SB_Initiated_Persona_Verification_Response {
    ValidationUtils.assert_non_null_data(jsondata, debugInfo);

    return {
      verification_url: ValidationUtils.assert_optional_string(
        jsondata.verification_url,
        debugInfo + ".verification_url",
      ),
      persona_template_id: ValidationUtils.assert_optional_string(
        jsondata.persona_template_id,
        debugInfo + ".persona_template_id",
      ),
      persona_reference_id: ValidationUtils.assert_optional_string(
        jsondata.persona_reference_id,
        debugInfo + ".persona_reference_id",
      ),
    };
  }
}
