import React from "react";

import InputMask from "react-input-mask";
import { IProps } from "./types";

const CodeInput = ({ value, onChangeText, disabled }: IProps) => {
  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChangeText?.(e.currentTarget.value);
  };
  return (
    <InputMask
      mask="9 9 9 9 9 9"
      autoFocus
      value={value}
      maskChar={null}
      placeholder={"0 0 0 0 0 0"}
      inputMode="numeric"
      onChange={handleOnChange}
      className={"masked-input"}
      disabled={disabled}
    />
  );
};

CodeInput.displayName = "RegularTextInput";

export default CodeInput;
