import { createRoot } from "react-dom/client";
import App from "./App";
import "utils/dayjs";
import "utils/console";
import "./styles.scss";
import initAppProvider from "utils/InitAppProvider";
import { DevConstants } from "./DevConstants";
import { isMobileOnly } from "react-device-detect";

initAppProvider.initAppRootStyles();
const isDevMode = DevConstants.isDevMode;

const container = document.getElementById("root");
if (isDevMode && !isMobileOnly) {
  container?.classList.add("desktop-root");
}
const root = createRoot(container!);
root.render(<App />);
