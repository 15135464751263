import { ServerUIMode } from "server/legacyCore/data/constants";
import { TRestrictionCodes } from "server/core/data/objects";

class AppUIModesManager {
  public _localAppUIMode: TRestrictionCodes | undefined;
  private _restrictionReason: null | string = null;

  public get restrictionReason() {
    return this._restrictionReason;
  }

  public get isDefaultMode() {
    return this._localAppUIMode === undefined;
  }

  public get isDisabledMode() {
    return this._localAppUIMode === 15001;
  }

  public get isExcludedMode() {
    return this._localAppUIMode === 15002;
  }

  public get isPermanentMode() {
    return this._localAppUIMode === 15003;
  }

  public get isTemporaryMode() {
    return this._localAppUIMode === 15004;
  }

  public setLocalAppUIMode = (
    mode: TRestrictionCodes | undefined,
    restrictionReason: string | null,
  ): void => {
    this._localAppUIMode = mode;
    this._restrictionReason = restrictionReason;
  };

  public isServerFullUIModeEnabled = (mode: ServerUIMode): boolean =>
    mode === ServerUIMode.CODE_300_FULL_UI_MODE;

  public isServerIOSLightUIMode = (mode: ServerUIMode): boolean =>
    mode === ServerUIMode.CODE_200_MEDIUM_UI_MODE;

  public isServerLightModeEnabled = (mode: ServerUIMode): boolean =>
    mode === ServerUIMode.CODE_100_LIGHT_UI_MODE;

  public isServerAndroidModeEnabled = (mode: ServerUIMode): boolean =>
    mode === ServerUIMode.CODE_280_ANDROID_LIGHT_UI_MODE;
}

export default new AppUIModesManager();
