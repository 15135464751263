import { FliffException } from "server/legacyCore/FliffException";
import { TAnyAlias } from "src/types";
import { AppUtils } from "utils/AppUtils";

export class ValidationUtils {
  public static assert_non_null_data(
    data: TAnyAlias,
    debugInfo: string,
  ): TAnyAlias {
    if (typeof data === "undefined") {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_null_data - (detected undefined) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }
    if (AppUtils.isNullable(data)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_null_data - (detected null) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_non_empty_string<T extends string>(
    data: T,
    debugInfo: string,
  ): T {
    return ValidationUtils.common_assert_string(data, false, debugInfo);
  }

  public static assert_optional_string(
    data: TAnyAlias,
    debugInfo: string,
  ): string {
    return ValidationUtils.common_assert_string(data, true, debugInfo);
  }

  public static assert_any_integer(data: TAnyAlias, debugInfo: string): number {
    return ValidationUtils.common_assert_integer(data, debugInfo);
  }

  public static assert_optional_integer(
    data: TAnyAlias,
    debugInfo: string,
  ): number {
    return ValidationUtils.common_assert_integer(data, debugInfo, true);
  }

  public static assert_positive_integer<T = number>(
    data: TAnyAlias,
    debugInfo: string,
  ): T {
    const odata = data;

    data = ValidationUtils.common_assert_integer(data, debugInfo);

    if (data <= 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_integer - (data <= 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_positive_integer_or_zero<Data extends number>(
    data: TAnyAlias,
    debugInfo: string,
  ): Data {
    const odata = data;

    data = ValidationUtils.common_assert_integer(data, debugInfo);

    if (data < 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_integer_or_zero - (data < 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data as Data;
  }

  public static assert_positive_integer_or_null(
    data: TAnyAlias,
    debugInfo: string,
  ): number | null {
    if (AppUtils.isNullable(data)) {
      return null;
    }

    const odata = data;

    data = ValidationUtils.common_assert_integer(data, debugInfo);

    if (data <= 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_integer_or_null - (data <= 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_positive_integer_or_zero_or_null(
    data: TAnyAlias,
    debugInfo: string,
  ): number | null {
    if (AppUtils.isNullable(data)) {
      return null;
    }

    const odata = data;

    data = ValidationUtils.common_assert_integer(data, debugInfo);

    if (data < 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_integer_or_zero_or_null - (data <= 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_any_float(data: TAnyAlias, debugInfo: string): number {
    //    const odata = data;

    data = ValidationUtils.common_assert_float(data, debugInfo);

    return data;
  }

  public static assert_positive_float(
    data: TAnyAlias,
    debugInfo: string,
  ): number {
    const odata = data;

    data = ValidationUtils.common_assert_float(data, debugInfo);

    if (data <= 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_float - (data <= 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_positive_float_or_zero(
    data: TAnyAlias,
    debugInfo: string,
  ): number {
    const odata = data;

    data = ValidationUtils.common_assert_float(data, debugInfo);

    if (data < 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_positive_float_or_zero - (data < 0) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_boolean(data: TAnyAlias, debugInfo: string): boolean {
    const odata = data;

    ValidationUtils.assert_non_null_data(data, debugInfo);

    if (data !== true && data !== false) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_boolean - (non boolean data) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_non_null_array(
    data: TAnyAlias,
    debugInfo: string,
  ): TAnyAlias {
    ValidationUtils.assert_non_null_data(data, debugInfo);

    if (!Array.isArray(data)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_null_array - (!Array.isArray(data)) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static assert_non_empty_array(
    data: TAnyAlias,
    debugInfo: string,
  ): TAnyAlias {
    ValidationUtils.assert_non_null_array(data, debugInfo);

    if (data.length === 0) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "assert_non_empty_array - (data.length == 0) [" +
          data +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }

  public static validate_enum<Enum>(
    inputEnum: Enum,
    data: unknown,
    debugInfo: string,
  ): Enum {
    const nn = ValidationUtils.assert_any_integer(data, debugInfo);

    if (typeof inputEnum !== "object" || !inputEnum || !(nn in inputEnum)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        `unexpected [${nn}] [${debugInfo}]`,
      );
    }

    return nn as Enum;
  }

  private static common_assert_string<T extends string>(
    data: T,
    isEmptyStringAllowed: boolean,
    debugInfo: string,
  ): T {
    const odata = data;

    ValidationUtils.assert_non_null_data(data, debugInfo);

    // ?? do we need this
    data = String(data) as T;

    data = data.trim() as T;

    if (!isEmptyStringAllowed) {
      if (data.length === 0) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "assert_non_empty_string - (data.length == 0) [" +
            odata +
            "] for [" +
            debugInfo +
            "]",
        );
      }
    }
    return data;
  }

  private static common_assert_integer(
    data: TAnyAlias,
    debugInfo: string,
    isEmptyIntegerAllowed?: boolean,
  ): number {
    const odata = data;

    if (!isEmptyIntegerAllowed) {
      ValidationUtils.assert_non_null_data(data, debugInfo);

      // convert anything to number
      data = +data;

      // check if valid number
      if (isNaN(data)) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "common_assert_integer - (isNaN(data)) [" +
            odata +
            "] for [" +
            debugInfo +
            "]",
        );
      }

      // check if valid integer
      if (Math.floor(data) !== data) {
        throw new FliffException(
          FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
          "common_assert_integer - (Math.floor(data) != data) [" +
            odata +
            "] for [" +
            debugInfo +
            "]",
        );
      }
    }

    return data;
  }

  private static common_assert_float(
    data: TAnyAlias,
    debugInfo: string,
  ): number {
    const odata = data;

    ValidationUtils.assert_non_null_data(data, debugInfo);

    // convert anything to number
    data = +data;

    // check if valid number
    if (isNaN(data)) {
      throw new FliffException(
        FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
        "common_assert_float - (isNaN(data)) [" +
          odata +
          "] for [" +
          debugInfo +
          "]",
      );
    }

    return data;
  }
}
