import { ApiBase } from "./ApiBase";

import { IFliffResponse, TFliffResult } from "server/sharedCore/data/objects";
import { IFCMRequest } from "server/core/data/objects";
import { InternalProtocolRequestBuilderImpl } from "./impl/HelperInternalProtocolRequestBuilderImpl";
import { InternalProtocolResponseProcessorImpl } from "./impl/HelperInternalProtocolResponseProcessorImpl";
import { InternalNetworkConnectorImpl } from "./impl/HelperInternalNetworkConnectorImpl";
import ApiUIHandler from "server/sharedCore/ApiUIHandler";

class ApiCore extends ApiUIHandler {
  private readonly _ImplInstance = new ApiBase(
    new InternalProtocolRequestBuilderImpl(),
    new InternalProtocolResponseProcessorImpl(),
    new InternalNetworkConnectorImpl(),
  );

  public executeBlockingRequest<
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<Response> {
    return this.blockingDefaultProgressBarRequest<Request, Response>(
      this._ImplInstance.executeRequest,
    )(request);
  }

  public safeExecuteBlockingRequest<
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<TFliffResult<Response>> {
    // never throws exception - errors are wrapped in TFliffResult
    return this.safeBlockingDefaultProgressBarRequest<Request, Response>(
      this._ImplInstance.safeExecuteRequest,
    )(request);
  }

  public safeExecuteBlockingTransparentNoErrorModalRequest<
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<TFliffResult<Response>> {
    // never throws exception - errors are wrapped in TFliffResult
    return this.safeBlockingTransparentNoErrorModalProgressBarRequest<
      Request,
      Response
    >(this._ImplInstance.safeExecuteRequest)(request);
  }

  public safeExecuteBackgroundRequest<
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<TFliffResult<Response>> {
    return this._ImplInstance.safeExecuteRequest(request);
  }

  public executeNonBlockingNoErrorModalRequest<
    Request extends IFCMRequest,
    Response extends IFliffResponse,
  >(request: Request): Promise<Response> {
    return this._ImplInstance.executeRequest(request);
  }
}

export default new ApiCore();
