import { GoogleOAuthProvider } from "@react-oauth/google";
import AppContent from "Renders";
import SportEventsProvider from "context/SportEventsContext/SportEventsProvider";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import store from "reduxLocal/store";
import { DevConstants } from "src/DevConstants";
import RootRouter from "router/common/RootRouter";
import MakePaymentContextProvider from "context/MakePaymentContext/MakePaymentContextProvider";
import AppConfig from "utils/AppConfig";

const App = () => {
  const isDevMode = DevConstants.isDevMode;

  return (
    <Provider store={store}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {isDevMode ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>
      <RootRouter>
        <SportEventsProvider>
          <MakePaymentContextProvider>
            <GoogleOAuthProvider clientId={AppConfig.googleClientId}>
              <AppContent />
            </GoogleOAuthProvider>
          </MakePaymentContextProvider>
        </SportEventsProvider>
      </RootRouter>
    </Provider>
  );
};

export default App;
