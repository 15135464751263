import dayjs, { Dayjs } from "dayjs";
import { Duration } from "dayjs/plugin/duration";

import { ServerClock } from "utils/ServerClock";
import DateFormatters from "utils/DateFormatters";

export class TimeUtils {
  public static now(
    input?: dayjs.ConfigType,
    format?: string,
    strict?: boolean,
  ): Dayjs {
    return dayjs(input, format, strict);
  }

  public static calcCurrentAndNextDayStartStampsInUTC(
    dateString: string,
  ): [number, number, string] {
    const localDate = TimeUtils.now(dateString, "YYYY-MM-DD", true);
    const dayStart = localDate.startOf("day");
    const nextDayStart = localDate.add(1, "day").startOf("day");

    return [
      dayStart.valueOf(),
      nextDayStart.valueOf(),
      DateFormatters.formatRegularVisualDate(localDate),
    ];
  }

  /*
  calculates concept for 'current day' in client timezone based on last known server time:
  <0 - before today
   0 - today
   1 - tomorrow
  >1 - after tomorrow
  */
  public static dayOffsetFromToday(utcStamp: number): number {
    const dayStart = TimeUtils.now(utcStamp).startOf("day");

    const serverNow = TimeUtils.now(
      ServerClock.latestKnownServerUtcStampMillis,
    );
    const serverTodayStart = serverNow.startOf("day");
    const serverTomorrowStart = serverNow.add(1, "day").startOf("day");
    const serverTomorrowEnd = serverNow.add(1, "day").endOf("day");

    if (dayStart.isBefore(serverTodayStart)) {
      // before today
      return -1;
    }
    if (dayStart.isBefore(serverTomorrowStart)) {
      // today
      return 0;
    }
    if (dayStart.isBefore(serverTomorrowEnd)) {
      // tomorrow
      return 1;
    }

    return 2;
  }

  public static durationFromStampSecondsUtc(utcStamp: number): string {
    return TimeUtils.now(utcStamp).fromNow(true);
  }

  public static daysFromServerToday(
    serverToday: string,
    date: dayjs.ConfigType,
  ): number {
    const duration =
      TimeUtils.now(date, "YYYY-MM-DD").toDate().getTime() -
      TimeUtils.now(serverToday, "YYYY-MM-DD").toDate().getTime();

    return Math.trunc(dayjs.duration(duration).asDays());
  }

  public static isToday(secondsUTCStamp: number) {
    const serverTodayStart = TimeUtils.now().startOf("day");
    const timestamp = TimeUtils.now(
      TimeUtils.fromSecondsToMills(secondsUTCStamp),
    );
    return serverTodayStart.isSame(timestamp, "day");
  }

  public static fromMillsToMinutesStamp(timeUtcStamp: number): number {
    return timeUtcStamp / 60000;
  }

  public static fromSecondsToMills(secondsStamp: number): number {
    return secondsStamp * 1000;
  }

  public static diffInHoursFromNow(timeUtcStamp: number): number {
    const now = TimeUtils.now();
    const end = TimeUtils.now(timeUtcStamp);
    const duration = dayjs.duration(now.diff(end));
    return duration.asHours();
  }

  public static getMillsDuration(input: number): Duration {
    return dayjs.duration(input, "milliseconds");
  }
}
