import axios from "axios";

import AppConfig from "utils/AppConfig";
import { FliffException } from "server/legacyCore/FliffException";
import CommonLocationManager from "utils/LocationManagers/Common";
import { DevConstants } from "src/DevConstants";
import { RestrictedModalService } from "components/molecules/mobile/Modals/RestrictedStateModal";
import { AppUIShowModalDialogAction } from "reduxLocal/appUI/appUI.actions";

interface IGeocode {
  lat: number;
  lng: number;
}

interface IGeocodeParseSuccessResponse {
  plus_code: { compound_code: string; global_code: string };
  results: [
    {
      address_components: {
        long_name: string;
        short_name: string;
        types: string[];
      }[];
      formatted_address: string;
      geometry: {
        bounds: { northeast: IGeocode; southwest: IGeocode };
        location: IGeocode;
        location_type: string;
        viewport: { northeast: IGeocode; southwest: IGeocode };
      };
      place_id: string;
      types: string[];
    },
  ];
  status: string;
}

class LegacyLocationManager {
  private readonly _ipApiAxiosInstance = axios.create({
    baseURL: "https://ipapi.co/",
    timeout: 20000,
  });
  private readonly _geocodingApiAxiosInstance = axios.create({
    baseURL: "https://maps.googleapis.com/maps/api/geocode/json",
    timeout: 20000,
  });
  private readonly _queryStateIndication =
    "administrative_area_level_1" as const;

  public verifyLocationByIp = async (): Promise<string | void> => {
    try {
      const regionCode = await this._getIpRegionCode();
      CommonLocationManager.setModeByRegionCode(regionCode);
      CommonLocationManager.setIpCheckResult({
        isPassed: true,
        regionCode,
        meta: "successGetRegionCode",
      });

      return regionCode;
    } catch (err) {
      //We bypass IP check on service error and allow user to proceed without any restrictions.
      CommonLocationManager.setIpCheckResult({
        isPassed: true,
        regionCode: "",
        meta: `unknownRegionCode.${err}`,
      });
      return;
      // throw new FliffException(
      //   FliffException.ERROR_9998__VALIDATE_IP_ADDRESS_FAILURE,
      //   "Error on validate IP address",
      // );
    }
  };

  public _handleExcludedRestrictions() {
    if (CommonLocationManager.geocodeCheckResult.isPassed) {
      AppUIShowModalDialogAction.dispatchShowRestrictionInfoDialog();
      return;
    }
    RestrictedModalService.show(
      CommonLocationManager.ipCheckResult.regionCode,
      null,
      true,
    );
  }

  public verifyLocationByPhysicalLocation = async (): Promise<void> => {
    const isPermissionGranted = await this._requestLocationPermission();
    if (!isPermissionGranted) {
      throw new FliffException(
        FliffException.ERROR_9997__PERMISSION_PHYSICAL_LOCATION_FAILURE,
        "Unable to get your physical location.\n\nPlease, go to settings and grant the permission.",
      );
    }
    const coordinates = await this._getGeocode();
    if (!coordinates) {
      throw new FliffException(
        FliffException.ERROR_9996__COORDINATES_PHYSICAL_LOCATION_FAILURE,
        "Unable to get your physical location.\n\nPlease, go to settings and grant the permission.",
      );
    }
    const regionCode = await this._parseGeocodeToLocation(coordinates);
    // We bypass error on service unavailability.
    // if (!regionCode) {
    //   throw new FliffException(
    //     FliffException.ERROR_9995__GOOGLE_API_GEOCODE_PHYSICAL_LOCATION_FAILURE,
    //     "Unable to get your physical location.",
    //   );
    // }
    CommonLocationManager.setModeByRegionCode(regionCode);
    CommonLocationManager.setGeocodeCheckResult({
      isPassed: true,
      regionCode,
      meta: regionCode ? "successGetRegionCode" : "unableToParseRegionCode",
    });
  };

  private _parseGeocodeToLocation = async (
    geocode: IGeocode,
  ): Promise<string> => {
    //We bypass Location check on service error and allow user to proceed without any restrictions.
    try {
      if (DevConstants.enforceLegacyGeoDetectedState) {
        if (DevConstants.enforceLegacyGeoDetectedState === "error") {
          alert("Error occurred and bypassed");
          throw new FliffException(
            123,
            "Dummy error on .enforceLegacyGeoDetectedState",
          );
        }
        return DevConstants.enforceLegacyGeoDetectedState;
      }
      const response =
        await this._geocodingApiAxiosInstance.get<IGeocodeParseSuccessResponse>(
          "",
          {
            params: {
              key: AppConfig.googleDecodingKey,
              latlng: `${geocode.lat},${geocode.lng}`,
              language: "en",
              result_type: this._queryStateIndication,
            },
          },
        );
      if (response.data.status !== "OK") {
        return "";
      }
      return response.data.results.reduce<string>((acc, curr) => {
        curr.address_components.forEach(({ short_name: shortName, types }) => {
          if (types.includes(this._queryStateIndication)) {
            acc = shortName;
          }
        });
        return acc;
      }, "");
    } catch (err) {
      return "";
    }
  };

  private _getGeocode = async () => {
    const position = await new Promise<GeolocationPosition>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject),
    );

    return {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
  };

  public _requestLocationPermission = (): Promise<string> => {
    return new Promise(resolve => {
      if ("permissions" in navigator) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((result: PermissionStatus) => {
            if (result.state === "prompt") {
              if ("geolocation" in navigator) {
                window.navigator.geolocation.getCurrentPosition(
                  () => resolve("granted"),
                  () => resolve("denied"),
                  {
                    maximumAge: Infinity,
                    timeout: 0,
                  },
                );
              } else {
                resolve("prompt");
              }
            } else {
              resolve(result.state);
            }
          });
      } else if ("geolocation" in navigator) {
        window.navigator.geolocation.getCurrentPosition(
          () => resolve("granted"),
          () => resolve("denied"),
          {
            maximumAge: Infinity,
            timeout: 0,
          },
        );
      } else {
        resolve("prompt");
      }
    });
  };

  private _getIpRegionCode = async (): Promise<string> => {
    if (DevConstants.enforceLegacyIPDetectedState) {
      if (DevConstants.enforceLegacyIPDetectedState === "error") {
        alert("Error occurred and bypassed");
        throw new FliffException(
          123,
          "Dummy error on .enforceLegacyIPDetectedState",
        );
      }
      return DevConstants.enforceLegacyIPDetectedState;
    }

    return (
      await this._ipApiAxiosInstance.get(
        `region_code/?key=${AppConfig.ipApiKey}`,
      )
    ).data;
  };
}

export default new LegacyLocationManager();
