import Logger from "utils/Logger";
import { DevConstants } from "src/DevConstants";
import { AppProfileUtils } from "utils/AppProfileUtils";

class CoreApiUrlManager {
  private _nextPublicEndpoint = "";
  private _nextPrivateEndpoint = "";
  private _nextHeraldPublicEndpoint = "";
  public get defaultBaseUrl(): string {
    return AppProfileUtils.coreServerBaseUrl;
  }

  public get nextHeraldPublicEndpoint() {
    return this._nextHeraldPublicEndpoint;
  }

  public get nextPublicEndpoint() {
    return this._nextPublicEndpoint;
  }

  public get nextPrivateEndpoint() {
    return this._nextPrivateEndpoint;
  }

  public setLastKnownUrls(
    publicEndpoint: string,
    privateEndpoint: string,
    heraldPublicEndpoint: string,
  ): void {
    if (publicEndpoint !== "default") {
      this._nextPublicEndpoint = publicEndpoint;
    }
    if (privateEndpoint !== "default") {
      this._nextPrivateEndpoint = privateEndpoint;
    }
    this._nextHeraldPublicEndpoint = heraldPublicEndpoint;
  }

  public getBaseUrl = (
    prevBaseUrl: string | undefined,
    isPublic: boolean | undefined,
  ): string => {
    if (!prevBaseUrl) {
      Logger.logMessage("getBaseUrl => initialUrl is not defined.");
      return "";
    }
    if (typeof isPublic === "undefined") {
      Logger.logMessage("getBaseUrl => isPublic is not defined.");
      return prevBaseUrl;
    }

    if (isPublic) {
      if (DevConstants.isDevMode) {
        return this._replaceDefaultPublicUrl(prevBaseUrl);
      }
      if (this._nextPublicEndpoint.length > 0) {
        return this._nextPublicEndpoint;
      }
      return this._replaceDefaultPublicUrl(prevBaseUrl);
    }

    if (DevConstants.isDevMode) {
      return this._replaceDefaultPrivateUrl(prevBaseUrl);
    }

    if (this._nextPrivateEndpoint.length > 0) {
      return this._nextPrivateEndpoint;
    }
    return this._replaceDefaultPrivateUrl(prevBaseUrl);
  };

  private _replaceDefaultPublicUrl(prevBaseUrl: string): string {
    let result = prevBaseUrl;
    result = result.replace("/app.getfliff.com", "/m-c2.app.getfliff.com");
    result = result.replace("/dev.getfliff.com", "/m-c2.dev.getfliff.com");

    return result;
  }

  private _replaceDefaultPrivateUrl(prevBaseUrl: string): string {
    let result = prevBaseUrl;
    result = result.replace("/app.getfliff.com", "/m-c1.app.getfliff.com");
    result = result.replace("/dev.getfliff.com", "/m-c1.dev.getfliff.com");

    return result;
  }
}

export default new CoreApiUrlManager();
