import { BaseUIAmountsFormatters } from "./BaseUIAmountsFormatters";

class CashAmountFormatters extends BaseUIAmountsFormatters {
  public toFliffCash = (amountInCents: number): string => {
    // 2929-05-05 / Ivan / confirmed with Mario - for the time being we format 'fliff cash' in the same way as gold coins
    return `${this.withThousandSeparatorsMillionLimited(
      this.prepareCentsInDollarsImpl(amountInCents).toFixed(2),
    )}`;
  };

  public fliffCentsAsNumber(amountInCents: number): number {
    const dollarAmountAsString = this.toFliffCash(amountInCents);
    const dollarAmountAsNumber = Number(dollarAmountAsString);
    if (
      Number.isNaN(dollarAmountAsNumber) ||
      !Number.isFinite(dollarAmountAsNumber) ||
      dollarAmountAsNumber < 0
    ) {
      return 0;
    }
    return dollarAmountAsNumber;
  }

  /*
  format dollar amount
  input:  1231212321
  output: $12,312,123.21
*/
  public toDollarAmount = (amountInCents: number): string => {
    return `$${this.toFliffCash(amountInCents)}`;
  };

  public toShortenFCCentsAmount = (num: number, digits = 1): string => {
    if (num < 100) {
      return this.toFliffCash(num);
    }
    return this._toShortenFCAmountImpl(num, digits, true);
  };

  public toShortenFCDollarAmount = (num: number, digits = 1): string => {
    return this._toShortenFCAmountImpl(num, digits, false);
  };

  /*
    in order for values ot look huge, by default, fliff cash is displayed as 'cents' (with thousands separators)
    input: 1231212321
    output: 1,231,212,321
    at several very specific places, we display the value as 'amount in dollars with dollar sign'
  */
  public prepareCentsInDollars = (amountInCents: number): number => {
    return this.prepareCentsInDollarsImpl(amountInCents);
  };

  private _toShortenFCAmountImpl = (
    num: number,
    digits = 1,
    isAmountInCents: boolean,
  ): string => {
    if (num < 1000 && !isAmountInCents) {
      return `${num}`;
    }
    const toShorten = isAmountInCents ? num / 100 : num;
    const item = this.getShorteningAmountPattern(toShorten);
    return item
      ? this.replaceShortenAmountPattern(
          (toShorten / item.value).toFixed(digits),
          item.symbol,
        )
      : "0";
  };
}

export default new CashAmountFormatters();
