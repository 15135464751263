import React from "react";

import "./styles.scss";

import { ReactComponent as CheckIcon } from "assets/img/check.svg";
import { IProps } from "./types";

const GreenCheckmark = ({ className }: IProps) => (
  <div className={`green-checkmark-container ${className ? className : ""}`}>
    <CheckIcon width={"60%"} height={"60%"} />
  </div>
);

export default React.memo(GreenCheckmark);
