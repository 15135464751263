import { FliffException } from "server/legacyCore/FliffException";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
import {
  SocialAccessLevel,
  SocialPrivacyLevel,
} from "server/social/data/constants";
import { TAnyAlias } from "src/types";

export const validate_SocialAccessLevel = (
  jsondata: TAnyAlias,
  debugInfo: string,
): SocialAccessLevel => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in SocialAccessLevel)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn;
};

export const validate_SocialPrivacyLevel = (
  jsondata: TAnyAlias,
  debugInfo: string,
): SocialPrivacyLevel => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in SocialPrivacyLevel)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn;
};
