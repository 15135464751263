import { IProps } from "./types";
import colors from "assets/styles/design-tokens/colors.module.scss";
import { REMOTE_URLS } from "constants/REMOTE_URLS";
import LinkifiedText from "components/atoms_new/shared/LinkifiedText";
import "./styles.scss";
import classNames from "classnames";

const Checkbox = ({
  onChange,
  isChecked,
  type,
  isValid,
  theme,
  className,
}: IProps) => {
  const handleOpenTermsOfUseUrl = () => {
    window.open(REMOTE_URLS.termsOfUse, "_blank");
  };

  const handleOpenSweepstakesRulesUrl = () => {
    window.open(REMOTE_URLS.sweepstakes, "_blank");
  };

  const handlePrivacyPolicyPress = () => {
    window.open(REMOTE_URLS.privacyPolicy, "_blank");
  };

  const renderContent = () => {
    switch (type) {
      case "age":
        return (
          <span className={"checkbox-container__terms-wrapper"}>
            I certify that I am at least{" "}
            <span className="checkbox-container__age-label">18 years</span> of
            age.
          </span>
        );
      default:
        return (
          <span className={"checkbox-container__terms-wrapper"}>
            <LinkifiedText
              text={
                "I accept the <link>Terms of Use</link>, <link>Privacy Policy</link> and <link>Sweepstakes Rules</link> of Fliff Inc."
              }
              handlers={[
                handleOpenTermsOfUseUrl,
                handlePrivacyPolicyPress,
                handleOpenSweepstakesRulesUrl,
              ]}
            />
          </span>
        );
    }
  };

  const classes = classNames("checkbox-container", className, {
    "checkbox-container--dark": theme === "dark",
  });

  return (
    <label className={classes}>
      {renderContent()}
      <input
        className={"checkbox"}
        type={"checkbox"}
        checked={isChecked}
        onChange={onChange}
      />
      <span
        className={"checkbox-checkmark"}
        {...(!isValid && {
          style: { borderColor: colors.primaryColor },
        })}
      />
    </label>
  );
};

export default Checkbox;
