import Mixpanel from "mixpanel-browser";

import { IMixpanelInitUserData } from "src/types";
import Logger from "utils/Logger";
import { DevConstants } from "src/DevConstants";
import AppConfig from "utils/AppConfig";

class MixPanelWrapper {
  protected static _isInit = false;

  public static async init(initData: IMixpanelInitUserData) {
    if (MixPanelWrapper._isInit) {
      console.warn("Mixpanel is already init.");
      return;
    }
    await Mixpanel.init(AppConfig.mixpanelKey, {
      debug: DevConstants.isDevMode,
    });
    MixPanelWrapper._isInit = true;
    await MixPanelWrapper.setCustomerIdentifier(initData);
  }

  protected static setCustomerIdentifier = async (
    initData: IMixpanelInitUserData,
  ): Promise<void> => {
    if (!MixPanelWrapper._isInit) {
      Logger.warnMessage("Mixpanel is not init on setCustomerIdentifier call");
      return;
    }
    await Mixpanel.identify(`${initData.userId}`);
    await Mixpanel.people.set("ID", `${initData.userId}`);
    await Mixpanel.people.set("$email", initData.primaryEmail);
    await Mixpanel.people.set("Phone Number", initData.primaryPhoneNumber);
    await Mixpanel.people.set("name", initData.username);
    await Mixpanel.people.set("Full Name", initData.fullName);
    if (initData.referrerId !== 0) {
      await Mixpanel.people.set("Referrer ID", initData.referrerId);
    }
    if (initData.referrerUsername !== "") {
      await Mixpanel.people.set("Referrer Name", initData.referrerUsername);
    }
    // We are ridding off from Branch SDK from mob & mobile.
    // await BranchUtils.initMixpanelCustomer(`${initData.userId}`);
    return;
  };

  public reset = (): void => {
    if (!MixPanelWrapper._isInit) {
      return;
    }
    Mixpanel.reset();
  };

  protected trackEvent = (
    eventName: string,
    params?: Record<string, string | number>,
  ): void => {
    if (!MixPanelWrapper._isInit) {
      return;
    }
    Mixpanel.track(eventName, params);
  };
}

export default MixPanelWrapper;
