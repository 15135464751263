import ApiCore from "server/core/server/ApiCore";

import {
  CoreGetLastTransactionsAction,
  CoreGetPaymentTokensAction,
} from "reduxLocal/core/core.actions";
import * as I from "server/core/data/objects";
import { TActionCode, TFliffResult } from "server/sharedCore/data/objects";
import { BundleObtainMethod } from "server/core/data/constants";

class Private {
  private readonly _localRequestMeta = { isPrivate: true } as const;

  public safeBlockingObtainFreeBundle(
    data: Pick<
      I.IFCMObtainBundleRequest,
      "bundle_code" | "serialized_goods" | "price_in_cents"
    >,
  ): Promise<TFliffResult<I.IFCMObtainBundleResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMObtainBundleRequest,
      I.IFCMObtainBundleResponse
    >({
      __object_class_name: "FCM__Obtain_Bundle__Request",
      obtain_method: BundleObtainMethod.CONST_77091_CLAIM_FREE_BUNDLE,
      localMeta: this._localRequestMeta,
      ...data,
    });
  }

  public safeBackgroundRefreshUserProfile(): Promise<
    TFliffResult<I.IFCMRefreshUserProfileResponse>
  > {
    return ApiCore.safeExecuteBackgroundRequest<
      I.IFCMRefreshUserProfileRequest,
      I.IFCMRefreshUserProfileResponse
    >({
      __object_class_name: "FCM__Refresh_User_Profile__Request",
      localMeta: this._localRequestMeta,
    });
  }

  public safeBlockingRefreshUserProfile = (): Promise<
    TFliffResult<I.IFCMRefreshUserProfileResponse>
  > => {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMRefreshUserProfileRequest,
      I.IFCMRefreshUserProfileResponse
    >({
      __object_class_name: "FCM__Refresh_User_Profile__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBlockingGetReferralReport = (): Promise<
    TFliffResult<I.IFCMGetReferralReportResponse>
  > => {
    return ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFCMGetReferralReportRequest,
      I.IFCMGetReferralReportResponse
    >({
      __object_class_name: "FCM__Get_Referral_Report__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBackgroundGetLastTransactions = async (): Promise<
    TFliffResult<I.IFCMGetTransactionsReportResponse>
  > => {
    const result = await ApiCore.safeExecuteBackgroundRequest<
      I.IFCMGetTransactionsReportRequest,
      I.IFCMGetTransactionsReportResponse
    >({
      __object_class_name: "FCM__Get_Transactions_Report__Request",
      localMeta: this._localRequestMeta,
    });

    if (result.response) {
      CoreGetLastTransactionsAction.dispatchGetLastTransactions(
        result.response,
      );
    }

    return result;
  };

  public safeBlockingGetPeriodTransactionsReport = (
    reportPeriod: I.IFCMGetTransactionsReportRequest["report_period"],
  ): Promise<TFliffResult<I.IFCMGetTransactionsReportResponse>> => {
    return ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFCMGetTransactionsReportRequest,
      I.IFCMGetTransactionsReportResponse
    >({
      __object_class_name: "FCM__Get_Transactions_Report__Request",
      localMeta: this._localRequestMeta,
      report_period: reportPeriod,
    });
  };

  public safeBlockingInitiateTOUSession = (): Promise<
    TFliffResult<I.IFCMInitiateTOUSessionResponse>
  > => {
    return ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
      I.IFCMInitiateTOUSessionRequest,
      I.IFCMInitiateTOUSessionResponse
    >({
      __object_class_name: "FCM__Initiate_TOU_Session__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBlockingGetContactUsSubjectLine = (): Promise<
    TFliffResult<I.IFCMGetContactUsSubjectLineResponse>
  > => {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMGetContactUsSubjectLineRequest,
      I.IFCMGetContactUsSubjectLineResponse
    >({
      __object_class_name: "FCM__Get_Contact_US_Subject_Line__Request",
      localMeta: this._localRequestMeta,
    });
  };

  public safeBlockingObtainBundleWithXP(
    data: Pick<
      I.IFCMObtainBundleRequest,
      "bundle_code" | "serialized_goods" | "price_in_cents"
    >,
  ): Promise<TFliffResult<I.IFCMObtainBundleResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMObtainBundleRequest,
      I.IFCMObtainBundleResponse
    >({
      __object_class_name: "FCM__Obtain_Bundle__Request",
      obtain_method: BundleObtainMethod.CONST_77092_OBTAIN_BUNDLE_VIA_XP,
      localMeta: this._localRequestMeta,
      ...data,
    });
  }

  public safeBlockingObtainBundleWithFC(
    data: Pick<
      I.IFCMObtainBundleRequest,
      "bundle_code" | "serialized_goods" | "price_in_cents"
    >,
  ): Promise<TFliffResult<I.IFCMObtainBundleResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IFCMObtainBundleRequest,
      I.IFCMObtainBundleResponse
    >({
      __object_class_name: "FCM__Obtain_Bundle__Request",
      obtain_method:
        BundleObtainMethod.CONST_77093_OBTAIN_BUNDLE_VIA_REDEEMABLE_FC,
      localMeta: this._localRequestMeta,
      ...data,
    });
  }

  public safeBlockingTransparentGetPaymentTokens = async (): Promise<
    TFliffResult<I.IFCMGetPaymentTokensResponse>
  > => {
    const result =
      await ApiCore.safeExecuteBlockingTransparentNoErrorModalRequest<
        I.IFCMGetPaymentTokensRequest,
        I.IFCMGetPaymentTokensResponse
      >({
        __object_class_name: "FCM__Get_All_Payment_Tokens__Request",
        localMeta: this._localRequestMeta,
      });
    if (result.response) {
      CoreGetPaymentTokensAction.dispatchGetPaymentTokens(
        result.response.payment_tokens,
      );
    }

    return result;
  };

  public safeBlockingTransparentDisablePaymentToken = async (
    paymentToken: string,
  ): Promise<TFliffResult<I.IFCMDisablePaymentTokenResponse>> => {
    const result = await ApiCore.safeExecuteBlockingRequest<
      I.IFCMDisablePaymentTokenRequest,
      I.IFCMDisablePaymentTokenResponse
    >({
      __object_class_name: "FCM__Disable_Payment_Token__Request",
      payment_token: paymentToken,
      localMeta: this._localRequestMeta,
    });
    if (result.response) {
      CoreGetPaymentTokensAction.dispatchGetPaymentTokens(
        result.response.payment_tokens,
      );
    }

    return result;
  };

  public safeBackgroundGetOpenPodsReport(): Promise<
    TFliffResult<I.IFCMGetPodsReportResponse>
  > {
    return ApiCore.safeExecuteBackgroundRequest<
      I.IFCMGetPodsReportRequest,
      I.IFCMGetPodsReportResponse
    >({
      __object_class_name: "FCM__Get_Pods_Report__Request",
      localMeta: this._localRequestMeta,
    });
  }

  public safeBackgroundGetPodsReport(
    minKnownPodFkey: string,
    actionCode: TActionCode,
  ): Promise<TFliffResult<I.IFCMGetPodsReportResponse>> {
    return ApiCore.safeExecuteBackgroundRequest<
      I.IFCMGetPodsReportRequest,
      I.IFCMGetPodsReportResponse
    >({
      __object_class_name: "FCM__Get_Pods_Report__Request",
      localMeta: this._localRequestMeta,
      scrolling_load_action: {
        action_code: actionCode,
        max_known_fkey: "",
        min_known_fkey: minKnownPodFkey,
      },
    });
  }

  public safeBlockingInitiateBundlePurchase(
    data: Pick<
      I.IInitiateBundlePurchaseRequest,
      "bundle_code" | "vendor_code" | "price_in_cents" | "payment_token"
    >,
  ): Promise<TFliffResult<I.IInitiateBundlePurchaseResponse>> {
    return ApiCore.safeExecuteBlockingRequest<
      I.IInitiateBundlePurchaseRequest,
      I.IInitiateBundlePurchaseResponse
    >({
      __object_class_name: "FCM__Initiate_Bundle_Purchase__Request",
      localMeta: this._localRequestMeta,
      ...data,
    });
  }
}

export default new Private();
