import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef } from "react";
import Select, { SingleValue } from "react-select";
import { TAnyAlias } from "src/types";
import { IValidationReturnType } from "utils/FormValidationUtils";
import ValidationStateMark from "../ValidationStateMark";
import IndicatorSeparator from "./atoms/IndicatorSeparator";
import SingleValueComponent from "./atoms/SingleValueComponent";
import "./styles.scss";

export interface ISelectOption<T> {
  value: T;
  label: string;
}

export type TOnOptionSelect<T> = (
  itemValue: ISelectOption<T>["value"],
) => () => void;
export type TOptionsSelectData<T> = ISelectOption<T>[];

export interface IProps<T> {
  onSelect: TOnOptionSelect<T>;
  data: TOptionsSelectData<T>;
  placeholder?: string;
  validationState: IValidationReturnType<T>;
  onBlur: () => void;
  theme: "light" | "dark";
}

const SelectList = ({
  onSelect,
  data,
  placeholder,
  validationState,
  onBlur,
  theme,
}: IProps<TAnyAlias>) => {
  const selectRef = useRef<TAnyAlias | null>(null);
  const wasEverOpen = useRef(false);

  const handleSelectItem = useCallback(
    (item: SingleValue<ISelectOption<string>>) => () => {
      onSelect(item?.value || "")();
    },
    [onSelect],
  );

  const handleMenuOpen = () => {
    wasEverOpen.current = true;
  };

  const handleChange = (item: SingleValue<ISelectOption<string>>) => {
    handleSelectItem(item)();
  };

  // Fix for missing subject when value is empty string or 0
  const remapValue = useMemo((): ISelectOption<string> | null => {
    if (!validationState.value) {
      return null;
    }
    const selectedItem = data.find(
      item => item.value === validationState.value,
    );
    return {
      label: selectedItem?.label || validationState.value,
      value: validationState.value,
    };
  }, [validationState.value, data]);

  const inputSelectClasses = classNames("select-list", {
    "select-list--invalid": !validationState.isValid,
    "select-list--dark": theme === "dark",
  });

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (selectRef.current) {
        const target = event.target as Node;
        if (
          !selectRef.current.contains(target) &&
          !validationState.value &&
          wasEverOpen.current
        ) {
          onBlur();
        }
      }
    },
    [validationState.value, onBlur],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchend", handleClickOutside, {
      passive: true,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className={inputSelectClasses} ref={selectRef}>
      <Select
        value={remapValue}
        onChange={handleChange}
        isMulti={false}
        options={data}
        placeholder={placeholder}
        components={{ IndicatorSeparator, SingleValue: SingleValueComponent }}
        classNamePrefix="select-list__input"
        onMenuOpen={handleMenuOpen}
      />
      <ValidationStateMark validationState={validationState} />
    </div>
  );
};

export default SelectList;
