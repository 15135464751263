import { connect } from "react-redux";

import { TGlobalAppState } from "reduxLocal/rootReducer";

import * as sportsBookSelectors from "selectors/sportsBook";

export const mapStateToProps = (state: TGlobalAppState) => ({
  channels: sportsBookSelectors.getSortedChannels(state),
  events: sportsBookSelectors.getEvents(state),
  allChannels: sportsBookSelectors.getAllChannels(state),
  allSportsChannels: sportsBookSelectors.getAllSportsChannels(state),
});

export default connect(mapStateToProps);
