import AppleSocialButton from "components/atoms_new/shared/SocialButton/molecules/Apple";
import GoogleSocialButton from "components/atoms_new/shared/SocialButton/molecules/Google";
import { FliffException } from "server/legacyCore/FliffException";
import { DefaultBIFResponse } from "server/legacyCore/objectsLegacyCore";
import "./styles.scss";
import { IModalControls } from "components/atoms_new/desktop/Modals/AuthenticationModal/types";

const SocialButtons = ({ onClose, openNext }: IModalControls) => {
  const handleSocialAuthSuccess = (response: DefaultBIFResponse): void => {
    try {
      if (response.is_error) {
        return;
      }
      if (
        response.resultCode ===
        FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED
      ) {
        openNext("completeProfile");
      } else if (
        response.resultCode ===
        FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER
      ) {
        openNext("verifyCode", { mode: 3 });
      } else if (response.resultCode === 321) {
        // Successfully login. Close the modals
        onClose();
      } else if (
        response.resultCode ===
        FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE
      ) {
        // TODO: Navigate to terms of use page target blank
        console.log("Navigate to terms of use page target blank");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <div className="social-buttons">
      <AppleSocialButton
        onSuccess={handleSocialAuthSuccess}
        className="social-buttons__transparent"
      />
      <GoogleSocialButton
        onSuccess={handleSocialAuthSuccess}
        className="social-buttons__transparent"
      />
    </div>
  );
};

export default SocialButtons;
