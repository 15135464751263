const ENV_VARS = process.env;
console.log(ENV_VARS);
class AppConfig {
  public get modalProgressBarTransparentMode() {
    return -1;
  }
  // default - modal, fully visible progress bar
  public get modalProgressBarEnforceIndicatorMode() {
    return 0;
  }
  // actually milliseconds - show temp transparent overlay, then switch to modal progress bar
  public get modalProgressBarDefaultMode() {
    return 2000;
  }
  // actually milliseconds - show temp transparent overlay forever (30 sec)
  public get modalProgressBarModalTransparent() {
    return 20000;
  }

  public get env() {
    if (!ENV_VARS.REACT_APP_ENV) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_ENV;
  }
  public get marketingVersion() {
    if (!ENV_VARS.REACT_APP_MARKETING_VERSION) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_MARKETING_VERSION;
  }

  public get RPMDomain() {
    if (!ENV_VARS.REACT_APP_RPM_DOMAIN) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_RPM_DOMAIN;
  }

  public get googleClientId() {
    if (!ENV_VARS.REACT_APP_GOOGLE_CLIENT_ID) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_GOOGLE_CLIENT_ID;
  }

  public get mixpanelKey() {
    if (!ENV_VARS.REACT_APP_MIXPANEL_CLIENT_KEY) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_MIXPANEL_CLIENT_KEY;
  }

  public get googleDecodingKey() {
    if (!ENV_VARS.REACT_APP_GOOGLE_DECODING_API_KEY) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_GOOGLE_DECODING_API_KEY;
  }

  public get ipApiKey() {
    if (!ENV_VARS.REACT_APP_IP_API_KEY) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_IP_API_KEY;
  }

  public get capabilityNumber() {
    if (!ENV_VARS.REACT_APP_CAPABILITY_NUMBER) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_CAPABILITY_NUMBER;
  }

  public get log() {
    if (!ENV_VARS.REACT_APP_LOG) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_LOG;
  }

  public get infoLog() {
    if (!ENV_VARS.REACT_APP_INFO_LOG) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_INFO_LOG;
  }

  public get socialReturnUrl() {
    if (!ENV_VARS.REACT_APP_SOCIAL_RETURN_URL) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_SOCIAL_RETURN_URL;
  }

  public get isMaintenanceMode() {
    if (!ENV_VARS.REACT_APP_IS_MAINTENANCE_MODE) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_IS_MAINTENANCE_MODE === "enabled";
  }

  public get radarApiKey() {
    if (!ENV_VARS.REACT_APP_RADAR_CLIENT_KEY) {
      throw new Error(".env or .env.development is missing");
    }
    return ENV_VARS.REACT_APP_RADAR_CLIENT_KEY;
  }
}

export default new AppConfig();
