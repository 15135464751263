import DeviceInfo from "utils/DeviceInfo";
import Logger from "utils/Logger";
import { DevConstants } from "src/DevConstants";
import { PersistentStorage } from "utils/PersistentStorage";

export class EnvUtils {
  public static async extractEnvData(): Promise<string> {
    let res = "";
    try {
      res += "" + PersistentStorage.getLoggedAccountDebugInfoForSupportEmail();
      res += "\nversion: " + DevConstants.appVersionPlusCapability;
      res += "\n\n=======\n";

      res +=
        "\napplication: " +
        DeviceInfo.applicationName +
        ", version: " +
        DeviceInfo.version +
        ", system build: " +
        DevConstants.capabilityNumber;
      res +=
        "\nos: " +
        DeviceInfo.systemName +
        ", version: " +
        DeviceInfo.systemVersion;
      res +=
        "\nbrand: " +
        DeviceInfo.brand +
        ", model: " +
        DeviceInfo.model +
        ", deviceId: " +
        (await DeviceInfo.getDeviceId());
      res += "\ndevice name: " + DeviceInfo.deviceName;
      res += "\ndetails: " + DeviceInfo.fingerprint;
    } catch (error) {
      Logger.warnAny("  x in extractEnvData", error);
      res += "\n\n\n error in extractEnvData";
    }

    return res;
  }
}
