import classNames from "classnames";
import "./styles.scss";
import { IProps } from "./types";

import { ReactComponent as InfoIcon } from "assets/img/info.svg";

const WarningSubtitle = ({ subtitle, className }: IProps) => {
  const classes = classNames("warning-subtitle", className);

  return (
    <div className={classes}>
      <InfoIcon className="warning-subtitle__icon" />
      <span className="warning-subtitle__subtitle">{subtitle}</span>
    </div>
  );
};

export default WarningSubtitle;
