import { useEffect } from "react";

import { useSelector } from "react-redux";

import useTimer from "hooks/useTimer";
import CoreSelectors from "reduxLocal/core/core.selectors";
import { TimeUtils } from "utils/TimeUtils";

const usePhoneVerificationTimer = (secondsToEnd?: number) => {
  const [timeFormat, startTimer, stopTimer, isTicking] = useTimer("mm:ss");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stopTimer, []);

  const nextClaimStamp = useSelector(CoreSelectors.Attributes.phoneNextStamp);

  useEffect(() => {
    if (nextClaimStamp) {
      startTimer(TimeUtils.fromSecondsToMills(nextClaimStamp));
      return;
    }
    if (secondsToEnd) {
      const now = TimeUtils.now();
      startTimer(+now + secondsToEnd * 1000);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextClaimStamp, secondsToEnd]);

  const Label = (
    <div className={"phone-verification-timer"}>
      <span className={"phone-verification-time"}>{timeFormat}</span>
      <span className={"phone-verification-text"}>
        Until a new code can be sent.
      </span>
    </div>
  );

  return { isTicking, Label, timeFormat } as const;
};

export default usePhoneVerificationTimer;
