import { createBrowserHistory } from "history";
import useRunOnce from "hooks/lifecycles/useRunOnce";
import { ReactNode } from "react";
import { Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import URLUtils from "utils/URLUtils";

const initialLocationKey = "__INITIAL_LOCATION__";
const DEFAULT_FALLBACK_PATH = "/";

interface ILocationState {
  [initialLocationKey]: boolean;
}
interface IProps {
  children: ReactNode;
}

const RootRouter = ({ children }: IProps) => {
  const history = createBrowserHistory<ILocationState>();

  // Run one per session
  useRunOnce(() => {
    history.replace({
      ...history.location,
      state: {
        [initialLocationKey]: true,
      },
    });
  }, initialLocationKey);

  history.goBack = () => {
    const state = history.location.state;

    // Replace history with a fallback path if there are no previous locations
    if (state && state[initialLocationKey]) {
      history.replace(DEFAULT_FALLBACK_PATH, state);
      return;
    }

    window.history.go(-1);
  };

  return (
    <Router history={history}>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: URLUtils.parse,
          objectToSearchString: URLUtils.stringify,
          updateType: "replaceIn",
        }}>
        {children}
      </QueryParamProvider>
    </Router>
  );
};

export default RootRouter;
