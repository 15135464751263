import {
  BundleObtainMethod,
  FeedSyncCode,
  PaymentVendor,
  PlayThroughPodCredibleState,
  PlayThroughPodPlayableState,
  PlayThroughPodProgressState,
  TransactionType,
} from "server/core/data/constants";
import {
  DataReq__SB_Profile_Meta,
  Data__SB_Config,
  Data__SB_FeedDiff_Shelf_Update,
  Data__SB_SportEvent,
  Data__SB_UserProfile,
  Data__SB_X_Delta_Profile,
  Data__Slide_User_Gift_Card,
  IPlayThroughBundle,
} from "server/legacyCore/data/objects";
import {
  Data__SB_Unfinished_Order,
  IFeedMeta,
  IFliffProtocolRequest,
  IFliffRequest,
  IFliffResponse,
  TActionCode,
  TMergeCode,
} from "server/sharedCore/data/objects";

export interface IFCMRequest extends IFliffRequest {
  localMeta: { isPrivate: boolean; forceAttemptToHerald?: boolean };
}

export interface IFCMProtocolRequestSlotsMeta {
  sb_config_version: number;
  sb_user_profile_version: number;
  sb_user_profile_meta: DataReq__SB_Profile_Meta | null;
}

export interface IFCMProtocolRequest<Request extends IFCMRequest>
  extends IFliffProtocolRequest<Request> {
  x_sb_meta: IFCMProtocolRequestSlotsMeta;
}

export interface IFCMProtocolResponseSlots {
  config: Data__SB_Config | null;
  profile: Data__SB_UserProfile | null;
  deltaProfile: Data__SB_X_Delta_Profile | null;
  inplayConflicts: Data__SB_SportEvent[] | null;
  prematchConflicts: Data__SB_SportEvent[] | null;
  inplaySubfeedsUpdate: Data__SB_FeedDiff_Shelf_Update[] | null;
  prematchSubfeedsUpdate: Data__SB_FeedDiff_Shelf_Update[] | null;
}

export interface IFCMPingRequest extends IFCMRequest {
  __object_class_name: "FCM__Ping__Request";
  message: string;
}

export type TRestrictionCodes = 15001 | 15002 | 15003 | 15004;

export interface IUSAStateRestriction {
  restriction_code: TRestrictionCodes;
  restriction_message: string;
  usa_state_code: string;
}

export interface IReferralReportItem {
  rewards_cash_earned_in_cents: number;
  user_id: number;
  username: string;
}

export type TDeviceLocationVendor = "radar" | "internal";

export interface IFCMPingResponse extends IFliffResponse {
  __object_class_name: "FCM__Ping__Response";
  data: {
    min_app_build: number;
    server_build: number;
    server_instance_info: string;
    server_timestamp: number;
    usa_state_restrictions: IUSAStateRestriction[];
    core_sever_public_endpoint: string;
    core_sever_private_endpoint: string;
    proposals_feed_endpoint: string;
    device_location_vendor: TDeviceLocationVendor;
    is_alt_prop_button_enabled: boolean;
  };
}

export interface IFCMClaimFreeBundleRequest extends IFCMRequest {
  __object_class_name: "FCM__Claim_Free_Bundle__Request";
}

export interface IFCMClaimFreeBundleResponse extends IFliffResponse {
  __object_class_name: "FCM__Claim_Free_Bundle__Response";
}

export interface IFCMRefreshUserProfileRequest extends IFCMRequest {
  __object_class_name: "FCM__Refresh_User_Profile__Request";
}

export interface IFCMRefreshUserProfileResponse extends IFliffResponse {
  __object_class_name: "FCM__Refresh_User_Profile__Response";
}

export interface IFCMGetReferralReportRequest extends IFCMRequest {
  __object_class_name: "FCM__Get_Referral_Report__Request";
}

export interface IFCMGetReferralReportResponse extends IFliffResponse {
  __object_class_name: "FCM__Get_Referral_Report__Response";
  data: IReferralReportItem[];
  max_rewards_cash_per_user: number;
  total_rewards_cash_in_cents: number;
}

export interface IFCMTransaction {
  id: number;
  created_timestamp_utc: number;
  type: TransactionType;
  amount: number;
  obtained_coins: number;
  obtained_v2_tokens: number;
  obtained_fliff_cash: number;
  obtained_v3_xpoints: number;
  d_5004_rewards_cash: number;
  // 2021-03-01 / Ivan / this field will be non-null for CONST__4062__SLIDE_GIFT_CARD_CASHBACK
  // 2022-05-29 / Ivan / and also for CONST__4063__SLIDE_GIFT_CARD_PURCHASE_WITHOUT_CASHBACK
  user_gift_card: Data__Slide_User_Gift_Card | null;
  // 2021-05-20 / Ivan / we will just reuse existing DTO object, IMPORTANT NOTE rewards_cash_earned_in_cents will be == 0
  // 2021-05-20 / Ivan / this field will be non-null for CONST__4038__REFERRAL_PARENT_BONUS_FOR_REWARDS_CASH_PURCHASE_TOKENS_BUNDLE
  referral_data: IReferralReportItem | null;
}

export interface IFCMGetTransactionsReportRequest extends IFCMRequest {
  __object_class_name: "FCM__Get_Transactions_Report__Request";
  report_period?: {
    request_id: string;
    start_stamp_seconds_utc: number;
    end_stamp_seconds_utc: number;
    other_user_id?: number;
  };
}

export interface IFCMGetTransactionsReportResponse extends IFliffResponse {
  __object_class_name: "FCM__Get_Transactions_Report__Response";
  transactions: IFCMTransaction[];
  unfinished_orders: Data__SB_Unfinished_Order[];
}

export interface IFCMInitiateTOUSessionRequest extends IFCMRequest {
  __object_class_name: "FCM__Initiate_TOU_Session__Request";
}

export interface IFCMInitiateTOUSessionResponse extends IFliffResponse {
  __object_class_name: "FCM__Initiate_TOU_Session__Response";
  tou_session_enter_url: string;
}

export interface IFCMGetPaymentTokensRequest extends IFCMRequest {
  __object_class_name: "FCM__Get_All_Payment_Tokens__Request";
}

export interface IFCMGetPaymentTokensResponse extends IFliffResponse {
  __object_class_name: "FCM__Get_All_Payment_Tokens__Response";
  payment_tokens: IFCMPaymentMethodToken[];
}

export enum WithdrawalPaymentInstrumentType {
  CONST_8101_MAZOOMA_DEFAULT = 8101,
  CONST_8201_FISERV_VIA_BANK_DEFAULT = 8201,
  CONST_8251_FISERV_VIA_CARD_DEFAULT = 8251,
  CONST_8252_FISERV_VIA_CARD_VISA = 8252,
  CONST_8253_FISERV_VIA_CARD_MASTERCARD = 8253,
}

export enum WithdrawalPaymentInstrumentStatus {
  CONST_8901_VALID = 8901,
  CONST_8902_EXPIRED = 8902,
}

export interface IFCMPaymentMethodToken {
  payment_token: string;
  visual_name_1: string;
  visual_name_2: string;
  visual_name_3?: string;
  instrument_type?: WithdrawalPaymentInstrumentType;
  instrument_status?: WithdrawalPaymentInstrumentStatus;
}

export interface IFCMDisablePaymentTokenRequest extends IFCMRequest {
  __object_class_name: "FCM__Disable_Payment_Token__Request";
  payment_token: string;
}

export interface IFCMDisablePaymentTokenResponse extends IFliffResponse {
  __object_class_name: "FCM__Disable_Payment_Token__Response";
  payment_tokens: IFCMPaymentMethodToken[];
}

export interface IFCMPublicFeedSyncRequest extends IFCMRequest {
  __object_class_name: "FCM__Public_Feed_Sync__Request";
  code: FeedSyncCode;
  subfeed_meta: IFeedMeta;
}

export interface IFCMPublicFeedSyncResponse extends IFliffResponse {
  __object_class_name: "FCM__Public_Feed_Sync__Response";
}

export interface IFCMUploadMobileLogRequest extends IFCMRequest {
  __object_class_name: "FCM__Upload_Mobile_Log__Request";
  einfo: string;
  winfo: string;
}

export interface IFCMUploadMobileLogResponse extends IFliffResponse {
  __object_class_name: "FCM__Upload_Mobile_Log__Response";
}

export interface IFCMPublicFeedSyncResponse extends IFliffResponse {
  __object_class_name: "FCM__Public_Feed_Sync__Response";
}

export interface IFCMPublicFeedSyncRequest extends IFCMRequest {
  __object_class_name: "FCM__Public_Feed_Sync__Request";
  code: FeedSyncCode;
  subfeed_meta: IFeedMeta;
}

export interface IFSMContactUsPickSubject {
  type: "pickId";
  name: string;
}

export interface IFSMContactUsOptionSubject {
  type: "option";
  name: string;
}

export interface IFSMContactUsSelectSubject {
  type: "select";
  name: string;
  subject_lines: TFSMContactUsSubjectLine[];
}

export type TFSMContactUsSubjectLine =
  | IFSMContactUsPickSubject
  | IFSMContactUsOptionSubject
  | IFSMContactUsSelectSubject;

export interface IFCMGetContactUsSubjectLineRequest extends IFCMRequest {
  __object_class_name: "FCM__Get_Contact_US_Subject_Line__Request";
}

export interface IFCMGetContactUsSubjectLineResponse extends IFliffResponse {
  __object_class_name: "FCM__Get_Contact_US_Subject_Line__Response";
  subject_lines: TFSMContactUsSubjectLine[];
}

export interface IFCMObtainBundleRequest
  extends IFCMRequest,
    Pick<
      IPlayThroughBundle,
      "bundle_code" | "serialized_goods" | "price_in_cents"
    > {
  __object_class_name: "FCM__Obtain_Bundle__Request";
  obtain_method: BundleObtainMethod;
}

export interface IFCMObtainBundleResponse extends IFliffResponse {
  __object_class_name: "FCM__Obtain_Bundle__Response";
}

export interface IInitiateBundlePurchaseRequest
  extends IFCMRequest,
    Pick<IPlayThroughBundle, "bundle_code" | "price_in_cents"> {
  __object_class_name: "FCM__Initiate_Bundle_Purchase__Request";
  vendor_code: PaymentVendor;
  payment_token: IFCMPaymentMethodToken["payment_token"];
}

export interface IBundleContext {
  last_claim_stamp_sec: number;
  tags: string[];
  usa_state_code: string;
  user_total_fliff_cash: number;
  user_total_usd_spent: number;
}

export interface IBundleGoods
  extends Pick<
    IPlayThroughBundle,
    | "amount_fliff_cash"
    | "amount_social_coins"
    | "price_in_cents"
    | "bundle_code"
  > {
  applied_tags: string[];
  bundle_context: IBundleContext;
  multiplier_in_percents: number;
}

export interface IInitiateBundlePurchaseResponse extends IFliffResponse {
  __object_class_name: "FCM__Initiate_Bundle_Purchase__Response";
  initiate_url: string;
  v9_bundle_goods_2_initiated: IBundleGoods;
  own_account_ref_code: string | undefined;
  own_invoice_ref_code: string | undefined;
  own_invoice_id: number | undefined;
}

export interface IPlayThroughPod {
  created_stamp_millis: number;
  creator_fkey: string;
  credible_state: PlayThroughPodCredibleState;
  open_pick_fkeys: string[];
  playable_state: PlayThroughPodPlayableState;
  pod_fkey: string;
  progress_state: PlayThroughPodProgressState;
  r_5011_v5_playable_fliff_cash: number;
  r_5012_v5_locked_in_picks_fliff_cash: number;
  r_5014_v5_transferred_fliff_cash: number;
  r_7001_initial_fliff_cash: number;
  r_7002_play_through_target: number;
  r_7003_play_through_progress: number;
  r_7022_initial_multiplier_in_percents: number;
  v_transaction_amount: string;
  v_transaction_type: string;
}

export interface IFCMGetPodsReportRequest extends IFCMRequest {
  __object_class_name: "FCM__Get_Pods_Report__Request";
  scrolling_load_action?: {
    action_code: TActionCode;
    max_known_fkey: string;
    min_known_fkey: string;
  };
}

export interface IFCMGetPodsReportResponse extends IFliffResponse {
  __object_class_name: "FCM__Get_Pods_Report__Response";
  playable_pods: IPlayThroughPod[];
  merge_code: TMergeCode;
}

export interface IFCMRegisterRadarSDKCallBackDataRequest extends IFCMRequest {
  __object_class_name: "FCM__Register_Radar_SDK_CallBack_Data__Request";
  raw_sdk_data_encoded_as_string: string;
}

export interface IFCMRegisterRadarSDKCallBackDataResponse
  extends IFliffResponse {
  __object_class_name: "FCM__Register_Radar_SDK_CallBack_Data__Response";
  usa_state_code: string;
  usa_state_code_source: string;
}
