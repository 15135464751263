import { TAnyAlias } from "src/types";

export enum FWebSocketState {
  IDLE,
  SCHEDULE_RECONNECT,
  ESTABLISHING_CONNECTION,
  ACTIVE,
  CLOSED,
}

export class FWebSocketStats {
  public c_11_connect = 0;
  public c_12_open = 0;
  public c_13_error = 0;
  public c_14_on_close = 0;

  public c_22_frames_out_skipped = 0;
  public c_23_frames_out_sent = 0;
  public c_29_bytes_out = 0;

  public c_31_frames_in = 0;
  public c_39_bytes_in = 0;
}

export interface IEndpoint {
  get address(): string;
  get stats(): FWebSocketStats;

  send(msg: string): boolean;

  close(): void;
  removeListener(
    event: string | symbol,
    listener: (...args: TAnyAlias[]) => void,
  ): this;
  on(event: string | symbol, listener: (...args: TAnyAlias[]) => void): this;
}

export interface IOnIncomingMessageCallBack {
  onIncomingMessage(message: string): void;
}

export interface IOnEndpointConnectedCallback {
  // temporary any, until we remove the zmq code
  getMessagesOnConnect(endpoint: TAnyAlias): string[];
}
