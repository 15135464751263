import React from "react";
import FliffLogoDesc from "components/atoms_new/shared/FliffLogoDesc";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import "./styles.scss";
import TextLink from "components/atoms_new/desktop/TextLink";
import Button from "components/atoms_new/desktop/Button";
import { IModalControls } from "components/atoms_new/desktop/Modals/AuthenticationModal/types";
import SocialButtons from "components/atoms_new/desktop/SocialButtons";

const SignUpModal = React.forwardRef<HTMLDivElement, IModalControls>(
  ({ onClose, openNext, openPrevious }, forwardedRef) => {
    const handleSignUpWithEmail = () => {
      openNext("signUpEmail");
    };

    const handleSignIn = () => {
      openNext("signIn");
    };

    return (
      <div className="sign-up-modal" ref={forwardedRef}>
        <ModalHeader title={"Sign Up"} onClose={onClose} />
        <FliffLogoDesc className="sign-up-modal__description" />

        <SocialButtons
          onClose={onClose}
          openNext={openNext}
          openPrevious={openPrevious}
        />
        <Button onClick={handleSignUpWithEmail} label={"Sign Up With Email"} />

        <div className={"sign-up-modal__footer"}>
          <span className={"sign-up-modal__label"}>Have an account ?</span>
          <TextLink label={"Sign In"} onClick={handleSignIn} />
        </div>
      </div>
    );
  },
);

SignUpModal.displayName = "SignUpModal";

export default SignUpModal;
