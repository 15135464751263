import { TokenResponse, useGoogleLogin } from "@react-oauth/google";

import SocialButton from "components/atoms_new/shared/SocialButton";

import GoogleImg from "assets/img/google-sign-in.svg";
import { SportsBookSocialSignInBIF } from "server/legacyCore/api/SportsBookSocialSignInBIF";

import { IProps } from "./types";

const GoogleSocialButton = ({ onSuccess, className }: IProps) => {
  const handleSuccessResponse = async (
    googleResponse: Omit<
      TokenResponse,
      "error" | "error_description" | "error_uri"
    >,
  ): Promise<void> => {
    try {
      const response = await SportsBookSocialSignInBIF.signInWithGoogle(
        googleResponse,
      );
      onSuccess(response);
    } catch (err) {
      SportsBookSocialSignInBIF.handleErrorOnSocialSignIn(err);
    }
  };

  //Lib produces a warning using flow: "implicit" (default), has nothing to the success workflow.
  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: handleSuccessResponse,
    onError: SportsBookSocialSignInBIF.handleErrorOnSocialSignIn,
  });

  return (
    <SocialButton
      onClick={handleGoogleSignIn}
      socialName={"Google"}
      imageSource={GoogleImg}
      className={className}
    />
  );
};

export default GoogleSocialButton;
