/*
  2020-12-05 / Ivan / separated the concept for device uptime - a counter which does not depend on user device time/timezone settings

  Notes:
  1. 'device update' requires async native call, for the time being, we will periodically poll and save the last known value
  2. initially we will use  very dummy implementation may use new Date() instead of real 'device uptime'
*/

import AppStateManager from "utils/AppStateManager";

export class DeviceUptimeClock {
  // last known polled value
  private static _initialDeviceUptimeMillis = -1;
  private static _lastDeviceUptimeMillis = -1;
  private static _isInsideUpdate = false;

  public static get relativeUptimeMillis() {
    if (
      DeviceUptimeClock._lastDeviceUptimeMillis === -1 ||
      DeviceUptimeClock._initialDeviceUptimeMillis === -1
    ) {
      return -1;
    }

    return (
      DeviceUptimeClock._lastDeviceUptimeMillis -
      DeviceUptimeClock._initialDeviceUptimeMillis
    );
  }

  public static get lastDeviceUptimeMillis() {
    return DeviceUptimeClock._lastDeviceUptimeMillis;
  }

  // in this basic implementation, we will periodically do (await) poll for device uptime
  public static init() {
    AppStateManager.initInterval(
      DeviceUptimeClock._updateLastDeviceUptimeMillis,
      24,
      "DeviceUptimeClock",
    );
  }

  public static nativeDeviceUptimeMillis(): number {
    const res = new Date().getTime();

    // 2022-01-04 / Ivan / moved from update_last_device_uptime_millis()
    DeviceUptimeClock._lastDeviceUptimeMillis = res;
    if (DeviceUptimeClock._initialDeviceUptimeMillis === -1) {
      DeviceUptimeClock._initialDeviceUptimeMillis = res;
    }

    return res;
  }

  private static _updateLastDeviceUptimeMillis(): void {
    // very basic re-entry check, not sure if it is really needed
    if (DeviceUptimeClock._isInsideUpdate) {
      return;
    }

    DeviceUptimeClock._isInsideUpdate = true;
    DeviceUptimeClock.nativeDeviceUptimeMillis();
    DeviceUptimeClock._isInsideUpdate = false;
  }
}
