import React, { createRef } from "react";
import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalDialogAction,
  AppUIShowModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";
import ModalOverlayBase from "components/atoms/mobile/Modals/ModalOverlayBase";
import ConfirmationModal from "components/molecules/mobile/Modals/ConfirmationModal";
import AppUIModesManager from "utils/AppUIModesManager";
import AppConfig from "utils/AppConfig";
import LegacyLocationManager from "utils/LocationManagers/Legacy";

interface IState {
  stateName: string;
  modalTitlePrefix: string;
}

export const restrictedModalRef = React.createRef<RestrictedStateModal>();

const show = (
  stateName: string,
  onVerifyData: (() => Promise<void>) | null,
  shouldSkipVerifyOnDismiss: boolean,
): void =>
  restrictedModalRef.current?.handleShowModal(
    stateName,
    onVerifyData,
    shouldSkipVerifyOnDismiss,
  );

export const RestrictedModalService = { show, restrictedModalRef } as const;

class RestrictedStateModal extends React.PureComponent<unknown, IState> {
  public state = { stateName: "", modalTitlePrefix: "" };

  private readonly _modalRef = createRef<ModalOverlayBase>();
  private _onVerifyData: (() => Promise<void>) | null = null;
  private _shouldSkipVerifyOnDismiss = false;

  public handleVerifyPhysicalLocation = async (): Promise<void> => {
    try {
      this.handleHideModal();
      AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
        AppConfig.modalProgressBarDefaultMode,
      );
      await LegacyLocationManager.verifyLocationByPhysicalLocation();
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
      if (AppUIModesManager.isDefaultMode) {
        if (this._onVerifyData) {
          await this._onVerifyData();
        }
      }
      if (
        AppUIModesManager.isExcludedMode ||
        AppUIModesManager.isPermanentMode ||
        AppUIModesManager.isTemporaryMode
      ) {
        AppUIShowModalDialogAction.dispatchShowRestrictionInfoDialog(
          this._shouldSkipVerifyOnDismiss
            ? undefined
            : this._onVerifyData ?? undefined,
        );
      }
      if (
        AppUIModesManager.isDisabledMode &&
        !window.location.href.includes("/restricted")
      ) {
        window.location.href = "/restricted";
      }
    } catch (error) {
      AppUIShowModalDialogAction.dispatchShowErrorDialogForException(
        error,
        "RestrictedStateModal.dispatchShowErrorDialogForException",
      );
    } finally {
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
      this._onVerifyData = null;
    }
  };

  public handleCancelPress = async (): Promise<void> => {
    if (
      AppUIModesManager.isDisabledMode &&
      !window.location.href.includes("/restricted")
    ) {
      window.location.href = "/restricted";
    }
    if (
      AppUIModesManager.isExcludedMode ||
      AppUIModesManager.isPermanentMode ||
      AppUIModesManager.isTemporaryMode
    ) {
      this.handleHideModal();
      if (this._onVerifyData && !this._shouldSkipVerifyOnDismiss) {
        await this._onVerifyData();
      }
      this._onVerifyData = null;
      this._shouldSkipVerifyOnDismiss = false;
    }
  };

  public handleShowModal = (
    stateName: string,
    onVerifyData: (() => Promise<void>) | null,
    shouldSkipVerifyOnCancel: boolean,
  ): void =>
    this.setState(
      { stateName, modalTitlePrefix: AppUIModesManager.restrictionReason! },
      () => {
        this._onVerifyData = onVerifyData;
        this._shouldSkipVerifyOnDismiss = shouldSkipVerifyOnCancel;
        this._modalRef.current?.show();
      },
    );

  public handleHideModal = (): void =>
    this.setState({ stateName: "", modalTitlePrefix: "" }, () => {
      this._modalRef.current?.hide();
    });

  render() {
    return (
      <ConfirmationModal
        disableDismiss
        title={`It appears you are located in the state of ${this.state.stateName}.\n${this.state.modalTitlePrefix}\n\nIf your access has been restricted in error, please enable Location Services on the next screen, so that the app can capture your accurate location.`}
        ref={this._modalRef}
        onCancel={this.handleCancelPress}
        onSubmit={this.handleVerifyPhysicalLocation}
        buttonsType="geolocation"
      />
    );
  }
}

export default RestrictedStateModal;
