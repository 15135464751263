import { TransactionType } from "server/core/data/constants";

class Constants {
  private readonly _cancelableTransactionTypes = [
    TransactionType.CONST_4017_V5_WITHDRAW_LOCK_AMOUNT_BANK_WIRE,
    TransactionType.CONST_4018_V5_WITHDRAW_LOCK_AMOUNT_CASH_APP,
    TransactionType.CONST_4019_V5_WITHDRAW_LOCK_AMOUNT_SKRILL,
    TransactionType.CONST_4020_V5_WITHDRAW_LOCK_AMOUNT_BITCOIN,
    TransactionType.CONST_4023_V6_WITHDRAW_LOCK_AMOUNT_PAYMENT_TOKEN,
    TransactionType.CONST_4024_V6_WITHDRAW_LOCK_AMOUNT_MAZOOMA_DIRECT,
    TransactionType.CONST_4161_V6_FISERV_WITHDRAW_LOCK_AMOUNT_PAYMENT_TOKEN,
  ];
  private readonly _processedTransactionTypes = [
    TransactionType.CONST_4131_ADMIN_OPERATION_WITHDRAWAL_REQUEST_APPROVED,
    TransactionType.CONST_4141_MAZOOMA_TRANSFER_CONFIRMED_SUCCESS,
    TransactionType.CONST_4132_ADMIN_OPERATION_WITHDRAWAL_REQUEST_DECLINED,
    TransactionType.CONST_4022_WITHDRAW_CANCELLED_BY_USER,
    TransactionType.CONST_4142_MAZOOMA_TRANSFER_FAILED,
    TransactionType.CONST_4171_FISERV_TRANSFER_CONFIRMED_SUCCESS,
    TransactionType.CONST_4172_FISERV_TRANSFER_FAILED,
  ];

  public get cancelableTransactionTypes() {
    return this._cancelableTransactionTypes;
  }

  public get processedTransactionTypes() {
    return this._processedTransactionTypes;
  }
}

export default new Constants();
