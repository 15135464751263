import {
  IFliffProtocolResponse,
  IFliffResponse,
} from "server/sharedCore/data/objects";
import { IInternalProtocolResponseProcessor } from "server/sharedCore/interfaces";
import { IFSMProtocolResponseSlots } from "server/social/data/objects";

export class InternalProtocolResponseProcessorImpl
  implements
    IInternalProtocolResponseProcessor<
      IFSMProtocolResponseSlots,
      IFliffResponse
    > {
  public async processProtocolResponseSlots<Response extends IFliffResponse>(
    _protocolResponse: IFliffProtocolResponse<
      IFSMProtocolResponseSlots,
      Response
    >,
  ): Promise<void> {
    //    const x_slots: FSM__Protocol__Response__Slots|null = protocol_response.x_slots;
    // TODO - if not null, broadcast redux action with FSM__Protocol__Response__Slots object in order to update global redux state
  }
}
