import React from "react";

import "./styles.scss";
import { IProps } from "./types";

import FliffLogo from "assets/img/logo-fliff.png";
import classNames from "classnames";

const FliffLogoDesc = ({ description, className }: IProps) => {
  const classes = classNames("logo", className);
  return (
    <div className={classes}>
      <img className="logo__img" src={FliffLogo} alt={"fliff-logo"} />
      <span className="logo__description">
        {description ||
          "Make sports picks, win cash\nand earn rewards while you play!"}
      </span>
    </div>
  );
};

export default React.memo(FliffLogoDesc);
