import * as I from "server/herald/data/objects";
import { FWebSocketStats } from "server/herald/types";

import { DevConstants } from "src/DevConstants";
import { DeviceUptimeClock } from "utils/DeviceUptimeClock";
import { PersistentStorage } from "utils/PersistentStorage";
import { ServerClock } from "utils/ServerClock";
import { AppUtils } from "utils/AppUtils";
import HeraldHealthMonitor from "server/herald/HeraldHealthMonitor";
import { IFeedKnownSubfeedRevision } from "server/sharedCore/data/objects";
import DeviceInfo from "utils/DeviceInfo";

export class MessageFactory {
  private static _seqCounter = 0;
  private static _lastSavedAppToken = "";

  private static get _appToken(): string {
    if (MessageFactory._lastSavedAppToken === "") {
      MessageFactory._lastSavedAppToken = AppUtils.randomString(8);
    }
    return MessageFactory._lastSavedAppToken;
  }

  public static get _requestHeader(): I.IHeraldClientRequestHeader {
    return {
      device_x_id: `${DeviceInfo.OS}.${PersistentStorage.cachedDeviceId}`,
      app_x_version: DevConstants.appVersionPlusCapability,
      app_install_token: PersistentStorage.cachedInstallToken,
      app_start_token: MessageFactory._appToken,
      ip_address: HeraldHealthMonitor.lastIpAddress,
      auth_token: HeraldHealthMonitor.lastAuthToken,
      // local time, recalled server time, app uptime
      device_local_stamp_millis: new Date().getTime(),
      device_server_stamp_millis: ServerClock.serverStampMillis(true),
      app_uptime_millis: DeviceUptimeClock.relativeUptimeMillis,
      seq_no: MessageFactory._seqCounter++,
      platform: DevConstants.isDevMode ? "dev" : "prod",
    };
  }

  public static toggleSubscriptionRequest(
    topic: string,
  ): I.IHeraldClientRequest {
    return {
      header: MessageFactory._requestHeader,
      commands: [MessageFactory._toggleSubscriptionCommand(topic)],
    };
  }

  public static declareSubscriptionRequest(
    topic: string,
    stats: FWebSocketStats,
    activeSubscriptionsCount: number,
  ): I.IHeraldClientRequest {
    return {
      header: MessageFactory._requestHeader,
      commands: [
        MessageFactory._declareSubscriptionCommand(
          topic,
          stats,
          activeSubscriptionsCount,
        ),
      ],
    };
  }

  public static clientHeartbeatRequest(
    stats: FWebSocketStats,
    activeSubscriptionsCount: number,
  ): I.IHeraldClientRequest {
    return {
      header: MessageFactory._requestHeader,
      commands: [
        MessageFactory._clientHeartbeatCommand(stats, activeSubscriptionsCount),
      ],
    };
  }

  public static subscribeRequest(topic: string): I.IHeraldClientRequest {
    return {
      header: MessageFactory._requestHeader,
      commands: [MessageFactory._subscribeCommand(topic)],
    };
  }

  public static unsubscribeRequest(topic: string): I.IHeraldClientRequest {
    return {
      header: MessageFactory._requestHeader,
      commands: [MessageFactory._unsubscribeCommand(topic)],
    };
  }

  public static declareConnectRequest(
    stats: FWebSocketStats,
    activeSubscriptionsCount: number,
    packedSubfeedRevisions: IFeedKnownSubfeedRevision[],
  ): I.IHeraldClientRequest {
    return {
      header: MessageFactory._requestHeader,
      commands: [
        MessageFactory._declareConnectCommand(
          stats,
          activeSubscriptionsCount,
          packedSubfeedRevisions,
        ),
      ],
    };
  }

  private static _getConnectionStats(
    stats: FWebSocketStats,
    activeSubscriptionsCount: number,
  ): I.IHeraldConnectivityStats {
    return { ...stats, activeSubscriptionsCount };
  }

  private static _subscribeCommand(topic: string): I.IHeraldCommandSubscribe {
    return { name: "HCommand___Subscribe", topic };
  }

  private static _unsubscribeCommand(
    topic: string,
  ): I.IHeraldCommandUnsubscribe {
    return { name: "HCommand___Unsubscribe", topic };
  }

  private static _declareConnectCommand(
    stats: FWebSocketStats,
    activeSubscriptionsCount: number,
    packedSubfeedRevisions: IFeedKnownSubfeedRevision[],
  ): I.IHeraldCommandDeclareConnect {
    return {
      name: "HCommand___DeclareConnect",
      conn_stats: MessageFactory._getConnectionStats(
        stats,
        activeSubscriptionsCount,
      ),
      packed_subfeed_revisions: packedSubfeedRevisions,
    };
  }

  private static _declareSubscriptionCommand(
    topic: string,
    stats: FWebSocketStats,
    activeSubscriptionsCount: number,
  ): I.IHeraldCommandDeclareSubscription {
    return {
      name: "HCommand___DeclareSubscription",
      topic,
      conn_stats: MessageFactory._getConnectionStats(
        stats,
        activeSubscriptionsCount,
      ),
    };
  }

  private static _clientHeartbeatCommand(
    stats: FWebSocketStats,
    activeSubscriptionsCount: number,
  ): I.IHeraldCommandClientHeartbeat {
    return {
      name: "HCommand___ClientHeartbeat",
      conn_stats: MessageFactory._getConnectionStats(
        stats,
        activeSubscriptionsCount,
      ),
    };
  }

  private static _toggleSubscriptionCommand(
    topic: string,
  ): I.IHeraldCommandToggleSubscription {
    return { name: "HCommand___ToggleSubscription", topic };
  }
}
