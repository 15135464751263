import { animated, config, useSpring, useTransition } from "@react-spring/web";
import Modal from "components/molecules_new/desktop/Modal";
import { IModalHandle } from "components/molecules_new/desktop/Modal/types";
import { ToastMessageModalService } from "context/desktop/ToastService";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { TGlobalAppState } from "reduxLocal/rootReducer";
import CompleteProfile from "./modals/CompleteProfile";
import EnterSMSCode from "./modals/EnterSMSCode";
import ForgotPassword from "./modals/ForgotPassword";
import SignInModal from "./modals/SignIn";
import SignInWithEmail from "./modals/SignInWithEmail";
import SignUpModal from "./modals/SignUp";
import SignUpWithEmail from "./modals/SignUpWithEmail";
import VerifyPhoneNumber from "./modals/VerifyPhoneNumber";
import { IAuthModalHandle, ICustomModalProps, TModalType } from "./types";
import { AppUIHideModalDialogAction } from "reduxLocal/appUI/appUI.actions";

const AuthenticationModal = forwardRef<IAuthModalHandle>((_, ref) => {
  const modalRef = useRef<IModalHandle>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [modalData, setModalData] = useState<
    { type: TModalType; props: ICustomModalProps }[]
  >([]);
  const coreAppUI = useSelector((state: TGlobalAppState) => state.coreAppUI);
  const [size, setSize] = useSpring(() => ({
    width: 0,
    height: 0,
    config: { tension: 450, friction: 40 },
  }));

  useImperativeHandle(
    ref,
    () => ({
      openAuthModal(type: TModalType, props?: ICustomModalProps) {
        setModalData([{ type, props: props || {} }]);
        modalRef.current?.show();
      },
    }),
    [],
  );

  useEffect(() => {
    if (modalData.length !== 0 && coreAppUI.isModalDialogVisible) {
      AppUIHideModalDialogAction.dispatchHideModalDialog();

      ToastMessageModalService.showToast(
        "error",
        coreAppUI.modalDialogTitle,
        coreAppUI.modalDialogMessage,
      );
    }
  }, [modalData.length, coreAppUI]);

  const handleCloseModal = () => {
    modalRef.current?.close();
  };

  const openNext = (type: TModalType, props: ICustomModalProps = {}) => {
    setModalData(prev => [...prev, { type, props }]);
  };

  const openPrevious = () => {
    if (modalData.length <= 1) {
      handleCloseModal();
      return;
    }
    setModalData(prev => prev.slice(0, -1));
  };

  const currentModal = useMemo(() => {
    return modalData[modalData.length - 1] || { type: null, props: {} };
  }, [modalData]);

  const transitions = useTransition(currentModal.type, {
    from:
      modalData.length > 1
        ? {
            opacity: 0.3,
          }
        : {},
    enter: {
      opacity: 1,
    },
    config: config.stiff,
  });

  const ModalContent = useMemo(() => {
    switch (currentModal.type) {
      case "signUp":
        return SignUpModal;
      case "signIn":
        return SignInModal;
      case "signInEmail":
        return SignInWithEmail;
      case "forgotPassword":
        return ForgotPassword;
      case "signInPhone":
        return VerifyPhoneNumber;
      case "signUpEmail":
        return SignUpWithEmail;
      case "verifyCode":
        return EnterSMSCode;
      case "completeProfile":
        return CompleteProfile;
      default:
        return SignInModal;
    }
  }, [currentModal.type]);

  useEffect(() => {
    if (contentRef.current) {
      const { offsetWidth, offsetHeight } = contentRef.current;
      setSize.start({ width: offsetWidth, height: offsetHeight });
    }
  }, [currentModal, setSize]);

  return (
    <Modal
      ref={modalRef}
      onClose={handleCloseModal}
      preventClose={currentModal.props.preventClose}>
      {transitions((style, item) =>
        item ? (
          <animated.div style={{ ...style, ...size }}>
            <ModalContent
              {...currentModal.props}
              onClose={handleCloseModal}
              openNext={openNext}
              openPrevious={openPrevious}
              ref={contentRef}
            />
          </animated.div>
        ) : null,
      )}
    </Modal>
  );
});

AuthenticationModal.displayName = "AuthenticationModal";

export default React.memo(AuthenticationModal);
