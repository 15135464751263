import { forwardRef } from "react";
import { IProps } from "./types";
import "./styles.scss";

import ModalOverlayBase, {
  TModalOverlayBase,
} from "components/atoms/mobile/Modals/ModalOverlayBase";
import Title from "components/molecules/mobile/Modals/ConfirmationModal/atoms/Title";

const types = {
  regular: { confirm: "Yes", decline: "No" },
  confirm: { confirm: "Confirm", decline: "Back" },
  geolocation: { confirm: "Get Location", decline: "OK" },
  deleteRequest: { confirm: "Delete", decline: "Cancel" },
  deleteConfirmation: { confirm: "I do, delete my account", decline: "Cancel" },
} as const;

const ConfirmationModal = forwardRef<TModalOverlayBase, IProps>(
  ({ onSubmit, title, onCancel, disableDismiss, buttonsType }, ref) => {
    return (
      <ModalOverlayBase ref={ref} disableDismiss={disableDismiss}>
        <div className="confirmation-modal">
          <Title title={title} />
          <div className="confirmation-modal__buttons">
            <button
              onClick={onSubmit}
              className="confirmation-modal__button confirmation-modal__button--primary">
              {types[buttonsType].confirm}
            </button>
            <button
              onClick={onCancel}
              className="confirmation-modal__button confirmation-modal__button--secondary">
              {types[buttonsType].decline}
            </button>
          </div>
        </div>
      </ModalOverlayBase>
    );
  },
);

ConfirmationModal.displayName = "ConfirmationModal";
export default ConfirmationModal;
