import { DevConstants } from "src/DevConstants";
import { Hack_DecoderSerializedException } from "server/legacyCore/api/x_objects_protocol";
import { TAnyAlias } from "src/types";

// common parent for all our exceptions
export class FliffException extends Error {
  public static ERROR_1001__COMMON_NETWORK_ERROR = 1001;
  public static ERROR_1009__DUMMY_NETWORK_ERROR = 1009;

  // 2019-12-03 / Ivan / random code used by 'ui blocked' exceptions
  public static ERROR_1111__UI_IS_BLOCKED = 1111;

  // 2019-12-22 / Ivan / 100 error codes are reserved for session expired / throw user out of app
  public static ERROR_1900__SESSION_EXPIRED__BASE_ERROR_CODE = 1900;
  public static ERROR_1901__SESSION_EXPIRED = 1901;
  public static ERROR_1902__SESSION_EXPIRED__INVALID_REFRESH_TOKEN = 1902;
  public static ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED = 1903;
  public static ERROR_1904__SESSION_EXPIRED__APP_VERSION_TOO_OLD = 1904;
  public static ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER = 1905;
  public static ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE = 1906;

  public static ERROR_2001__LOGIN_ERROR__BAD_CREDENTIALS = 2001;

  // 2019-11-29 / Ivan / 100 error codes are reserved for facebook SDK errors
  public static ERROR_2300__SDK_FACEBOOK__BASE_ERROR_CODE = 2300;
  public static ERROR_2301__SDK_FACEBOOK__SDK_ERROR_LOGINWITHPERMISSIONS = 2301;
  public static ERROR_2302__SDK_FACEBOOK__SDK_ERROR_GETCURRENTACCESSTOKEN = 2302;
  public static ERROR_2321__SDK_FACEBOOK__LOGIN_CANCELED = 2321;
  public static ERROR_2322__SDK_FACEBOOK__NO_TOKEN = 2323;
  public static ERROR_2323__SDK_FACEBOOK__BAD_TOKEN = 2323;
  public static ERROR_2399__SDK_FACEBOOK__UNKNOWN_ERROR = 2399;

  // 2019-11-29 / Ivan / 100 error codes are reserved for twitter SDK errors
  public static ERROR_2400__SDK_TWITTER__BASE_ERROR_CODE = 2400;
  public static ERROR_2401__SDK_TWITTER__SDK_ERROR_RNTWITTERSIGNIN_INIT = 2401;
  public static ERROR_2402__SDK_TWITTER__SDK_ERROR_RNTWITTERSIGNIN_LOGIN = 2402;
  public static ERROR_2421__SDK_TWITTER__NO_AUTH_TOKEN = 2421;
  public static ERROR_2422__SDK_TWITTER__NO_AUTH_TOKEN_SECRET = 2422;
  public static ERROR_2499__SDK_TWITTER__UNKNOWN_ERROR = 2499;

  public static ERROR_2500__SDK_GOOGLE__BASE_ERROR_CODE = 2500;
  public static ERROR_2501__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_CONFIGURE = 2501;
  public static ERROR_2502__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_HASPLAYSERVICES = 2502;
  public static ERROR_2503__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_SIGN_OTHER = 2503;
  public static ERROR_2504__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_SIGN_IN_UNKNOWN = 2504;
  public static ERROR_2521__SDK_GOOGLE__NO_ACCESS_TOKEN = 2521;
  public static ERROR_2531__SDK_GOOGLE__SIGN_IN_CANCELLED = 2531;
  public static ERROR_2532__SDK_GOOGLE__IN_PROGRESS = 2532;
  public static ERROR_2533__SDK_GOOGLE__PLAY_SERVICES_NOT_AVAILABLE = 2533;
  public static ERROR_2599__SDK_GOOGLE__UNKNOWN_ERROR = 2599;

  public static ERROR_2600__SDK_APPLEID__BASE_ERROR_CODE = 2600;
  public static ERROR_2601__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_OTHER = 2601;
  public static ERROR_2602__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_UNKNOWN = 2602;

  public static ERROR_2621__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_CANCELED = 2621;
  public static ERROR_2622__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_CREDENTIAL = 2622;
  public static ERROR_2623__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_INVALID_SCOPE = 2623;
  public static ERROR_2624__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_REQUEST_FAILED = 2624;
  public static ERROR_2625__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_UNAVAILABLE = 2625;

  public static ERROR_2699__SDK_APPLEID__UNKNOWN_ERROR = 2699;

  // 2020-05-22 / Ivan / 100 error codes are reserved for in app purchases errors
  public static ERROR_3000__IAP__BASE_ERROR_CODE = 3000;
  public static ERROR_3001__IAP__initConnection = 3001;
  public static ERROR_3002__IAP__purchaseListeners = 3002;
  public static ERROR_3003__IAP__getAvailablePurchases = 3003;
  public static ERROR_3004__IAP__getProducts = 3004;
  public static ERROR_3005__IAP__requestPurchase = 3005;
  //  public static ERROR_3009__IAP__finishConsumableTransaction = 3009;
  public static ERROR_3030__IAP__E_USER_CANCELLED = 3030;
  public static ERROR_3031__IAP__E_ALREADY_OWNED = 3031;

  public static ERROR_6001__COMMON_VALIDATION_ERROR = 6001;

  // unexpected errors
  public static ERROR_9000__UNEXPECTED_ERROR__BASE_ERROR_CODE = 9000;
  public static ERROR_9001__UNEXPECTED_ERROR__UNKNOWN_AXIOS_ERROR_WITH_RESPONSE = 9001;
  public static ERROR_9002__UNEXPECTED_ERROR__UNKNOWN_AXIOS_ERROR_WITH_REQUEST = 9002;
  public static ERROR_9003__UNEXPECTED_ERROR__UNKNOWN_AXIOS_ERROR_NO_REQUEST_AND_RESPONSE = 9003;
  public static ERROR_9021__CANNOT_OBTAIN_NEW_VALID_TOKENS = 9021;
  public static ERROR_9022__INVALID_TOKENS_IN_ENSURE_TOKENS = 9022;
  public static ERROR_9031__UNEXPECTED_INCOMPLETE_PROFILE = 9031;
  public static ERROR_9998__VALIDATE_IP_ADDRESS_FAILURE = 9998;
  public static ERROR_9997__PERMISSION_PHYSICAL_LOCATION_FAILURE = 9997;
  public static ERROR_9996__COORDINATES_PHYSICAL_LOCATION_FAILURE = 9996;
  public static ERROR_9995__GOOGLE_API_GEOCODE_PHYSICAL_LOCATION_FAILURE = 9995;
  public static ERROR_9999__INTERNAL_ERROR = 9999;

  public static ERROR_10000__HTTP_RESPONSE_ERROR__BASE_ERROR_CODE = 10000;

  // 100 error codes reserved for own API / account login
  public static ERROR_11000__ACCOUNT_LOGIN__BASE_ERROR_CODE = 11000;
  public static ERROR_11001__ACCOUNT_LOGIN__BAD_CONFIG_INVALID_CLIENT_ID_OR_SECRET = 11001;
  public static ERROR_11090__ACCOUNT_LOGIN__BAD_CREDENTIALS = 11090;

  // 100 error codes reserved for own API / social login
  public static ERROR_12000__SOCIAL_LOGIN__BASE_ERROR_CODE = 12000;
  public static ERROR_12001__SOCIAL_LOGIN__BAD_CONFIG_INVALID_CLIENT_ID_OR_SECRET = 12001;
  public static ERROR_12002__SOCIAL_LOGIN__BAD_CONFIG_ALT_INVALID_CLIENT_ID_OR_SECRET = 12002;
  public static ERROR_12003__SOCIAL_LOGIN__BAD_CONFIG_INVALID_REQUEST_BAD_BACKEND_PARAMETER = 12003;
  public static ERROR_12004__SOCIAL_LOGIN__BAD_CONFIG_INVALID_REQUEST_BAD_ACCESS_TOKEN = 12004;
  public static ERROR_12019__SOCIAL_LOGIN__BAD_CONFIG_INVALID_REQUEST_OTHER = 12019;
  public static ERROR_12020__SOCIAL_LOGIN__ACCESS_DENIED = 12020;

  // 100 error codes reserved for own API / refresh token login
  public static ERROR_13000__REFRESH_TOKEN__BASE_ERROR_CODE = 13000;
  public static ERROR_13001__REFRESH_TOKEN__BAD_CONFIG_INVALID_CLIENT_ID_OR_SECRET = 13001;
  public static ERROR_13091__REFRESH_TOKEN__INVALID_OR_EXPIRED_ACCESS_TOKEN = 13091;
  public static ERROR_13092__REFRESH_TOKEN__INVALID_OR_EXPIRED_REFRESH_TOKEN = 13092;

  public static ERROR_20000__SERVER_OPERATION__BASE_ERROR_CODE = 20000;
  public static ERROR_30000__SERVER_OPERATION__BASE_ERROR_CODE = 30000;
  public static ERROR_30060__ACCOUNT_LOGIN__CLAIM_VERIFICATION_CODE_SUCCESS = 30060;
  public static ERROR_30921__ACCOUNT_PHONE_LOGIN_SMS_SENT_ERROR_CODE = 30921;
  public static ERROR_30079__ACCOUNT_EMAIL_SIGN_IN_REQUIRE_2FA = 30079;

  //  public static ERROR_600__PLACE_PICK__BASE_ERROR_CODE = 600;
  //  public static ERROR__601__PLACE_PICK__MARKET_OFFER__UNKNOWN_ID = 601;
  //  public static ERROR__602__PLACE_PICK__MARKET_OFFER__NOT_ACTIVE = 602;
  //  public static ERROR__603__PLACE_PICK__MARKET_OFFER__DIFF_OUTCOME_PARAM_1 = 603;
  //  public static ERROR__604__PLACE_PICK__MARKET_OFFER__EVENT_NOT_ACTIVE = 604;
  //  public static ERROR__605__PLACE_PICK__MARKET_OFFER__BAD_PARLAY_COMBINATION = 605;
  //  public static ERROR__611__PLACE_PICK__MARKET_OFFER_SELECTION__UNKNOWN_ID = 611;
  //  public static ERROR__612__PLACE_PICK__MARKET_OFFER_SELECTION__DIFF_COEFF = 612;
  //  public static ERROR__621__PLACE_PICK__BALANCE__NOT_ENOUGH_GOLD_COINS = 621;
  //  public static ERROR__622__PLACE_PICK__BALANCE__NOT_ENOUGH_FLIFF_CASH = 622;
  //  public static ERROR__681__PLACE_PICK__PARAMS__BAD_CURRENCY_CODE = 681;
  //  public static ERROR__682__PLACE_PICK__PARAMS__BAD_COUNT_OF_SELECTIONS_FOR_PARLAY_MODE = 682;
  //  public static ERROR__683__PLACE_PICK__PARAMS__BAD_COUNT_OF_SELECTIONS_FOR_STRAIGHT_MODE = 683;
  //  public static ERROR__684__PLACE_PICK__PARAMS__BAD_TYPE = 684;
  //  public static ERROR__685__PLACE_PICK__PARAMS__RISK_AMOUNT_FLIFF_CASH_BELOW_MIN = 685;
  //  public static ERROR__686__PLACE_PICK__PARAMS__RISK_AMOUNT_FLIFF_CASH_ABOVE_MAX = 686;
  //  public static ERROR__687__PLACE_PICK__PARAMS__RISK_AMOUNT_GOLD_COINS_BELOW_MIN = 687;
  //  public static ERROR__688__PLACE_PICK__PARAMS__RISK_AMOUNT_GOLD_COINS_ABOVE_MAX = 688;
  //  public static ERROR__689__PLACE_PICK__PARAMS__BAD_COEFF = 689;
  //  public static ERROR__690__PLACE_PICK__PARAMS__DIFF_EXPECTED_PAYOUT_AMOUNT = 690;

  //  public static ERROR_22005__SERVER_USER_PROFILE_NOT_COMPLETED = 22005;

  public error_code: number;
  public error_message: string;
  public debug_error_source: string | null;

  constructor(
    aerror_code: number,
    aerror_message: string,
    adebug_error_source: string | null = null,
  ) {
    // 2021-11-29 / Ivan / extreme hack - in some situations (like oauth2 calls)
    // we cannot serve back standard FliffError
    // as a workaround, we are going to serialize / deserialize the error object inside the text message
    const deserializedError =
      Hack_DecoderSerializedException.decode_serialized_operation_error(
        aerror_message,
      );
    if (deserializedError) {
      super(deserializedError.error_message);
      this.error_code = deserializedError.error_code;
      this.error_message = deserializedError.error_message;
      this.debug_error_source = deserializedError.debug_error_source;
    } else {
      super(aerror_message);
      this.error_code = aerror_code;
      this.error_message = aerror_message;
      this.debug_error_source = adebug_error_source;
    }
  }

  /*
  returns title + text for error dialog
  */
  public static getVisualErrorTextsForException(
    error: TAnyAlias,
    exDebugInfo: string = "",
  ): [string, string] {
    let debugSuffix = "";
    if (exDebugInfo) {
      debugSuffix = " [" + exDebugInfo + "]";
    }

    if (error.debug_error_source) {
      debugSuffix = error.debug_error_source + debugSuffix;
    }

    if (debugSuffix) {
      debugSuffix = "\n\n(error source: " + debugSuffix + ")";
    }

    if (!DevConstants.enableDebugSuffixInErrorDialogs) {
      debugSuffix = "";
    } // "\n";

    // 2019-11-17 / Ivan / for the time being, we expect FliffException
    if (!(error instanceof FliffException)) {
      return [
        "Internal error ",
        "Please, wait a couple of seconds and try again. If you receive the same error, please contact support." +
          debugSuffix,
      ];
    }

    // 2021-11-29 / Ivan / extreme hack - in some situations (like oauth2 calls)
    // we cannot serve back standard FliffError
    // as a workaround, we are going to serialize / deserialize the error object inside the text message
    const decoded_error =
      Hack_DecoderSerializedException.decode_serialized_operation_error(
        error.error_message,
      );
    if (decoded_error) {
      error = decoded_error;
    }

    if (
      error.error_code >
        FliffException.ERROR_2300__SDK_FACEBOOK__BASE_ERROR_CODE &&
      error.error_code <
        100 + FliffException.ERROR_2300__SDK_FACEBOOK__BASE_ERROR_CODE
    ) {
      return FliffException.getVisualErrorTextsForException_facebook(
        error,
        debugSuffix,
      );
    }

    if (
      error.error_code >
        FliffException.ERROR_2400__SDK_TWITTER__BASE_ERROR_CODE &&
      error.error_code <
        100 + FliffException.ERROR_2400__SDK_TWITTER__BASE_ERROR_CODE
    ) {
      return FliffException.getVisualErrorTextsForException_twitter(
        error,
        debugSuffix,
      );
    }

    if (
      error.error_code >
        FliffException.ERROR_2500__SDK_GOOGLE__BASE_ERROR_CODE &&
      error.error_code <
        100 + FliffException.ERROR_2500__SDK_GOOGLE__BASE_ERROR_CODE
    ) {
      return FliffException.getVisualErrorTextsForException_google(
        error,
        debugSuffix,
      );
    }

    if (
      // Apple JS API doesn't provide error codes to be handled on the client.
      // Only one error can be caught up – "popup_closed_by_user".
      error.message === "popup_closed_by_user" ||
      (error.error_code >
        FliffException.ERROR_2600__SDK_APPLEID__BASE_ERROR_CODE &&
        error.error_code <
          100 + FliffException.ERROR_2600__SDK_APPLEID__BASE_ERROR_CODE)
    ) {
      return FliffException.getVisualErrorTextsForException_appleId(
        error,
        debugSuffix,
      );
    }

    if (
      error.error_code > FliffException.ERROR_3000__IAP__BASE_ERROR_CODE &&
      error.error_code < 100 + FliffException.ERROR_3000__IAP__BASE_ERROR_CODE
    ) {
      return FliffException.getVisualErrorTextsForException_in_app_purchase(
        error,
        debugSuffix,
      );
    }

    // 2019-11-18 / Ivan / for the time being first 100 hundred codes by default are reserved for 'config' errors
    // (some exceptions are handled above)
    if (
      (error.error_code >
        FliffException.ERROR_11000__ACCOUNT_LOGIN__BASE_ERROR_CODE &&
        error.error_code <
          100 + FliffException.ERROR_11000__ACCOUNT_LOGIN__BASE_ERROR_CODE) ||
      (error.error_code >
        FliffException.ERROR_12000__SOCIAL_LOGIN__BASE_ERROR_CODE &&
        error.error_code <
          100 + FliffException.ERROR_12000__SOCIAL_LOGIN__BASE_ERROR_CODE) ||
      (error.error_code >
        FliffException.ERROR_13000__REFRESH_TOKEN__BASE_ERROR_CODE &&
        error.error_code <
          100 + FliffException.ERROR_13000__REFRESH_TOKEN__BASE_ERROR_CODE)
    ) {
      return [
        "System config error [{0}]".format(error.error_code),
        error.error_message +
          "\n\n" +
          "Please, wait a couple of seconds and try again. If you receive the same error, please contact support." +
          debugSuffix,
      ];
    }

    if (
      error.error_code >
        FliffException.ERROR_1900__SESSION_EXPIRED__BASE_ERROR_CODE &&
      error.error_code <
        100 + FliffException.ERROR_1900__SESSION_EXPIRED__BASE_ERROR_CODE
    ) {
      return [
        "Session expired [{0}]".format(error.error_code),
        error.error_message + debugSuffix,
      ];
    }

    if (
      error.error_code >
        FliffException.ERROR_9000__UNEXPECTED_ERROR__BASE_ERROR_CODE &&
      error.error_code <
        100 + FliffException.ERROR_9000__UNEXPECTED_ERROR__BASE_ERROR_CODE
    ) {
      return [
        "Unexpected error [{0}]".format(error.error_code),
        error.error_message + debugSuffix,
      ];
    }

    switch (error.error_code) {
      case FliffException.ERROR_9997__PERMISSION_PHYSICAL_LOCATION_FAILURE:
        return [
          "Unexpected error [{0}]".format(error.error_code),
          error.error_message + debugSuffix,
        ];
      case FliffException.ERROR_9996__COORDINATES_PHYSICAL_LOCATION_FAILURE:
        return [
          "Unexpected error [{0}]".format(error.error_code),
          error.error_message + debugSuffix,
        ];
      case FliffException.ERROR_9995__GOOGLE_API_GEOCODE_PHYSICAL_LOCATION_FAILURE:
        return [
          "Unexpected error [{0}]".format(error.error_code),
          "Please check your network settings and try again." +
            "\n\n" +
            error.error_message +
            debugSuffix,
        ];
      case FliffException.ERROR_1001__COMMON_NETWORK_ERROR:
        return [
          "Communication error",
          "Please check your network settings and try again." +
            "\n\n" +
            error.error_message +
            debugSuffix,
        ];

      case FliffException.ERROR_1009__DUMMY_NETWORK_ERROR:
        return [
          "(dummy) Communication error",
          "(dummy) Please check your network settings and try again." +
            "\n\n" +
            error.error_message +
            debugSuffix,
        ];

      case FliffException.ERROR_2001__LOGIN_ERROR__BAD_CREDENTIALS:
        return [
          "Login error",
          "Please verify your password and try again." + debugSuffix,
        ];

      case FliffException.ERROR_6001__COMMON_VALIDATION_ERROR:
        return [
          "Invalid response received from server",
          "Details: {0}".format(error.error_message) +
            "\n\n" +
            "Please, wait a couple of seconds and try again. If you receive the same error, please contact support." +
            debugSuffix,
        ];
    }

    // error messages via 'old protocol'
    if (
      error.error_code >=
      FliffException.ERROR_20000__SERVER_OPERATION__BASE_ERROR_CODE
    ) {
      return [
        "Response [{0}]".format(error.error_code),
        error.error_message + debugSuffix,
      ];
    }
    // error messages via 'new protocol' (Point Streak)
    if (
      error.error_code >=
      FliffException.ERROR_30000__SERVER_OPERATION__BASE_ERROR_CODE
    ) {
      return [
        "Response [{0}]".format(error.error_code),
        error.error_message + debugSuffix,
      ];
    }

    if (
      error.error_code >=
      FliffException.ERROR_10000__HTTP_RESPONSE_ERROR__BASE_ERROR_CODE
    ) {
      return [
        "Service error [{0}]".format(error.error_code),
        error.error_message + debugSuffix,
      ];
    }

    // default message for unexpected FliffException
    return [
      "Unexpected error [{0}]".format(error.error_code),
      "Please, wait a couple of seconds and try again. If you receive the same error, please contact support." +
        debugSuffix,
    ];
  }

  public static isNetworkError(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return false;
    }

    return (
      error.error_code === FliffException.ERROR_1001__COMMON_NETWORK_ERROR ||
      error.error_code === FliffException.ERROR_1009__DUMMY_NETWORK_ERROR
    );
  }

  public static isTermsOfUseAcceptanceRequired(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return false;
    }

    return (
      error.error_code ===
      FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE
    );
  }

  public static isProfileNotCompletedError(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return false;
    }

    return (
      error.error_code ===
      FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED
    );
  }

  public static isPrimaryPhoneNumberRequired(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return false;
    }

    return (
      error.error_code ===
      FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER
    );
  }

  public static isEmailSignInRequires2FA(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return false;
    }

    return (
      error.error_code ===
      FliffException.ERROR_30079__ACCOUNT_EMAIL_SIGN_IN_REQUIRE_2FA
    );
  }

  public static isLoginClaimVerificationCodeSuccess(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return false;
    }

    return (
      error.error_code ===
      FliffException.ERROR_30060__ACCOUNT_LOGIN__CLAIM_VERIFICATION_CODE_SUCCESS
    );
  }

  public static isLoginClaimVerificationCodeSentError(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return false;
    }

    return (
      error.error_code ===
      FliffException.ERROR_30921__ACCOUNT_PHONE_LOGIN_SMS_SENT_ERROR_CODE
    );
  }

  public static getSecondsFromVerificationCodeError(error: TAnyAlias) {
    if (!(error instanceof FliffException)) {
      return null;
    }

    const matcher = FliffException.isLoginClaimVerificationCodeSuccess(error)
      ? /(?<=expiring in )(.*)(?= seconds)/gm
      : /(?<=wait )(.*)(?= seconds)/gm;

    const secondsFromErrorMessage =
      error.error_message?.match(matcher)?.[0] || "";

    if (+secondsFromErrorMessage) {
      return +secondsFromErrorMessage;
    }

    console.warn(
      "No seconds value provided in error [" + error.error_code + "]",
    );

    return null;
  }

  public static getVisualErrorTextsForException_facebook(
    error: TAnyAlias,
    debugSuffix: string,
  ): [string, string] {
    switch (error.error_code) {
      case FliffException.ERROR_2301__SDK_FACEBOOK__SDK_ERROR_LOGINWITHPERMISSIONS:
        return [
          "Facebook login error [{0}]".format(error.error_code),
          "Error from Facebook while trying to login" + debugSuffix,
        ];
      case FliffException.ERROR_2302__SDK_FACEBOOK__SDK_ERROR_GETCURRENTACCESSTOKEN:
        return [
          "Facebook login error [{0}]".format(error.error_code),
          "Error from Facebook while trying to get access token" + debugSuffix,
        ];
      case FliffException.ERROR_2321__SDK_FACEBOOK__LOGIN_CANCELED:
        return [
          "Facebook login error [{0}]".format(error.error_code),
          "Login canceled" + debugSuffix,
        ];
      case FliffException.ERROR_2322__SDK_FACEBOOK__NO_TOKEN:
        return [
          "Facebook login error [{0}]".format(error.error_code),
          "Bad identity token" + debugSuffix,
        ];
      case FliffException.ERROR_2323__SDK_FACEBOOK__BAD_TOKEN:
        return [
          "Facebook login error [{0}]".format(error.error_code),
          "Bad identity token" + debugSuffix,
        ];
      case FliffException.ERROR_2399__SDK_FACEBOOK__UNKNOWN_ERROR:
      default:
        return [
          "Facebook login error [{0}]".format(error.error_code),
          "Unexpected error while trying to login via Facebook" + debugSuffix,
        ];
    }
  }

  public static getVisualErrorTextsForException_twitter(
    error: TAnyAlias,
    debugSuffix: string,
  ): [string, string] {
    switch (error.error_code) {
      case FliffException.ERROR_2401__SDK_TWITTER__SDK_ERROR_RNTWITTERSIGNIN_INIT:
        return [
          "Twitter login error [{0}]".format(error.error_code),
          "Error from Twitter during init" + debugSuffix,
        ];
      case FliffException.ERROR_2402__SDK_TWITTER__SDK_ERROR_RNTWITTERSIGNIN_LOGIN:
        return [
          "Twitter login error [{0}]".format(error.error_code),
          "Error from Twitter during login" + debugSuffix,
        ];
      case FliffException.ERROR_2421__SDK_TWITTER__NO_AUTH_TOKEN:
        return [
          "Twitter login error [{0}]".format(error.error_code),
          "Bad identity token" + debugSuffix,
        ];
      case FliffException.ERROR_2422__SDK_TWITTER__NO_AUTH_TOKEN_SECRET:
        return [
          "Twitter login error [{0}]".format(error.error_code),
          "Bad identity token" + debugSuffix,
        ];
      case FliffException.ERROR_2499__SDK_TWITTER__UNKNOWN_ERROR:
      default:
        return [
          "Twitter login error [{0}]".format(error.error_code),
          "Unexpected error while trying to login via Twitter" + debugSuffix,
        ];
    }
  }

  public static getVisualErrorTextsForException_google(
    error: TAnyAlias,
    debugSuffix: string,
  ): [string, string] {
    switch (error.error_code) {
      case FliffException.ERROR_2501__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_CONFIGURE:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Error from Google SDK during configure" + debugSuffix,
        ];

      case FliffException.ERROR_2502__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_HASPLAYSERVICES:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Google login requires Google Play Services" + debugSuffix,
        ];

      case FliffException.ERROR_2503__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_SIGN_OTHER:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Unknown error from Google SDK during sign in [{0}]".format(
            error.error_message,
          ) + debugSuffix,
        ];

      case FliffException.ERROR_2504__SDK_GOOGLE__SDK_ERROR_GOOGLESIGNIN_SIGN_IN_UNKNOWN:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Unexpected error from Google SDK during sign in" + debugSuffix,
        ];

      case FliffException.ERROR_2521__SDK_GOOGLE__NO_ACCESS_TOKEN:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Bad identity token" + debugSuffix,
        ];

      case FliffException.ERROR_2531__SDK_GOOGLE__SIGN_IN_CANCELLED:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Login canceled" + debugSuffix,
        ];

      case FliffException.ERROR_2532__SDK_GOOGLE__IN_PROGRESS:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Unexpected error from Google SDK during sign in (in progress)" +
            debugSuffix,
        ];
      case FliffException.ERROR_2533__SDK_GOOGLE__PLAY_SERVICES_NOT_AVAILABLE:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Unexpected error from Google SDK during sign in (play services not available)" +
            debugSuffix,
        ];

      case FliffException.ERROR_2599__SDK_GOOGLE__UNKNOWN_ERROR:
      default:
        return [
          "Google login error [{0}]".format(error.error_code),
          "Unexpected error while trying to login via Google" + debugSuffix,
        ];
    }
  }

  public static getVisualErrorTextsForException_appleId(
    error: TAnyAlias,
    debugSuffix: string,
  ): [string, string] {
    switch (error.error_code) {
      case FliffException.ERROR_2601__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_OTHER:
        return [
          "Apple ID login error [{0}]".format(error.error_code),
          "Unknown error from Apple ID SDK during sign in [{0}]".format(
            error.error_message,
          ) + debugSuffix,
        ];
      case FliffException.ERROR_2602__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_UNKNOWN:
        return [
          "Apple ID login error [{0}]".format(error.error_code),
          "Unexpected error from Apple ID SDK during sign in" + debugSuffix,
        ];
      case FliffException.ERROR_2621__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_CANCELED:
      case FliffException.ERROR_2622__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_CREDENTIAL:
      case FliffException.ERROR_2623__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_INVALID_SCOPE:
      case FliffException.ERROR_2624__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_REQUEST_FAILED:
      case FliffException.ERROR_2625__SDK_APPLEID__SDK_ERROR_APPLEAUTHENTICATION_SIGNINASYNC_APPLE_AUTHENTICATION_UNAVAILABLE:
        // 2019-12-12 / Ivan / we now trust apple for error message text
        return [
          "Apple ID login error [{0}]".format(error.error_code),
          error.error_message + debugSuffix,
        ];

      /*
        case FliffException.ERROR_26xx__SDK_APPLEID__ERROR_ZZZZ:
          return ["Apple ID login error [{0}]".format(error.error_code), tapp.ERROR_MESSAGE__ERROR_26xx__SDK_APPLEID__ERROR_ZZZZ + debugSuffix];
  */
      case FliffException.ERROR_2699__SDK_APPLEID__UNKNOWN_ERROR:
      default:
        return [
          "Apple ID login error [{0}]".format(error.error_code),
          "Unexpected error while trying to login via Apple ID" + debugSuffix,
        ];
    }
  }

  public static getVisualErrorTextsForException_in_app_purchase(
    error: TAnyAlias,
    debugSuffix: string,
  ): [string, string] {
    switch (error.error_code) {
      // return custom title + message for canceled
      case FliffException.ERROR_3030__IAP__E_USER_CANCELLED:
        return [
          "In-App Purchase Canceled".format(error.error_code),
          // Ivan / the party which throws the exception is supposed to supply correct error message ?
          "In-App Purchase has been canceled by user".format(
            error.error_message,
          ) + debugSuffix,
        ];
      case FliffException.ERROR_3003__IAP__getAvailablePurchases:
        return [
          "In-App Purchase Canceled",
          "Unable to fetch in-app purchases. Please, make sure a payment method is configured for in-app purchases.\n\nIf the problem persists, please contact support@getfliff.com",
        ];
      default:
        return [
          "In-App Purchase Error [{0}]".format(error.error_code),
          // Ivan / the party which throws the exception is supposed to supply correct error message ?
          "{0}".format(error.error_message) + debugSuffix,
        ];
    }
  }
}
