import Icon from "components/atoms/common/Icon";
import "./styles.scss";
import { IProps } from "./types";

const CalendarArrow = ({ direction }: IProps) => (
  <div
    className={"calendar-arrow-container"}
    style={
      direction === "right"
        ? { transform: "rotate(180deg)" }
        : { marginLeft: 15 }
    }>
    <Icon name={"angle-left"} />
  </div>
);

export default CalendarArrow;
