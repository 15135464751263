import AppSelectors from "reduxLocal/app/app.selectors";
import { TGlobalAppState } from "reduxLocal/rootReducer";
import { createSelector } from "reselect";
import { getCustomerProfile } from "selectors/sportsBook";
import SocialUtils from "utils/SocialUtils";

class SocialSelectors {
  public get socialAuthenticatedUserBadgeBannerData() {
    return createSelector(
      this._socialUsersAccessor,
      getCustomerProfile,
      AppSelectors.isAuthenticated,
      (users, sbProfile, isAuthenticated) => {
        if (!isAuthenticated) {
          return null;
        }
        const customer = users[SocialUtils.toValidUserFkey(sbProfile.user_id)];
        if (!customer) {
          return null;
        }

        if (!customer.badge_banner_data) {
          return null;
        }

        return customer.badge_banner_data;
      },
    );
  }

  public get combinedAuthenticatedUserProfile() {
    return createSelector(
      this.socialMyProfile,
      getCustomerProfile,
      (socialProfile, sbProfile) => {
        if (!socialProfile) {
          return {
            username: sbProfile.username,
            rpm_bgcolor: "",
            rpm_avatar_half_body: "",
            user_fkey: SocialUtils.toValidUserFkey(sbProfile.user_id),
          };
        }
        return socialProfile;
      },
    );
  }

  public get socialMyProfile() {
    return createSelector(
      this._socialUsersAccessor,
      getCustomerProfile,
      AppSelectors.isAuthenticated,
      (users, sbProfile, isAuthenticated) => {
        if (!isAuthenticated) {
          return null;
        }
        const customer = users[SocialUtils.toValidUserFkey(sbProfile.user_id)];
        if (!customer) {
          return null;
        }

        return customer.profile;
      },
    );
  }

  public makeSelectUser() {
    return createSelector(
      this._socialUsersAccessor,
      (_: TGlobalAppState, userFKey: string) => userFKey,
      (users, userFKey) => users[userFKey],
    );
  }

  private _socialUsersAccessor = (state: TGlobalAppState) => {
    return this._accessor(state).users;
  };

  private _accessor({ social }: TGlobalAppState): TGlobalAppState["social"] {
    return social;
  }
}

export default new SocialSelectors();
