import Tooltip from "../Tooltip";

import GreenCheckmark from "components/atoms/mobile/GreenCheckmark";
import { IProps } from "./types";

const ValidationStateMark = ({ validationState }: IProps) => {
  if (validationState.state === 0) {
    return <Tooltip message={validationState.validationError} />;
  }

  if (validationState.state === 1) {
    return <GreenCheckmark />;
  }

  return null;
};

export default ValidationStateMark;
