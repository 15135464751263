import colors from "assets/styles/design-tokens/colors.module.scss";
import CashIcon from "assets/img/3d_vertical_cash.png";
import CoinsIcon from "assets/img/3d_fliff_coin.png";
import HighStakeIcon from "assets/img/high-stake-coins.png";
import {
  PickSelectionState,
  PickType,
  UIConstants,
} from "server/legacyCore/data/constants";
import PickUtils from "utils/PickUtils";
import { Data__SB_Pick_Selection } from "server/legacyCore/data/objects";
import { StakeCategory } from "server/social/data/constants";

import { ReactComponent as WinIcon } from "assets/img/selectionStates/win.svg";
import { ReactComponent as PendingIcon } from "assets/img/selectionStates/pending.svg";
import { ReactComponent as LossIcon } from "assets/img/selectionStates/loss.svg";
import { ReactComponent as NoActionIcon } from "assets/img/selectionStates/noAction.svg";
import { ReactComponent as PushIcon } from "assets/img/selectionStates/push.svg";
import DateFormatters from "utils/DateFormatters";

const SGP_SELECTION_HEIGHT = 60;
const PARLAY_SELECTION_HEIGHT = 93;
const CARD_SELECTION_HEIGHT = 100;

class UIPickUtils {
  public getPickState = (state: PickSelectionState) => {
    if (state === PickSelectionState.CONST_501_PENDING) {
      return "Active";
    }
    if (state === PickSelectionState.CONST_511_SETTLED_AS_WIN) {
      return "WIN";
    }
    if (state === PickSelectionState.CONST_512_SETTLED_AS_LOSS) {
      return "Loss";
    }
    if (state === PickSelectionState.CONST_513_SETTLED_AS_PUSH) {
      return "Push";
    }
    if (state === PickSelectionState.CONST_514_SETTLED_AS_NO_ACTION) {
      return "No Action";
    }
    return "Unknown";
  };

  public getPickColor = (state: PickSelectionState) => {
    if (state === PickSelectionState.CONST_511_SETTLED_AS_WIN) {
      return colors.tertiaryColor;
    }
    if (state === PickSelectionState.CONST_512_SETTLED_AS_LOSS) {
      return colors.primaryColor;
    }
    return colors.secondaryParagraphColor;
  };

  public getPickBackgroundColor = (
    state: PickSelectionState,
    currency: number,
  ): string => {
    const isPickWon = state === PickSelectionState.CONST_511_SETTLED_AS_WIN;
    const wonPickBackgroundColor = UIConstants.isModeCash(currency)
      ? colors.tertiaryColor
      : colors.goldColor;
    return isPickWon ? wonPickBackgroundColor : colors.whiteColor;
  };

  public getPickCurrencyIcon = (
    currency: number,
    stakeCategory: StakeCategory,
  ): string => {
    const isPickCashCurrency = UIConstants.isModeCash(currency);
    if (isPickCashCurrency) {
      return CashIcon;
    }
    if (stakeCategory === StakeCategory.CONST_2909_HIGH_STAKE) {
      return HighStakeIcon;
    }
    return CoinsIcon;
  };

  public getPickLabelColor = (state: PickSelectionState): string => {
    const isPickWon = state === PickSelectionState.CONST_511_SETTLED_AS_WIN;

    return isPickWon ? colors.purpleColor : colors.secondaryParagraphColor;
  };

  public getPickCoefficient = (
    coefficient: number,
    outcomeParam: string,
  ): string => {
    const hasOutcome = outcomeParam !== "";
    const parsedCoeff = PickUtils.parseCoeff(coefficient);
    if (hasOutcome) {
      return `${outcomeParam} (${parsedCoeff})`;
    }
    return parsedCoeff;
  };

  public getSGPHeaderText = (selections: Data__SB_Pick_Selection[]) => {
    const [selection] = selections;

    return {
      title: selection.t_121_event_info,
      subtitle: DateFormatters.formatDateTime(
        selection.event_start_timestamp_utc,
      ),
    };
  };

  public getParlayHeaderText = (selectionsLength: number) => {
    return {
      title: selectionsLength + " Leg Parlay",
      subtitle: "Multiple selections included",
    };
  };

  public getSelectionHeightByPickType = (pickType: PickType) => {
    if (pickType === PickType.CONST_82_PARLAY) {
      return PARLAY_SELECTION_HEIGHT;
    }
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return SGP_SELECTION_HEIGHT;
    }
    return CARD_SELECTION_HEIGHT;
  };

  public getMultiSelectionStatusIcon = (state: PickSelectionState) => {
    if (state === PickSelectionState.CONST_511_SETTLED_AS_WIN) {
      return WinIcon;
    }
    if (state === PickSelectionState.CONST_501_PENDING) {
      return PendingIcon;
    }
    if (state === PickSelectionState.CONST_512_SETTLED_AS_LOSS) {
      return LossIcon;
    }
    if (state === PickSelectionState.CONST_514_SETTLED_AS_NO_ACTION) {
      return NoActionIcon;
    }
    if (state === PickSelectionState.CONST_513_SETTLED_AS_PUSH) {
      return PushIcon;
    }
    return null;
  };

  public getClassNameForPickType = (pickType: PickType) => {
    if (pickType === PickType.CONST_82_PARLAY) {
      return "parley";
    }
    if (pickType === PickType.CONST_83_SAME_GAME_PARLAY) {
      return "sgp";
    }
    return "straight";
  };
}

export default new UIPickUtils();
