import { IProps } from "./types";
import "./styles.scss";

import CashIcon2 from "assets/img/fliffCashBundles/CashBundle02.png";
import { CashAmountFormatters } from "utils/UIAmountsFormatters";
const FliffCashBox = ({ fliffCashAmount }: IProps) => {
  if (!fliffCashAmount || fliffCashAmount <= 0) {
    return null;
  }
  return (
    <section className="fliff-cash-box">
      <img
        src={CashIcon2}
        alt="Fliff cash icon"
        className="fliff-cash-box__icon"
      />
      <p className="fliff-cash-box__amount">
        {`You also received\n${CashAmountFormatters.toFliffCash(
          fliffCashAmount,
        )} FREE Fliff Cash`}
      </p>
    </section>
  );
};

export default FliffCashBox;
