import AppUIModesManager from "utils/AppUIModesManager";
import {
  IUSAStateRestriction,
  TDeviceLocationVendor,
} from "server/core/data/objects";

class CommonLocationManager {
  private _ipCheckResult = { isPassed: false, regionCode: "", meta: "" };
  private _geocodeCheckResult = { isPassed: false, regionCode: "", meta: "" };
  private _lastSetRegionCode = "";

  private _deviceLocationVendor: TDeviceLocationVendor = "radar";
  private readonly _cachedLocalUSAStatesRestrictions: IUSAStateRestriction[] =
    [];

  public get isRadarLocationVendor() {
    return this._deviceLocationVendor === "radar";
  }

  public get isInternalLocationVendor() {
    return this._deviceLocationVendor === "internal";
  }

  public get lastSetRegionCode() {
    return this._lastSetRegionCode;
  }

  public get ipCheckResult() {
    return this._ipCheckResult;
  }

  public get geocodeCheckResult() {
    return this._geocodeCheckResult;
  }

  public get debugSourceInfo() {
    return `origin=ipApi|regionCode=${this._ipCheckResult.regionCode}|meta=${this._ipCheckResult.meta}|origin=googleApi|regionCode=${this._geocodeCheckResult.regionCode}|meta=${this._geocodeCheckResult.meta}`;
  }

  public setIpCheckResult(ipCheckResult: typeof this._ipCheckResult): void {
    this._ipCheckResult = ipCheckResult;
  }

  public setGeocodeCheckResult(
    geocodeCheckResult: typeof this._geocodeCheckResult,
  ): void {
    this._geocodeCheckResult = geocodeCheckResult;
  }

  public setCachedLocalUSAStatesRestrictions = (
    restrictions: IUSAStateRestriction[],
  ): void => {
    if (this._cachedLocalUSAStatesRestrictions.length !== 0) {
      return;
    }
    this._cachedLocalUSAStatesRestrictions.push(...restrictions);
  };

  public setModeByRegionCode = (regionCode: string): void => {
    this._lastSetRegionCode = regionCode;
    const response = this._cachedLocalUSAStatesRestrictions.find(
      restriction => restriction.usa_state_code === regionCode,
    );
    AppUIModesManager.setLocalAppUIMode(
      response?.restriction_code ?? undefined,
      response?.restriction_message ?? null,
    );
  };

  public setLocationVendor = (locationVendor: TDeviceLocationVendor) => {
    this._deviceLocationVendor = locationVendor;
  };
}

export default new CommonLocationManager();
