export const REMOTE_URLS = {
  sweepstakes: "https://getfliff.com/sweepstakes-rules",
  privacyPolicy: "https://getfliff.com/privacy-policy",
  sportPredictionRules: "https://getfliff.com/sports-prediction-rules",
  termsOfUse: "https://getfliff.com/terms-of-use",
  termsOfUseAgreement:
    "https://www.getfliff.com/terms-of-use#toc-11-binding-arbitration-class-action-waiver-agreement",
  termsOfUseProcedure:
    "https://www.getfliff.com/terms-of-use#toc-11-5-option-and-procedure-to-opt-out-of-arbitration",
  contactUs: "https://www.getfliff.com/contact",
  aboutUs: "https://www.getfliff.com/about",
  twitter: "https://x.com/fliff",
  instagram: "https://www.instagram.com/getfliff/",
  youtube: "https://www.youtube.com/@fliff-social",
  tikTok: "https://www.tiktok.com/@fliff_sports",
  appStore: "https://apps.apple.com/us/app/fliff-sports-picks/id1489145500",
  googlePlayStore:
    "https://play.google.com/store/apps/details?id=com.fliff.fapp",
  zendesk: "https://fliff.zendesk.com/hc/en-us",
};
