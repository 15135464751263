import AppSelectors from "reduxLocal/app/app.selectors";
import { staticGetState } from "reduxLocal/store";
import { PrivateCoreApi, PublicCoreApi } from "server/core/server/CoreApi";
import { HeraldBackend } from "server/herald/HeraldBackend";
import { SportsBookInPlayFeedMonitor } from "server/legacyCore/api/SportsBookInPlayFeedMonitor";
import { SportsBookPrematchFeedMonitor } from "server/legacyCore/api/SportsBookPrematchFeedMonitor";
import { SportsBookUserProfileMonitor } from "server/legacyCore/api/SportsBookUserProfileMonitor";
import AppStateManager from "utils/AppStateManager";
import Logger from "utils/Logger";
import { MemoryStorage } from "utils/MemoryStorage";

export class SportsBookMonitor {
  private static _POLLING_INTERVAL_TIMER = 500;

  public static init() {
    Logger.warnMessage("[SportsBookMonitor] - init");
    AppStateManager.initInterval(
      this._onTick,
      this._POLLING_INTERVAL_TIMER,
      "SportsBookMonitor",
    );
    AppStateManager.runOnAppActivation(async () => {
      await SportsBookMonitor.sportsBookForceUpdate();
    });
  }

  public static async sportsBookForceUpdate() {
    if (!MemoryStorage.enableSportsBookMonitor) {
      return;
    }

    Logger.warnMessage("[SportsBookMonitor] - sportsBookForceUpdate");
    const isAuthenticated = AppSelectors.isAuthenticated(staticGetState());

    // TODO: consider to be ONE request with ONE dispatched action.
    if (isAuthenticated) {
      await Promise.all([
        PublicCoreApi.safeNonBlockingNoErrorModalInitPublicData(),
        PrivateCoreApi.safeBackgroundRefreshUserProfile(),
        PrivateCoreApi.safeBackgroundGetLastTransactions(),
      ]);
      return;
    }
    await PublicCoreApi.safeNonBlockingNoErrorModalInitPublicData();
  }

  private static _onTick() {
    SportsBookUserProfileMonitor.onTick();
    SportsBookPrematchFeedMonitor.onTick();
    SportsBookInPlayFeedMonitor.onTick();
    HeraldBackend.onTick();
  }
}
