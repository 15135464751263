import classNames from "classnames";
import "./styles.scss";
import { IProps } from "./types";

const SocialButton = ({
  imageSource,
  socialName,
  onClick,
  className,
}: IProps) => {
  const classes = classNames("social-button-container", className);
  return (
    <button type={"button"} className={classes} onClick={onClick}>
      <img className={"img"} alt={"social-auth"} src={imageSource} />
      <span className={"title"}>{`Sign In with ${socialName}`}</span>
    </button>
  );
};

export default SocialButton;
