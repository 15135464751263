import {
  IFSMDataUserInfo,
  IFSMGetUserProfileResponse,
  IFSMModifyRelationshipResponse,
} from "server/social/data/objects";
import { TFliffResult } from "server/sharedCore/data/objects";
import { staticDispatch } from "reduxLocal/store";

class SocialSetUserAction {
  public static readonly type = "@type/SOCIAL_SET_USER" as const;
  public static readonly payload: IFSMGetUserProfileResponse;
  public type: typeof SocialSetUserAction.type;
  public payload: typeof SocialSetUserAction.payload;

  public static dispatchGetSocialUser(
    data: IFSMGetUserProfileResponse,
  ): IFSMGetUserProfileResponse {
    staticDispatch(this._action(data));
    return data;
  }

  public static dispatchSetSocialUser(
    result: TFliffResult<IFSMGetUserProfileResponse>,
  ) {
    if (result.response) {
      this.dispatchGetSocialUser(result.response);
    }

    return result;
  }

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

class SocialSetMultipleProfilesAction {
  static readonly type = "@type/SOCIAL_SET_MULTIPLE_PROFILES" as const;
  static readonly payload: IFSMDataUserInfo[];
  public type: typeof SocialSetMultipleProfilesAction.type;
  public payload: typeof SocialSetMultipleProfilesAction.payload;

  public static dispatchSetSocialMultipleProfiles = (
    result: TFliffResult<IFSMModifyRelationshipResponse>,
  ) => {
    if (result.response) {
      staticDispatch(
        this._action([
          result.response.anchor_profile,
          result.response.observer_profile,
        ]),
      );
    }

    return result;
  };

  private static _action(payload: typeof this.payload) {
    return { type: this.type, payload };
  }
}

export { SocialSetUserAction, SocialSetMultipleProfilesAction };
