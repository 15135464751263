export enum SocialUserModifyRelationshipOperationCode {
  CONST_3861_BLOCK_USER = 3861,
  CONST_3862_UNBLOCK_USER = 3862,
  CONST_3863_AUTO_FOLLOW_USER = 3863,
  CONST_3864_UNFOLLOW_USER = 3864,
}

export enum SocialAccessLevel {
  CONST_5070_DEFAULT = 5070,
  CONST_5079_BLOCKED = 5079,
}

export enum SocialPrivacyLevel {
  CONST_6601_LIMITED = 6601,
  CONST_6602_FRIENDS_ONLY = 6602,
  // Was removed. Discuss with Alex Askerka can it be safely removed.
  // (Note: old users potentially could have it as a set privacy level – this will prevent them to successfully load their profile, an error in console will appear)
  CONST_6603_RESTRICTED = 6603,
}

export enum SocialUserRelationStatus {
  CONST_4231_FOLLOWER = 4231,
  CONST_4232_FOLLOWING = 4232,
  CONST_4233_FRIEND = 4233,
  CONST_4239_NONE = 4239,
}

export enum SocialUserType {
  CONST_7130_UNKNOWN = 7130,
  CONST_7131_ORDINARY_USER = 7131,
  CONST_7132_INFLUENCER = 7132,
}

export enum StakeCategory {
  CONST_2901_DEFAULT = 2901,
  CONST_2909_HIGH_STAKE = 2909,
}

export enum PlayerLevelLeaderboardUpgrading {
  SAME = 0,
  PROMOTION = 1,
  RELEGATION = 2,
}
