import React from "react";
import { IProps } from "./types";
import "./styles.scss";

const GoldButton = ({ title, textClassName, onClick, className }: IProps) => (
  <div
    className={`gold-button-container ${className ? className : ""}`}
    onClick={onClick}>
    <span className={`gold-button-title ${textClassName ? textClassName : ""}`}>
      {title}
    </span>
  </div>
);

export default React.memo(GoldButton);
