import { IProps } from "./types";
import "./styles.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getCurrencyMode } from "selectors/sportsBook";
import { UIConstants } from "server/legacyCore/data/constants";

const TextLink = ({ label, onClick, to, className, ...props }: IProps) => {
  const currencyMode = useSelector(getCurrencyMode);
  const isModeCash = UIConstants.isModeCash(currencyMode);

  const classes = classNames("text-link", className, {
    "text-link--cash": isModeCash,
  });
  if (to) {
    return (
      <a
        href={to}
        onClick={onClick}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        className={classes}>
        {label}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={classes}
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}>
      {label}
    </button>
  );
};

export default TextLink;
