import AsyncLock from "async-lock";
import { Buffer } from "buffer";
import { ApiExecutorTokensHelper } from "server/sharedCore/ApiExecutorTokensHelper";
import { AppTokens } from "server/sharedCore/AppTokens";
import { AppProfileUtils } from "utils/AppProfileUtils";
import { AuthTokensResponse } from "server/sharedCore/data/serverAdapters";
import { FliffException } from "server/legacyCore/FliffException";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { TAnyAlias } from "src/types";

class AppTokensManager {
  public helper = new ApiExecutorTokensHelper();
  protected lastUsedAccessToken = "";
  protected lastUsedAccessTokenCounter = 0;
  private _lock = new AsyncLock();

  public get hasSavedCredentials(): boolean {
    return this.helper.hasSavedCredentials;
  }

  public get apiExecutorTokensHelper(): ApiExecutorTokensHelper {
    return this.helper;
  }

  public damageAccessToken(): void {
    this.helper.damageAccessToken();
  }

  // 2019-12-23 / Ivan / called on startup, returns true if we have saved credentials
  public initOnStartup(): boolean {
    return this.helper.initOnStartup();
  }

  // 2019-12-23 / Ivan / called on successfully login with completed profile - need to start persisting tokens
  public persistAuthTokens(): void {
    this.helper.persistAuthTokens();
  }

  // 2019-12-23 / Ivan / called on logout - need to clear all tokens
  public resetAuthTokens(): void {
    this.helper.resetAuthTokens();
  }

  public async apiRequestWithAuthImpl<
    Data extends AxiosRequestConfig,
    DebugErrorSource extends string,
    OnRequest extends (
      data: Data,
      debugErrorSource: DebugErrorSource,
    ) => Promise<AxiosResponse>,
  >(
    onRequest: OnRequest,
    data: Data,
    debugErrorSource: DebugErrorSource,
  ): Promise<AxiosResponse> {
    const addAccessTokenAuthorization = true;
    let tokens: AppTokens;
    let currentAccessToken = "";

    // both accessToken and refreshToken are guaranteed to be non-empty here
    // we'll try max 3 times to execute request / possibly try to refresh tokens
    for (let i = 0; i < 3; i++) {
      // try to execute request
      try {
        // will return 'last known tokens' or 'just refreshed tokens' or throw an exception
        [tokens] = await this._ensureTokens<Data, DebugErrorSource, OnRequest>(
          onRequest,
          debugErrorSource,
          currentAccessToken,
        );

        // save last accessToken, will be used to clear tokens.accessToken if invalid
        currentAccessToken = tokens.accessToken;

        if (this.lastUsedAccessToken !== currentAccessToken) {
          this.lastUsedAccessToken = currentAccessToken;
          this.lastUsedAccessTokenCounter++;
        }

        // adjust auth headers
        this._apiRequestAdjustData(
          data,
          currentAccessToken,
          addAccessTokenAuthorization,
        );

        return await onRequest(data, debugErrorSource);
      } catch (error: TAnyAlias) {
        // this is not 'access token expired' error - just re-throw it
        if (
          error.error_code !==
          FliffException.ERROR_13091__REFRESH_TOKEN__INVALID_OR_EXPIRED_ACCESS_TOKEN
        ) {
          throw error;
        }
      }
    }

    // this is a very unexpected case
    throw new FliffException(
      FliffException.ERROR_9021__CANNOT_OBTAIN_NEW_VALID_TOKENS,
      "cannot obtain valid tokens",
      debugErrorSource,
    );
  }

  private _apiRequestAdjustData(
    data: AxiosRequestConfig,
    accessToken: string,
    addAccessTokenAuthorization: boolean,
  ): void {
    if (!data.headers) {
      data.headers = {};
    }

    // if there is no auth header, and we have global auth tokens - attach them to the request
    if (addAccessTokenAuthorization && accessToken) {
      data.headers.Authorization = `Bearer ${accessToken}`;
      data.headers["x-dd-request-code"] = "access_token_auth";
    }
  }

  private async _ensureTokens<
    Data extends AxiosRequestConfig,
    DebugErrorSource extends string,
    OnRequest extends (
      data: Data,
      debugSource: DebugErrorSource,
    ) => Promise<AxiosResponse>,
  >(
    onRequest: OnRequest,
    debugErrorSource: DebugErrorSource,
    knownInvalidAccessToken = "",
  ): Promise<[AppTokens, boolean]> {
    // critical section - only one request can enter it at the same time
    const [tokens, wereRefreshedFromServer] = await this._lock.acquire(
      "tokens",
      async (): Promise<[AppTokens, boolean]> => {
        return await this._ensureTokensImpl<Data, DebugErrorSource, OnRequest>(
          onRequest,
          debugErrorSource,
          knownInvalidAccessToken,
        );
      },
    );

    return [tokens, wereRefreshedFromServer];
  }

  private async _refreshTokensFromServer<
    Data extends AxiosRequestConfig,
    DebugErrorSource extends string,
    OnRequest extends (
      data: Data,
      debugSource: DebugErrorSource,
    ) => Promise<AxiosResponse>,
  >(onRequest: OnRequest, refreshToken: string): Promise<AppTokens> {
    try {
      const authHeader = `Basic ${Buffer.from(
        `${AppProfileUtils.coreServerClientId}:${AppProfileUtils.coreServerClientSecret}`,
      ).toString("base64")}`;
      const requestData = {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authHeader,
        "x-dd-request-code": "refresh_token",
      };

      const rawResponse = await onRequest(
        {
          method: "POST",
          url: "/api/v1/oauth2/token/",
          headers,
          data: new URLSearchParams(requestData).toString(),
        } as unknown as Data,
        "refresh_token" as DebugErrorSource,
      );

      const response = AuthTokensResponse.decode(rawResponse.data);
      return this.helper.installAuthTokensResponse(response, null);
    } catch (error: TAnyAlias) {
      if (error.config && error.config.headers) {
        error.config.headers["x-dd-refresh-token"] = refreshToken;
      }
      if (
        error.error_code ===
        FliffException.ERROR_13092__REFRESH_TOKEN__INVALID_OR_EXPIRED_REFRESH_TOKEN
      ) {
        await this.resetAuthTokens();
        throw new FliffException(
          FliffException.ERROR_1902__SESSION_EXPIRED__INVALID_REFRESH_TOKEN,
          `invalid or expired refresh token: ${error.error_message}`,
          error.debugErrorSource,
        );
      }

      throw error;
    }
  }

  private async _ensureTokensImpl<
    Data extends AxiosRequestConfig,
    DebugErrorSource extends string,
    OnRequest extends (
      data: Data,
      debugSource: DebugErrorSource,
    ) => Promise<AxiosResponse>,
  >(
    onRequest: OnRequest,
    debugErrorSource: DebugErrorSource,
    knownInvalidAccessToken = "",
  ): Promise<[AppTokens, boolean]> {
    let tokens = this.helper.tokens;

    if (!tokens.refreshToken) {
      throw new FliffException(
        FliffException.ERROR_1901__SESSION_EXPIRED,
        "missing refresh token",
        debugErrorSource,
      );
    }

    // we know that this token is no longer valid - reset it
    if (
      !!knownInvalidAccessToken &&
      knownInvalidAccessToken === tokens.accessToken
    ) {
      tokens = this.helper.resetAccessToken();
    }

    let isRefreshedFromServer = false;

    if (!tokens.accessToken) {
      // no access token, try to refresh it
      tokens = await this._refreshTokensFromServer<
        Data,
        DebugErrorSource,
        OnRequest
      >(onRequest, tokens.refreshToken);
      isRefreshedFromServer = true;
    }

    // should never happen
    if (!tokens.accessToken || !tokens.refreshToken) {
      throw new FliffException(
        FliffException.ERROR_9022__INVALID_TOKENS_IN_ENSURE_TOKENS,
        "invalid tokens in ensure_tokens",
        debugErrorSource,
      );
    }

    return [tokens, isRefreshedFromServer];
  }
}

export default new AppTokensManager();
