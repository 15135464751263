import { TGlobalAppState } from "reduxLocal/rootReducer";

export const is_authenticated_s = ({ app }: TGlobalAppState) =>
  app.authenticated;

export const sports_book_profile_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.profile;

export const sports_book_channels_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.config.channels;

export const sports_book_events_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.events;

export const bet_supermarket_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.bet_supermarket.shelves_by_conflict_fkey;

export const points_transaction_report_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.points_transaction_report;

export const xpoints_for_complete_single_challenge = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.config.v3_xpoints_for_complete_single_challenge;

export const points_for_complete_all_daily_challenges_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.config.v3_xpoints_for_complete_all_daily_challenges;

export const today_challenges_report_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.today_challenges_report;

export const cart_items_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.shopping_cart.items;

export const ui_currency_mode_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.shopping_cart_ui.ui_currency_mode;

export const coins_balance_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.profile.amount_v2_tokens;

export const this_week_spent_tokens_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.profile.this_week_stats.s_5005_v2_tokens;

export const this_week_won_credits_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.profile.this_week_stats.s_5007_v2_credits;

export const prev_week_spent_tokens_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.profile.prev_week_stats.s_5005_v2_tokens;

export const prev_week_won_credits_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.profile.prev_week_stats.s_5007_v2_credits;

export const sports_book_config_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.config;

export const shopping_cart_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.shopping_cart;

export const amount_max_v3_xpoints_for_place_pick_s = ({
  sportsBook,
}: TGlobalAppState) => sportsBook.profile.amount_max_v3_xpoints_for_place_pick;

export const getShoppingCartUIS = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.shopping_cart_ui;

export const sports_book_pdata_s = ({ sportsBook }: TGlobalAppState) =>
  sportsBook.pdata;

export const getSocialUsers = ({ social }: TGlobalAppState) => social.users;

export const d_51221_last_v2_transaction_id_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51221_last_v2_transaction_id;

export const d_51202_last_v2_weekly_leaderboard_bonus_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51202_last_v2_weekly_leaderboard_bonus;

export const d_51207_last_v4_xpoints_bonus_s = ({
  sportsBook,
}: TGlobalAppState) => sportsBook.x_delta_profile.d_51207_last_v4_xpoints_bonus;

export const d_51206_last_v2_completed_daily_challenge_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51206_last_v2_completed_daily_challenge;

export const d_51231_last_v3_purchase_error_order_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51231_last_v3_purchase_error_order;

export const d_51251_last_transaction__4116_pending_fc_credit_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51251_last_transaction_4116_pending_fc_credit;

export const d_51232_last_v3_purchase_success_transaction_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51232_last_v3_purchase_success_transaction;

export const d_51250_last_transaction__4115_mail_in_entry_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51250_last_transaction_4115_mail_in_entry;

export const d_51252_last_transaction__4117_cleared_fc_credit_s = ({
  sportsBook,
}: TGlobalAppState) =>
  sportsBook.x_delta_profile.d_51252_last_transaction_4117_cleared_fc_credit;
