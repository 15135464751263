import classNames from "classnames";
import { IProps } from "./types";
import "./styles.scss";

const Label = ({ htmlFor, className, label }: IProps) => {
  const classes = classNames("input-label", className);
  return (
    <label htmlFor={htmlFor} className={classes}>
      {label}
    </label>
  );
};

export default Label;
