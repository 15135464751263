import queryString from "query-string";
import { TAnyAlias } from "src/types";

class URLUtils {
  /**
   * Custom function to parse the query string and replace hyphens with spaces.
   * @param query - The query string to parse.
   * @returns Parsed query object with hyphens replaced by spaces.
   */
  static parse(query: string): Record<string, TAnyAlias> {
    const parsed = queryString.parse(query);
    for (const key in parsed) {
      if (typeof parsed[key] === "string") {
        parsed[key] = (parsed[key] as string).replace(/-/g, " ");
      }
    }
    return parsed;
  }

  /**
   * Custom function to stringify the query object, replace spaces with hyphens, and convert values to lowercase.
   * @param queryObject - The query object to stringify.
   * @returns Stringified query with spaces replaced by hyphens and values in lowercase.
   */
  static stringify(queryObject: Record<string, TAnyAlias>): string {
    const transformed = { ...queryObject };
    for (const key in transformed) {
      if (typeof transformed[key] === "string") {
        transformed[key] = (transformed[key] as string)
          .replace(/\s+/g, "-")
          .toLowerCase();
      }
    }
    return queryString.stringify(transformed);
  }
}

export default URLUtils;
