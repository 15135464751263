import classNames from "classnames";
import { IProps } from "./types";
import "./styles.scss";
import { useSelector } from "react-redux";
import { getCurrencyMode } from "selectors/sportsBook";
import { UIConstants } from "server/legacyCore/data/constants";

const Button = ({
  onClick,
  label,
  disabled,
  className,
  children,
  isSecondary,
  ...props
}: IProps) => {
  const currencyMode = useSelector(getCurrencyMode);
  const isModeCash = UIConstants.isModeCash(currencyMode);

  const classes = classNames("fliff-button fliff-button-icon", className, {
    "fliff-button-disabled": disabled,
    "fliff-button--cash": isModeCash,
    "fliff-button--secondary": isSecondary,
  });

  return (
    <button onClick={onClick} className={classes} {...props}>
      {children}
      <span className={"fliff-button__label"}>{label}</span>
    </button>
  );
};

export default Button;
