import AppleSignIn from "react-apple-signin-auth";

import AppleImg from "assets/img/apple-sign-in.svg";
import { DevConstants } from "src/DevConstants";

import SocialButton from "components/atoms_new/shared/SocialButton";
import {
  IAppleAuthResponse,
  SportsBookSocialSignInBIF,
} from "server/legacyCore/api/SportsBookSocialSignInBIF";
import { IProps } from "../Google/types";

const authOptions = {
  clientId: "com.fliff.app.web",
  redirectURI: DevConstants.socialReturnUrl,
  usePopup: true,
} as const;

//  eslint-disable-next-line
const AppleSocialButton = ({ onSuccess, className }: IProps) => {
  const handleSuccessResponse = async (
    appleAuthResponse: IAppleAuthResponse,
  ): Promise<void> => {
    try {
      const response = await SportsBookSocialSignInBIF.handleSignInWithAppleID(
        appleAuthResponse,
      );
      onSuccess(response);
    } catch (err) {
      SportsBookSocialSignInBIF.handleErrorOnSocialSignIn(err);
    }
  };

  return (
    <AppleSignIn
      authOptions={authOptions}
      uiType={"dark"}
      className={"apple-auth-btn"}
      noDefaultStyle={false}
      buttonExtraChildren={"Continue with Apple"}
      skipScript={false}
      onSuccess={handleSuccessResponse}
      onError={SportsBookSocialSignInBIF.handleErrorOnSocialSignIn}
      render={(props: { onClick: () => void }) => (
        <SocialButton
          socialName={"Apple"}
          onClick={props.onClick}
          imageSource={AppleImg}
          className={className}
        />
      )}
    />
  );
};

export default AppleSocialButton;
