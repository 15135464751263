import React, { forwardRef, InputHTMLAttributes } from "react";

import "./styles.scss";

import ValidationStateMark from "../ValidationStateMark";
import { IValidationReturnType } from "utils/FormValidationUtils";
import classNames from "classnames";

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  validationState: IValidationReturnType;
  onChangeText?: (input: string) => void;
  hideValidationIcon?: boolean;
  theme: "light" | "dark";
}

const RegularTextInput = forwardRef<HTMLInputElement, IProps>(
  (
    {
      validationState,
      onChangeText,
      className,
      hideValidationIcon,
      theme,
      ...props
    },
    ref,
  ) => {
    const isInvalidState = validationState.state === 0;

    const inputContainerClasses = classNames(
      "input-container-regular",
      className,
      {
        "input-container-regular--invalid": isInvalidState,
        "input-container-regular--dark": theme === "dark",
        "input-container-regular--disabled": props.disabled,
      },
    );

    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
      onChangeText?.(e.currentTarget.value);
    };

    return (
      <div className={inputContainerClasses}>
        <input
          ref={ref}
          {...props}
          onChange={handleOnChange}
          value={validationState.value}
        />
        {!hideValidationIcon && (
          <ValidationStateMark validationState={validationState} />
        )}
      </div>
    );
  },
);

RegularTextInput.displayName = "RegularTextInput";

export default RegularTextInput;
