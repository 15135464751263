import Radar from "radar-sdk-js";

import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";
import AppConfig from "utils/AppConfig";
import { PublicCoreApi } from "server/core/server/CoreApi";
import CommonLocationManager from "utils/LocationManagers/Common";
import AppUIModesManager from "utils/AppUIModesManager";
import { DevConstants } from "src/DevConstants";
import { FliffException } from "server/legacyCore/FliffException";
import { TAnyAlias } from "../../types";
import LegacyLocationManager from "utils/LocationManagers/Legacy";
import * as H from "history";
import { RadarPermissionsError } from "radar-sdk-js/dist/errors";

export type TLocationState = 0 | 1 | 2 | 3;

interface IRadarMetadata {
  [key: string]: TAnyAlias;
}

class CoreLocationManager {
  public async init(
    shouldRequestPermission: boolean,
    history: H.History,
  ): Promise<TLocationState> {
    try {
      Radar.initialize(AppConfig.radarApiKey);

      if (shouldRequestPermission) {
        const isPermissionGranted = await this._requestLocationPermission();
        if (!isPermissionGranted) {
          if (await this._checkPermissionNotDetermined()) {
            return 3;
          }
          return 1;
        }
        AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
          AppConfig.modalProgressBarEnforceIndicatorMode,
        );
      }

      const radarTrackCallback = await Radar.trackOnce();

      const result =
        await PublicCoreApi.safeBlockingRegisterRadarSDKCallBackData(
          radarTrackCallback,
        );
      if (result.response) {
        let regionCode = result.response.usa_state_code;
        if (DevConstants.enforceRadarDetectedState) {
          if (DevConstants.enforceRadarDetectedState === "error") {
            alert("Error occurred and bypassed");
            throw new FliffException(
              123,
              "Dummy error on .enforceRadarDetectedState",
            );
          }
          regionCode = DevConstants.enforceRadarDetectedState;
        }
        CommonLocationManager.setModeByRegionCode(regionCode);
        CommonLocationManager.setIpCheckResult({
          isPassed: true,
          regionCode,
          meta: regionCode ? "successGetRegionCode" : "unableToParseRegionCode",
        });
        CommonLocationManager.setGeocodeCheckResult({
          isPassed: true,
          regionCode,
          meta: regionCode ? "successGetRegionCode" : "unableToParseRegionCode",
        });

        if (AppUIModesManager.isDisabledMode) {
          if (!shouldRequestPermission) {
            history.push("/verify-location", { status: 0 });
          }
          return 0;
        }
        return 2;
      }
      return 0;
    } catch (err) {
      return this._handleInitError(err as Error);
    } finally {
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
    }
  }

  public async checkGeolocationPermission() {
    if ("permissions" in navigator) {
      try {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });
        return result.state; // 'granted', 'denied', or 'prompt'
      } catch (error) {
        console.error("Error checking geolocation permission:", error);
        return null;
      }
    } else {
      console.warn("Permissions API is not supported in this browser.");
      return null;
    }
  }

  public async checkPermissionGranted() {
    const status = await this.checkGeolocationPermission();

    return status === "granted";
  }

  public initUserDataOnAuthSuccess<Data extends IRadarMetadata>(data: Data) {
    Radar.setUserId(`${data.userId}`);
    Radar.setMetadata(data);
  }

  private _handleInitError(error: Error): TLocationState {
    console.warn("CoreLocationManager._handleInitError", error.message);
    CommonLocationManager.setIpCheckResult({
      isPassed: true,
      regionCode: "",
      meta: `unknownRegionCode.${error.message}`,
    });
    CommonLocationManager.setGeocodeCheckResult({
      isPassed: true,
      regionCode: "",
      meta: `unknownRegionCode.${error.message}`,
    });

    if ((error as RadarPermissionsError).status === "ERROR_PERMISSIONS") {
      return 3;
    }

    return 0;
  }

  private async _checkPermissionNotDetermined(): Promise<boolean> {
    const status = await this.checkGeolocationPermission();
    return status === "prompt";
  }

  private _requestLocationPermission = async (): Promise<boolean> => {
    await LegacyLocationManager._requestLocationPermission();

    return await this.checkPermissionGranted();
  };
}

export default new CoreLocationManager();
