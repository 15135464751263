import { AbstractBIF } from "server/legacyCore/AbstractBIF";
import { ApiCore } from "server/legacyCore/ApiCore";
import { FliffException } from "server/legacyCore/FliffException";
import { ApiSportsBook } from "server/legacyCore/api/ApiSportsBook";
import { ServerClock } from "utils/ServerClock";
import { DeviceUptimeClock } from "utils/DeviceUptimeClock";
import {
  OwnershipAttributeCode,
  UpdatePersonalDataMode,
} from "server/legacyCore/data/constants";
import { AppSetUserAccountAction } from "reduxLocal/app/app.actions";
import { CoreStateAction } from "reduxLocal/core/core.actions";
import {
  DataReq__SB_RateUsInfo,
  DataReq__SB_Update_Personal_Data,
  DataReq__SB_v2_Complete_Profile_Data,
  Data__SB_UserProfile,
} from "server/legacyCore/data/objects";
import {
  DefaultBIFResponse,
  IDataReqAuthMeta,
  IDataReqSignInUserData,
  ISignUpViaEmailFullData,
  ServerObj_Temp_Data_For_Complete_Profile,
  TDataReqSignIn,
  TISignUpViaEmailUserData,
} from "server/legacyCore/objectsLegacyCore";
import AppTokensManager from "server/sharedCore/AppTokensManager";
import SocialFeedAPI from "server/social/server/SocialFeedAPI";
import Analytics from "utils/Analytics";
import MixPanelShared from "utils/Analytics/MixPanelWrapper/MixPanelShared";
import SocialUtils from "utils/SocialUtils";
import { DevConstants } from "src/DevConstants";
import AppConfig from "utils/AppConfig";
import {
  SportsBookRequest,
  SportsBookRequestFactory,
  SportsBookResponse,
} from "./x_objects_protocol";
import { PersistentStorage } from "utils/PersistentStorage";
import Logger from "utils/Logger";
import ProfileVerificationUtils from "utils/ProfileVerificationUtils";
import { AppUtils } from "utils/AppUtils";
import CoreLocationManager from "utils/LocationManagers/Core";
/*
2019-11-03 / Ivan / BIF is just a random suffix taken from first google search result for 'mixing ui with network requests'
https://medium.com/hackernoon/frontend-in-the-backend-a-pattern-for-cleaner-code-b497c92d0b49

I don't want to use overused terms like 'controller' etc here
*/

export class SportsBookSignInBIF extends AbstractBIF {
  public static buildAuthRequestMeta = (): IDataReqAuthMeta => ({
    meta_device_os: "web",
    meta_app_version: DevConstants.appVersion,
    meta_app_build: DevConstants.capabilityNumber,
    meta_install_token: PersistentStorage.cachedInstallToken,
    meta_device_id: PersistentStorage.cachedDeviceId,
  });

  //2021-11-29 / Ivan / extreme hack - we will pass serialized request object inside 'password' field
  public static async signInWithFliffAccount(
    login_data: IDataReqSignInUserData,
    should_unblock_layout: boolean,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.common_blocking_operation(
      "loginWithFliffAccount",
      async (): Promise<DefaultBIFResponse> => {
        // 2021-11-27 / Ivan / extreme hack - we will pass signup data as serialized text in password field
        // in order to allow better tracking + login via any workflows and amount of attributes
        const the_request: {
          login_data: TDataReqSignIn;
          __object_class_name: string;
        } = {
          login_data: {
            ...login_data,
            ...SportsBookSignInBIF.buildAuthRequestMeta(),
          },
          __object_class_name: "Fliff_Login_Request",
        };

        const v2_auth_username = "fliff_v2_auth";
        const v2_auth_password: string = JSON.stringify(the_request);

        await ApiCore.accountSignIn(v2_auth_username, v2_auth_password);
        return await SportsBookSignInBIF.commonLoadAndVerifyUserAccount();
      },
      should_unblock_layout,
    );
  }

  /*
  public static async loginWithSocialToken(
    backend_code: string,
    backend_token: string,
    dispatch: Dispatch,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.common_blocking_operation(
      dispatch,
      "loginWithSocialToken",
      async (): Promise<DefaultBIFResponse> => {
        await ApiCore.social_login(backend_code, backend_token);

        return await SportsBookLoginBIF.commonLoadAndVerifyUserAccount(
          dispatch,
        );
      },
    );
  }
*/
  public static async xloginWithSocialToken(
    backend_code: string,
    callback: () => [string, string],
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.common_blocking_operation(
      "loginWithSocialToken_" + backend_code,
      async (): Promise<DefaultBIFResponse> => {
        // ask callback to obtain login token
        const [backend_token, details]: [string, string] = callback();

        await ApiCore.socialSignIn(backend_code, backend_token, details);

        return await SportsBookSignInBIF.commonLoadAndVerifyUserAccount();
      },
      true,
    );
  }

  public static blockingAuthenticateWithSavedTokensOnStartup(): Promise<DefaultBIFResponse> {
    return AbstractBIF.common_x_blocking_operation(
      AppConfig.modalProgressBarEnforceIndicatorMode,
      838,
      "blockingAuthenticateWithSavedTokensOnStartup",
      async () => {
        return await SportsBookSignInBIF.authenticateWithSavedTokensOnStartup();
      },
      true,
    );
  }

  public static async authenticateWithSavedTokensOnStartup(): Promise<DefaultBIFResponse> {
    const ores: DefaultBIFResponse =
      await SportsBookSignInBIF.commonLoadAndVerifyUserAccount();

    if (
      ores.resultCode ===
      FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED
    ) {
      throw new FliffException(
        FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED,
        "incomplete profile",
      );
    }
    if (
      ores.resultCode ===
      FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER
    ) {
      throw new FliffException(
        FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER,
        "verify phone number",
      );
    }
    if (
      ores.resultCode ===
      FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE
    ) {
      throw new FliffException(
        FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE,
        "accept terms of use",
      );
    }

    return ores;
  }

  public static async signupViaEmailWithAutoSignIn(
    signupRequest: TISignUpViaEmailUserData,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.common_blocking_operation(
      "signupViaEmailWithAutoLogin",
      async (): Promise<DefaultBIFResponse> => {
        // 2021-11-27 / Ivan / extreme hack - we will pass signup data as serialized text in password field
        // in order to 1. validate and finish creation of user profile and 2. get automatic oauth2 login
        const the_request: {
          signup_data: ISignUpViaEmailFullData;
          __object_class_name: string;
        } = {
          signup_data: {
            ...signupRequest,
            ...SportsBookSignInBIF.buildAuthRequestMeta(),
          },
          __object_class_name: "Fliff_Signup_V2_Request",
        };

        const v2_auth_username = "fliff_v2_auth";
        const v2_auth_password = JSON.stringify(the_request);

        //        /*const signupViaEmailResponse: SignupViaEmailResponse */ await api_FliffApp.signupViaEmail(
        //          signupRequest,
        //        );

        // 2019-09-28 / Ivan / standard workflow - register / login / load_profile
        // we need to redesign the logical workflow to handle scenarios like: register ok, login or load profile - network error
        await ApiCore.accountSignIn(v2_auth_username, v2_auth_password);

        return await SportsBookSignInBIF
          .commonLoadAndVerifyUserAccount
          //          signupRequest.bonus_code,
          ();
      },
      true,
    );
  }

  /*
// 2021-11-29 / Ivan / switching to DataReq__SB_v2_Complete_Profile_Data
  public static async completeUserAccountWithAutoLogin(
    data: DataReq_UpdateProfile,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.common_blocking_operation(
      "completeUserAccountWithAutoLogin",
      async (): Promise<DefaultBIFResponse> => {
        await api_FliffApp.completeUserAccount(data);

        const ores: DefaultBIFResponse = await SportsBookLoginBIF.commonLoadAndVerifyUserAccount(
//          data.coupon_code,
        );

        if (
          ores.resultCode ===
          FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED
        ) {
          throw new FliffException(
            FliffException.ERROR_9031__UNEXPECTED_INCOMPLETE_PROFILE,
            "unexpected incomplete profile",
          );
        }

        return ores;
      },
    );
  }
*/

  public static async blocking_v2_complete_profile_data(
    request_data: DataReq__SB_v2_Complete_Profile_Data,
  ): Promise<DefaultBIFResponse> {
    return await AbstractBIF.common_blocking_operation(
      "v2_complete_profile_data",
      async (): Promise<DefaultBIFResponse> => {
        const psoreq: SportsBookRequest =
          SportsBookRequestFactory.create_for_OPERATION__48__V2_COMPLETE_USER_PROFILE(
            request_data,
          );

        const psoresp: SportsBookResponse =
          await ApiSportsBook.sendPossiblyPartialProfileRequest(
            psoreq,
            "operationName_" + "v2_complete_profile_data",
          );

        if (psoresp.operationError) {
          throw psoresp.operationError;
        }

        return await SportsBookSignInBIF.commonLoadAndVerifyUserAccount();
      },
      true,
    );
  }

  public static async logout() {
    MixPanelShared.reset();
    await PersistentStorage.resetAuthTokens();
    await PersistentStorage.updateLoggedAccountStatusOnLogout();
    window.location.href = "/";
  }

  private static async commonLoadAndVerifyUserAccount(): Promise<DefaultBIFResponse> {
    // step 1 - load all data from server via single call
    const [sresp, sfliff_account]: [
      SportsBookResponse,
      ServerObj_Temp_Data_For_Complete_Profile,
    ] = await SportsBookSignInBIF.login_step__1__load_data();

    // step 2 - log some debug info
    SportsBookSignInBIF.login_step__2__log_response(sfliff_account);

    // 2022-03-17 / Ivan / introduce is_primary_phone_number_required
    // step 3 - save partial profile info and throw exception
    // 2019-11-16 / Ivan / profile not complete - inject the user profile data in some temp storage
    if (
      !sfliff_account.is_completed ||
      sfliff_account.is_primary_phone_number_required ||
      sfliff_account.is_accept_terms_of_use_required
    ) {
      return SportsBookSignInBIF.login_step__3__on_profile_not_completed(
        sresp,
        sfliff_account,
      );
    }

    // 2022-01-29 / Ivan / upload personal details from local storage and then replace prev answer with latest data from server
    sresp.profile = await SportsBookSignInBIF.sync_personal_data_on_login(
      sfliff_account.id,
    );

    // step 4 - save all info and inform caller that he can proceed
    return await SportsBookSignInBIF.login_step__4__on_all_done(sresp);
  }

  // 2020-05-10 / Ivan / we just got session token, load all the required data
  private static async login_step__1__load_data(): Promise<
    [SportsBookResponse, ServerObj_Temp_Data_For_Complete_Profile]
  > {
    const start_uptime_millis = DeviceUptimeClock.nativeDeviceUptimeMillis();
    const data_rateus: DataReq__SB_RateUsInfo = {
      action_stamp: 0,
      action_code: 0,
      display_stamp: 0,
      account_id: 0,
      nag_debug_reason: "",
    };

    const sreq: SportsBookRequest =
      SportsBookRequestFactory.createSportsBookRequest_for__OPERATION__50__PARTIALLY_VALID_USER__SYNC_ON_LOGIN(
        data_rateus,
      );

    const sresp: SportsBookResponse =
      await ApiSportsBook.sendPossiblyPartialProfileRequest(
        sreq,
        "load_data_on_login",
      );

    // should never happen, but at the moment underlying protocol allows optional values during decoding
    if (AppUtils.isNullable(sresp.temp_fliff_account)) {
      throw new Error("unexpected (sresp.temp_fliff_account == null)");
    }

    // 2019-11-18 / Ivan / operation 'sync' is not supposed to complete with error code, but we'll leave this throw - just in case
    if (sresp.operationError) {
      throw sresp.operationError;
    }

    if (!ServerClock.isInitialized) {
      ServerClock.init(sresp.server_timestamp, start_uptime_millis);
      // 2020-06-10 / Ivan / we are not interested in general FliffAppMonitor any more, may be reimplemented when we get the native module done
      // 2019-12-22 / Ivan / also flush server time to state
      //      await FliffAppMonitor.flushServerTimeUpdate();
    }

    //    await ServerClock.dump();

    return [sresp, sresp.temp_fliff_account];
  }

  private static login_step__2__log_response(
    user: ServerObj_Temp_Data_For_Complete_Profile,
  ): void {
    const info = "";
    PersistentStorage.saveLastLoggedProfile(user.id, user.username);

    if (user.is_completed) {
      Logger.logMessage(
        "[login] " +
          user.id +
          " / " +
          user.is_completed +
          " / " +
          user.username +
          " / " +
          info,
      );
    } else {
      Logger.logMessage(
        "[login (!user.is_completed)] " +
          user.id +
          " / " +
          user.is_completed +
          " / " +
          user.username +
          " / " +
          info,
      );
    }
  }

  private static login_step__3__on_profile_not_completed(
    sresp: SportsBookResponse,
    sfliff_account: ServerObj_Temp_Data_For_Complete_Profile,
  ): DefaultBIFResponse {
    AppSetUserAccountAction.dispatchSetIncompleteProfile(sresp);

    if (!sfliff_account.is_completed) {
      return DefaultBIFResponse.create_for_resultCode(
        FliffException.ERROR_1903__SESSION_EXPIRED__PROFILE_NOT_COMPLETED,
      );
    }

    if (sfliff_account.is_primary_phone_number_required) {
      return DefaultBIFResponse.create_for_resultCode(
        FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER,
      );
    }

    return DefaultBIFResponse.create_for_resultCode(
      FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE,
    );
  }

  private static async login_step__4__on_all_done(
    sresp: SportsBookResponse,
  ): Promise<DefaultBIFResponse> {
    await AppTokensManager.persistAuthTokens();

    AppSetUserAccountAction.dispatchSetProfileOnSuccessAuth();

    CoreStateAction.dispatchUpdateCoreState(sresp);

    if (sresp.profile) {
      const data = {
        username: sresp.profile.username,
        referrerUsername: sresp.profile.referrer_username,
        referrerId: sresp.profile.referrer_id,
        userId: sresp.profile.user_id,
        primaryEmail:
          sresp.profile.attributes.find(
            ({ code }) =>
              code === OwnershipAttributeCode.CONST_9122_PRIMARY_EMAIL,
          )?.value || "[unknown email]",
        fullName: `${sresp.profile.personal_info.first_name} ${sresp.profile.personal_info.last_name}`,
        primaryPhoneNumber:
          sresp.profile.attributes.find(
            ({ code }) =>
              code === OwnershipAttributeCode.CONST_9121_PRIMARY_PHONE_NUMBER,
          )?.value || "[unknown phone number]",
      };
      await Analytics.init(data);
      await SocialFeedAPI.safeBackgroundGetUserProfile(
        SocialUtils.toValidUserFkey(sresp.profile.user_id),
        sresp.profile.username,
      );
      await CoreLocationManager.initUserDataOnAuthSuccess(data);
    } else {
      console.warn(
        "Profile is not available on SportsBookBIF [login_step__4__on_all_done]",
      );
      Logger.warnMessage(
        "Profile is not available on SportsBookBIF [login_step__4__on_all_done]",
      );
    }

    return DefaultBIFResponse.create_for_resultCode(321);
  }

  private static async sync_personal_data_on_login(
    userId: number,
  ): Promise<Data__SB_UserProfile> {
    // 2022-01-29 / Ivan / upload local personal data to server - we can do this only AFTER we know the user_id
    const personal_data: DataReq__SB_Update_Personal_Data =
      await SportsBookSignInBIF.build_SB_Update_Personal_Data(userId);

    const xsreq: SportsBookRequest =
      SportsBookRequestFactory.create_for_operation_42__UPDATE_PERSONAL_DATA(
        personal_data,
      );

    const xsresp: SportsBookResponse = await ApiSportsBook.sendPrivateRequest(
      xsreq,
      "sync_personal_data_on_login",
    );

    if (xsresp.operationError) {
      throw xsresp.operationError;
    }

    // should never happen
    if (xsresp.profile === null) {
      throw new Error("unexpected (xsresp.profile == null)");
    }

    return xsresp.profile;
  }

  private static build_SB_Update_Personal_Data(
    userId: number,
  ): DataReq__SB_Update_Personal_Data {
    const personal_info = ProfileVerificationUtils.extractPersonalInfo(userId);

    return {
      ...DataReq__SB_Update_Personal_Data.EMPTY,
      mode: UpdatePersonalDataMode.CONST_1071_SYNC_ON_STARTUP,
      info: personal_info,
      is_locally_verified_with_persona: false,
    };
  }
}
