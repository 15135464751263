import { Fragment } from "react";
import { IProps } from "./types";

const Title = ({ title }: IProps) => {
  const titleLines = typeof title === "string" ? title.split("\n") : [title];

  return (
    <span className={"confirmation-modal__title"}>
      {titleLines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {index < titleLines.length - 1 && <br />}
        </Fragment>
      ))}
    </span>
  );
};

export default Title;
