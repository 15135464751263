// 2020-05-07 / Ivan / as usual - we use random funny constants
// they do not matter for enums and TypeScripts compile time checks work fine
// but hopefully can help us to easily identify some nasty logical errors when value of wrong attribute is passed around
export enum TransactionType {
  CONST_4043_V2_POINTS_BONUS_COMPLETED_CHALLENGE = 4043,
  CONST_4044_V2_REWARDS_CASH_FOR_LEVEL_POINTS_CHANGE = 4044,
  CONST_4045_V2_POINTS_BONUS_PLACE_PICK = 4045,
  CONST_4046_V2_POINTS_BONUS_COMPLETED_ALL_DAILY_CHALLENGES = 4046,
  CONST_4048_V3_XPOINTS_BONUS_PLACE_PICK = 4048,
  CONST_4049_V3_XPOINTS_BONUS_COMPLETED_CHALLENGE = 4049,
  CONST_4050_V3_XPOINTS_BONUS_COMPLETED_ALL_DAILY_CHALLENGES = 4050,
  CONST_4016_V3_WITHDRAW_LOCK_AMOUNT = 4016,
  CONST_4021_V3_EXECUTE_XPOINTS_OFFER = 4021,
  CONST_4074_V4_PURCHASE_BUNDLE_VIA_XP = 4074,
  CONST_4075_V4_XP_PROGRESS_BONUS = 4075,
  CONST_4093_V4_REFERRAL_BONUS_CODE_ON_CREATE = 4093,
  CONST_4017_V5_WITHDRAW_LOCK_AMOUNT_BANK_WIRE = 4017,
  CONST_4018_V5_WITHDRAW_LOCK_AMOUNT_CASH_APP = 4018,
  CONST_4019_V5_WITHDRAW_LOCK_AMOUNT_SKRILL = 4019,
  CONST_4020_V5_WITHDRAW_LOCK_AMOUNT_BITCOIN = 4020,
  CONST_4023_V6_WITHDRAW_LOCK_AMOUNT_PAYMENT_TOKEN = 4023,
  CONST_4004_WITHDRAW = 4004,
  CONST_4005_PURCHASE_VIA_GOOGLE_IAP = 4005,
  CONST_4006_PURCHASE_VIA_APPLE_IAP = 4006,
  CONST_4010_OBTAIN_BUNDLE_VIA_REWARDS_CASH = 4010,
  CONST_4022_WITHDRAW_CANCELLED_BY_USER = 4022,
  CONST_4027_PURCHASE_VIA_PAYSAFE = 4027,
  CONST_4038_REFERRAL_PARENT_BONUS_FOR_REWARDS_CASH_PURCHASE_TOKENS_BUNDLE = 4038,
  CONST_4041_PURCHASE_BONUS_FOR_TOKENS = 4041,
  CONST_4061_CREATE_SLIDE_GIFT_CARD_ORDER = 4061,
  CONST_4062_SLIDE_GIFT_CARD_CASHBACK = 4062,
  CONST_4063_SLIDE_GIFT_CARD_PURCHASE_WITHOUT_CASHBACK = 4063,
  CONST_4081_PURCHASE_BUNDLE_VIA_REDEEMABLE_FC = 4081,
  // Creating custom transaction type in order to have single list and recognize this transaction by this type
  // this type is NOT using in backend, only inside mobile app
  CONST_4070_SLIDE_UNFINISHED_TRANSACTION = 4070,

  CONST_4112_ADMIN_OPERATION_REWARDS_CASH_BONUS = 4112,
  CONST_4113_ADMIN_OPERATION_REWARDS_CASH_CORRECTION = 4113,
  CONST_4131_ADMIN_OPERATION_WITHDRAWAL_REQUEST_APPROVED = 4131,
  CONST_4132_ADMIN_OPERATION_WITHDRAWAL_REQUEST_DECLINED = 4132,
  CONST_4115_ADMIN_OPERATION_BALANCE_CORRECTION_MAIL_IN_ENTRY = 4115,
  CONST_4116_ADMIN_OPERATION_BALANCE_CORRECTION_PENDING_FC_CREDIT = 4116,
  CONST_4117_ADMIN_OPERATION_BALANCE_CORRECTION_CLEARED_FC_CREDIT = 4117,
  CONST_9131_ADMIN_OPERATION_WITHDRAWAL_REQUEST_PENDING = 9131,
  // Mazooma Types
  CONST_4141_MAZOOMA_TRANSFER_CONFIRMED_SUCCESS = 4141,
  CONST_4142_MAZOOMA_TRANSFER_FAILED = 4142,
  CONST_4143_MAZOOMA_TRANSFER_BOUNCED = 4143,
  CONST_4144_MAZOOMA_TRANSFER_FAILED_TO_SUCCESS = 4144,
  CONST_9141_MAZOOMA_TRANSFER_CONFIRMED_PENDING = 9141,
  CONST_4054_REVERSED_MAZOOMA_DEPOSIT = 4054,
  CONST_4024_V6_WITHDRAW_LOCK_AMOUNT_MAZOOMA_DIRECT = 4024,
  CONST_4026_PURCHASE_VIA_MAZOOMA_WEB = 4026,
  // Fiserv Types
  CONST_4161_V6_FISERV_WITHDRAW_LOCK_AMOUNT_PAYMENT_TOKEN = 4161,
  CONST_4171_FISERV_TRANSFER_CONFIRMED_SUCCESS = 4171,
  CONST_4172_FISERV_TRANSFER_FAILED = 4172,
  CONST_4173_FISERV_TRANSFER_BOUNCED = 4173,
  CONST_4174_FISERV_TRANSFER_FAILED_TO_SUCCESS = 4174,
  CONST_9171_FISERV_TRANSFER_CONFIRMED_PENDING = 9171,
}

export enum FeedSyncCode {
  CONST_3054_PREMATCH_INPLAY = 3054,
  CONST_3055_PREMATCH = 3055,
  CONST_3056_INPLAY = 3056,
  CONST_3061_ENTER_MORE_MARKETS = 3061,
  CONST_3062_SELECT_CHANNEL = 3062,
  CONST_3063_ALT_PLAYER_PROPS = 3063,
}

export enum WithdrawalPaymentInstrumentType {
  CONST_8101_MAZOOMA_DEFAULT = 8101,
  CONST_8201_FISERV_VIA_BANK_DEFAULT = 8201,
  CONST_8251_FISERV_VIA_CARD_DEFAULT = 8251,
  CONST_8252_FISERV_VIA_CARD_VISA = 8252,
  CONST_8253_FISERV_VIA_CARD_MASTERCARD = 8253,
}

export enum WithdrawalPaymentInstrumentStatus {
  CONST_8901_VALID = 8901,
  CONST_8902_EXPIRED = 8902,
}

export enum BundleObtainMethod {
  CONST_77091_CLAIM_FREE_BUNDLE = 77091,
  CONST_77092_OBTAIN_BUNDLE_VIA_XP = 77092,
  CONST_77093_OBTAIN_BUNDLE_VIA_REDEEMABLE_FC = 77093,
}

export enum PlayThroughPodCredibleState {
  CONST_43201_VALID = 42301,
  CONST_43209_REVERSED = 42309,
}

export enum PlayThroughPodPlayableState {
  CONST_43301_OPEN = 42301,
  CONST_43308_CLOSED = 42302,
}

export enum PlayThroughPodProgressState {
  CONST_43401_IN_PROGRESS = 43001,
  CONST_43409_TARGET_REACHED = 43009,
}

export enum PaymentVendor {
  CODE_61_GOOGLE_IN_APP = 61,
  CODE_62_APPLE_IN_APP = 62,
  CODE_71_MAZOOMA_WEB = 71,
  CODE_81_PAYSAFE = 81,
}

export enum BundleBonusSkin {
  CONST_2001_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_1 = 2001,
  CONST_2002_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_2 = 2002,
  CONST_2003_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_3 = 2003,
  CONST_2004_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_4 = 2004,
  CONST_2005_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_5 = 2005,
}

export enum BundleOfferSkin {
  CONST_DEFAULT_SKIN = -1,
  CONST_DEFAULT_SKIN_2 = 0,
  CONST_701_SKIN_FREE_HOURLY_NO_FC_BONUS = 701,
  CONST_702_SKIN_FREE_DAILY_NO_FC_BONUS = 702,
  CONST_703_SKIN_FREE_HOURLY_WITH_FC_BONUS = 703,
  CONST_704_SKIN_FREE_DAILY_WITH_FC_BONUS = 704,

  CONST_801_SKIN_SOCIAL_COINS_PRICE_LEVEL_1 = 801,
  CONST_802_SKIN_SOCIAL_COINS_PRICE_LEVEL_2 = 802,
  CONST_803_SKIN_SOCIAL_COINS_PRICE_LEVEL_3 = 803,
  CONST_804_SKIN_SOCIAL_COINS_PRICE_LEVEL_4 = 804,
  CONST_805_SKIN_SOCIAL_COINS_PRICE_LEVEL_5 = 805,
  CONST_806_SKIN_SOCIAL_COINS_PRICE_LEVEL_6 = 806,

  CONST_821_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_1 = 821,
  CONST_822_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_2 = 822,
  CONST_823_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_3 = 823,
  CONST_824_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_4 = 824,
  CONST_825_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_5 = 825,
  CONST_826_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_6 = 826,

  CONST_901_SKIN_FLIFF_CASH_PRICE_LEVEL_1 = 901,
  CONST_902_SKIN_FLIFF_CASH_PRICE_LEVEL_2 = 902,
  CONST_903_SKIN_FLIFF_CASH_PRICE_LEVEL_3 = 903,
  CONST_904_SKIN_FLIFF_CASH_PRICE_LEVEL_4 = 904,
  CONST_905_SKIN_FLIFF_CASH_PRICE_LEVEL_5 = 905,
  CONST_906_SKIN_FLIFF_CASH_PRICE_LEVEL_6 = 906,

  CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1 = 921,
  CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2 = 922,
  CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3 = 923,
  CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4 = 924,
  CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5 = 925,
  CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6 = 926,
}
