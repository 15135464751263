import {
  WithdrawalPaymentInstrumentStatus,
  WithdrawalPaymentInstrumentType,
} from "../../data/objects";
import {
  PlayThroughPodCredibleState,
  PlayThroughPodPlayableState,
  PlayThroughPodProgressState,
  TransactionType,
} from "server/core/data/constants";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
import { FliffException } from "server/legacyCore/FliffException";
import { TAnyAlias } from "src/types";

export const validateTransactionType = (
  jsonData: TAnyAlias,
  debugInfo: string,
): TransactionType => {
  const nn = ValidationUtils.assert_positive_integer(jsonData, debugInfo);

  if (!(nn in TransactionType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TTransactionType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as TransactionType;
};

export const validatePaymentMethodInstrumentType = (
  jsonData: TAnyAlias,
  debugInfo: string,
): WithdrawalPaymentInstrumentType => {
  const nn = ValidationUtils.assert_positive_integer(jsonData, debugInfo);

  if (!(nn in WithdrawalPaymentInstrumentType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected WithdrawalPaymentInstrumentType [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as WithdrawalPaymentInstrumentType;
};

export const validatePaymentMethodInstrumentStatus = (
  jsonData: TAnyAlias,
  debugInfo: string,
): WithdrawalPaymentInstrumentStatus => {
  const nn = ValidationUtils.assert_positive_integer(jsonData, debugInfo);

  if (!(nn in WithdrawalPaymentInstrumentStatus)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected WithdrawalPaymentInstrumentStatus [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as WithdrawalPaymentInstrumentStatus;
};

export const validatePodCreditableState = (
  jsonData: TAnyAlias,
  debugInfo: string,
) =>
  ValidationUtils.validate_enum(
    PlayThroughPodCredibleState as unknown as PlayThroughPodCredibleState,
    jsonData,
    debugInfo,
  );

export const validatePodPlayableState = (
  jsonData: TAnyAlias,
  debugInfo: string,
) =>
  ValidationUtils.validate_enum(
    PlayThroughPodPlayableState as unknown as PlayThroughPodPlayableState,
    jsonData,
    debugInfo,
  );

export const validatePodProgressState = (
  jsonData: TAnyAlias,
  debugInfo: string,
) =>
  ValidationUtils.validate_enum(
    PlayThroughPodProgressState as unknown as PlayThroughPodProgressState,
    jsonData,
    debugInfo,
  );
