import platform from "platform";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import { DevConstants } from "src/DevConstants";

class DeviceInfo {
  private _userAgent = platform.parse(navigator.userAgent);
  private _loadedFPInstance = FingerprintJS.load();

  public get OS() {
    return "web";
  }

  public get applicationName() {
    return "Fliff Social Sportsbook Web";
  }

  public get version() {
    return DevConstants.appVersion;
  }

  public get systemName() {
    if (!platform.os) {
      return "[unknown platform]";
    }
    return platform.os.toString();
  }

  public get systemVersion() {
    if (!platform.version) {
      return "[unknown version]";
    }
    return platform.version;
  }

  public get brand() {
    if (!this._userAgent) {
      return "[unknown brand]";
    }
    return this._userAgent.product;
  }

  public get model() {
    if (!platform.description) {
      return "[unknown model]";
    }
    return platform.description;
  }

  public get deviceName() {
    if (!platform.description) {
      return "[unknown deviceName]";
    }
    return platform.description || "";
  }

  public get fingerprint() {
    return navigator.userAgent;
  }

  public async getDeviceId() {
    const result = await (await this._loadedFPInstance).get();
    return result.visitorId;
  }
}

export default new DeviceInfo();
