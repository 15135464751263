import { animated, config, useTransition } from "@react-spring/web";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
import { IModalHandle, IModalProps } from "./types";

const Modal = forwardRef<IModalHandle, IModalProps>(
  ({ children, onClose, preventClose }, ref) => {
    const [visible, setVisible] = useState(false);
    const modalContentRef = useRef<HTMLDivElement>(null);

    const transitions = useTransition(visible, {
      from: { opacity: 0, transform: "scale(0.5)" },
      enter: { opacity: 1, transform: "scale(1)" },
      leave: { opacity: 0, transform: "scale(0.5)" },
      config: config.stiff,
      onRest: () => {
        if (!visible && !preventClose && onClose) {
          onClose();
        }
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        show() {
          setVisible(true);
        },
        close() {
          if (!preventClose) {
            setVisible(false);
          }
        },
      }),
      [preventClose],
    );

    const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(e.target as Node) &&
        !preventClose
      ) {
        setVisible(false);
      }
    };

    return transitions(
      (styles, item) =>
        item &&
        ReactDOM.createPortal(
          <animated.div
            className="modalBackdrop"
            style={{ opacity: styles.opacity }}
            onClick={handleBackdropClick}>
            <animated.div
              className="modal"
              style={styles}
              ref={modalContentRef}>
              <div className="modal__content">{children}</div>
            </animated.div>
          </animated.div>,
          document.body,
        ),
    );
  },
);

Modal.displayName = "Modal";

export default Modal;
