import { Component } from "react";

import "./styles.scss";

import container from "./LoadingModal.container";
import SplashScreen from "pages/mobile/SplashScreen";
import LoadingSpinner from "../../../atoms_new/shared/LoadingSpinner";

export interface IProps {
  propVisible: boolean;
  propIndicatorMode: number;
}

export interface IState {
  stateIsVisible: boolean;
  stateOwnerId: number;
  stateIndicatorMode: number;
  stateVisibleType: "Transparent" | "Default";
}

class LoadingModal extends Component<IProps, IState> {
  state: IState = {
    stateIsVisible: false,
    stateOwnerId: 0,
    stateIndicatorMode: 0,
    stateVisibleType: "Default",
  };

  private _isFirstTimeLoading = true;

  componentDidUpdate() {
    // no change (why will this method be called ??) - just exit
    // do we want to restart if indicator mode changes ?
    if (this.state.stateIsVisible === this.props.propVisible) {
      return;
    }

    if (this.props.propVisible) {
      this.show();
    } else {
      this.hide();
    }
  }

  show = () => {
    /*
  2019-12-10 / Ivan / modes
  < 0 means modal transparent blocking forever
  = 0 means modal progress forever (classic mode)
  > 0 means modal transparent blocking for xx millis, then show progress
  // see coreAppUI.modalProgressIndicatorMode
*/

    // 2019-12-10 / Ivan / it is possible that this component is shown / hidden multiple times, if we are not the current owner, just stop processing
    const stateOwnerId = this.state.stateOwnerId + 1;

    if (this.props.propIndicatorMode < 0) {
      this.setState({
        stateIsVisible: true,
        stateOwnerId: stateOwnerId,
        stateVisibleType: "Transparent",
      });
    } else if (this.props.propIndicatorMode === 0) {
      this.setState({
        stateIsVisible: true,
        stateOwnerId: stateOwnerId,
        stateVisibleType: "Default",
      });
    } else {
      // 2019-12-10 / Ivan / need to be transparent for some time and then switch to standard mode
      this.setState({
        stateIsVisible: true,
        stateOwnerId: stateOwnerId,
        stateVisibleType: "Transparent",
      });

      //      const self = this;
      setTimeout(() => {
        // if we still 'own' this widget, switch from transparent mode to modal progress bar
        if (
          !this.state.stateIsVisible ||
          this.state.stateOwnerId !== stateOwnerId
        ) {
          return;
        }

        this.setState({
          stateVisibleType: "Default",
        });
      }, this.props.propIndicatorMode);
    }
  };

  hide = () => {
    this.setState({
      stateIsVisible: false,
    });
  };

  render() {
    const { stateIsVisible, stateVisibleType } = this.state;

    if (!stateIsVisible) {
      return null;
    }

    if (this._isFirstTimeLoading) {
      this._isFirstTimeLoading = false;
      return <SplashScreen />;
    }

    if (stateVisibleType === "Transparent") {
      return <div className={"loading-modal-component-transparent"} />;
    }

    return (
      <div className={"loading-modal-component"}>
        <LoadingSpinner size="medium" />
      </div>
    );
  }
}

export default container(LoadingModal);
