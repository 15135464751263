import { FliffException } from "server/legacyCore/FliffException";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
// 2020-05-30 / Ivan / remove some extra syntax from the main file
// I am sure that there is a much better general solution, but no time to research it now
import {
  TBetOrBetGroupStatus,
  ChannelType,
  ConflictType,
  TDiffUpdateType,
  GiftCardRedemptionConfigType,
  GiftCardRedemptionMethodType,
  MarketOfferSelectionChangeType,
  PickSelectionState,
  PickType,
  PublicChallengeType,
  RawPeriodType,
  UnfinishedOrderStateCategory,
  UIConstants,
  ServerUIMode,
  IdentityVerificationStatus,
  PlacePicksOperationStatus,
  UserAccountDetailsStatus,
  BundleOfferV8Skin,
  HeraldProtocolVersion,
  PlacePickPlayThroughMode,
} from "server/legacyCore/data/constants";
import { TAnyAlias, TCurrencyMode } from "src/types";
import { BundleBonusSkin, BundleOfferSkin } from "server/core/data/constants";
export const validate_TChannelType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): ChannelType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in ChannelType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TChannelType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as ChannelType;
};

export const validate_TPlacePicksOperationStatus = (
  jsondata: TAnyAlias,
  debugInfo: string,
): PlacePicksOperationStatus => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in PlacePicksOperationStatus)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPlacePicksOperationStatus [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as PlacePicksOperationStatus;
};

// export const validate_TOutcomeScopeCode = (
//   jsondata: TAnyAlias,
//   debugInfo: string,
// ): TOutcomeScopeCode => {
//   const nn: number = ValidationUtils.assert_positive_integer(
//     jsondata,
//     debugInfo,
//   );
//
//   if (!(nn in TOutcomeScopeCode)) {
//     throw new FliffException(
//       FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
//       "unexpected TOutcomeScopeCode [" + nn + "] [" + debugInfo + "]",
//     );
//   }
//
//   return nn as TOutcomeScopeCode;
// };

// export const validate_TMarketSelectionCode = (
//   jsondata: TAnyAlias,
//   debugInfo: string,
// ): TMarketSelectionCode => {
//   const nn: number = ValidationUtils.assert_positive_integer(
//     jsondata,
//     debugInfo,
//   );
//
//   if (!(nn in TMarketSelectionCode)) {
//     throw new FliffException(
//       FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
//       "unexpected TMarketSelectionCode [" + nn + "] [" + debugInfo + "]",
//     );
//   }
//
//   return nn as TMarketSelectionCode;
// };

// export const validate_TXMarketSelectionCode = (
//   jsondata: TAnyAlias,
//   debugInfo: string,
// ): TXMarketSelectionCode => {
//   const nn: number = ValidationUtils.assert_positive_integer(
//     jsondata,
//     debugInfo,
//   );
//
//   if (!(nn in TXMarketSelectionCode)) {
//     throw new FliffException(
//       FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
//       "unexpected TXMarketSelectionCode [" + nn + "] [" + debugInfo + "]",
//     );
//   }
//
//   return nn as TXMarketSelectionCode;
// };

export const validate_TMarketOfferSelectionChangeType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): MarketOfferSelectionChangeType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in MarketOfferSelectionChangeType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TMarketOfferSelectionChangeType [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as MarketOfferSelectionChangeType;
};

export const validate_TPickSelectionState = (
  jsondata: TAnyAlias,
  debugInfo: string,
): PickSelectionState => {
  // 2020-06-01 / Ivan / temporary patch for old data

  let nn: number = ValidationUtils.assert_positive_integer_or_zero(
    jsondata,
    debugInfo,
  );

  if (nn === 0) {
    nn = 501; // PickSelectionState.CONST__501__PENDING
  }

  /*
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );
*/
  if (!(nn in PickSelectionState)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected PickSelectionState [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as PickSelectionState;
};

export const validate_TPickType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): PickType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in PickType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as PickType;
};

export const validate_TPickType_or_null = (
  jsondata: TAnyAlias,
  debugInfo: string,
): PickType | null => {
  const nn: number = ValidationUtils.assert_positive_integer_or_zero(
    jsondata,
    debugInfo,
  );

  if (nn === 0 || nn === 9999) {
    return null;
  }

  if (!(nn in PickType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as PickType;
};

export const validate_TPickCurrency = (
  jsondata: TAnyAlias,
  debugInfo: string,
): TCurrencyMode => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (
    ![
      UIConstants.CONST__331__CASH,
      UIConstants.CONST__333__FLIFF_COINS,
    ].includes(nn)
  ) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPickCurrency [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as TCurrencyMode;
};

export const validate_TRawPeriodType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): RawPeriodType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in RawPeriodType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TRawPeriodType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as RawPeriodType;
};

export const validate_PublicChallengeType = <T extends PublicChallengeType>(
  jsondata: TAnyAlias,
  debugInfo: string,
): T => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in PublicChallengeType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TPublicChallengeType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as T;
};

export const validate_TConflictType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): ConflictType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in ConflictType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TConflictType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as ConflictType;
};

export const validate_TConflictType_or_null = (
  jsondata: TAnyAlias,
  debugInfo: string,
): ConflictType | null => {
  const nn: number = ValidationUtils.assert_positive_integer_or_zero(
    jsondata,
    debugInfo,
  );

  if (nn === 0 || nn === 9999) {
    return null;
  }

  if (!(nn in ConflictType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TConflictType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as ConflictType;
};

// export const validate_TMarketType = (
//   jsondata: TAnyAlias,
//   debugInfo: string,
// ): TMarketType => {
//   const nn: number = ValidationUtils.assert_positive_integer_or_zero(
//     jsondata,
//     debugInfo,
//   );
//
//   if (!(nn in TMarketType)) {
//     throw new FliffException(
//       FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
//       "unexpected TMarketType [" + nn + "] [" + debugInfo + "]",
//     );
//   }
//
//   return nn as TMarketType;
// };

export const validate_TBetOrBetGroupStatus = (
  jsondata: TAnyAlias,
  debugInfo: string,
): TBetOrBetGroupStatus => {
  const nn: number = ValidationUtils.assert_positive_integer_or_zero(
    jsondata,
    debugInfo,
  );

  if (!(nn in TBetOrBetGroupStatus)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TBetOrBetGroupStatus [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as TBetOrBetGroupStatus;
};

export const validate_TDiffUpdateType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): TDiffUpdateType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in TDiffUpdateType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TDiffUpdateType [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as TDiffUpdateType;
};

export const validate_TUnfinishedOrderState = (
  jsondata: TAnyAlias,
  debugInfo: string,
): UnfinishedOrderStateCategory => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in UnfinishedOrderStateCategory)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TUnfinishedOrderStateCategory [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as UnfinishedOrderStateCategory;
};

export const validate_TGiftCardRedemptionConfigType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): GiftCardRedemptionConfigType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in GiftCardRedemptionConfigType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TGiftCardRedemptionConfigType [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as GiftCardRedemptionConfigType;
};

export const validate_TGiftCardRedemptionMethodType = (
  jsondata: TAnyAlias,
  debugInfo: string,
): GiftCardRedemptionMethodType => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in GiftCardRedemptionMethodType)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TGiftCardRedemptionMethodType [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as GiftCardRedemptionMethodType;
};

export const validate_TServerUIMode = (
  jsondata: TAnyAlias,
  debugInfo: string,
): ServerUIMode => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in ServerUIMode)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TServerUIMode [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as ServerUIMode;
};

export const validate_TIdentityVerificationStatus = (
  jsondata: TAnyAlias,
  debugInfo: string,
): IdentityVerificationStatus => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in IdentityVerificationStatus)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TIdentityVerificationStatus [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as IdentityVerificationStatus;
};

export const validate_TUser_Account_Details_Status = (
  jsondata: TAnyAlias,
  debugInfo: string,
): UserAccountDetailsStatus => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in UserAccountDetailsStatus)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TUser_Account_Details_Status [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as UserAccountDetailsStatus;
};

export const validate_TBundle_Offer_v8_Skin = (
  jsondata: TAnyAlias,
  debugInfo: string,
): BundleOfferV8Skin => {
  const nn: number = ValidationUtils.assert_positive_integer(
    jsondata,
    debugInfo,
  );

  if (!(nn in BundleOfferV8Skin)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TBundle_Offer_v8_Skin [" + nn + "] [" + debugInfo + "]",
    );
  }

  return nn as BundleOfferV8Skin;
};

export const validateHeraldProtocolVersion = (
  jsonData: TAnyAlias,
  debugInfo: string,
): HeraldProtocolVersion => {
  const nn = ValidationUtils.assert_positive_integer_or_zero(
    jsonData,
    debugInfo,
  );

  if (!(nn in HeraldProtocolVersion)) {
    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected TUser_Account_Details_Status [" +
        nn +
        "] [" +
        debugInfo +
        "]",
    );
  }

  return nn as HeraldProtocolVersion;
};

export const validateBundleOfferSkin = (
  jsonData: unknown,
  debugInfo: string,
): BundleOfferSkin =>
  ValidationUtils.validate_enum(
    BundleOfferSkin as unknown as BundleOfferSkin,
    jsonData,
    debugInfo,
  );

export const validateBundleBonusSkin = (
  jsonData: unknown,
  debugInfo: string,
): BundleBonusSkin =>
  ValidationUtils.validate_enum(
    BundleBonusSkin as unknown as BundleBonusSkin,
    jsonData,
    debugInfo,
  );

export const validatePlacePickPlayThroughMode = (
  jsonData: unknown,
  debugInfo: string,
): PlacePickPlayThroughMode =>
  ValidationUtils.validate_enum(
    PlacePickPlayThroughMode as unknown as PlacePickPlayThroughMode,
    jsonData,
    debugInfo,
  );
