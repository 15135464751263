import flatten from "lodash/flatten";
import AppConfig from "./AppConfig";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment, no-console
if (AppConfig.infoLog === "enabled") {
  console.info = (...data: unknown[]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment, no-console
    const finalOptions: unknown[] = [];
    const args = data.map(item => {
      if (typeof item === "string") {
        finalOptions.push("color: green");
        return [`%c${item}`];
      } else if (typeof item === "object") {
        finalOptions.push(item);
        return [`%o`];
      } else {
        return [item];
      }
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment, no-console
    console.log(flatten(args).join(" "), ...finalOptions);
  };
}

if (AppConfig.log === "disabled") {
  console.log(
    "%c Disabling React logs! ",
    "background: #27ae60; color: #ecf0f1; font-size: 18px;",
  );

  console.log = () => {
    return;
  };
  console.warn = () => {
    return;
  };
} else {
  console.log(
    "%c React logs are enabled! ",
    "background: #e74c3c; color: #ecf0f1; font-size: 18px;",
  );
}

export {};
