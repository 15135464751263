import { initialState } from "./social.initialState";
import { TAction } from "./types";
import {
  SocialSetMultipleProfilesAction,
  SocialSetUserAction,
} from "./social.actions";

const socialReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case SocialSetUserAction.type:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.profile.user_fkey]: {
            ...state.users[action.payload.profile.user_fkey],
            ...action.payload,
          },
        },
      };
    case SocialSetMultipleProfilesAction.type:
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload.reduce((acc, profile) => {
            return {
              ...acc,
              [profile.user_fkey]: {
                ...state.users[profile.user_fkey],
                profile,
              },
            };
          }, {}),
        },
      };
    default:
      return state;
  }
};

export default socialReducer;
