import { staticGetState } from "reduxLocal/store";
import {
  IFliffInvocation,
  IFliffRequest,
} from "server/sharedCore/data/objects";
import { IInternalProtocolRequestBuilder } from "server/sharedCore/interfaces";
import {
  IFSMProtocolRequestHeader,
  IFSMProtocolRequestSlotsMeta,
  IFCMProtocolRequest,
} from "server/social/data/objects";
import DeviceInfo from "utils/DeviceInfo";
import SocialUtils from "utils/SocialUtils";
import { DevConstants } from "src/DevConstants";
import { AppProfileUtils } from "utils/AppProfileUtils";
import { PersistentStorage } from "utils/PersistentStorage";
import { AppUtils } from "utils/AppUtils";
import AppTokensManager from "server/sharedCore/AppTokensManager";
import CommonLocationManager from "utils/LocationManagers/Common";

export class InternalProtocolRequestBuilderImpl
  implements IInternalProtocolRequestBuilder<IFliffRequest> {
  public buildProtocolRequest<Request extends IFliffRequest>(
    request: Request,
    xRequests: IFliffRequest[] | null,
  ): IFCMProtocolRequest<Request> {
    return {
      header: this._buildHeader(),
      invocation: this._buildInvocation(request),
      x_invocations: this._buildInvocations(xRequests),
      x_meta: this._buildSlotMeta(),
    };
  }

  private _buildHeader(): IFSMProtocolRequestHeader {
    // For now we use logged in user fKey as a token.
    const authToken = staticGetState().sportsBook.profile.user_id;
    const targetPlatform = AppProfileUtils.coreServerVisualName.includes("dev")
      ? "dev"
      : "prod";
    const helper = AppTokensManager.apiExecutorTokensHelper;
    const accessToken = helper.tokens.accessToken;

    return {
      device_x_id: `${DeviceInfo.OS}.${PersistentStorage.cachedDeviceId}`,
      app_x_version: DevConstants.appVersionPlusCapability,
      app_install_token: PersistentStorage.cachedInstallToken,
      // "Distinguishing" between public/private. However, under the hood they are all public.
      auth_token:
        authToken === -1 ? "" : SocialUtils.toValidUserFkey(authToken),
      target_social_platform: targetPlatform,
      conn_id: 0,
      access_token: accessToken,
      platform: DevConstants.isDevMode ? "dev" : "prod",
      usa_state_code: CommonLocationManager.lastSetRegionCode,
      usa_state_code_source: CommonLocationManager.debugSourceInfo,
    };
  }

  private _buildInvocation<Request extends IFliffRequest>(
    request: Request,
  ): IFliffInvocation<Request> {
    return { request };
  }

  private _buildInvocations<Request extends IFliffRequest>(
    xRequests: Request[] | null,
  ): IFliffInvocation<Request>[] | null {
    if (AppUtils.isNullable(xRequests)) {
      return null;
    }

    return xRequests.map(this._buildInvocation);
  }

  private _buildSlotMeta(): IFSMProtocolRequestSlotsMeta | null {
    // TODO - gather meta info (objects versions) from redux store
    return null;
  }
}
