import React from "react";
import classNames from "classnames";
import { IProps } from "./types";
import "./styles.scss";

const ExclamationMark = ({ color = "primary", size = "large" }: IProps) => {
  const exclamationMarkClasses = classNames("exclamation-mark", {
    "exclamation-mark--small": size === "small",
    "exclamation-mark--secondary": color === "secondary",
  });
  return (
    <div className={exclamationMarkClasses}>
      <span className="exclamation-mark__label">!</span>
    </div>
  );
};

export default React.memo(ExclamationMark);
