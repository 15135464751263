import { IPaymentMethod } from "context/MakePaymentContext/MakePaymentContext.props";
import usePostMessage, { VerifiedOriginsEnum } from "hooks/usePostMessage";
import { IMessageEventDataType } from "pages/mobile/RegisterBankAccount/types";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppUIShowModalDialogAction } from "reduxLocal/appUI/appUI.actions";

import { SuccessPurchaseModalService } from "components/molecules/mobile/Modals/SuccessPurchaseModal";
import { isMobileOnly } from "react-device-detect";
import { IFCMPaymentMethodToken } from "server/core/data/objects";
import { PrivateCoreApi } from "server/core/server/CoreApi";
import {
  MazoomaWebViewExistState,
  PaysafeWebViewExistState,
} from "server/legacyCore/data/constants";
import { PaymentVendor } from "server/core/data/constants";
import {
  IPlayThroughBundle,
  IPlayThroughBundleBonus,
} from "server/legacyCore/data/objects";
import { DevConstants } from "src/DevConstants";
import MixPanelShared from "utils/Analytics/MixPanelWrapper/MixPanelShared";
import PaymentMethodsUtils from "utils/PaymentMethodsUtils";
import { CoinsAmountFormatters } from "utils/UIAmountsFormatters";
import UIPaymentUtils from "utils/UIPaymentUtils";
import MakePaymentContext from "./MakePaymentContext";

interface IProps {
  children: ReactNode;
}

const MakePaymentContextProvider = ({ children }: IProps) => {
  const history = useHistory();

  const [selectedBankAccount, setSelectedBankAccount] =
    useState<IFCMPaymentMethodToken | null>(null);
  const [selectedBundle, setSelectedBundle] =
    useState<IPlayThroughBundle | null>(null);
  const [paymentURL, setPaymentURL] = useState<string | null>(null);

  const handleSelectBankAccount =
    (bank: IFCMPaymentMethodToken) => (): void => {
      if (bank?.payment_token === selectedBankAccount?.payment_token) {
        setSelectedBankAccount(null);
        return;
      }
      setSelectedBankAccount(bank);
    };

  const handlePaymentType = async (
    bundle: IPlayThroughBundle,
    bundleBonus: IPlayThroughBundleBonus,
    paymentMethod: IPaymentMethod | null,
    shouldRelocate: boolean,
  ): Promise<void> => {
    setSelectedBundle(bundle);
    if (paymentMethod) {
      return await handleSubmitPressWithPaymentMethod(
        bundle,
        bundleBonus,
        paymentMethod.paymentMethod,
        shouldRelocate,
      );
    }
    if (selectedBankAccount !== null) {
      return await handleSubmitPress(bundle, bundleBonus, shouldRelocate);
    }

    AppUIShowModalDialogAction.dispatchShowErrorDialog(
      "Bank account is required",
      "Please, select 'Register a new bank account' or an existing one to continue.",
    );
    return;
  };

  const handleSubmitPressWithPaymentMethod = async (
    bundle: IPlayThroughBundle,
    bundleBonus: IPlayThroughBundleBonus,
    vendor: PaymentVendor,
    shouldRelocate: boolean,
  ): Promise<void> => {
    if (vendor === null) {
      return;
    }
    if (shouldRelocate && vendor === PaymentVendor.CODE_71_MAZOOMA_WEB) {
      history.push("/cashier-payment", { bundle, bundleBonus });
    }

    if (vendor === PaymentVendor.CODE_81_PAYSAFE) {
      await PaymentMethodsUtils.handlePaysafePayment(
        bundle,
        bundleBonus,
        setPaymentURL,
        history,
        shouldRelocate,
      );
    }
  };

  const handleSubmitPress = async (
    bundle: IPlayThroughBundle,
    bundleBonus: IPlayThroughBundleBonus,
    shouldRelocate: boolean,
  ): Promise<void> => {
    if (selectedBankAccount === null) {
      return;
    }

    await PaymentMethodsUtils.handleMazoomaPayment(
      selectedBankAccount,
      bundle,
      bundleBonus,
      setPaymentURL,
      history,
      shouldRelocate,
    );
  };

  const handleMessageEvent = async (data: IMessageEventDataType) => {
    const title = UIPaymentUtils.parseMessageTitle(data.status);
    const description = UIPaymentUtils.parseMessageDescription(
      data.status,
      data.status_desc,
    );

    if (UIPaymentUtils.shouldUpdateAccountData(data.status)) {
      if (
        selectedBundle &&
        (data.status ===
          PaysafeWebViewExistState.CODE_24321_VERIFIED_AND_ACTIVE ||
          data.status ===
            MazoomaWebViewExistState.CODE_72818_VERIFIED_AND_ACTIVE)
      ) {
        MixPanelShared.trackCoinsCashPurchase(
          data.status ===
            MazoomaWebViewExistState.CODE_72818_VERIFIED_AND_ACTIVE
            ? "mazooma"
            : "paysafe",
          selectedBundle,
        );

        if (isMobileOnly) {
          SuccessPurchaseModalService.onShow(selectedBundle);
        } else {
          AppUIShowModalDialogAction.dispatchShowInfoDialog(
            "Successfull Purchase",
            `You purchased ${CoinsAmountFormatters.toGoldCoins(
              selectedBundle.amount_social_coins,
            )}\nFliff Coins`,
            () => history.replace("/shop"),
          );
        }
      } else {
        AppUIShowModalDialogAction.dispatchShowInfoDialog(
          title,
          description,
          () => history.replace("/shop"),
        );
      }
      await PrivateCoreApi.safeBackgroundRefreshUserProfile();
    }
  };

  const handleDeleteBankAccount =
    (bank: IFCMPaymentMethodToken) => async (): Promise<void> => {
      setSelectedBankAccount(null);
      await PrivateCoreApi.safeBlockingTransparentDisablePaymentToken(
        bank.payment_token,
      );
    };

  usePostMessage(
    handleMessageEvent,
    DevConstants.isDevMode
      ? VerifiedOriginsEnum.DEV_PUBLIC
      : VerifiedOriginsEnum.PROD,
  );

  const resetContextDetails = () => {
    setPaymentURL(null);
    setSelectedBankAccount(null);
    setSelectedBundle(null);
  };

  return (
    <MakePaymentContext.Provider
      value={{
        selectedBankAccount,
        handleSelectBankAccount,
        handleDeleteBankAccount,
        paymentURL,
        handlePaymentTypeSubmit: handlePaymentType,
        resetContextDetails,
      }}>
      {children}
    </MakePaymentContext.Provider>
  );
};

export default MakePaymentContextProvider;
