import React from "react";
import { components, SingleValueProps } from "react-select";
import { TAnyAlias } from "src/types";

// Override the default view for a selected item, to apply an Image in the selection -> Deyan
const SingleValueComponent = ({
  children,
  ...props
}: SingleValueProps<TAnyAlias>) => {
  const selectedOption = React.useMemo(() => {
    return props.options.find(p => p.value === props.data.value);
  }, [props.data, props.options]);

  const shouldPresentImage = React.useMemo(() => {
    if (props.options.length === 0) {
      return false;
    }
    if (!selectedOption) {
      return false;
    }
    return !!props.options[0].imageSource;
  }, [props.options, selectedOption]);

  return (
    <components.SingleValue {...props} className={"option-item-custom"}>
      {shouldPresentImage && (
        <img
          src={selectedOption.imageSource}
          alt={props.data.label}
          className={"selected-option-image"}
        />
      )}
      {children}
    </components.SingleValue>
  );
};

export default SingleValueComponent;
