import React from "react";
import "./styles.scss";
import MarkdownUtils from "utils/MarkdownUtils";
import { IProps } from "./types";
import classNames from "classnames";

const linkify = (
  input: string,
  handlers: (() => void)[],
): (string | React.ReactNode)[] => {
  const splitted = MarkdownUtils.getSplittedText(input);
  const filtered = MarkdownUtils.getFilteredText(splitted);
  return splitted.reduce<(string | React.ReactNode)[]>((acc, curr, index) => {
    const parsedText = MarkdownUtils.getParsedText(curr);
    if (parsedText) {
      const handlerByIndex = handlers[filtered.indexOf(curr)];
      acc.push(
        <span
          key={index}
          className={"linkified-text__link"}
          onClick={handlerByIndex}
          tabIndex={0}>
          {parsedText}
        </span>,
      );
      return acc;
    }
    acc.push(curr);
    return acc;
  }, []);
};

const LinkifiedText = ({ text, handlers, className }: IProps) => {
  const classes = classNames("linkified-text", className);
  return <span className={classes}>{linkify(text, handlers)}</span>;
};

export default LinkifiedText;
