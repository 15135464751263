import SportEventsContext from "./sportEventsContext";
import container from "./sportEventsContext.container";
import { IProps } from "./sportEventsContext.props";
import React from "react";
import isEqual from "react-fast-compare";
import { matchPath, withRouter } from "react-router-dom";
import ChannelUtils from "utils/ChannelUtils";
import { FeedFocusUtils } from "utils/FeedFocusUtils";
import { MemoryStorage } from "utils/MemoryStorage";
import {
  Data__SB_Channel,
  Data__SB_SportEvent,
} from "server/legacyCore/data/objects";
import { IGroupedLiveEvents, TAnyAlias } from "src/types";
import { DevConstants } from "src/DevConstants";
import { HeraldBackend } from "server/herald/HeraldBackend";
import qs from "qs";
import queryString from "query-string";

interface IState {
  catchallChannel: Data__SB_Channel | null;
}

class SportEventsProvider extends React.Component<IProps, IState> {
  public state = {
    catchallChannel: null,
  };
  // atm there is no need to put it in state. (subscribe for it's update)
  public lastSelectedChannelId = DevConstants.getHomeChannelId;

  public get liveChannelIdFromPathName(): number {
    const { liveChannelId } = queryString.parse(this.props.location.search);
    const asNumber = Number(liveChannelId);

    if (isNaN(asNumber)) {
      return 0;
    }
    if (ChannelUtils.getChannelById(this.props.allChannels, asNumber)) {
      return asNumber;
    }
    return 0;
  }

  public get channelIdFromPathName(): number {
    const response = matchPath(this.props.location.pathname, {
      path: "/channels",
      exact: true,
      strict: false,
    });
    if (response && response.isExact) {
      const params = queryString.parse(this.props.location.search);
      this.lastSelectedChannelId = Number(params.channelId);

      return this.lastSelectedChannelId;
    }
    return DevConstants.getHomeChannelId;
  }

  shouldComponentUpdate(
    nextProps: Readonly<IProps>,
    nextState: Readonly<IState>,
  ): boolean {
    if (!isEqual(nextProps.events, this.props.events)) {
      return true;
    }
    if (!isEqual(this.state.catchallChannel, nextState.catchallChannel)) {
      return true;
    }
    if (!isEqual(this.props.allChannels, nextProps.allChannels)) {
      return true;
    }
    if (this.props.location !== nextProps.location) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.applyInitialState();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const channelIdFromPathName = this.channelIdFromPathName;
    const doesSelectedChannelExist = !!ChannelUtils.getChannelById(
      this.props.allChannels,
      channelIdFromPathName,
    );
    if (!doesSelectedChannelExist) {
      this.applyInitialState();
      return;
    }
    if (
      !ChannelUtils.areChannelIdsIdentical(
        prevProps.allChannels,
        this.props.allChannels,
      )
    ) {
      if (doesSelectedChannelExist) {
        if (
          ChannelUtils.isChannelFromAllSports(
            this.props.allChannels,
            this.props.channels,
            channelIdFromPathName,
          )
        ) {
          FeedFocusUtils.handleSelectChannelFromAllSports(
            channelIdFromPathName,
            this.selectCatchallChannel,
          );
          return;
        }
        return;
      }
      this.applyInitialState();
    }
  }

  applyInitialState = (): void => {
    if (this.props.channels.length === 0) {
      return;
    }
    const firstChannelId = this.props.channels[0].id;
    this.selectChannel(firstChannelId);
  };

  selectChannelFromFeed = (id: number): number =>
    requestAnimationFrame(() => {
      this.props.history.replace(`/channels?channelId=${id}`);
    });

  selectCatchallChannel = (id: number): number => {
    if (
      ChannelUtils.isChannelFromAllSports(
        this.props.allChannels,
        this.props.channels,
        id,
      )
    ) {
      return requestAnimationFrame(() => {
        this.props.history.replace(`/channels?channelId=${id}`);
        this.setState({
          catchallChannel: ChannelUtils.getChannelById(
            this.props.allChannels,
            id,
          ),
        });
      });
    }
    return this.selectChannelFromFeed(id);
  };

  selectChannel = (id: number, fromAllSports?: boolean): void | number => {
    const prevChannelId = MemoryStorage.focusedChannelId;
    const selectedLiveChannel = this.liveChannelIdFromPathName;
    const subscribeId =
      id === DevConstants.getLiveChannelId ? selectedLiveChannel : id;
    if (
      prevChannelId !== subscribeId &&
      subscribeId !== DevConstants.getLiveChannelId
    ) {
      MemoryStorage.setFocusedChannelId(subscribeId);
      HeraldBackend.subscribeForChannelId(subscribeId);
      if (prevChannelId) {
        HeraldBackend.unsubscribeForChannelId(prevChannelId);
      }
    }

    if (id === DevConstants.getLiveChannelId) {
      const { channels } = this.groupedLiveEvents();
      if (channels.length === 0) {
        this.selectChannelFromFeed(id);
        return;
      }
      this.selectLiveChannel(channels[0].id);
      return;
    }
    if (fromAllSports) {
      return this.selectCatchallChannel(id);
    }
    this.selectChannelFromFeed(id);
  };

  selectLiveChannel = (id: number): number => {
    const prevChannelId = MemoryStorage.focusedChannelId;
    if (prevChannelId !== id) {
      MemoryStorage.setFocusedChannelId(id);
      HeraldBackend.subscribeForChannelId(id);
      if (prevChannelId) {
        HeraldBackend.unsubscribeForChannelId(prevChannelId);
      }
    }
    return requestAnimationFrame(() => {
      const query = qs.stringify({
        channelId: DevConstants.getLiveChannelId,
        liveChannelId: id,
      });
      this.props.history.push(`/channels?${query}`);
    });
  };

  getFilteredEventsByChannelIds = (
    selectedChannelId: number,
  ): Data__SB_SportEvent[] => {
    const liveChannelId = this.liveChannelIdFromPathName;
    if (liveChannelId) {
      const { events, channels } = this.groupedLiveEvents();
      if (channels.length === 0) {
        return [];
      }
      return events[liveChannelId];
    }
    return this.props.events.filter(({ filter_ids }) =>
      filter_ids.includes(selectedChannelId),
    );
  };

  groupedLiveEvents = (): IGroupedLiveEvents => {
    const grouped = this.props.events.reduce<IGroupedLiveEvents>(
      (acc, curr) => {
        if (!curr.channelId || !curr.isLive) {
          return acc;
        }
        const id = curr.channelId;

        if (!acc.events[id]) {
          acc.events[id] = [curr];
          acc.gamesCountByChannelId[id] = 1;
          acc.totalGamesCount =
            acc.totalGamesCount + acc.gamesCountByChannelId[id];
          const extractedChannel = ChannelUtils.getChannelById(
            this.props.allChannels,
            id,
          );
          if (extractedChannel) {
            acc.channels.push(extractedChannel);
          }
          return acc;
        }
        acc.events[id].push(curr);
        acc.gamesCountByChannelId[id] = acc.gamesCountByChannelId[id] + 1;
        acc.totalGamesCount = acc.totalGamesCount + 1;

        return acc;
      },
      {
        channels: [],
        events: {},
        gamesCountByChannelId: {},
        totalGamesCount: 0,
      },
    );

    grouped.channels = ChannelUtils.sortLiveChannels(grouped.channels);
    return grouped;
  };

  public render() {
    const selectedChannelId = this.channelIdFromPathName;
    const isInPlayChannelSelected =
      selectedChannelId === DevConstants.getLiveChannelId;
    const {
      totalGamesCount: totalLiveGames,
      gamesCountByChannelId,
      channels: liveChannels,
    } = this.groupedLiveEvents();
    const selectedLiveChannel = this.liveChannelIdFromPathName;

    return (
      <SportEventsContext.Provider
        value={{
          events: this.getFilteredEventsByChannelIds(selectedChannelId),
          selectChannel: this.selectChannel,
          selectedChannel: selectedChannelId,
          channels: this.props.channels,
          liveChannels,
          selectLiveChannel: this.selectLiveChannel,
          selectedLiveChannel,
          isInPlayChannelSelected,
          catchallChannel: this.state.catchallChannel,
          allChannels: this.props.allChannels,
          totalLiveGames,
          gamesCountByChannelId,
          allSportsChannels: this.props.allSportsChannels,
          allEvents: this.props.events,
          lastSelectedChannelId: this.lastSelectedChannelId,
        }}>
        {this.props.children}
      </SportEventsContext.Provider>
    );
  }
}

export default withRouter(container(SportEventsProvider) as TAnyAlias);
