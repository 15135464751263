class AppStateManager {
  private _runningIntervals: { intervalId: NodeJS.Timeout; key: string }[] = [];

  private _intervalsToRun: {
    interval: number;
    callback: () => void;
    key: string;
  }[] = [];
  private _callbacksToRunOnAppActivation: (() => void)[] = [];
  private _callbacksToRunOnAppInactive: (() => void)[] = [];

  constructor() {
    document.addEventListener("visibilitychange", () => {
      this._clearIntervals();

      if (document.visibilityState === "visible") {
        this._startIntervals();
        this._runCallbacksOnAppActivation();
      } else {
        this._runCallbacksOnAppInactive();
      }
    });
  }

  public initInterval = (
    callback: () => void,
    interval: number,
    key: string,
  ): NodeJS.Timeout => {
    this._intervalsToRun.push({ callback, interval, key });
    return this._startInterval({ callback, interval, key });
  };

  public runOnAppActivation = (callback: () => void) => {
    return this._callbacksToRunOnAppActivation.push(callback) - 1;
  };

  public runOnAppDeactivation = (callback: () => void): number => {
    return this._callbacksToRunOnAppInactive.push(callback) - 1;
  };

  public removeCallbackOnAppActivation = (callbackIndex: number) => {
    this._callbacksToRunOnAppActivation =
      this._callbacksToRunOnAppActivation.filter((_, i) => i !== callbackIndex);
  };

  public clearSingleInterval = (intervalKey: string): void => {
    this._runningIntervals = this._runningIntervals.filter(
      ({ intervalId, key }) => {
        if (key === intervalKey) {
          clearInterval(intervalId);
        }
        return key !== intervalKey;
      },
    );
    this._intervalsToRun = this._intervalsToRun.filter(
      interval => interval.key !== intervalKey,
    );
  };

  private _clearIntervals = () => {
    this._runningIntervals.forEach(({ intervalId }) =>
      clearInterval(intervalId),
    );
    this._runningIntervals = [];
  };

  private _startIntervals = () => {
    this._intervalsToRun.forEach(this._startInterval);
  };

  private _startInterval = ({
    callback,
    interval,
    key,
  }: (typeof this._intervalsToRun)[number]): NodeJS.Timeout => {
    const intervalId = setInterval(callback, interval);
    this._runningIntervals.push({ intervalId, key });

    return intervalId;
  };

  private _runCallbacksOnAppInactive = (): void => {
    if (this._callbacksToRunOnAppInactive.length === 0) {
      return;
    }
    this._callbacksToRunOnAppInactive.forEach(callback => {
      callback();
    });
  };

  private _runCallbacksOnAppActivation = () => {
    if (this._callbacksToRunOnAppActivation.length === 0) {
      return;
    }
    this._callbacksToRunOnAppActivation.forEach(callback => {
      callback();
    });
  };
}

export default new AppStateManager();
