import { PersistentStorage } from "utils/PersistentStorage";
import { AppProfileUtils } from "utils/AppProfileUtils";
import { AuthTokensResponse } from "server/sharedCore/data/serverAdapters";
import { AppTokens } from "server/sharedCore/AppTokens";

export class ApiExecutorTokensHelper {
  private _tokens = AppTokens.createEmpty();

  // shouldPersistTokens will become true only after we have loaded valid & completed user profile
  private _shouldPersistTokens: boolean = false;

  public get tokens() {
    return this._tokens;
  }

  public get hasSavedCredentials(): boolean {
    return !!this._tokens.accessToken && !!this._tokens.refreshToken;
  }

  public resetAccessToken(): AppTokens {
    // do we want to save this change in persistent storage ??
    this._tokens = AppTokens.createForResetAccessToken(this._tokens);
    return this._tokens;
  }

  public damageAccessToken(): AppTokens {
    // do we want to save this change in persistent storage ??
    this._tokens = AppTokens.createForDamageAccessToken(this._tokens);
    return this._tokens;
  }

  public initOnStartup(): boolean {
    // 2020-05-23 / Ivan / quick hack - this method is always called on startup,
    // so we will use it to preload cached values
    PersistentStorage.initOnStartup();

    const [
      serverCode,
      accessToken,
      refreshToken,
      tokensSource,
      tokensRefreshCount,
    ] = PersistentStorage.loadAuthTokens();
    this._tokens = new AppTokens(
      accessToken,
      refreshToken,
      tokensSource,
      tokensRefreshCount,
    );

    if (serverCode) {
      // install last known server/app profile
      AppProfileUtils.selectCurrentAppProfile(serverCode);
    }

    if (serverCode !== AppProfileUtils.coreServerAxiosCode) {
      // can happen only if we have saved session for some app profile which is no longer available
      this.resetAuthTokens();
    }

    // if we have valid tokens, we should continue to persist the changes
    this._shouldPersistTokens =
      !!this._tokens.accessToken && !!this._tokens.refreshToken;

    return this._shouldPersistTokens;
  }

  public resetAuthTokens(): void {
    this._tokens = AppTokens.createEmpty();

    this._shouldPersistTokens = false;
    this._saveAuthTokensToStorage();
  }

  public installAuthTokensResponse(
    response: AuthTokensResponse,
    tokensSource: string | null,
  ): AppTokens {
    this._tokens = AppTokens.createForAuthTokensResponse(
      this._tokens,
      response,
      tokensSource,
    );

    if (this._shouldPersistTokens) {
      this._saveAuthTokensToStorage();
    }

    return this._tokens;
  }

  public persistAuthTokens(): void {
    // from now on, we should persist all changes in tokens
    // this method is called by business logic AFTER we have loaded valid & complete user profile
    this._shouldPersistTokens = true;
    this._saveAuthTokensToStorage();
  }

  private _saveAuthTokensToStorage(): void {
    PersistentStorage.saveAuthTokens(
      AppProfileUtils.coreServerAxiosCode,
      this._tokens.accessToken,
      this._tokens.refreshToken,
      this._tokens.tokensSource,
      this._tokens.tokensRefreshCount,
    );
  }
}
