import { animated, useTransition } from "@react-spring/web";
import classNames from "classnames";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import { IProps, TToastType } from "./types";

export interface IToastMessageModal {
  showToast: (type: TToastType, title: string, message: string) => void;
}

const toastMessageRef = React.createRef<IToastMessageModal>();
export const ToastMessageModalService = {
  showToast: (type: TToastType, title: string, message: string) =>
    toastMessageRef.current?.showToast(type, title, message),
  toastMessageRef,
};

const ToastMessageModal = forwardRef<IToastMessageModal>((_props, ref) => {
  const [toasts, setToasts] = useState<IProps[]>([]);
  const toastId = useRef(0);

  const showToast = useCallback(
    (type: TToastType, title: string, message: string) => {
      const id = toastId.current++;
      setToasts(prevToasts => {
        if (prevToasts.length >= 4) {
          prevToasts.shift();
        }
        return [...prevToasts, { id, type, title, message }];
      });

      setTimeout(() => {
        hideToast(id);
      }, 3000);
    },
    [],
  );

  const hideToast = (id: number) => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  };

  const transitions = useTransition(toasts, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0,0)" },
    leave: { opacity: 0, transform: "translate3d(100%,0,0)" },
  });

  useImperativeHandle(ref, () => ({ showToast }), [showToast]);

  const handleHideToast = (id: number) => () => {
    hideToast(id);
  };

  return (
    <div className={"toast-notification"}>
      {transitions((style, item) => (
        <animated.div
          key={item.id}
          style={style}
          className={classNames(
            "toast-notification__toast",
            `toast-notification__toast--${item.type}`,
          )}>
          <div className={"toast-notification__toast-content"}>
            <div className={"toast-notification__header"}>
              <strong className={"toast-notification__title"}>
                {item.title}
              </strong>
              <button
                className={"toast-notification__close-button"}
                onClick={handleHideToast(item.id)}>
                &times;
              </button>
            </div>
            <p className={"toast-notification__message"}>{item.message}</p>
          </div>
        </animated.div>
      ))}
    </div>
  );
});

ToastMessageModal.displayName = "ToastMessageModal";

export default ToastMessageModal;
