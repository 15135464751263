import { BaseUIAmountsFormatters } from "./BaseUIAmountsFormatters";

class CoinsAmountFormatters extends BaseUIAmountsFormatters {
  /*
  2020-05-18 / Ivan / coins are measured as 'coin amount', with thousands separators
  example: 4,183,272
*/
  public toGoldCoins = (amountCoins: number): string => {
    return this.withThousandSeparatorsMillionLimited("" + amountCoins);
  };

  public toFullGoldCoinsAmountWithThousandsSeparator = (
    amountCoins: number,
  ): string => {
    return this.withThousandSeparators("" + amountCoins);
  };

  public toShortenGoldCoinsAmount = (goldCoinsAmount: number): string => {
    if (goldCoinsAmount < 1000) {
      return `${goldCoinsAmount}`;
    }
    const item = this.getShorteningAmountPattern(goldCoinsAmount);
    return item
      ? this.replaceShortenAmountPattern(
          (goldCoinsAmount / item.value).toFixed(1),
          item.symbol,
        )
      : "0";
  };
}

export default new CoinsAmountFormatters();
