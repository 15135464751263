import React, { Ref } from "react";
import ReactDOM from "react-dom";

import { IProps, IState } from "./types";
import "./styles.scss";
import classNames from "classnames";

export type TModalOverlayBaseRef = Ref<ModalOverlayBase | null>;
export type TModalOverlayBase = ModalOverlayBase;

class ModalOverlayBase extends React.PureComponent<IProps, IState> {
  state = {
    isVisible: false,
  };
  private readonly _modalClasses = classNames(
    "base-overlay-modal__content",
    this.props.className,
  );

  public show = (): void => {
    if (this.props.onShow) {
      this.props.onShow();
    }
    this.setState({ isVisible: true });
  };

  public hide = (): void => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ isVisible: false });
  };

  public handleDismiss = (): void => {
    if (this.props.disableDismiss) {
      return;
    }
    this.hide();
  };

  public render() {
    const { isVisible } = this.state;
    return ReactDOM.createPortal(
      <>
        {isVisible && (
          <div className="base-overlay-modal">
            <div className={this._modalClasses}>{this.props.children}</div>
          </div>
        )}
      </>,
      document.body,
    );
  }
}

export default ModalOverlayBase;
