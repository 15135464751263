import { DevConstants } from "src/DevConstants";
import MixPanelWrapper from "./MixPanelWrapper";
import MixPanelShared from "./MixPanelWrapper/MixPanelShared";

import { IMixpanelInitUserData } from "src/types";

class Analytics {
  public init = async (initData: IMixpanelInitUserData): Promise<void> => {
    if (!DevConstants.isMixPanelDisabled) {
      await MixPanelWrapper.init(initData);
      MixPanelShared.trackAppOpen();
    }
  };
}

export default new Analytics();
