import { IDataHeraldMessageAbstract } from "server/herald/data/objects";
import {
  AdapterHeraldMessageFeedDiffUpdate,
  AdapterHeraldMessageHeartbeat,
  AdapterHeraldMessageWelcome,
} from "server/herald/data/serverAdapters";
import { FliffException } from "server/legacyCore/FliffException";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
import { TAnyAlias } from "src/types";
import Logger from "utils/Logger";

class HeraldUtils {
  public decodeSerializedMessage(
    serializedMessageData: string,
  ): IDataHeraldMessageAbstract | null {
    try {
      return this.decodeMessage(
        JSON.parse(serializedMessageData),
        "serialized_message_data",
      );
    } catch (error) {
      Logger.warnAny(" x in decode_serialized_message", error);
      return null;
    }
  }

  public decodeMessage(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IDataHeraldMessageAbstract {
    const messageName = ValidationUtils.assert_non_empty_string(
      jsonData.message_name,
      debugInfo + ".message_name",
    );

    if (messageName === "Message_Heartbeat") {
      return AdapterHeraldMessageHeartbeat.decode(jsonData, debugInfo);
    }
    if (messageName === "Message_Welcome") {
      return AdapterHeraldMessageWelcome.decode(jsonData, debugInfo);
    }
    if (messageName === "Message_FeedDiffUpdate") {
      return AdapterHeraldMessageFeedDiffUpdate.decode(jsonData, debugInfo);
    }

    throw new FliffException(
      FliffException.ERROR_6001__COMMON_VALIDATION_ERROR,
      "unexpected message_name [" + messageName + "]",
    );
  }
}

export default new HeraldUtils();
