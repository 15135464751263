import { ApiCore } from "server/legacyCore/ApiCore";
import {
  SportsBookRequest,
  SportsBookResponse,
  SportsBookOperationFactory,
} from "./x_objects_protocol";

export class ApiSportsBook extends ApiCore {
  public static async sendPublicRequest(
    request: SportsBookRequest,
    debugErrorSource: string | null = null,
  ): Promise<SportsBookResponse> {
    return await ApiSportsBook._sendRequestImpl(0, request, debugErrorSource);
  }

  public static async sendPossiblyPartialProfileRequest(
    request: SportsBookRequest,
    debugErrorSource: string | null = null,
  ): Promise<SportsBookResponse> {
    return await ApiSportsBook._sendRequestImpl(1, request, debugErrorSource);
  }

  public static async sendPrivateRequest(
    request: SportsBookRequest,
    debugErrorSource: string | null = null,
  ): Promise<SportsBookResponse> {
    return await ApiSportsBook._sendRequestImpl(99, request, debugErrorSource);
  }

  private static async _sendRequestImpl(
    mode: number,
    request: SportsBookRequest,
    debugErrorSource: string | null = null,
  ): Promise<SportsBookResponse> {
    if (debugErrorSource) {
      debugErrorSource += "/";
    } else {
      debugErrorSource = "";
    }
    debugErrorSource += SportsBookOperationFactory.debug_info(request);

    // public vs private URL

    if (mode === 0) {
      const url = "/api/v1/sports_book_public/";

      return await ApiCore.api_public_x_request_wrapper(
        url,
        debugErrorSource,
        request,
        SportsBookResponse.from_server_response,
      );
    } else if (mode === 1) {
      const url = "/api/v1/sports_book_check/";

      return await ApiCore.api_alt_x_request_wrapper(
        url,
        debugErrorSource,
        request,
        SportsBookResponse.from_server_response,
      );
    } else {
      const url = "/api/v1/sports_book/";

      return await ApiCore.api_alt_x_request_wrapper(
        url,
        debugErrorSource,
        request,
        SportsBookResponse.from_server_response,
      );
    }
  }
}
