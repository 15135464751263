import isEqual from "react-fast-compare";
import rfdcInstance from "rfdc";

import {
  CONST_TOTAL_AMOUNT_PARLAY,
  PickType,
  UIConstants,
} from "server/legacyCore/data/constants";
import {
  Data__SB_Config,
  Data__SB_SportEvent,
  Data__SB_UserProfile,
  Data__SB_X_Delta_Profile,
  TProposalsMap,
} from "server/legacyCore/data/objects";
import {
  Data__SB_ShoppingCart,
  Data__SB_ShoppingCartProposalItem,
  Data__SB_SuperMarket,
} from "server/legacyCore/data/objects_local";
import { TAnyAlias } from "src/types";
import ProposalUtils from "utils/ProposalUtils";
import {
  CoreCopyCartAction,
  CoreCopyPickAction,
  CoreGetEventStatsAction,
  CoreGetLastTransactionsAction,
  CoreGetPaymentTokensAction,
  CoreHeraldSubfeedsUpdateAction,
  CoreInjectProtocolSlotsAction,
  CoreSetIsAltPropButtonEnabledAction,
  CoreSetTicketProcessingStateAction,
  CoreStateAction,
  CoreUpdateCartItemAction,
  CoreUpdateCartItemAmountsAction,
  CoreUpdateCurrencyMode,
  CoreUpdateSGPCoeffSuccessAction,
  CoreUpdateTicketParlayRiskAmountAction,
  CoreUpdateTicketPickTypeAction,
} from "./core.actions";

import { HeraldBackend } from "server/herald/HeraldBackend";
import CoreApiUrlManager from "server/sharedCore/CoreApiUrlManager";
import FeedMergeUtils from "utils/FeedMergeUtils";
import { MemoryStorage } from "utils/MemoryStorage";
import { AppUtils } from "utils/AppUtils";
import { initialState } from "./core.initialState";
import { ICoreState } from "./types";

const rfdc = rfdcInstance();

const processSBConfig = (
  nextState: ICoreState,
  nextConfig: Data__SB_Config,
): void => {
  nextState.config = { ...nextConfig };
  if (nextState.shopping_cart.totalPlacedFliffCashAmount === 0) {
    nextState.shopping_cart = {
      ...nextState.shopping_cart,
      totalPlacedFliffCashAmount: 0,
    };
  }
  if (nextState.shopping_cart.totalPlacedGoldCoinsAmount === 0) {
    nextState.shopping_cart = {
      ...nextState.shopping_cart,
      totalPlacedGoldCoinsAmount:
        nextConfig.credits_parlay__min_pick_amount_in_cents,
    };
  }

  // 2022-01-15 / Ivan / introduce universal concept for permissions / config stuff (anon + per user)
  nextState.last_default_pdata = nextConfig.default_pdata;
  if (AppUtils.isNullable(nextState.last_user_pdata)) {
    if (!isEqual(nextState.pdata, nextState.last_default_pdata)) {
      nextState.pdata = nextState.last_default_pdata;
      // 2021-12-23 / Ivan / introducing zmq support
      // inform the engine for latest config data
      HeraldBackend.onDefaultHeraldEndpoint(
        nextState.pdata.herald_endpoint,
        nextState.pdata.caller_ip_address,
        nextState.pdata.herald_protocol_version,
      );
      CoreApiUrlManager.setLastKnownUrls(
        nextState.pdata.core_sever_public_endpoint,
        nextState.pdata.core_sever_private_endpoint,
        nextState.pdata.proposals_feed_endpoint,
      );
    }
  }

  // 2021-01-02 / Ivan / add support for server controlled polling intervals
  if (nextConfig.default_pdata.config__personalized__20021 > 0) {
    MemoryStorage.config__20021__poll_interval_profile_after_ok =
      nextConfig.default_pdata.config__personalized__20021;
  }
  if (nextConfig.default_pdata.config__personalized__20022 > 0) {
    MemoryStorage.config__20022__poll_interval_profile_after_error =
      nextConfig.default_pdata.config__personalized__20022;
  }
  if (nextConfig.default_pdata.config__personalized__20031 > 0) {
    MemoryStorage.config__20031__poll_interval_public_prematch_after_ok =
      nextConfig.default_pdata.config__personalized__20031;
  }
  if (nextConfig.default_pdata.config__personalized__20032 > 0) {
    MemoryStorage.config__20032__poll_interval_public_prematch_after_error =
      nextConfig.default_pdata.config__personalized__20032;
  }
  if (nextConfig.default_pdata.config__personalized__20041 > 0) {
    MemoryStorage.config__20041__poll_interval_public_inplay_after_ok =
      nextConfig.default_pdata.config__personalized__20041;
  }
  if (nextConfig.default_pdata.config__personalized__20042 > 0) {
    MemoryStorage.config__20042__poll_interval_public_inplay_after_error =
      nextConfig.default_pdata.config__personalized__20042;
  }
};

const processSBProfile = (
  nextState: ICoreState,
  nextProfile: Data__SB_UserProfile,
): void => {
  // 2021-03-30 / Ivan / verify that we don't override user profile with older version
  // (may happen when we foreground and background requests work in parallel)
  if (nextState.profile.obj_version < nextProfile.obj_version) {
    // 2021-12-23 / Ivan / pass last known ip address from server
    HeraldBackend.onNewAuthToken("user_" + nextProfile.user_id);

    nextState.profile = { ...nextProfile };
  }
};

const processSBDeltaProfile = (
  nextState: ICoreState,
  nextDeltaProfile: Data__SB_X_Delta_Profile,
): void => {
  // copy whole object to ensure read only pojos
  const copyDeltaProfile = { ...nextState.x_delta_profile };

  // 2022-01-15 / Ivan / introduce universal concept for permissions / config stuff (anon + per user)
  nextState.last_user_pdata = nextDeltaProfile.user_pdata;
  if (!isEqual(nextState.pdata, nextState.last_user_pdata)) {
    nextState.pdata = nextState.last_user_pdata;

    // 2021-12-23 / Ivan / introducing zmq support
    // inform the engine for latest config data
    HeraldBackend.onDefaultHeraldEndpoint(
      nextState.pdata.herald_endpoint,
      nextState.pdata.caller_ip_address,
      nextState.pdata.herald_protocol_version,
    );

    nextState.events = [
      ...nextState.last_prematch_conflicts,
      ...nextState.last_inplay_conflicts,
    ].filter(
      ({ pcats }) =>
        pcats.filter(pcat => nextState.pdata.conflict_pcats.has(pcat)).length >
        0,
    );
  }

  // 2021-02-12 / Ivan / add alt value for leaderboard bonus - actually d_51202 and d_51203 are same
  if (nextDeltaProfile.d_51202_last_v2_weekly_leaderboard_bonus !== null) {
    copyDeltaProfile.d_51202_last_v2_weekly_leaderboard_bonus =
      nextDeltaProfile.d_51202_last_v2_weekly_leaderboard_bonus;

    MemoryStorage.mem_cache__id_51202 =
      copyDeltaProfile.d_51202_last_v2_weekly_leaderboard_bonus.id;
  }
  if (nextDeltaProfile.d_51203_last_v2_weekly_leaderboard_thropy !== null) {
    copyDeltaProfile.d_51203_last_v2_weekly_leaderboard_thropy =
      nextDeltaProfile.d_51203_last_v2_weekly_leaderboard_thropy;

    MemoryStorage.mem_cache__id_51203 =
      copyDeltaProfile.d_51203_last_v2_weekly_leaderboard_thropy.id;
  }
  if (nextDeltaProfile.d_51204_last_v2_won_number_of_picks_badge !== null) {
    copyDeltaProfile.d_51204_last_v2_won_number_of_picks_badge =
      nextDeltaProfile.d_51204_last_v2_won_number_of_picks_badge;

    MemoryStorage.mem_cache__id_51204 =
      copyDeltaProfile.d_51204_last_v2_won_number_of_picks_badge.id;
  }
  if (nextDeltaProfile.d_51207_last_v4_xpoints_bonus !== null) {
    copyDeltaProfile.d_51207_last_v4_xpoints_bonus =
      nextDeltaProfile.d_51207_last_v4_xpoints_bonus;

    MemoryStorage.mem_cache__id_51207 =
      copyDeltaProfile.d_51207_last_v4_xpoints_bonus.id;
  }
  if (nextDeltaProfile.d_51206_last_v2_completed_daily_challenge !== null) {
    copyDeltaProfile.d_51206_last_v2_completed_daily_challenge =
      nextDeltaProfile.d_51206_last_v2_completed_daily_challenge;

    MemoryStorage.mem_cache__id_51206 =
      copyDeltaProfile.d_51206_last_v2_completed_daily_challenge.id;
  }
  if (nextDeltaProfile.d_51221_last_v2_transaction_id > 0) {
    copyDeltaProfile.d_51221_last_v2_transaction_id =
      nextDeltaProfile.d_51221_last_v2_transaction_id;
    MemoryStorage.mem_cache__id_51221 =
      copyDeltaProfile.d_51221_last_v2_transaction_id;
  }
  if (nextDeltaProfile.d_51231_last_v3_purchase_error_order !== null) {
    copyDeltaProfile.d_51231_last_v3_purchase_error_order =
      nextDeltaProfile.d_51231_last_v3_purchase_error_order;

    MemoryStorage.mem_cache__id_51231 =
      copyDeltaProfile.d_51231_last_v3_purchase_error_order.id;
  }
  if (nextDeltaProfile.d_51232_last_v3_purchase_success_transaction !== null) {
    copyDeltaProfile.d_51232_last_v3_purchase_success_transaction =
      nextDeltaProfile.d_51232_last_v3_purchase_success_transaction;
  }

  if (nextDeltaProfile.d_51241_last_v3_order_updates_tracker > 0) {
    // also save identifier for 'total orders updated counter'
    copyDeltaProfile.d_51241_last_v3_order_updates_tracker =
      nextDeltaProfile.d_51241_last_v3_order_updates_tracker;
    MemoryStorage.mem_cache__id_51241 =
      copyDeltaProfile.d_51241_last_v3_order_updates_tracker;
  }

  if (nextDeltaProfile.d_51250_last_transaction_4115_mail_in_entry !== null) {
    copyDeltaProfile.d_51250_last_transaction_4115_mail_in_entry =
      nextDeltaProfile.d_51250_last_transaction_4115_mail_in_entry;
    MemoryStorage.mem_cache__id_51250 =
      copyDeltaProfile.d_51250_last_transaction_4115_mail_in_entry.id;
  }
  if (
    nextDeltaProfile.d_51251_last_transaction_4116_pending_fc_credit !== null
  ) {
    copyDeltaProfile.d_51251_last_transaction_4116_pending_fc_credit =
      nextDeltaProfile.d_51251_last_transaction_4116_pending_fc_credit;
    MemoryStorage.mem_cache__id_51251 =
      copyDeltaProfile.d_51251_last_transaction_4116_pending_fc_credit.id;
  }
  if (
    nextDeltaProfile.d_51252_last_transaction_4117_cleared_fc_credit !== null
  ) {
    copyDeltaProfile.d_51252_last_transaction_4117_cleared_fc_credit =
      nextDeltaProfile.d_51252_last_transaction_4117_cleared_fc_credit;
    MemoryStorage.mem_cache__id_51252 =
      copyDeltaProfile.d_51252_last_transaction_4117_cleared_fc_credit.id;
  }

  nextState.x_delta_profile = copyDeltaProfile;
};

const processPrematchConflicts = (
  nextState: ICoreState,
  conflicts: Data__SB_SportEvent[],
): void => {
  nextState.last_prematch_conflicts = [...conflicts];
  let events = [
    ...nextState.last_prematch_conflicts,
    ...nextState.last_inplay_conflicts,
  ];

  events = events.filter(
    e =>
      e.pcats.filter(pcat => nextState.pdata.conflict_pcats.has(pcat)).length >
      0,
  );
  nextState.events = events;
};

const processInPlayConflicts = (
  nextState: ICoreState,
  conflicts: Data__SB_SportEvent[],
) => {
  nextState.last_inplay_conflicts = [...conflicts];
  let events = [
    ...nextState.last_prematch_conflicts,
    ...nextState.last_inplay_conflicts,
  ];
  events = events.filter(
    e =>
      e.pcats.filter(pcat => nextState.pdata.conflict_pcats.has(pcat)).length >
      0,
  );
  nextState.events = events;
};

const processSportsBookStateAction = (
  coreState: ICoreState,
  action: CoreStateAction,
): ICoreState => {
  const nextState = { ...coreState };

  if (action.payload.config !== null) {
    processSBConfig(nextState, action.payload.config);
  }
  if (action.payload.profile !== null) {
    processSBProfile(nextState, action.payload.profile);
  }
  if (action.payload.x_delta_profile !== null) {
    processSBDeltaProfile(nextState, action.payload.x_delta_profile);
  }
  if (action.payload.prematch_conflicts !== null) {
    processPrematchConflicts(nextState, action.payload.prematch_conflicts);
  }

  if (action.payload.inplay_conflicts !== null) {
    processInPlayConflicts(nextState, action.payload.inplay_conflicts);
  }

  if (action.payload.prematch_subfeeds_update !== null) {
    nextState.bet_supermarket = FeedMergeUtils.mergeDataFeedUpdate(
      nextState.bet_supermarket,
      action.payload.prematch_subfeeds_update,
    );
    nextState.proposals = FeedMergeUtils.mapPublicUpdatesToProposalMap(
      nextState.proposals,
      nextState.shopping_cart_ui.isTicketProcessing,
      nextState.shopping_cart.items,
      action.payload.prematch_subfeeds_update,
    );
  }

  if (action.payload.inplay_subfeeds_update !== null) {
    nextState.bet_supermarket = FeedMergeUtils.mergeDataFeedUpdate(
      nextState.bet_supermarket,
      action.payload.inplay_subfeeds_update,
    );
    nextState.proposals = FeedMergeUtils.mapPublicUpdatesToProposalMap(
      nextState.proposals,
      nextState.shopping_cart_ui.isTicketProcessing,
      nextState.shopping_cart.items,
      action.payload.inplay_subfeeds_update,
    );
  }
  if (action.payload.points_transaction_report !== null) {
    nextState.points_transaction_report = {
      ...action.payload.points_transaction_report,
    };
  }
  if (action.payload.today_challenges_report !== null) {
    nextState.today_challenges_report = {
      ...action.payload.today_challenges_report,
    };
  }
  if (action.payload.slide_card_brands !== null) {
    nextState.slide_card_brands = { ...action.payload.slide_card_brands };
  }

  return nextState;
};

const processSportsBookShoppingCartUIOptionsChangeAction = (
  originalstate: ICoreState,
  action: CoreUpdateCurrencyMode,
): ICoreState => ({
  ...originalstate,
  shopping_cart_ui: {
    ...originalstate.shopping_cart_ui,
    ui_currency_mode: action.payload!,
  },
});

const processEmptyTheWholeCartSportsBookUpdateCartItemAction = (
  ostate: ICoreState,
): ICoreState => {
  HeraldBackend.onCartItemsSubscriptionChange(ostate.shopping_cart.items);
  return {
    ...ostate,
    shopping_cart: {
      items: {},
      sourcePick: null,
      unique_shopping_cart_id: AppUtils.randomString(10),
      totalPlacedGoldCoinsAmount:
        ostate.config.credits_parlay__min_pick_amount_in_cents,
      totalPlacedFliffCashAmount: 0,
      pickType: PickType.CONST_81_STRAIGHT,
    },
    shopping_cart_ui: {
      ...ostate.shopping_cart_ui,
      sgpStatusData: {
        shouldRequestNewCoeff: false,
        coeffOnSubmitDidChange: false,
        totalCoeff: 0,
      },
    },
  };
};

const clearPicksFromUnavailableMarkets = (
  proposalsMap: TProposalsMap,
  betSupermarket: Data__SB_SuperMarket["shelves_by_conflict_fkey"],
  shoppingCart: Data__SB_ShoppingCart,
  events: Data__SB_SportEvent[],
): Data__SB_ShoppingCart => {
  const copy = rfdc(shoppingCart);
  const keys = Object.keys(copy.items);
  keys.forEach(key => {
    const {
      event: { conflict_fkey: conflictFkey },
      proposal_fkey: proposalFkey,
      group_id: groupId,
    } = shoppingCart.items[key];
    const proposal = ProposalUtils.getBetProposalByProposalFkey(
      proposalFkey,
      conflictFkey,
      proposalsMap,
      betSupermarket,
    );
    const eventByKey = AppUtils.getEventByConflictKey(events, conflictFkey);
    const isProposalOTB = ProposalUtils.isProposalOTB(
      proposal?.status,
      eventByKey?.live_status,
    );
    const doesEventStillExists = !!eventByKey;

    if (isProposalOTB || !proposal || !doesEventStillExists) {
      delete copy.items[groupId];
    }
  });

  return copy;
};

const updatePicksFromNewMarketsValues = (
  proposalsMap: TProposalsMap,
  betSupermarket: Data__SB_SuperMarket["shelves_by_conflict_fkey"],
  shoppingCart: Data__SB_ShoppingCart,
): Data__SB_ShoppingCart => {
  const keys = Object.keys(shoppingCart.items);
  keys.forEach(key => {
    const item = shoppingCart.items[key];
    const proposal = ProposalUtils.getBetProposalByProposalFkey(
      item.proposal_fkey,
      item.event.conflict_fkey,
      proposalsMap,
      betSupermarket,
    );
    if (proposal) {
      shoppingCart.items[key].coeff = proposal.coeff;
      shoppingCart.items[key].place_pick_update_utc =
        proposal.prev_coeff_updated_utc;
      shoppingCart.items[key].isReplaced = false;
    }
  });
  return shoppingCart;
};

const processApplyChangesSportsBookUpdateCartItemAction = (
  state: ICoreState,
): ICoreState => {
  let newCart = clearPicksFromUnavailableMarkets(
    state.proposals,
    state.bet_supermarket.shelves_by_conflict_fkey,
    state.shopping_cart,
    state.events,
  );

  newCart = updatePicksFromNewMarketsValues(
    state.proposals,
    state.bet_supermarket.shelves_by_conflict_fkey,
    newCart,
  );
  newCart.unique_shopping_cart_id = AppUtils.randomString(10);
  HeraldBackend.onCartItemsSubscriptionChange(
    state.shopping_cart.items,
    newCart.items,
  );

  return {
    ...state,
    shopping_cart: rfdc(newCart),
    shopping_cart_ui: {
      ...state.shopping_cart_ui,
      sgpStatusData: {
        ...state.shopping_cart_ui.sgpStatusData,
        shouldRequestNewCoeff: true,
        coeffOnSubmitDidChange: false,
      },
    },
  };
};

const processSportsBookCopyCartAction = (
  state: ICoreState,
  action: CoreCopyCartAction,
): ICoreState => {
  HeraldBackend.onCartItemsSubscriptionChange(
    state.shopping_cart.items,
    action.payload.items,
  );

  return {
    ...state,
    shopping_cart: {
      ...action.payload,
      unique_shopping_cart_id: AppUtils.randomString(10),
    },
    shopping_cart_ui: {
      ...state.shopping_cart_ui,
      sgpStatusData: {
        shouldRequestNewCoeff: true,
        coeffOnSubmitDidChange: false,
        totalCoeff: 0,
      },
    },
  };
};

const processSportsBookCopyPickAction = (
  state: ICoreState,
  action: CoreCopyPickAction,
): ICoreState => {
  HeraldBackend.onCartItemsSubscriptionChange(
    state.shopping_cart.items,
    action.payload.items,
  );

  return {
    ...state,
    shopping_cart: {
      ...state.shopping_cart,
      ...action.payload,
      unique_shopping_cart_id: AppUtils.randomString(10),
    },
    shopping_cart_ui: {
      ...state.shopping_cart_ui,
      sgpStatusData: {
        shouldRequestNewCoeff: true,
        coeffOnSubmitDidChange: false,
        totalCoeff: 0,
      },
    },
  };
};

export const processSportsBookUpdateCartItemAction = (
  ostate: ICoreState,
  action: CoreUpdateCartItemAction,
): ICoreState => {
  // 2020-06-02 / Ivan / very ugly hack to get empty_the_whole_cart action procesed by reducer
  if (action.payload.actionCode === CONST_TOTAL_AMOUNT_PARLAY) {
    return processEmptyTheWholeCartSportsBookUpdateCartItemAction(ostate);
  }

  if (action.payload.actionCode === -888) {
    return processApplyChangesSportsBookUpdateCartItemAction(ostate);
  }

  const oitems = ostate.shopping_cart.items;
  const item = action.payload.item;
  const offerId = item.group_id;

  const { [offerId]: ovalue, ...rest } = oitems;
  let newitems = rfdc(rest);

  // temporary using hardcoded constants: -1 - remove, any other - toggle
  if (action.payload.actionCode === -1) {
    // remove - already removed
  } else {
    // toggle - add if missing
    if (ovalue === undefined) {
      newitems[offerId] = action.payload.item;
    } else if (ovalue.proposal_fkey !== action.payload.item.proposal_fkey) {
      newitems[offerId] = action.payload.item;
    }
  }

  if (action.payload.existingItemKeyToReplace) {
    const newKey = action.payload.item.group_id;
    newitems = AppUtils.renameProp(
      action.payload.existingItemKeyToReplace,
      newKey,
      newitems,
    );
    newitems[newKey] = action.payload.item;
  }

  const sortedEntries = Object.entries<Data__SB_ShoppingCartProposalItem>(
    newitems,
  )
    .sort(([, a], [, b]) => a.createdStamp - b.createdStamp)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const updatedValues = Object.values(sortedEntries);

  const shouldRequestNewCoeff =
    updatedValues.length === 0
      ? false
      : // on selection replace we don't want to request new coeff.
        action.payload.actionCode !== 123 &&
        (Object.values(ostate.shopping_cart.items).length !==
          updatedValues.length ||
          !isEqual(ostate.shopping_cart.items, updatedValues));

  HeraldBackend.onCartItemsSubscriptionChange(
    ostate.shopping_cart.items,
    sortedEntries,
  );

  return {
    ...ostate,
    shopping_cart: {
      ...ostate.shopping_cart,
      items: sortedEntries,
      unique_shopping_cart_id: AppUtils.randomString(10),
    },
    shopping_cart_ui: {
      ...ostate.shopping_cart_ui,
      sgpStatusData: {
        ...ostate.shopping_cart_ui.sgpStatusData,
        shouldRequestNewCoeff,
        coeffOnSubmitDidChange: false,
        ...(updatedValues.length <= 1 && { totalCoeff: 0 }),
      },
    },
  };
};

const processSportsBookUpdateCartItemAmountsActionParlay = (
  ostate: ICoreState,
  amount: number,
): ICoreState => {
  const ocart = ostate.shopping_cart;
  const amountToUpdate = UIConstants.isModeCash(
    ostate.shopping_cart_ui.ui_currency_mode,
  )
    ? "totalPlacedFliffCashAmount"
    : "totalPlacedGoldCoinsAmount";

  const newcart = { ...ocart, [amountToUpdate]: amount };

  return { ...ostate, shopping_cart: newcart };
};

const processSportsBookUpdateCartItemAmountsActionStraight = (
  ostate: ICoreState,
  action: CoreUpdateCartItemAmountsAction,
): ICoreState => {
  const oitems = { ...ostate.shopping_cart.items };

  const ovalue = oitems[action.payload.marketOfferId];
  const amountToUpdate = UIConstants.isModeCash(
    ostate.shopping_cart_ui.ui_currency_mode,
  )
    ? "placed_amount__fliff_cash"
    : "placed_amount__gold_coins";

  if (ovalue !== undefined) {
    oitems[action.payload.marketOfferId] = {
      ...oitems[action.payload.marketOfferId],
      [amountToUpdate]: action.payload.amount,
    };
  }

  return {
    ...ostate,
    shopping_cart: { ...ostate.shopping_cart, items: oitems },
  };
};

const processUpdatePickType = (
  state: ICoreState,
  action: CoreUpdateTicketPickTypeAction,
): ICoreState => ({
  ...state,
  shopping_cart: { ...state.shopping_cart, pickType: action.payload },
});

const processUpdateSGPCoeffSuccess = (
  state: ICoreState,
  action: CoreUpdateSGPCoeffSuccessAction,
): ICoreState => ({
  ...state,
  shopping_cart: {
    ...state.shopping_cart,
    unique_shopping_cart_id: AppUtils.randomString(10),
  },
  shopping_cart_ui: {
    ...state.shopping_cart_ui,
    sgpStatusData: {
      coeffOnSubmitDidChange: action.payload.hasChangedOnSubmit,
      shouldRequestNewCoeff: false,
      totalCoeff: action.payload.coeff,
    },
  },
});

const processInjectedSlots = (
  state: ICoreState,
  action: CoreInjectProtocolSlotsAction,
): ICoreState => {
  const nextState = { ...state };

  if (action.payload.deltaProfile !== null) {
    processSBDeltaProfile(nextState, action.payload.deltaProfile);
  }
  if (action.payload.profile !== null) {
    processSBProfile(nextState, action.payload.profile);
  }
  if (action.payload.config !== null) {
    processSBConfig(nextState, action.payload.config);
  }
  if (action.payload.prematchConflicts !== null) {
    processPrematchConflicts(nextState, action.payload.prematchConflicts);
  }
  if (action.payload.inplayConflicts !== null) {
    processInPlayConflicts(nextState, action.payload.inplayConflicts);
  }

  if (action.payload.prematchSubfeedsUpdate !== null) {
    nextState.bet_supermarket = FeedMergeUtils.mergeDataFeedUpdate(
      nextState.bet_supermarket,
      action.payload.prematchSubfeedsUpdate,
    );
    nextState.proposals = FeedMergeUtils.mapPublicUpdatesToProposalMap(
      nextState.proposals,
      nextState.shopping_cart_ui.isTicketProcessing,
      nextState.shopping_cart.items,
      action.payload.prematchSubfeedsUpdate,
    );
  }
  if (action.payload.inplaySubfeedsUpdate !== null) {
    nextState.bet_supermarket = FeedMergeUtils.mergeDataFeedUpdate(
      nextState.bet_supermarket,
      action.payload.inplaySubfeedsUpdate,
    );
    nextState.proposals = FeedMergeUtils.mapPublicUpdatesToProposalMap(
      nextState.proposals,
      nextState.shopping_cart_ui.isTicketProcessing,
      nextState.shopping_cart.items,
      action.payload.inplaySubfeedsUpdate,
    );
  }

  return nextState;
};

const processHeraldSubfeedsUpdateAction = (
  state: ICoreState,
  action: CoreHeraldSubfeedsUpdateAction,
): ICoreState => {
  const nextState = { ...state };

  if (action.payload.prematch_conflicts !== null) {
    processPrematchConflicts(nextState, action.payload.prematch_conflicts);
  }

  if (action.payload.inplay_conflicts !== null) {
    processInPlayConflicts(nextState, action.payload.inplay_conflicts);
  }

  if (action.payload.prematch_subfeeds_update !== null) {
    nextState.bet_supermarket = FeedMergeUtils.mergeDataFeedUpdate(
      nextState.bet_supermarket,
      action.payload.prematch_subfeeds_update,
    );
    nextState.proposals = FeedMergeUtils.mapPublicUpdatesToProposalMap(
      nextState.proposals,
      nextState.shopping_cart_ui.isTicketProcessing,
      nextState.shopping_cart.items,
      action.payload.prematch_subfeeds_update,
    );
  }

  if (action.payload.inplay_subfeeds_update !== null) {
    nextState.bet_supermarket = FeedMergeUtils.mergeDataFeedUpdate(
      nextState.bet_supermarket,
      action.payload.inplay_subfeeds_update,
    );
    nextState.proposals = FeedMergeUtils.mapPublicUpdatesToProposalMap(
      nextState.proposals,
      nextState.shopping_cart_ui.isTicketProcessing,
      nextState.shopping_cart.items,
      action.payload.inplay_subfeeds_update,
    );
  }

  return nextState;
};

const processGetLastTransactions = (
  state: ICoreState,
  action: CoreGetLastTransactionsAction,
): ICoreState => ({
  ...state,
  lastTransactionsReport: {
    transactions: action.payload.transactions,
    unfinishedOrders: action.payload.unfinished_orders,
  },
});

const processGetPaymentTokens = (
  state: ICoreState,
  action: CoreGetPaymentTokensAction,
): ICoreState => ({ ...state, paymentTokens: action.payload });

const processGetEventStats = (
  state: ICoreState,
  action: CoreGetEventStatsAction,
): ICoreState => ({
  ...state,
  eventStats: {
    ...state.eventStats,
    [action.payload.stats_event.info.event_fkey]: action.payload,
  },
});

const processTicketProcessingState = (
  state: ICoreState,
  action: CoreSetTicketProcessingStateAction,
): ICoreState => ({
  ...state,
  shopping_cart_ui: {
    ...state.shopping_cart_ui,
    isTicketProcessing: action.payload,
  },
});

const processSetIsAltPropButtonEnabled = (
  state: ICoreState,
  action: CoreSetIsAltPropButtonEnabledAction,
): ICoreState => ({
  ...state,
  isAltPropButtonEnabled: action.payload,
});

export default (state = initialState, action: TAnyAlias): ICoreState => {
  switch (action.type) {
    case CoreStateAction.type:
      return processSportsBookStateAction(state, action);
    case CoreUpdateCurrencyMode.type:
      return processSportsBookShoppingCartUIOptionsChangeAction(state, action);
    case CoreUpdateCartItemAmountsAction.type:
      return processSportsBookUpdateCartItemAmountsActionStraight(
        state,
        action,
      );
    case CoreUpdateCartItemAction.type:
      return processSportsBookUpdateCartItemAction(state, action);
    case CoreCopyPickAction.type:
      return processSportsBookCopyPickAction(state, action);
    case CoreCopyCartAction.type:
      return processSportsBookCopyCartAction(state, action);
    case CoreUpdateTicketPickTypeAction.type:
      return processUpdatePickType(state, action);
    case CoreUpdateTicketParlayRiskAmountAction.type:
      return processSportsBookUpdateCartItemAmountsActionParlay(
        state,
        action.payload,
      );
    case CoreUpdateSGPCoeffSuccessAction.type:
      return processUpdateSGPCoeffSuccess(state, action);
    case CoreInjectProtocolSlotsAction.type:
      return processInjectedSlots(state, action);
    case CoreGetPaymentTokensAction.type:
      return processGetPaymentTokens(state, action);
    case CoreGetEventStatsAction.type:
      return processGetEventStats(state, action);
    case CoreSetTicketProcessingStateAction.type:
      return processTicketProcessingState(state, action);
    case CoreHeraldSubfeedsUpdateAction.type:
      return processHeraldSubfeedsUpdateAction(state, action);
    case CoreGetLastTransactionsAction.type:
      return processGetLastTransactions(state, action);
    case CoreSetIsAltPropButtonEnabledAction.type:
      return processSetIsAltPropButtonEnabled(state, action);
    default:
      return state;
  }
};
