import dayjs from "dayjs";
import React, { useEffect, useRef, useState, useCallback } from "react";
import Calendar from "components/atoms/common/Calendar";
import DateFormatters from "utils/DateFormatters";
import ValidationStateMark from "../ValidationStateMark";
import "./styles.scss";
import { IProps } from "./types";
import classNames from "classnames";

const DatePick = ({
  validationState,
  onChangeText,
  placeholder,
  onBlur,
}: IProps) => {
  const datePickerRef = useRef<HTMLDivElement>(null);
  const [isCalendarVisible, setCalendarVisibility] = useState(false);
  const calendarValue = React.useMemo(() => {
    return validationState.value ? new Date(validationState.value) : new Date();
  }, [validationState]);

  const handleDateChange = (date: Date) => {
    const value = DateFormatters.formatRegularVisualDate(dayjs(date).valueOf());
    onChangeText(value);
    handleToggleCalendar();
  };

  const handleToggleCalendar = (): void => {
    setCalendarVisibility(prev => !prev);
  };
  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setCalendarVisibility(false);

        if (onBlur) {
          onBlur();
        }
      }
    },
    [onBlur],
  );

  const isPlaceholder = React.useMemo(() => {
    return validationState.value === "";
  }, [validationState.value]);

  const inputClasses = classNames("input-container-regular", {
    "input-container-regular--invalid": validationState.state === 0,
  });

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchend", handleClickOutside);
    };
  }, [handleClickOutside, isCalendarVisible]);

  return (
    <div className={"date-pick-container"} ref={datePickerRef}>
      <div
        className={`my-picks-calendar-container-mobile ${
          isCalendarVisible ? "my-picks-calendar-container-mobile-open" : ""
        }`}>
        <Calendar
          detail={"century"}
          value={calendarValue}
          minDate={new Date("1900-01-01")}
          onDateChange={() => {}}
          onClickDay={handleDateChange}
        />
      </div>
      <div className={inputClasses} onClick={handleToggleCalendar}>
        <span className={`${isPlaceholder ? "date-input-placeholder" : ""}`}>
          {validationState.value || placeholder}
        </span>
        <ValidationStateMark validationState={validationState} />
      </div>
    </div>
  );
};

export default React.memo(DatePick);
