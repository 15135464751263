import Button from "components/atoms_new/desktop/Button";
import { IModalControls } from "components/atoms_new/desktop/Modals/AuthenticationModal/types";
import TextLink from "components/atoms_new/desktop/TextLink";
import Inputs from "components/atoms_new/shared/Inputs";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import { ToastMessageModalService } from "context/desktop/ToastService";
import useTextInput from "hooks/useTextInput";
import React from "react";
import { SportsBookCommonBIF } from "server/legacyCore/api/SportsBookCommonBIF";
import FormValidationUtils from "utils/FormValidationUtils";
import "./styles.scss";

const ForgotPassword = React.forwardRef<HTMLDivElement, IModalControls>(
  ({ openPrevious, onClose }, forwardedRef) => {
    const validateEmail = () =>
      FormValidationUtils.validateEmail(emailValidationState.value);

    const [emailValidationState, handleEmailChange, handleEmailBlur] =
      useTextInput<string>("", validateEmail);

    const onResetForgottenPassword = async () => {
      const opres = await SportsBookCommonBIF.blockingResetForgottenPassword(
        emailValidationState.value.trim(),
      );
      if (opres.is_error) {
        return;
      }
      ToastMessageModalService.showToast(
        "success",
        "Forgot Password Success",
        "Instructions to reset your password have been sent to your email address.",
      );

      return;
    };

    const handleResetForgottenPassword = () => {
      const { isValid: isValidEmail } = handleEmailBlur();
      if (!isValidEmail) {
        return;
      }
      onResetForgottenPassword();
    };

    return (
      <div className={"forgot-password"} ref={forwardedRef}>
        <ModalHeader
          title={"Forgot Password"}
          onClose={onClose}
          onGoBack={openPrevious}
        />

        <p className="forgot-password__description">
          Please enter the email address associated with your account. We’ll
          send you an email with instructions on how to reset your password.
        </p>

        <Inputs.Label htmlFor="email" label="Email" />
        <Inputs.RegularText
          type={"email"}
          onChangeText={handleEmailChange}
          onBlur={handleEmailBlur}
          validationState={emailValidationState}
          placeholder={"your.email@email.com"}
          id="email"
          theme="dark"
        />
        <div className={"forgot-password__footer"}>
          <span className={"forgot-password__label"}>Return to</span>
          <TextLink label={"Login"} onClick={openPrevious} />
        </div>
        <Button label={"Submit"} onClick={handleResetForgottenPassword} />
      </div>
    );
  },
);

ForgotPassword.displayName = "ForgotPassword";

export default ForgotPassword;
