import React, { forwardRef, useImperativeHandle, useRef } from "react";
import AuthenticationModal from "components/atoms_new/desktop/Modals/AuthenticationModal";
import {
  IAuthModalHandle,
  ICustomModalProps,
  TModalType,
} from "components/atoms_new/desktop/Modals/AuthenticationModal/types";

const authModalRef = React.createRef<IAuthModalHandle>();

export const AuthenticationModalService = {
  openAuthModal: (type: TModalType, props?: ICustomModalProps) =>
    authModalRef.current?.openAuthModal(type, props),
  authModalRef,
};

const AuthenticationModals = forwardRef<IAuthModalHandle>((_props, ref) => {
  const modalRef = useRef<IAuthModalHandle>(null);

  useImperativeHandle(ref, () => ({
    openAuthModal: (type: TModalType, props?: ICustomModalProps) => {
      modalRef.current?.openAuthModal(type, props);
    },
  }));

  return <AuthenticationModal ref={modalRef} />;
});

AuthenticationModals.displayName = "AuthenticationModals";

export default AuthenticationModals;
