import { IAppUIState } from "./types";

export const initialState: IAppUIState = {
  appUiMode: undefined,
  isModalProgressIndicatorVisible: false,
  modalProgressIndicatorMode: 0,
  isModalDialogVisible: false,
  modalDialogMessage: "",
  modalDialogTitle: "",
  modalDialogAutoExitOnOk: false,
  onOkCallback: () => ({}),
};
