import AppConfig from "utils/AppConfig";

import { FliffException } from "server/legacyCore/FliffException";
import {
  DefaultBIFResponse,
  DataBIFResponse,
} from "server/legacyCore/objectsLegacyCore";

import { MemoryStorage } from "utils/MemoryStorage";
import {
  AppUIHideModalProgressIndicator,
  AppUIShowModalDialogAction,
  AppUIShowModalProgressIndicator,
} from "reduxLocal/appUI/appUI.actions";
import { staticGetState } from "reduxLocal/store";
import { TAnyAlias } from "src/types";
import { CoreSetTicketProcessingStateAction } from "reduxLocal/core/core.actions";

/*
2019-11-03 / Ivan / BIF is just a random suffix taken from first google search result for 'mixing ui with network requests'
https://medium.com/hackernoon/frontend-in-the-backend-a-pattern-for-cleaner-code-b497c92d0b49

I don't want to use overused terms like 'controller' etc here
*/

export class AbstractBIF {
  private static readonly _disabledErrorModalOperationNames = ["get_sgp_coeff"];
  protected static async common_blocking_operation<
    T extends DefaultBIFResponse,
  >(
    operationName: string,
    operationImpl: () => Promise<T>,
    should_unblock_layout: boolean,
  ): Promise<T | DefaultBIFResponse> {
    return await AbstractBIF.common_x_blocking_operation(
      AppConfig.modalProgressBarDefaultMode,
      0,
      operationName,
      operationImpl,
      should_unblock_layout,
    );
  }

  /*
  2019-12-03 / Ivan / temporary using some stupid constants for errorDialogMode
  errorDialogMode = 707 - show standard error dialog only if not network error
  errorDialogMode = other - show standard error dialog always
  */
  protected static async common_x_blocking_operation<
    T extends DefaultBIFResponse,
  >(
    modalProgressBarMode: number,
    errorDialogMode: number,
    operationName: string,
    operationImpl: () => Promise<T>,
    should_unblock_layout: boolean,
    operationCode = -1,
  ): Promise<T | DefaultBIFResponse> {
    try {
      // 2021-08-08 / Ivan / introduce basic protection from double click - allow ignore requests if blocking operation is in progress
      MemoryStorage.is_blocking_operation_in_progress = true;

      // block UI - show top level modal progress bar
      AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
        modalProgressBarMode,
      );

      // 2019-11-24 / Ivan / introduced new mechanism for passing response info to caller
      let responseObj: T | DefaultBIFResponse = await operationImpl();

      if (!responseObj) {
        responseObj = DefaultBIFResponse.create_for_resultCode(-999);
      }

      // unblock UI - hide top level modal progress bar
      if (should_unblock_layout) {
        AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();
      }

      // 2021-08-08 / Ivan / introduce basic protection from double click - allow ignore requests if blocking operation is in progress
      MemoryStorage.is_blocking_operation_in_progress = false;

      return responseObj;
    } catch (error: TAnyAlias) {
      console.warn(" [" + operationName + "] error", error);
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();

      let shouldShowExceptionModal = false;

      if (errorDialogMode === 838) {
        // 2021-04-06 / Ivan / dont display error dialog / another stupid constant
      } else if (
        errorDialogMode === 707 &&
        FliffException.isNetworkError(error)
      ) {
        // 2019-12-03 / Ivan / in case of network error, caller is in charge to display branded 'retry' dialog
        // used by notifications (claim airdrop) dialog
      } else {
        shouldShowExceptionModal = AbstractBIF._shouldShowExceptionModal(
          error.error_code,
          operationName,
        );
      }
      if (shouldShowExceptionModal) {
        AppUIShowModalDialogAction.dispatchShowErrorDialogForException(
          error,
          operationName,
        );
      } else {
        // 2021-08-08 / Ivan / introduce basic protection from double click - allow ignore requests if blocking operation is in progress
        MemoryStorage.is_blocking_operation_in_progress = false;
      }

      if (
        staticGetState().sportsBook.shopping_cart_ui.isTicketProcessing &&
        operationName === "place_pick" &&
        operationCode === 21
      ) {
        CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(
          false,
        );
      }

      return DefaultBIFResponse.create_for_exception(error);
    }
  }

  /*
  2020-05-09 / Ivan / experiment - try to parametrize the response in order to allow us to return just anything to caller

  2019-12-03 / Ivan / temporary using some stupid constants for errorDialogMode
  errorDialogMode = 707 - show standard error dialog only if not network error
  errorDialogMode = other - show standard error dialog always
  */
  protected static async common_y_blocking_operation<T>(
    modalProgressBarMode: number,
    errorDialogMode: number,
    operationName: string,
    operationImpl: () => Promise<DataBIFResponse<T>>,
    operationCode = -1,
  ): Promise<DataBIFResponse<T>> {
    try {
      // 2021-08-08 / Ivan / introduce basic protection from double click - allow ignore requests if blocking operation is in progress
      MemoryStorage.is_blocking_operation_in_progress = true;

      // block UI - show top level modal progress bar
      AppUIShowModalProgressIndicator.dispatchShowModalProgressIndicator(
        modalProgressBarMode,
      );

      // 2019-11-24 / Ivan / introduced new mechanism for passing response info to caller
      const responseObj: DataBIFResponse<T> = await operationImpl();

      // need to fix the verification check
      if (!responseObj) {
        throw new Error(
          " unexpected (!responseObj) in common_y_blocking_operation",
        );
      }

      // unblock UI - hide top level modal progress bar
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();

      // 2021-08-08 / Ivan / introduce basic protection from double click - allow ignore requests if blocking operation is in progress
      MemoryStorage.is_blocking_operation_in_progress = false;

      return responseObj;
    } catch (error: TAnyAlias) {
      console.warn(" [" + operationName + "] error", error);
      AppUIHideModalProgressIndicator.dispatchHideModalProgressIndicator();

      let shouldShowExceptionModal = false;

      if (errorDialogMode === 838) {
        // 2021-04-06 / Ivan / dont display error dialog / another stupid constant
      } else if (
        errorDialogMode === 707 &&
        FliffException.isNetworkError(error)
      ) {
        // 2019-12-03 / Ivan / in case of network error, caller is in charge to display branded 'retry' dialog
        // used by notifications (claim airdrop) dialog
      } else {
        shouldShowExceptionModal = AbstractBIF._shouldShowExceptionModal(
          error.error_code,
          operationName,
        );
      }
      if (shouldShowExceptionModal) {
        AppUIShowModalDialogAction.dispatchShowErrorDialogForException(
          error,
          operationName,
        );
      } else {
        // 2021-08-08 / Ivan / introduce basic protection from double click - allow ignore requests if blocking operation is in progress
        MemoryStorage.is_blocking_operation_in_progress = false;
      }

      if (
        staticGetState().sportsBook.shopping_cart_ui.isTicketProcessing &&
        operationName === "place_pick" &&
        operationCode === 21
      ) {
        CoreSetTicketProcessingStateAction.dispatchSetTicketProcessingState(
          false,
        );
      }

      return DataBIFResponse.create_for_exception(error);
    }
  }

  private static _shouldShowExceptionModal = (
    errorCode: number,
    operationName: string,
  ): boolean => {
    if (AbstractBIF._disabledErrorModalOperationNames.includes(operationName)) {
      return false;
    }
    const { error_codes_without_modal_error } =
      staticGetState().sportsBook.config;

    return !error_codes_without_modal_error.includes(errorCode);
  };
}
