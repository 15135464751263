import { initialState } from "./app.initialState";
import { AppSetUserAccountAction } from "./app.actions";

import { TAction } from "./types";

const appReducer = (state = initialState, action: TAction) => {
  switch (action.type) {
    case AppSetUserAccountAction.type:
      return {
        ...state,
        ...(action.payload.stempUserAccount && {
          tempUserAccount: action.payload.stempUserAccount,
        }),
        authenticated: action.payload.shouldSetAuthenticatedFlag,
      };
    default:
      return state;
  }
};

export default appReducer;
