import { ValidationUtils } from "server/legacyCore/ValidationUtils";
import {
  Data__SB_FeedDiff_Packed_Shelf_Update,
  Data__SB_FeedDiff_Shelf_Update,
  Data__SB_SportEvent,
  TBetMarket,
} from "server/legacyCore/data/objects";
import {
  AdapterHelper,
  Adapter__SB_FeedDiffItem_Abstract,
} from "server/legacyCore/data/server_adapters";
import { validate_TDiffUpdateType } from "server/legacyCore/data/server_enum_helpers";
import {
  IFliffError,
  IFliffMessage,
  IFliffProtocolResponse,
  IFliffProtocolResponseHeader,
  IFliffResponse,
  TFliffResult,
} from "server/sharedCore/data/objects";
import { TAnyAlias } from "src/types";

export class AuthTokensResponse {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  scope: string;
  expiresIn: number;

  public static decode(jsonData: TAnyAlias): AuthTokensResponse {
    ValidationUtils.assert_non_null_data(jsonData, "jsonData");

    return {
      accessToken: ValidationUtils.assert_non_empty_string(
        jsonData.access_token,
        "access_token",
      ),
      refreshToken: ValidationUtils.assert_non_empty_string(
        jsonData.refresh_token,
        "refresh_token",
      ),
      tokenType: ValidationUtils.assert_non_empty_string(
        jsonData.token_type,
        "token_type",
      ),
      scope: ValidationUtils.assert_non_empty_string(jsonData.scope, "scope"),
      expiresIn: ValidationUtils.assert_positive_integer(
        jsonData.expires_in,
        "expires_in",
      ),
    };
  }
}

export class AdapterFliffError {
  public static decode(jsonData: TAnyAlias, debugInfo: string): IFliffError {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      errorCode: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.error_code,
        debugInfo + ".error_code",
      ),
      errorMessage: ValidationUtils.assert_non_empty_string(
        jsonData.error_message,
        debugInfo + ".error_message",
      ),
      errorSource: null,
      incidentTag: ValidationUtils.assert_optional_string(
        jsonData.incident_tag,
        debugInfo + ".incident_tag",
      ),
    };
  }
}

export class AdapterFliffResult {
  public static decode<Response extends IFliffResponse>(
    jsonData: TAnyAlias,
    debugInfo: string,
    messageDecoder: (
      nextJsonData: TAnyAlias,
      nextDebugInfo: string,
    ) => IFliffMessage,
  ): TFliffResult<Response> {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      error: AdapterHelper.null_or_decode<IFliffError>(
        AdapterFliffError.decode,
        jsonData.error,
        debugInfo + ".error",
      ),
      response: AdapterHelper.null_or_decode<IFliffResponse>(
        messageDecoder,
        jsonData.response,
        debugInfo + ".response",
      ),
    } as TFliffResult<Response>;
  }
}

export class AdapterFliffProtocolResponseHeader {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): IFliffProtocolResponseHeader {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      server_instance_id: ValidationUtils.assert_non_empty_string(
        jsonData.server_instance_id,
        debugInfo + ".server_instance_id",
      ),
      server_stamp_millis: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.server_stamp_millis,
        debugInfo + ".server_stamp_millis",
      ),
      build_version: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.build_version,
        debugInfo + ".build_version",
      ),
    };
  }
}

export class AdapterFliffProtocolResponse {
  public static decode<ResponseSlots, Response extends IFliffResponse>(
    jsonData: TAnyAlias,
    debugInfo: string,
    responseSlotsDecoder: (
      nextJsonData: TAnyAlias,
      nextDebugInfo: string,
    ) => ResponseSlots,
    messageDecoder: (
      nextJsonData: TAnyAlias,
      nextDebugInfo: string,
    ) => IFliffMessage,
  ): IFliffProtocolResponse<ResponseSlots, Response> {
    return {
      header: AdapterFliffProtocolResponseHeader.decode(
        jsonData.header,
        debugInfo + ".header",
      ),
      result: AdapterFliffResult.decode<Response>(
        jsonData.result,
        debugInfo + ".result",
        messageDecoder,
      ),
      x_results: null,
      x_slots: AdapterHelper.null_or_decode(
        responseSlotsDecoder,
        jsonData.x_slots,
        debugInfo + ".x_slots",
      ),
    };
  }
}

export class AdapterEvent {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_SportEvent {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    const conflictFkey = ValidationUtils.assert_non_empty_string(
      jsonData.conflict_fkey,
      debugInfo + ".conflict_fkey",
    );
    const isLive = conflictFkey.endsWith("inplay");

    const filterIds = AdapterHelper.decode_array_of_integers(
      jsonData.filter_ids,
      debugInfo + ".filter_ids",
    );

    // 2022-01-20 / Ivan / introduce display mode per event - will be used to fine tune the game card
    let displayMode = 0;
    //TODO: remove this.
    // ugly magic constant for BOOSTED_OFFERS_CHANNELS_FOR_ROOKIES
    if (filterIds.includes(-801)) {
      displayMode = 1;
    }

    return {
      conflict_fkey: conflictFkey,
      channelId: ValidationUtils.assert_positive_integer(
        jsonData.channel_id,
        debugInfo + ".channel_id",
      ),
      event_start_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsonData.event_start_timestamp_utc,
        debugInfo + ".event_start_timestamp_utc",
      ),
      homeTeamName: ValidationUtils.assert_non_empty_string(
        jsonData.home_team_name,
        debugInfo + ".home_team_name",
      ),
      awayTeamName: ValidationUtils.assert_non_empty_string(
        jsonData.away_team_name,
        debugInfo + ".away_team_name",
      ),
      isLive,
      live_state_desc: ValidationUtils.assert_optional_string(
        jsonData.live_state_desc,
        debugInfo + ".live_state_desc",
      ),
      live_score_home: ValidationUtils.assert_any_integer(
        jsonData.live_home_score,
        debugInfo + ".live_home_score",
      ),
      live_score_away: ValidationUtils.assert_any_integer(
        jsonData.live_away_score,
        debugInfo + ".live_away_score",
      ),
      live_x_score_home: ValidationUtils.assert_optional_string(
        jsonData.live_x_score_home,
        debugInfo + ".live_x_score_home",
      ),
      live_x_score_away: ValidationUtils.assert_optional_string(
        jsonData.live_x_score_away,
        debugInfo + ".live_x_score_away",
      ),
      event_note: ValidationUtils.assert_optional_string(
        jsonData.event_note,
        debugInfo + ".event_note",
      ),
      event_watch_url: ValidationUtils.assert_optional_string(
        jsonData.event_watch_url,
        debugInfo + ".event_watch_url",
      ),
      filter_ids: filterIds,
      displayMode: displayMode,
      estimated_markets_count: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.estimated_markets_count,
        debugInfo + ".estimated_markets_count",
      ),
      expect_live_betting: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.expect_live_betting,
        debugInfo + ".expect_live_betting",
      ),
      live_status: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.live_status,
        debugInfo + ".live_status",
      ),
      conflict_class_code: ValidationUtils.assert_positive_integer(
        jsonData.conflict_class_code,
        debugInfo + ".conflict_class_code",
      ),
      pcats: AdapterHelper.decode_array_of_integers(
        jsonData.pcats,
        debugInfo + ".pcats",
      ),
      weight: ValidationUtils.assert_positive_integer(
        jsonData.weight,
        debugInfo + ".weight",
      ),
    };
  }
}

export class AdapterFeedDiffPackedShelfUpdate {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiff_Packed_Shelf_Update {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      subfeed_code: ValidationUtils.assert_any_integer(
        jsonData.subfeed_code,
        debugInfo + ".subfeed_code",
      ),
      revision_id: ValidationUtils.assert_any_integer(
        jsonData.revision_id,
        debugInfo + ".revision_id",
      ),
      revision_code: ValidationUtils.assert_non_empty_string(
        jsonData.revision_code,
        debugInfo + ".revision_code",
      ),
      update_type: validate_TDiffUpdateType(
        jsonData.update_type,
        debugInfo + ".update_type",
      ),
      diff_data: AdapterHelper.decode_array<TBetMarket>(
        Adapter__SB_FeedDiffItem_Abstract.decode,
        jsonData.market_updates,
        debugInfo + ".market_updates",
      ),
      conflict_fkeys: AdapterHelper.decode_array_of_strings(
        jsonData.conflict_fkeys,
        debugInfo + ".conflict_fkeys",
      ),
    };
  }
}

export class AdapterFeedDiffUpdate {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): Data__SB_FeedDiff_Shelf_Update[] {
    const packedSubfeedUpdates =
      AdapterHelper.null_or_decode_array<Data__SB_FeedDiff_Packed_Shelf_Update>(
        AdapterFeedDiffPackedShelfUpdate.decode,
        jsonData,
        debugInfo,
      );
    if (!packedSubfeedUpdates) {
      return [];
    }

    const updates: Data__SB_FeedDiff_Shelf_Update[] = [];

    for (const shelfUpdate of packedSubfeedUpdates) {
      for (const conflictFkey of shelfUpdate.conflict_fkeys) {
        updates.push({
          update_type: shelfUpdate.update_type,
          revision_code: shelfUpdate.revision_code,
          subfeed_code: shelfUpdate.subfeed_code,
          revision_id: shelfUpdate.revision_id,
          diff_data: shelfUpdate.diff_data,
          conflict_fkey: conflictFkey,
        });
      }
    }

    return updates;
  }
}
