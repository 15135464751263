import { TransactionType } from "server/core/data/constants";
import * as I from "server/core/data/objects";
import {
  validatePaymentMethodInstrumentStatus,
  validatePaymentMethodInstrumentType,
  validatePodCreditableState,
  validatePodPlayableState,
  validatePodProgressState,
  validateTransactionType,
} from "server/core/server/impl/serverEnumHelpers";
import { ValidationUtils } from "server/legacyCore/ValidationUtils";
import {
  Data__SB_Config,
  Data__SB_SportEvent,
  Data__SB_UserProfile,
  Data__SB_X_Delta_Profile,
  Data__Slide_User_Gift_Card,
} from "server/legacyCore/data/objects";
import {
  AdapterHelper,
  Adapter__SB_Config,
  Adapter__SB_Unfinished_Order,
  Adapter__SB_UserProfile,
  Adapter__SB_X_Delta_Profile,
  Adapter__Slide_User_Gift_Card,
} from "server/legacyCore/data/server_adapters";
import { Data__SB_Unfinished_Order } from "server/sharedCore/data/objects";
import {
  AdapterEvent,
  AdapterFeedDiffUpdate,
} from "server/sharedCore/data/serverAdapters";
import { TAnyAlias } from "src/types";

class AdapterFCMPingResponseData {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPingResponse["data"] {
    return {
      min_app_build: ValidationUtils.assert_positive_integer(
        jsonData.min_app_build,
        debugInfo + ".min_app_build",
      ),
      server_build: ValidationUtils.assert_positive_integer(
        jsonData.server_build,
        debugInfo + ".server_build",
      ),
      server_timestamp: ValidationUtils.assert_positive_integer(
        jsonData.server_timestamp,
        debugInfo + ".server_timestamp",
      ),
      server_instance_info: ValidationUtils.assert_non_empty_string(
        jsonData.server_instance_info,
        debugInfo + ".server_instance_info",
      ),
      usa_state_restrictions: AdapterHelper.decode_array(
        AdapterUSAStateRestrictions.decode,
        jsonData.usa_state_restrictions,
        debugInfo + ".usa_state_restrictions",
      ),
      core_sever_public_endpoint: ValidationUtils.assert_optional_string(
        jsonData.core_sever_public_endpoint,
        ".core_sever_public_endpoint",
      ),
      core_sever_private_endpoint: ValidationUtils.assert_optional_string(
        jsonData.core_sever_private_endpoint,
        ".core_sever_private_endpoint",
      ),
      proposals_feed_endpoint: ValidationUtils.assert_optional_string(
        jsonData.proposals_feed_endpoint,
        ".proposals_feed_endpoint",
      ),
      device_location_vendor: ValidationUtils.assert_non_empty_string(
        jsonData.device_location_vendor,
        debugInfo + ".device_location_vendor",
      ),
      is_alt_prop_button_enabled: ValidationUtils.assert_boolean(
        jsonData.is_alt_prop_button_enabled,
        debugInfo + ".is_alt_prop_button_enabled",
      ),
    };
  }
}

export class AdapterFCMPingResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPingResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Ping__Response",
      data: AdapterFCMPingResponseData.decode(
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class AdapterUSAStateRestrictions {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IUSAStateRestriction {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      restriction_code: ValidationUtils.assert_positive_integer(
        jsonData.restriction_code,
        debugInfo + ".restriction_code",
      ),
      restriction_message: ValidationUtils.assert_non_empty_string(
        jsonData.restriction_message,
        debugInfo + ".restriction_message",
      ),
      usa_state_code: ValidationUtils.assert_non_empty_string(
        jsonData.usa_state_code,
        debugInfo + ".usa_state_code",
      ),
    };
  }
}

export class AdapterObtainBundleResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMObtainBundleResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return { __object_class_name: "FCM__Obtain_Bundle__Response" };
  }
}

class AdapterBundleContext {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IBundleContext {
    return {
      usa_state_code: ValidationUtils.assert_optional_string(
        jsonData.usa_state_code,
        debugInfo + ".usa_state_code",
      ),
      last_claim_stamp_sec: ValidationUtils.assert_any_integer(
        jsonData.last_claim_stamp_sec,
        debugInfo + ".last_claim_stamp_sec",
      ),
      tags: AdapterHelper.decode_array_of_strings(
        jsonData.tags,
        debugInfo + ".tags",
      ),
      user_total_fliff_cash: ValidationUtils.assert_any_integer(
        jsonData.user_total_fliff_cash,
        debugInfo + ".user_total_fliff_cash",
      ),
      user_total_usd_spent: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.user_total_usd_spent,
        debugInfo + ".user_total_usd_spent",
      ),
    };
  }
}

class AdapterBundleGoods {
  public static decode(jsonData: TAnyAlias, debugInfo: string): I.IBundleGoods {
    return {
      bundle_context: AdapterBundleContext.decode(
        jsonData.bundle_context,
        debugInfo + ".bundle_context",
      ),
      amount_fliff_cash: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.amount_fliff_cash,
        debugInfo + ".amount_fliff_cash",
      ),
      bundle_code: ValidationUtils.assert_non_empty_string(
        jsonData.bundle_code,
        debugInfo + ".bundle_code",
      ),
      price_in_cents: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.price_in_cents,
        debugInfo + ".price_in_cents",
      ),
      amount_social_coins: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.amount_social_coins,
        debugInfo + ".amount_social_coins",
      ),
      applied_tags: AdapterHelper.decode_array_of_strings(
        jsonData.applied_tags,
        debugInfo + ".applied_tags",
      ),
      multiplier_in_percents: ValidationUtils.assert_any_integer(
        jsonData.multiplier_in_percents,
        debugInfo + ".multiplier_in_percents",
      ),
    };
  }
}

export class AdapterInitiateBundleResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IInitiateBundlePurchaseResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Initiate_Bundle_Purchase__Response",
      initiate_url: ValidationUtils.assert_non_empty_string(
        jsonData.initiate_url,
        debugInfo + ".initiate_url",
      ),
      v9_bundle_goods_2_initiated: AdapterBundleGoods.decode(
        jsonData.v9_bundle_goods_2_initiated,
        debugInfo + ".v9_bundle_goods_2_initiated",
      ),
      own_invoice_ref_code: ValidationUtils.assert_optional_string(
        jsonData.own_invoice_ref_code || "",
        debugInfo + ".own_invoice_ref_code",
      ),
      own_account_ref_code: ValidationUtils.assert_optional_string(
        jsonData.own_account_ref_code || "",
        debugInfo + ".own_account_ref_code",
      ),
      own_invoice_id: ValidationUtils.assert_any_integer(
        jsonData.own_invoice_id || 0,
        debugInfo + ".own_invoice_id",
      ),
    };
  }
}

export class AdapterFCMProtocolResponseSlots {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMProtocolResponseSlots {
    return {
      config: AdapterHelper.null_or_decode<Data__SB_Config>(
        Adapter__SB_Config.decode,
        jsonData.sb_config,
        debugInfo + ".sb_config",
      ),
      profile: AdapterHelper.null_or_decode<Data__SB_UserProfile>(
        Adapter__SB_UserProfile.decode,
        jsonData.sb_user_profile,
        debugInfo + ".sb_user_profile",
      ),
      deltaProfile: AdapterHelper.null_or_decode<Data__SB_X_Delta_Profile>(
        Adapter__SB_X_Delta_Profile.decode,
        jsonData.sb_delta_user_profile,
        debugInfo + ".sb_delta_user_profile",
      ),
      inplayConflicts: AdapterHelper.null_or_decode_array<Data__SB_SportEvent>(
        AdapterEvent.decode,
        jsonData.active_inplay_conflicts,
        debugInfo + ".active_inplay_conflicts",
      ),
      prematchConflicts:
        AdapterHelper.null_or_decode_array<Data__SB_SportEvent>(
          AdapterEvent.decode,
          jsonData.active_prematch_conflicts,
          debugInfo + ".active_prematch_conflicts",
        ),
      prematchSubfeedsUpdate: AdapterFeedDiffUpdate.decode(
        jsonData.prematch_subfeeds_updates,
        debugInfo + ".prematch_subfeeds_updates",
      ),
      inplaySubfeedsUpdate: AdapterFeedDiffUpdate.decode(
        jsonData.inplay_subfeeds_updates,
        debugInfo + ".inplay_subfeeds_updates",
      ),
    };
  }
}

export class AdapterFCMRefreshUserProfileResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMRefreshUserProfileResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return { __object_class_name: "FCM__Refresh_User_Profile__Response" };
  }
}

export class AdapterFCMClaimFreeBundleResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMClaimFreeBundleResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return { __object_class_name: "FCM__Claim_Free_Bundle__Response" };
  }
}

export class AdapterFCMReferralReportItem {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IReferralReportItem {
    return {
      user_id: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.user_id,
        debugInfo + ".user_id",
      ),
      username: ValidationUtils.assert_non_empty_string(
        jsonData.username,
        debugInfo + ".username",
      ),
      rewards_cash_earned_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsonData.rewards_cash_earned_in_cents,
          debugInfo + ".rewards_cash_earned_in_cents",
        ),
    };
  }
}

export class AdapterFCMGetReferralReportResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetReferralReportResponse {
    return {
      __object_class_name: "FCM__Get_Referral_Report__Response",
      max_rewards_cash_per_user: ValidationUtils.assert_positive_integer(
        jsonData.max_rewards_cash_per_user,
        debugInfo + ".max_rewards_cash_per_user",
      ),
      total_rewards_cash_in_cents:
        ValidationUtils.assert_positive_integer_or_zero(
          jsonData.total_rewards_cash_in_cents,
          debugInfo + ".total_rewards_cash_in_cents",
        ),
      data: AdapterHelper.decode_array(
        AdapterFCMReferralReportItem.decode,
        jsonData.data,
        debugInfo + ".data",
      ),
    };
  }
}

export class AdapterFCMTransaction {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMTransaction {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);
    const type = validateTransactionType(jsonData.type, debugInfo + ".type");

    // 2021-03-01 / Ivan / all transactions of type 4062__SLIDE_GIFT_CARD_CASHBACK
    // will have attached info for user gift card
    let giftCard: Data__Slide_User_Gift_Card | null = null;

    if (
      type === TransactionType.CONST_4062_SLIDE_GIFT_CARD_CASHBACK ||
      type ===
        TransactionType.CONST_4063_SLIDE_GIFT_CARD_PURCHASE_WITHOUT_CASHBACK
    ) {
      giftCard = Adapter__Slide_User_Gift_Card.decode(
        jsonData.user_gift_card,
        debugInfo + ".user_gift_card",
      );
    }

    // 2021-03-01 / Ivan / all transactions of type CONST__4038__REFERRAL_PARENT_BONUS_FOR_REWARDS_CASH_PURCHASE_TOKENS_BUNDLE
    // will have attached info for referral source
    let referralData: I.IReferralReportItem | null = null;

    if (
      type ===
      TransactionType.CONST_4038_REFERRAL_PARENT_BONUS_FOR_REWARDS_CASH_PURCHASE_TOKENS_BUNDLE
    ) {
      referralData = AdapterFCMReferralReportItem.decode(
        jsonData.referral_data,
        debugInfo + ".referral_data",
      );
    }

    // 2021-05-20 / Ivan / we allow negative amount for some type of transactions
    let amountInCents: number;

    if (
      type ===
      TransactionType.CONST_4113_ADMIN_OPERATION_REWARDS_CASH_CORRECTION
    ) {
      amountInCents = ValidationUtils.assert_any_integer(
        jsonData.amount_in_cents,
        debugInfo + ".amount_in_cents",
      );
    } else {
      amountInCents = ValidationUtils.assert_positive_integer_or_zero(
        jsonData.amount_in_cents,
        debugInfo + ".amount_in_cents",
      );
    }

    return {
      id: ValidationUtils.assert_positive_integer(
        jsonData.id,
        debugInfo + ".id",
      ),
      type: type,
      created_timestamp_utc: ValidationUtils.assert_positive_integer(
        jsonData.created_timestamp_utc,
        debugInfo + ".created_timestamp_utc",
      ),
      // 2021-05-20 / Ivan / we allow negative amount for some type of transactions
      amount: amountInCents,
      obtained_coins: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.obtained_coins,
        debugInfo + ".obtained_coins",
      ),
      obtained_v2_tokens: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.obtained_v2_tokens,
        debugInfo + ".obtained_v2_tokens",
      ),
      obtained_fliff_cash: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.obtained_fliff_cash,
        debugInfo + ".obtained_fliff_cash",
      ),
      // 2021-06-25 / Ivan / moving to XP
      // 2021-06-26 / Ivan / may be negative - when you exchange for XP for data
      obtained_v3_xpoints: ValidationUtils.assert_any_integer(
        jsonData.obtained_v3_xpoints,
        debugInfo + ".obtained_v3_xpoints",
      ),
      // may be negative
      d_5004_rewards_cash: ValidationUtils.assert_any_integer(
        jsonData.d_5004_rewards_cash,
        debugInfo + ".d_5004_rewards_cash",
      ),
      user_gift_card: giftCard,
      referral_data: referralData,
    };
  }
}

export class AdapterFCMGetTransactionsReportResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetTransactionsReportResponse {
    return {
      __object_class_name: "FCM__Get_Transactions_Report__Response",
      transactions: AdapterHelper.decode_array<I.IFCMTransaction>(
        AdapterFCMTransaction.decode,
        jsonData.transactions,
        debugInfo + ".transactions",
      ),
      unfinished_orders: AdapterHelper.decode_array<Data__SB_Unfinished_Order>(
        Adapter__SB_Unfinished_Order.decode,
        jsonData.unfinished_orders,
        debugInfo + ".unfinished_orders",
      ),
    };
  }
}

export class AdapterFCMInitiateTOUSession {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMInitiateTOUSessionResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Initiate_TOU_Session__Response",
      tou_session_enter_url: ValidationUtils.assert_non_empty_string(
        jsonData.tou_session_enter_url,
        debugInfo + ".tou_session_enter_url",
      ),
    };
  }
}

class AdapterFCMPaymentMethodToken {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPaymentMethodToken {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      payment_token: ValidationUtils.assert_non_empty_string(
        jsonData.payment_token,
        debugInfo + ".payment_token",
      ),
      visual_name_1: ValidationUtils.assert_non_empty_string(
        jsonData.visual_name_1,
        debugInfo + ".visual_name_1",
      ),
      visual_name_2: ValidationUtils.assert_non_empty_string(
        jsonData.visual_name_2,
        debugInfo + ".visual_name_2",
      ),
      visual_name_3: ValidationUtils.assert_optional_string(
        jsonData.visual_name_3,
        debugInfo + ".visual_name_3",
      ),
      instrument_type: validatePaymentMethodInstrumentType(
        jsonData.instrument_type,
        debugInfo + ".instrument_type",
      ),
      instrument_status: validatePaymentMethodInstrumentStatus(
        jsonData.instrument_status,
        debugInfo + ".instrument_status",
      ),
    };
  }
}

export class AdaptersFCMGetPaymentTokensResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetPaymentTokensResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_All_Payment_Tokens__Response",
      payment_tokens: AdapterHelper.decode_array(
        AdapterFCMPaymentMethodToken.decode,
        jsonData.payment_tokens,
        debugInfo + ".payment_tokens",
      ),
    };
  }
}

export class AdaptersFCMDisablePaymentTokenResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMDisablePaymentTokenResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Disable_Payment_Token__Response",
      payment_tokens: AdapterHelper.decode_array(
        AdapterFCMPaymentMethodToken.decode,
        jsonData.payment_tokens,
        debugInfo + ".payment_tokens",
      ),
    };
  }
}

export class AdapterFCMPublicFeedSyncResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMPublicFeedSyncResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return { __object_class_name: "FCM__Public_Feed_Sync__Response" };
  }
}

export class AdapterFCMUploadMobileLogResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMUploadMobileLogResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return { __object_class_name: "FCM__Upload_Mobile_Log__Response" };
  }
}

export class AdaptersFCMGetContactUsPickSubject {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMContactUsPickSubject {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      type: ValidationUtils.assert_non_empty_string(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assert_non_empty_string(
        jsonData.name,
        debugInfo + ".name",
      ),
    };
  }
}

export class AdaptersFCMGetContactUsOptionSubject {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMContactUsOptionSubject {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      type: ValidationUtils.assert_non_empty_string(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assert_non_empty_string(
        jsonData.name,
        debugInfo + ".name",
      ),
    };
  }
}

export class AdaptersFCMGetContactUsSelectSubject {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFSMContactUsSelectSubject {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      type: ValidationUtils.assert_non_empty_string(
        jsonData.type,
        debugInfo + ".type",
      ),
      name: ValidationUtils.assert_non_empty_string(
        jsonData.name,
        debugInfo + ".name",
      ),
      subject_lines: AdaptersFCMGetContactUsSubjectLines.decode(
        jsonData.subject_lines,
        debugInfo + ".subject_lines",
      ),
    };
  }
}

export class AdaptersFCMGetContactUsSubjectLines {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.TFSMContactUsSubjectLine[] {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return AdapterHelper.decode_array_with_typed_items(
      {
        pickId: AdaptersFCMGetContactUsPickSubject.decode,
        option: AdaptersFCMGetContactUsOptionSubject.decode,
        select: AdaptersFCMGetContactUsSelectSubject.decode,
      },
      jsonData,
      debugInfo + ".subject_lines",
    );
  }
}

export class AdaptersFCMGetContactUsSubjectLineResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetContactUsSubjectLineResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_Contact_US_Subject_Line__Response",
      subject_lines: AdaptersFCMGetContactUsSubjectLines.decode(
        jsonData.subject_lines,
        debugInfo + ".subject_lines",
      ),
    };
  }
}

class AdapterPlayThroughPod {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IPlayThroughPod {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      created_stamp_millis: ValidationUtils.assert_positive_integer_or_zero(
        jsonData.created_stamp_millis,
        debugInfo + ".created_stamp_millis",
      ),
      creator_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.creator_fkey,
        debugInfo + ".creator_fkey",
      ),
      pod_fkey: ValidationUtils.assert_non_empty_string(
        jsonData.pod_fkey,
        debugInfo + ".pod_fkey",
      ),
      open_pick_fkeys: AdapterHelper.decode_array_of_strings(
        jsonData.open_pick_fkeys,
        debugInfo + ".open_pick_fkeys",
      ),
      r_7002_play_through_target: ValidationUtils.assert_any_integer(
        jsonData.r_7002_play_through_target,
        debugInfo + ".r_7002_play_through_target",
      ),
      r_7003_play_through_progress: ValidationUtils.assert_any_integer(
        jsonData.r_7003_play_through_progress,
        debugInfo + ".r_7003_play_through_progress",
      ),
      r_5011_v5_playable_fliff_cash: ValidationUtils.assert_any_integer(
        jsonData.r_5011_v5_playable_fliff_cash,
        debugInfo + ".r_5011_v5_playable_fliff_cash",
      ),
      r_7001_initial_fliff_cash: ValidationUtils.assert_any_integer(
        jsonData.r_7001_initial_fliff_cash,
        debugInfo + ".r_7001_initial_fliff_cash",
      ),
      r_5014_v5_transferred_fliff_cash: ValidationUtils.assert_any_integer(
        jsonData.r_5014_v5_transferred_fliff_cash,
        debugInfo + ".r_5014_v5_transferred_fliff_cash",
      ),
      r_5012_v5_locked_in_picks_fliff_cash:
        ValidationUtils.assert_positive_integer_or_zero(
          jsonData.r_5012_v5_locked_in_picks_fliff_cash,
          debugInfo + ".r_5012_v5_locked_in_picks_fliff_cash",
        ),
      r_7022_initial_multiplier_in_percents: ValidationUtils.assert_any_integer(
        jsonData.r_7022_initial_multiplier_in_percents,
        debugInfo + ".r_7022_initial_multiplier_in_percents",
      ),
      v_transaction_amount: ValidationUtils.assert_non_empty_string(
        jsonData.v_transaction_amount,
        debugInfo + ".v_transaction_amount",
      ),
      v_transaction_type: ValidationUtils.assert_optional_string(
        jsonData.v_transaction_type,
        debugInfo + ".v_transaction_type",
      ),
      credible_state: validatePodCreditableState(
        jsonData.credible_state,
        debugInfo + ".credible_state",
      ),
      playable_state: validatePodPlayableState(
        jsonData.playable_state,
        debugInfo + ".playable_state",
      ),
      progress_state: validatePodProgressState(
        jsonData.progress_state,
        debugInfo + ".progress_state",
      ),
    };
  }
}

export class AdapterGetPodsReportResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMGetPodsReportResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Get_Pods_Report__Response",
      playable_pods: AdapterHelper.decode_array(
        AdapterPlayThroughPod.decode,
        jsonData.playable_pods,
        debugInfo + ".playable_pods",
      ),
      merge_code: ValidationUtils.assert_positive_integer(
        jsonData.merge_code,
        debugInfo + ".merge_code",
      ),
    };
  }
}
export class AdapterFCMRegisterRadarSDKCallBackDataResponse {
  public static decode(
    jsonData: TAnyAlias,
    debugInfo: string,
  ): I.IFCMRegisterRadarSDKCallBackDataResponse {
    ValidationUtils.assert_non_null_data(jsonData, debugInfo);

    return {
      __object_class_name: "FCM__Register_Radar_SDK_CallBack_Data__Response",
      usa_state_code: ValidationUtils.assert_optional_string(
        jsonData.usa_state_code,
        debugInfo + ".usa_state_code",
      ),
      usa_state_code_source: ValidationUtils.assert_non_empty_string(
        jsonData.usa_state_code_source,
        debugInfo + ".usa_state_code_source",
      ),
    };
  }
}
