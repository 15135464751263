import React from "react";
import InputMask from "react-input-mask";

import "./styles.scss";
import { IProps } from "./types";
import classNames from "classnames";

const PhoneInput = ({
  value,
  onChangeText,
  disabled,
  className,
  maskChar = null,
  theme,
  ...props
}: IProps) => {
  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChangeText?.(e.currentTarget.value);
  };

  const classes = classNames("masked-input", className, {
    "masked-input--dark": theme === "dark",
    "masked-input--disabled": disabled,
  });
  return (
    <InputMask
      className={classes}
      mask="999-999-9999"
      value={value}
      autoFocus
      maskChar={maskChar}
      placeholder={"+1"}
      inputMode="numeric"
      onChange={handleOnChange}
      disabled={disabled}
      {...props}
    />
  );
};

PhoneInput.displayName = "RegularTextInput";

export default PhoneInput;
