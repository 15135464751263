import { isMobileOnly } from "react-device-detect";
import { DevConstants } from "../DevConstants";
import { Suspense, lazy } from "react";
import Loading from "components/atoms/mobile/Loading";

const Desktop = lazy(() => import("../Renders/Desktop"));
const Mobile = lazy(() => import("../Renders/Mobile"));
const MaintenanceMobile = lazy(() => import("../Renders/MaintenanceMobile"));

const RenderContent: React.ComponentType = isMobileOnly
  ? DevConstants.isMaintenanceMode
    ? MaintenanceMobile
    : Mobile
  : Desktop;

const AppContent = () => (
  <Suspense fallback={<Loading />}>
    <RenderContent />
  </Suspense>
);

export default AppContent;
