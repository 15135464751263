import { staticGetState } from "reduxLocal/store";
import { IStringMap } from "src/types";
import { MemoryStorage } from "utils/MemoryStorage";
import { IFeedKnownSubfeedRevision, IFeedMeta } from "./data/objects";
import { ILocalAxiosRequestConfig } from "server/legacyCore/objectsLegacyCore";
import { AxiosError, AxiosResponse } from "axios";
import { getBetSupermarket, getCartItems } from "selectors/sportsBook";

class SharedCoreUtils {
  public get feedMeta(): IFeedMeta {
    const supermarket = getBetSupermarket(staticGetState());
    const tempMap: IStringMap<IFeedKnownSubfeedRevision> = {};

    Object.values(supermarket).forEach(shelf => {
      if (!shelf.subfeeds) {
        return;
      }

      Object.values(shelf.subfeeds).forEach(subfeed => {
        const key = `${subfeed.subfeed_code}__${subfeed.revision_code}__${subfeed.max_revision_id}`;
        if (!(key in tempMap)) {
          tempMap[key] = {
            subfeed_code: subfeed.subfeed_code,
            revision_code: subfeed.revision_code,
            revision_id: subfeed.max_revision_id,
            conflict_fkeys: [],
          };
        }
        tempMap[key].conflict_fkeys.push(shelf.conflict_fkey);
      });
    });
    const cartItems = getCartItems(staticGetState());
    const cartItemsValues = Object.values(cartItems);
    const focusedTicketConflictFkeys = [
      ...new Set(cartItemsValues.map(({ event }) => event.conflict_fkey)),
    ];
    const focusedTicketProposalFkeys = [
      ...new Set(cartItemsValues.map(cartItem => cartItem.proposal_fkey)),
    ];

    const focusedTicketData = cartItemsValues.map(cartItem => ({
      proposal_fkey: cartItem.proposal_fkey,
      market_fkey: cartItem.market_fkey,
      player_fkey: cartItem.player_fkey,
      conflict_fkey: cartItem.event.conflict_fkey,
    }));

    return {
      packed_subfeed_revisions: Object.values(tempMap),
      focused_channel_id: MemoryStorage.focusedChannelId,
      focused_conflict_fkey: MemoryStorage.focusedConflictFkey,
      focused_player_fkey: MemoryStorage.focusedPlayerFkey,
      focused_ticket_conflict_fkeys: focusedTicketConflictFkeys,
      focused_ticket_proposal_fkeys: focusedTicketProposalFkeys,
      focused_ticket_data: focusedTicketData,
    };
  }
  public attachRequestCodeInterceptors = () => {
    let requestCode = "";

    return () => ({
      onRequest: (config: ILocalAxiosRequestConfig) => {
        if (config.headers?.["x-dd-request-code"]) {
          requestCode = config.headers?.["x-dd-request-code"];
          delete config.headers["x-dd-request-code"];
        }
        return config;
      },
      onResponse: (response: AxiosResponse) => {
        if (requestCode) {
          response.config.headers["x-dd-request-code"] = requestCode;
        }
        return response;
      },
      onError: (error: AxiosError) => {
        if (requestCode && error.config) {
          error.config.headers["x-dd-request-code"] = requestCode;
        }
        return error;
      },
      requestCode,
    });
  };
}

export default new SharedCoreUtils();
