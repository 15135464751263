import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
} from "axios";
import {
  IFCMProtocolResponseSlots,
  IFCMRequest,
} from "server/core/data/objects";
import { MessageDecoder } from "server/core/server/impl/MessageDecoder";
import { AdapterFCMProtocolResponseSlots } from "server/core/server/impl/serverAdapters";
import { ErrorHelper } from "server/legacyCore/ErrorHelper";
import { ILocalAxiosRequestConfig } from "server/legacyCore/objectsLegacyCore";
import AppTokensManager from "server/sharedCore/AppTokensManager";
import {
  IFliffProtocolRequest,
  IFliffProtocolResponse,
  IFliffResponse,
} from "server/sharedCore/data/objects";
import { AdapterFliffProtocolResponse } from "server/sharedCore/data/serverAdapters";
import { IInternalNetworkConnector } from "server/sharedCore/interfaces";
import { AppUtils } from "utils/AppUtils";
import { AppProfileUtils } from "utils/AppProfileUtils";
import { ServerClock } from "utils/ServerClock";
import CoreApiUrlManager from "server/sharedCore/CoreApiUrlManager";
import SharedCoreUtils from "server/sharedCore/SharedCoreUtils";

export class InternalNetworkConnectorImpl
  implements
    IInternalNetworkConnector<
      IFCMProtocolResponseSlots,
      IFCMRequest,
      IFliffResponse
    > {
  private static _cachedAxiosInstanceCode: string;
  private static _CachedAxiosInstance: AxiosInstance;
  private readonly _retryErrorCode = 40791 as const;
  private _requestCounter = 0;

  private static get _AxiosInstance() {
    const axiosCode = AppProfileUtils.coreServerAxiosCode;
    const baseURL = AppProfileUtils.coreServerBaseUrl;

    if (
      InternalNetworkConnectorImpl._cachedAxiosInstanceCode === axiosCode &&
      InternalNetworkConnectorImpl._CachedAxiosInstance
    ) {
      return InternalNetworkConnectorImpl._CachedAxiosInstance;
    }

    const Instance = axios.create({ baseURL, timeout: 20000 });
    this._setupInterceptors(Instance);

    InternalNetworkConnectorImpl._CachedAxiosInstance = Instance;
    InternalNetworkConnectorImpl._cachedAxiosInstanceCode = axiosCode;

    return InternalNetworkConnectorImpl._CachedAxiosInstance;
  }

  public async sendProtocolRequest<
    Request extends IFCMRequest,
    ProtocolRequest extends IFliffProtocolRequest<Request>,
    Response extends IFliffResponse,
  >(
    inputRequest: ProtocolRequest,
  ): Promise<IFliffProtocolResponse<IFCMProtocolResponseSlots, Response>> {
    this._incrementRequestCounter();
    const request = {
      ...inputRequest,
      header: { ...inputRequest.header, conn_id: this._requestCounter },
      invocation: {
        ...inputRequest.invocation,
        // We remove local meta from the request
        request: { ...inputRequest.invocation.request, localMeta: undefined },
      },
    };

    if (AppProfileUtils.injectedDelayToAllServers !== 0) {
      await this._injectNetworkDelay(AppProfileUtils.injectedDelayToAllServers);
    }

    const rawResp = await this._makeApiRequest(
      request,
      inputRequest.invocation.request.localMeta,
    );
    const response = AdapterFliffProtocolResponse.decode<
      IFCMProtocolResponseSlots,
      Response
    >(
      JSON.parse(rawResp.data),
      "protocol_response",
      AdapterFCMProtocolResponseSlots.decode,
      MessageDecoder.decodeMessage,
    );
    const error = response.result.error;
    if (error) {
      const incidentTagLength = error.incidentTag?.length || 0;
      if (error.errorCode === this._retryErrorCode && incidentTagLength > 0) {
        const nextRequest = {
          ...inputRequest,
          header: { ...inputRequest.header, incident_tag: error.incidentTag },
        };

        return await this.sendProtocolRequest(nextRequest);
      }
    }
    ServerClock.installLatestKnownServerUtcStampMillis(
      response.header.server_stamp_millis,
    );

    return response;
  }

  private static _setupInterceptors(instance: AxiosInstance): void {
    const factory = SharedCoreUtils.attachRequestCodeInterceptors();

    // Intercept the request and remove 'x-dd-request-code' header
    instance.interceptors.request.use(
      (config: ILocalAxiosRequestConfig) => {
        return factory().onRequest(config);
      },
      (error: AxiosError) => {
        const nextError = factory().onError(error);
        return Promise.reject(nextError);
      },
    );

    // Add 'x-dd-request-code' header back to the response
    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        return factory().onResponse(response);
      },
      (error: AxiosError) => {
        const nextError = factory().onError(error);
        return Promise.reject(nextError);
      },
    );
  }

  private async _makeApiRequest<
    Request extends IFCMRequest,
    ProtocolRequest extends IFliffProtocolRequest<Request>,
  >(
    request: ProtocolRequest,
    localMeta: IFCMRequest["localMeta"],
  ): Promise<AxiosResponse> {
    const serializedRequestData = JSON.stringify(request);

    const sharedData = {
      baseURL: CoreApiUrlManager.getBaseUrl(
        InternalNetworkConnectorImpl._AxiosInstance.defaults.baseURL,
        !localMeta.isPrivate,
      ),
      method: "POST",
      data: serializedRequestData,
      transformResponse: [],
      params: request.header,
      headers: this._buildRequestHeaders(localMeta.isPrivate),
    };

    if (localMeta.isPrivate) {
      return await AppTokensManager.apiRequestWithAuthImpl(
        this._makePrivateRequest,
        { ...sharedData, url: "/fc_mobile_api_private" },
        "InternalNetworkConnectorImpl._makeApiRequest",
      );
    } else {
      const forceAttemptToHerald =
        CoreApiUrlManager.nextHeraldPublicEndpoint.length !== 0 &&
        localMeta.forceAttemptToHerald;

      return await InternalNetworkConnectorImpl._AxiosInstance.request({
        ...sharedData,
        ...(forceAttemptToHerald && {
          baseURL: CoreApiUrlManager.nextHeraldPublicEndpoint,
        }),
        url: "/fc_mobile_api_public",
      });
    }
  }

  private async _makePrivateRequest(
    data: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    try {
      return await InternalNetworkConnectorImpl._AxiosInstance.request(data);
    } catch (error) {
      const apiException = ErrorHelper.apiErrorToDDException(error);
      apiException.debug_error_source = "error in server_v2/_privateRequest";
      throw apiException;
    }
  }

  private _buildRequestHeaders(isPrivate: boolean) {
    const headers = { "Content-Type": "application/json" } as const;

    const helper = AppTokensManager.apiExecutorTokensHelper;
    const accessToken = helper.tokens.accessToken;

    if (isPrivate && accessToken) {
      return {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        "x-dd-request-code": "access_token_auth",
      };
    }

    return headers;
  }

  private _incrementRequestCounter() {
    this._requestCounter += 1;
  }

  private async _injectNetworkDelay(delayInMs: number): Promise<void> {
    await AppUtils.sleep(delayInMs);
  }
}
