import { TCurrencyMode } from "src/types";
import { CashAmountFormatters, CoinsAmountFormatters } from ".";
import { UIConstants } from "server/legacyCore/data/constants";

class SharedAmountsFormatters {
  public selectFormatByCurrency = (
    goldCoins: number,
    fliffCash: number,
    currencyMode: TCurrencyMode,
  ): string => {
    if (UIConstants.isModeCash(currencyMode)) {
      return CashAmountFormatters.toFliffCash(fliffCash);
    }
    return CoinsAmountFormatters.toGoldCoins(goldCoins);
  };

  // 2021-09-04 / Ivan / display amount in specific currency - at the moment used in My Picks & Activity
  public formatByCurrency = (
    amount: number,
    currencyMode: TCurrencyMode,
  ): string => {
    if (UIConstants.isModeCash(currencyMode)) {
      return CashAmountFormatters.toFliffCash(amount);
    }
    return CoinsAmountFormatters.toGoldCoins(amount);
  };

  public formatCopiesCount = (copyCount: number) => {
    const floor = (count: number) => {
      return +(Math.floor(count * 10) / 10).toFixed(1);
    };
    if (copyCount < 1000) {
      return copyCount;
    }
    if (copyCount < 1000 * 100) {
      return `${floor(copyCount / 1000)}k`;
    }
    if (copyCount < 1000 * 1000) {
      return `${Math.floor(copyCount / 1000)}k`;
    }
    if (copyCount < 1000 * 1000 * 10) {
      return `${floor(copyCount / (1000 * 1000))}m`;
    }
    if (copyCount < 1000 * 1000 * 1000) {
      return `${Math.floor(copyCount / (1000 * 1000))}m`;
    }
    if (copyCount < 1000 * 1000 * 1000 * 1000) {
      return `${Math.floor(copyCount / (1000 * 1000 * 1000))}b`;
    }
    return "";
  };
}

export default new SharedAmountsFormatters();
