import { ErrorHelper } from "./ErrorHelper";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import {
  XCommonServerRequest,
  ILocalAxiosRequestConfig,
} from "server/legacyCore/objectsLegacyCore";
import CoreApiUrlManager from "server/sharedCore/CoreApiUrlManager";
import { TAnyAlias } from "src/types";
import { AppProfileUtils } from "utils/AppProfileUtils";
import SharedCoreUtils from "server/sharedCore/SharedCoreUtils";

export class ApiExecutorNoAuth {
  private _cachedAxiosInstanceCode: string;
  private _cachedAxiosInstance: AxiosInstance;

  private get _AxiosInstance() {
    const axiosCode = AppProfileUtils.coreServerAxiosCode;

    // Check if the current axios instance is already cached, if so, return the cached instance.
    if (
      this._cachedAxiosInstanceCode === axiosCode &&
      !!this._cachedAxiosInstance
    ) {
      return this._cachedAxiosInstance;
    }

    const instance = axios.create({
      baseURL: CoreApiUrlManager.defaultBaseUrl,
      timeout: 20000,
    });

    this._attachInterceptors(instance);

    // Update the cached instance and return the new instance.
    this._cachedAxiosInstanceCode = axiosCode;
    this._cachedAxiosInstance = instance;

    return this._cachedAxiosInstance;
  }

  /**
   * 2019-11-13 / Ivan / This is the correct place to convert the axios / server error to our own exception.
   * All server API calls are done via this method.
   */
  public async apiRequest(
    data: AxiosRequestConfig,
    debugErrorSource: string,
  ): Promise<AxiosResponse> {
    try {
      return await this._AxiosInstance.request(data);
    } catch (error) {
      const apiException = ErrorHelper.apiErrorToDDException(error);
      apiException.debug_error_source = debugErrorSource;
      throw apiException;
    }
  }

  public apiRequestNoAuth(
    _data: AxiosRequestConfig,
    _debugErrorSource: string,
  ): Promise<AxiosResponse> {
    throw new Error("not implemented");
  }

  /**
   * 2019-11-16 / Ivan / Now using strict types.
   * This method makes an HTTP call via axios and then calls the callback, which should extract a domain object from the HTTP response.
   */
  public async apiRequestWrapper<T>(
    debugErrorSource: string,
    data: AxiosRequestConfig,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    let rawResponse: AxiosResponse | null = null;
    try {
      rawResponse = await this.apiRequest(data, debugErrorSource);
      return await callback(rawResponse);
    } catch (error: TAnyAlias) {
      if (error.debugErrorSource !== debugErrorSource) {
        // if source is not attached - either set it or prepend it
        error.debugErrorSource = error.debugErrorSource
          ? debugErrorSource + " / " + error.debugErrorSource
          : debugErrorSource;
      }

      throw error;
    }
  }

  /**
   * 2019-11-16 / Ivan / Now using strict types.
   * This method makes an HTTP call via axios and then calls the callback, which should extract a domain object from the HTTP response.
   */
  public async apiPublicRequestWrapper<T>(
    debugErrorSource: string,
    data: AxiosRequestConfig,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    let rawResponse: AxiosResponse | null = null;
    try {
      rawResponse = await this.apiRequestNoAuth(data, debugErrorSource);
      return await callback(rawResponse);
    } catch (error: TAnyAlias) {
      if (error.debugErrorSource !== debugErrorSource) {
        // if source is not attached - either set it or prepend it
        error.debugErrorSource = error.debugErrorSource
          ? debugErrorSource + " / " + error.debugErrorSource
          : debugErrorSource;
      }

      throw error;
    }
  }

  public apiAltXRequestWrapper = async <T>(
    url: string,
    debugErrorSource: string,
    request: XCommonServerRequest,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> => {
    const { preparedRequest, nextDebugErrorSource } = this._prepareRequest(
      url,
      debugErrorSource,
      request,
    );

    return await this.apiRequestWrapper(
      nextDebugErrorSource,
      preparedRequest,
      callback,
    );
  };

  public async apiPublicXRequestWrapper<T>(
    url: string,
    debugErrorSource: string,
    request: XCommonServerRequest,
    callback: (rawResponse: AxiosResponse) => Promise<T> | T,
  ): Promise<T> {
    const { preparedRequest, nextDebugErrorSource } = this._prepareRequest(
      url,
      debugErrorSource,
      request,
    );
    return await this.apiPublicRequestWrapper(
      nextDebugErrorSource,
      preparedRequest,
      callback,
    );
  }

  private _prepareRequest(
    url: string,
    debugErrorSource: string,
    request: XCommonServerRequest,
  ) {
    const cleanedObjectData = JSON.stringify(request);

    return {
      preparedRequest: {
        method: "POST",
        timeout: 33000,
        url,
        headers: { "content-type": "application/json" },
        data: cleanedObjectData,
      },
      nextDebugErrorSource: `protocol[${debugErrorSource}]`,
    };
  }

  private _attachInterceptors(instance: AxiosInstance) {
    const factory = SharedCoreUtils.attachRequestCodeInterceptors();
    // Attach request interceptors.
    instance.interceptors.request.use(
      (inputConfig: ILocalAxiosRequestConfig) => {
        const staleUrlConfig = {
          ...inputConfig,
          baseURL: CoreApiUrlManager.getBaseUrl(
            inputConfig.baseURL,
            inputConfig.url?.includes("sports_book_public/"),
          ),
        };
        const updatedHeadersConfig = factory().onRequest(staleUrlConfig);
        AppProfileUtils.coreServerInterceptAxiosRequestSuccess(
          updatedHeadersConfig,
        );

        return this._handleNetworkDelay(updatedHeadersConfig);
      },
      (error: AxiosError) => {
        const nextError = factory().onError(error);
        return Promise.reject(nextError);
      },
    );

    // Attach response interceptors.
    instance.interceptors.response.use(
      response => {
        const nextResponse = factory().onResponse(response);
        AppProfileUtils.coreServerInterceptAxiosResponseSuccess(nextResponse);

        return nextResponse;
      },
      (error: AxiosError) => {
        const nextError = factory().onError(error);
        return Promise.reject(nextError);
      },
    );
  }

  private _handleNetworkDelay(
    config: ILocalAxiosRequestConfig,
  ): Promise<ILocalAxiosRequestConfig> {
    return new Promise<ILocalAxiosRequestConfig>(resolve => {
      setTimeout(() => {
        if (config.inject_network_error) {
          config.url = "/dummy_injected_error" + config.url;
        }

        resolve(config);
      }, config.inject_network_delay_in_millis || 0);
    });
  }
}
