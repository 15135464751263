export class LogUploaderMemoryStorage {
  protected pendingMessages: string[] = [];

  protected getNextSnippetForUpload(): [number, string] {
    let nn = 0;
    let logSnippet = "";

    for (let i = 0; i < this.pendingMessages.length; i++) {
      if (i > 0) {
        logSnippet += "\r\n";
      }

      logSnippet += this.pendingMessages[i];
      nn++;

      // if we have more than 30 Kb for upload, it's time to stop merging
      if (logSnippet.length > 30000 && nn > 3) {
        break;
      }
    }

    return [nn, logSnippet];
  }

  protected append(message: string): void {
    this.pendingMessages.push(message);
  }

  protected removeOldest(count: number): void {
    this.pendingMessages.splice(0, count);
  }
}
