import React, { forwardRef, LegacyRef, TextareaHTMLAttributes } from "react";
import "./styles.scss";
import classNames from "classnames";
import ValidationStateMark from "components/atoms_new/shared/Inputs/atoms/ValidationStateMark";
import { IProps } from "./types";

const TextArea = forwardRef<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  IProps
>(({ validationState, onChangeText, theme, ...props }, ref) => {
  const isInvalidState = validationState.state === 0;

  const classes = classNames("input-container-text-area", {
    "input-container-text-area--invalid": isInvalidState,
    "input-container-text-area--dark": theme === "dark",
  });

  const handleOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    onChangeText?.(e.currentTarget.value);
  };
  return (
    <div className={classes}>
      <textarea
        ref={ref as LegacyRef<HTMLTextAreaElement>}
        onChange={handleOnChange}
        value={validationState.value}
        rows={4}
        {...props}
      />
      <ValidationStateMark validationState={validationState} />
    </div>
  );
});

TextArea.displayName = "TextArea";

export default TextArea;
