import CashBundle02 from "assets/img/fliffCashBundles/CashBundle02.png";
import CashBundle03 from "assets/img/fliffCashBundles/CashBundle03.png";
import CashBundle04 from "assets/img/fliffCashBundles/CashBundle04.png";
import CashBundle05 from "assets/img/fliffCashBundles/CashBundle05.png";
import CashBundle06 from "assets/img/fliffCashBundles/CashBundle06.png";
import BundleSkin101 from "assets/img/coinsBundles/coin_bundles__bag_skin_101.png";
import BundleSkin102 from "assets/img/coinsBundles/coin_bundles__bag_skin_102.png";
import BundleSkin103 from "assets/img/coinsBundles/coin_bundles__bag_skin_103.png";
import BundleSkin104 from "assets/img/coinsBundles/coin_bundles__bag_skin_104.png";
import BundleSkin105 from "assets/img/coinsBundles/coin_bundles__bag_skin_105.png";
import BundleSkin106 from "assets/img/coinsBundles/coin_bundles__bag_skin_106.png";
import CashBundle01 from "assets/img/fliffCashBundles/CashBundle01.png";
import { BundleBonusSkin, BundleOfferSkin } from "server/core/data/constants";
import { BundleOfferV8Skin } from "server/legacyCore/data/constants";

export const BUNDLE_BONUS_SKIN_MAP: Record<BundleBonusSkin, string> = {
  [BundleBonusSkin.CONST_2001_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_1]: CashBundle02,
  [BundleBonusSkin.CONST_2002_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_2]: CashBundle03,
  [BundleBonusSkin.CONST_2003_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_3]: CashBundle04,
  [BundleBonusSkin.CONST_2004_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_4]: CashBundle05,
  [BundleBonusSkin.CONST_2005_SKIN_V9_BUNDLES_SELECTOR_L2_SKIN_5]: CashBundle06,
};

export const BUNDLE_OFFER_SKIN_MAP: Record<BundleOfferSkin, string> = {
  [BundleOfferSkin.CONST_DEFAULT_SKIN]: CashBundle06,
  [BundleOfferSkin.CONST_DEFAULT_SKIN_2]: CashBundle06,
  [BundleOfferSkin.CONST_701_SKIN_FREE_HOURLY_NO_FC_BONUS]: CashBundle06,
  [BundleOfferSkin.CONST_702_SKIN_FREE_DAILY_NO_FC_BONUS]: CashBundle06,
  [BundleOfferSkin.CONST_703_SKIN_FREE_HOURLY_WITH_FC_BONUS]: CashBundle06,
  [BundleOfferSkin.CONST_704_SKIN_FREE_DAILY_WITH_FC_BONUS]: CashBundle06,

  [BundleOfferSkin.CONST_801_SKIN_SOCIAL_COINS_PRICE_LEVEL_1]: BundleSkin101,
  [BundleOfferSkin.CONST_802_SKIN_SOCIAL_COINS_PRICE_LEVEL_2]: BundleSkin102,
  [BundleOfferSkin.CONST_803_SKIN_SOCIAL_COINS_PRICE_LEVEL_3]: BundleSkin103,
  [BundleOfferSkin.CONST_804_SKIN_SOCIAL_COINS_PRICE_LEVEL_4]: BundleSkin104,
  [BundleOfferSkin.CONST_805_SKIN_SOCIAL_COINS_PRICE_LEVEL_5]: BundleSkin105,
  [BundleOfferSkin.CONST_806_SKIN_SOCIAL_COINS_PRICE_LEVEL_6]: BundleSkin106,

  [BundleOfferSkin.CONST_901_SKIN_FLIFF_CASH_PRICE_LEVEL_1]: CashBundle01,
  [BundleOfferSkin.CONST_902_SKIN_FLIFF_CASH_PRICE_LEVEL_2]: CashBundle02,
  [BundleOfferSkin.CONST_903_SKIN_FLIFF_CASH_PRICE_LEVEL_3]: CashBundle03,
  [BundleOfferSkin.CONST_904_SKIN_FLIFF_CASH_PRICE_LEVEL_4]: CashBundle04,
  [BundleOfferSkin.CONST_905_SKIN_FLIFF_CASH_PRICE_LEVEL_5]: CashBundle05,
  [BundleOfferSkin.CONST_906_SKIN_FLIFF_CASH_PRICE_LEVEL_6]: CashBundle06,

  [BundleOfferSkin.CONST_821_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_1]: BundleSkin101,
  [BundleOfferSkin.CONST_822_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_2]: BundleSkin102,
  [BundleOfferSkin.CONST_823_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_3]: BundleSkin103,
  [BundleOfferSkin.CONST_824_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_4]: BundleSkin104,
  [BundleOfferSkin.CONST_825_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_5]: BundleSkin105,
  [BundleOfferSkin.CONST_826_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_6]: BundleSkin106,

  [BundleOfferSkin.CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1]: CashBundle01,
  [BundleOfferSkin.CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2]: CashBundle02,
  [BundleOfferSkin.CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3]: CashBundle03,
  [BundleOfferSkin.CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4]: CashBundle04,
  [BundleOfferSkin.CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5]: CashBundle05,
  [BundleOfferSkin.CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6]: CashBundle06,
};

export const BUNDLE_OFFER_SECONDARY_SKIN_MAP: Record<BundleOfferSkin, string> =
  {
    [BundleOfferSkin.CONST_DEFAULT_SKIN]: CashBundle06,
    [BundleOfferSkin.CONST_DEFAULT_SKIN_2]: CashBundle06,
    [BundleOfferSkin.CONST_701_SKIN_FREE_HOURLY_NO_FC_BONUS]: CashBundle06,
    [BundleOfferSkin.CONST_702_SKIN_FREE_DAILY_NO_FC_BONUS]: CashBundle06,
    [BundleOfferSkin.CONST_703_SKIN_FREE_HOURLY_WITH_FC_BONUS]: CashBundle06,
    [BundleOfferSkin.CONST_704_SKIN_FREE_DAILY_WITH_FC_BONUS]: CashBundle06,

    [BundleOfferSkin.CONST_801_SKIN_SOCIAL_COINS_PRICE_LEVEL_1]: BundleSkin101,
    [BundleOfferSkin.CONST_802_SKIN_SOCIAL_COINS_PRICE_LEVEL_2]: BundleSkin102,
    [BundleOfferSkin.CONST_803_SKIN_SOCIAL_COINS_PRICE_LEVEL_3]: BundleSkin103,
    [BundleOfferSkin.CONST_804_SKIN_SOCIAL_COINS_PRICE_LEVEL_4]: BundleSkin104,
    [BundleOfferSkin.CONST_805_SKIN_SOCIAL_COINS_PRICE_LEVEL_5]: BundleSkin105,
    [BundleOfferSkin.CONST_806_SKIN_SOCIAL_COINS_PRICE_LEVEL_6]: BundleSkin106,

    [BundleOfferSkin.CONST_901_SKIN_FLIFF_CASH_PRICE_LEVEL_1]: BundleSkin101,
    [BundleOfferSkin.CONST_902_SKIN_FLIFF_CASH_PRICE_LEVEL_2]: BundleSkin102,
    [BundleOfferSkin.CONST_903_SKIN_FLIFF_CASH_PRICE_LEVEL_3]: BundleSkin103,
    [BundleOfferSkin.CONST_904_SKIN_FLIFF_CASH_PRICE_LEVEL_4]: BundleSkin104,
    [BundleOfferSkin.CONST_905_SKIN_FLIFF_CASH_PRICE_LEVEL_5]: BundleSkin105,
    [BundleOfferSkin.CONST_906_SKIN_FLIFF_CASH_PRICE_LEVEL_6]: BundleSkin106,

    [BundleOfferSkin.CONST_821_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_1]:
      BundleSkin101,
    [BundleOfferSkin.CONST_822_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_2]:
      BundleSkin102,
    [BundleOfferSkin.CONST_823_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_3]:
      BundleSkin103,
    [BundleOfferSkin.CONST_824_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_4]:
      BundleSkin104,
    [BundleOfferSkin.CONST_825_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_5]:
      BundleSkin105,
    [BundleOfferSkin.CONST_826_SKIN_SOCIAL_COINS_2X_PRICE_LEVEL_6]:
      BundleSkin106,

    [BundleOfferSkin.CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1]: BundleSkin101,
    [BundleOfferSkin.CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2]: BundleSkin102,
    [BundleOfferSkin.CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3]: BundleSkin103,
    [BundleOfferSkin.CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4]: BundleSkin104,
    [BundleOfferSkin.CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5]: BundleSkin105,
    [BundleOfferSkin.CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6]: BundleSkin106,
  };

// TODO: Remove as part of Desktop transition
export const COIN_BUNDLE_SKINS_WEB = {
  [BundleOfferV8Skin.CONST_701_SKIN_FREE_HOURLY_NO_FC_BONUS]: BundleSkin101,
  [BundleOfferV8Skin.CONST_702_SKIN_FREE_DAILY_NO_FC_BONUS]: BundleSkin101,
  [BundleOfferV8Skin.CONST_703_SKIN_FREE_HOURLY_WITH_FC_BONUS]: BundleSkin101,
  [BundleOfferV8Skin.CONST_704_SKIN_FREE_DAILY_WITH_FC_BONUS]: BundleSkin101,

  [BundleOfferV8Skin.CONST_901_SKIN_FLIFF_CASH_PRICE_LEVEL_1]: BundleSkin101,
  [BundleOfferV8Skin.CONST_902_SKIN_FLIFF_CASH_PRICE_LEVEL_2]: BundleSkin102,
  [BundleOfferV8Skin.CONST_903_SKIN_FLIFF_CASH_PRICE_LEVEL_3]: BundleSkin103,
  [BundleOfferV8Skin.CONST_904_SKIN_FLIFF_CASH_PRICE_LEVEL_4]: BundleSkin104,
  [BundleOfferV8Skin.CONST_905_SKIN_FLIFF_CASH_PRICE_LEVEL_5]: BundleSkin105,
  [BundleOfferV8Skin.CONST_906_SKIN_FLIFF_CASH_PRICE_LEVEL_6]: BundleSkin106,

  [BundleOfferV8Skin.CONST_801_SKIN_SOCIAL_COINS_PRICE_LEVEL_1]: BundleSkin101,
  [BundleOfferV8Skin.CONST_802_SKIN_SOCIAL_COINS_PRICE_LEVEL_2]: BundleSkin102,
  [BundleOfferV8Skin.CONST_803_SKIN_SOCIAL_COINS_PRICE_LEVEL_3]: BundleSkin103,
  [BundleOfferV8Skin.CONST_804_SKIN_SOCIAL_COINS_PRICE_LEVEL_4]: BundleSkin104,
  [BundleOfferV8Skin.CONST_805_SKIN_SOCIAL_COINS_PRICE_LEVEL_5]: BundleSkin105,
  [BundleOfferV8Skin.CONST_806_SKIN_SOCIAL_COINS_PRICE_LEVEL_6]: BundleSkin106,

  [BundleOfferV8Skin.CONST_921_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_1]: BundleSkin101,
  [BundleOfferV8Skin.CONST_922_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_2]: BundleSkin102,
  [BundleOfferV8Skin.CONST_923_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_3]: BundleSkin103,
  [BundleOfferV8Skin.CONST_924_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_4]: BundleSkin104,
  [BundleOfferV8Skin.CONST_925_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_5]: BundleSkin105,
  [BundleOfferV8Skin.CONST_926_SKIN_FLIFF_CASH_2X_PRICE_LEVEL_6]: BundleSkin106,
} as Record<BundleOfferV8Skin, string>;
