import {
  MazoomaWebViewExistState,
  PaysafeWebViewExistState,
} from "server/legacyCore/data/constants";

const UIPaymentUtils = {
  parseMessageTitle: (
    status: PaysafeWebViewExistState | MazoomaWebViewExistState,
  ) => {
    switch (status) {
      case MazoomaWebViewExistState.CODE_72818_VERIFIED_AND_ACTIVE:
        return "Payment Successful";
      case MazoomaWebViewExistState.CODE_31719_VERIFICATION_CANCELED:
        return "Payment Unsuccessful";
      case PaysafeWebViewExistState.CODE_24321_VERIFIED_AND_ACTIVE:
        return "Congratulations!";
      case PaysafeWebViewExistState.CODE_24314_VERIFICATION_CANCELED:
        return "Fail";
      default:
        return "Payment Unsuccessful";
    }
  },

  parseMessageDescription: (
    status: PaysafeWebViewExistState | MazoomaWebViewExistState,
    defaultDesc: string,
  ) => {
    switch (status) {
      case PaysafeWebViewExistState.CODE_24321_VERIFIED_AND_ACTIVE:
        return "Your payment was processed successfully";
      case PaysafeWebViewExistState.CODE_24314_VERIFICATION_CANCELED:
        return "Your payment was processed unsuccessfully";
      default:
        return defaultDesc;
    }
  },

  shouldUpdateAccountData: (
    status: PaysafeWebViewExistState | MazoomaWebViewExistState,
  ) => {
    if (status === MazoomaWebViewExistState.CODE_72818_VERIFIED_AND_ACTIVE) {
      return true;
    }
    if (status === PaysafeWebViewExistState.CODE_24321_VERIFIED_AND_ACTIVE) {
      return true;
    }
    return false;
  },
};

export default UIPaymentUtils;
