import * as H from "history";

import { staticGetState } from "reduxLocal/store";
import { getPersonalDetailsVerificationStatus } from "selectors/sportsBook";
import ProfileVerificationUtils from "utils/ProfileVerificationUtils";
import { PrivateCoreApi } from "server/core/server/CoreApi";
import { AppUIShowModalDialogAction } from "reduxLocal/appUI/appUI.actions";

import {
  IPlayThroughBundle,
  IPlayThroughBundleBonus,
} from "server/legacyCore/data/objects";
import { PaymentVendor } from "server/core/data/constants";
import { FliffException } from "server/legacyCore/FliffException";
import { IFCMPaymentMethodToken } from "server/core/data/objects";

class PaymentMethodsUtils {
  public handlePaysafePayment = async (
    bundle: IPlayThroughBundle,
    bundleBonus: IPlayThroughBundleBonus,
    urlSetter: (value: string) => void,
    history: H.History,
    shouldRelocate: boolean,
  ) => {
    const personalDetailsVerificationStatus =
      getPersonalDetailsVerificationStatus(staticGetState());
    const isPersonalDetailsVerified =
      ProfileVerificationUtils.isPersonalDetailsVerified(
        personalDetailsVerificationStatus,
      );
    if (isPersonalDetailsVerified) {
      await this._handlePaysafeMethod(
        bundle,
        bundleBonus,
        urlSetter,
        history,
        shouldRelocate,
      );
    } else {
      ProfileVerificationUtils.handlePersonalDetailsVerification(history);
    }
  };

  public handleMazoomaPayment = async (
    selectedBankAccount: IFCMPaymentMethodToken | null,
    bundle: IPlayThroughBundle,
    bundleBonus: IPlayThroughBundleBonus,
    urlSetter: (value: string) => void,
    history: H.History,
    shouldRelocate: boolean,
  ) => {
    const personalDetailsVerificationStatus =
      getPersonalDetailsVerificationStatus(staticGetState());
    const isPersonalDetailsVerified =
      ProfileVerificationUtils.isPersonalDetailsVerified(
        personalDetailsVerificationStatus,
      );
    if (isPersonalDetailsVerified) {
      await this._handleMazoomaMethod(
        selectedBankAccount,
        bundle,
        bundleBonus,
        urlSetter,
        history,
        shouldRelocate,
      );
    } else {
      ProfileVerificationUtils.handlePersonalDetailsVerification(history);
    }
  };

  private _handleMazoomaMethod = async (
    selectedBankAccount: IFCMPaymentMethodToken | null,
    bundle: IPlayThroughBundle,
    bundleBonus: IPlayThroughBundleBonus,
    urlSetter: (value: string) => void,
    history: H.History,
    shouldRelocate: boolean,
  ) => {
    try {
      const isBankIdVerified = !!selectedBankAccount;

      if (!isBankIdVerified) {
        AppUIShowModalDialogAction.dispatchShowErrorDialog(
          "Bank account is required",
          "Please, select 'Register a new bank account' or an existing one to continue.",
        );
        return;
      }

      const result = await PrivateCoreApi.safeBlockingInitiateBundlePurchase({
        bundle_code: bundleBonus.bundle_code,
        payment_token:
          selectedBankAccount.payment_token === "register"
            ? ""
            : selectedBankAccount?.payment_token,
        price_in_cents: bundle.price_in_cents,
        vendor_code: PaymentVendor.CODE_71_MAZOOMA_WEB,
      });

      if (result.error) {
        return;
      }

      const redirectUrl = result.response.initiate_url;

      urlSetter(redirectUrl);
      if (shouldRelocate) {
        history.push("/cashier-payment-frame", { bundle });
      } else {
        window.open(redirectUrl, "_blank");
      }
    } catch (next: unknown) {
      const err = next as FliffException;
      if (shouldRelocate) {
        history.push("/cashier-payment", { bundle, bundleBonus });
      } else {
        // TODO...
      }
      AppUIShowModalDialogAction.dispatchShowErrorDialog(
        `Response [${err.error_code}]`,
        err.error_message,
      );
    }
  };

  private _handlePaysafeMethod = async (
    bundle: IPlayThroughBundle,
    bundleBonus: IPlayThroughBundleBonus,
    urlSetter: (value: string) => void,
    history: H.History,
    shouldRelocate: boolean,
  ) => {
    try {
      const result = await PrivateCoreApi.safeBlockingInitiateBundlePurchase({
        vendor_code: PaymentVendor.CODE_81_PAYSAFE,
        bundle_code: bundleBonus.bundle_code,
        payment_token: "",
        price_in_cents: bundle.price_in_cents,
      });

      if (result.error) {
        return;
      }

      console.log(
        "Bundle Codes: ",
        bundleBonus.bundle_code,
        bundle.bundle_code,
      );
      // Should never happen.
      if (
        bundleBonus.bundle_code !==
        result.response.v9_bundle_goods_2_initiated.bundle_code
      ) {
        console.log("Server is returning different bundle then initialised");
        return;
      }

      const redirectUrl = result.response.initiate_url;

      urlSetter(redirectUrl);
      if (shouldRelocate) {
        history.replace("/cashier-payment-frame", { bundle });
      } else {
        window.open(redirectUrl, "_blank");
      }
    } catch (next: unknown) {
      const err = next as FliffException;
      AppUIShowModalDialogAction.dispatchShowErrorDialog(
        `Response [${err.error_code}]`,
        err.error_message,
      );
    }
  };
}

export default new PaymentMethodsUtils();
