import { useEffect, useRef } from "react";

const useRunOnce = (fn: () => void, sessionKey?: string): void => {
  const isTriggered = useRef<boolean>(false);

  useEffect(() => {
    const hasBeenTriggered = sessionKey
      ? sessionStorage.getItem(sessionKey)
      : isTriggered.current;

    if (!hasBeenTriggered) {
      fn();
      isTriggered.current = true;

      if (sessionKey) {
        sessionStorage.setItem(sessionKey, "true");
      }
    }
  }, [fn, sessionKey]);
};

export default useRunOnce;
