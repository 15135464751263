import colors from "assets/styles/design-tokens/colors.module.scss";
import ButtonOutline from "components/atoms/mobile/ButtonOutline";
import SignUpPromoInfo from "components/atoms/mobile/SignUpPromoInfo";
import Checkbox from "components/atoms_new/shared/Checkbox";
import Inputs from "components/atoms_new/shared/Inputs";
import SkipValidations from "components/atoms_new/shared/Inputs/atoms/SkipValidations";
import ModalHeader from "components/molecules_new/desktop/Modal/atoms/ModalHeader";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { TGlobalAppState } from "reduxLocal/rootReducer";
import { FliffException } from "server/legacyCore/FliffException";
import { SportsBookBIF } from "server/legacyCore/api/SportsBookBIF";
import { SportsBookSignInBIF } from "server/legacyCore/api/SportsBookSignInBIF";
import { TAnyAlias } from "src/types";
import FormValidationUtils from "utils/FormValidationUtils";
import Logger from "utils/Logger";
import { alphaNumericReplacement } from "utils/validators";
import "./styles.scss";
import { IProps, IState } from "./types";
import Button from "components/atoms_new/desktop/Button";

const CompleteProfile = React.forwardRef<HTMLDivElement, IProps>(
  ({ isLaunchedOnAppInit, onClose, openPrevious, openNext }, forwardedRef) => {
    const tempUsername = useSelector(
      (state: TGlobalAppState) => state.app.tempUserAccount?.username,
    );

    const [state, setState] = useState<IState>({
      username: {
        value: tempUsername || "",
        isValid: true,
        validationError: "",
        state: -1,
      },
      termsAccepted: false,
      age: { value: "", isValid: true, validationError: "", state: -1 },
      couponCode: { value: "", isValid: true, validationError: "", state: -1 },
      isTermsAcceptedValid: true,
      verifiedUsernames: { [tempUsername || ""]: true },
    });

    const validateAge = useCallback(() => {
      return FormValidationUtils.validateAge(state.age.value);
    }, [state.age.value]);

    const handleTermsChanged = () =>
      setState(prevState => ({
        ...prevState,
        termsAccepted: !prevState.termsAccepted,
      }));

    const handleAgeChanged = (value: string) => {
      const validatedAge = FormValidationUtils.validateAge(value);
      setState(prevState => ({
        ...prevState,
        age: { ...prevState.age, ...validatedAge },
      }));
    };

    const handleCouponCodeChange = (value: string) => {
      setState(prevState => ({
        ...prevState,
        couponCode: {
          ...prevState.couponCode,
          value: alphaNumericReplacement(value),
        },
      }));
    };

    const handleUsernameBlur = () => {
      setState(prevState => ({
        ...prevState,
        username: validateUsername(prevState.username.value),
      }));
    };

    const handleAgeBlur = () => {
      setState(prevState => ({
        ...prevState,
        age: validateAge(),
      }));
    };

    const handleBackgroundValidation = async (updatedUsername: string) => {
      if (tempUsername?.trim() && tempUsername === updatedUsername) {
        setState(prevState => ({
          ...prevState,
          username: {
            isValid: true,
            value: updatedUsername,
            validationError: "",
            state: 1,
          },
        }));
        return;
      }
      try {
        const username = updatedUsername;
        const { is_error, resultCode } =
          await SportsBookBIF.background_validate_registration_data({
            email: "",
            username,
            is_background_check: true,
          });
        const usernameAlreadyExists = resultCode === 30351;
        if (is_error && resultCode !== 30351) {
          return;
        }
        setState(prevState => ({
          ...prevState,
          verifiedUsernames: {
            ...prevState.verifiedUsernames,
            [username]: !usernameAlreadyExists,
          },
        }));
      } catch (err: TAnyAlias) {
        Logger.logMessage("[CompleteProfileScreen] " + err.message);
      }
    };

    const validateUsername = (updatedUsername: string) => {
      handleBackgroundValidation(updatedUsername);
      return FormValidationUtils.validateUsername(
        updatedUsername,
        typeof state.verifiedUsernames[updatedUsername] !== "undefined" &&
          state.verifiedUsernames[updatedUsername],
      );
    };

    const handleUsernameChange = (value: string) => {
      setState(prevState => ({
        ...prevState,
        username: validateUsername(value),
      }));
    };

    const isFormInvalid =
      !state.username.isValid || !state.age.isValid || !state.termsAccepted;

    const onCompleteProfile = async () => {
      Logger.warnMessage("[CompleteProfileScreen] onCompleteProfile - enter");

      if (isFormInvalid) {
        Logger.warnMessage(
          "[CompleteProfileScreen] onCompleteProfile - case_1",
        );
        return;
      }

      Logger.warnMessage("[CompleteProfileScreen] onCompleteProfile - case_4");

      const opres = await SportsBookSignInBIF.blocking_v2_complete_profile_data(
        {
          username: state.username.value.trim(),
          referrer_hash: "",
          bonus_code: state.couponCode.value.trim(),
        },
      );

      if (opres.is_error) {
        return;
      }

      if (
        opres.resultCode ===
        FliffException.ERROR_1905__SESSION_EXPIRED__NEED_VERIFIED_PRIMARY_PHONE_NUMBER
      ) {
        openNext("signInPhone", { mode: 1 });
        return opres;
      } else if (
        opres.resultCode ===
        FliffException.ERROR_1906__SESSION_EXPIRED__NEED_ACCEPTED_TERMS_OF_USE
      ) {
        // TODO: Open the Terms of Use page
        // history.replace("/terms-of-use");
        return opres;
      }

      // Success
      onClose();
      return opres;
    };

    return (
      <div className="complete-profile" ref={forwardedRef}>
        <ModalHeader
          title={"Complete Profile"}
          onClose={isLaunchedOnAppInit ? undefined : onClose}
          onGoBack={isLaunchedOnAppInit ? undefined : openPrevious}
        />

        <SignUpPromoInfo className="complete-profile__promo-info" />

        <Inputs.Label htmlFor="username" label="Username" />
        <Inputs.RegularText
          maxLength={32}
          onBlur={handleUsernameBlur}
          onChangeText={handleUsernameChange}
          placeholder={"Enter Username"}
          validationState={state.username}
          id="username"
          theme="dark"
        />
        <Inputs.Label htmlFor="age" label="Date of Birth" />
        <Inputs.DateInput
          value={state.age.value}
          onChangeText={handleAgeChanged}
          onBlur={handleAgeBlur}
          validationState={state.age}
          id="age"
        />
        <Inputs.Label htmlFor="coupon" label="Referrer Code (Optional)" />
        <SkipValidations
          autoCapitalize={"none"}
          onChangeText={handleCouponCodeChange}
          value={state.couponCode.value}
          maxLength={20}
          placeholder={"Enter Promo Code"}
          id="coupon"
          theme="dark"
        />
        <Checkbox
          type={"terms"}
          isValid={state.isTermsAcceptedValid}
          isChecked={state.termsAccepted}
          onChange={handleTermsChanged}
          className="complete-profile__checkbox"
          theme="dark"
        />

        <Button
          label={"Confirm"}
          onClick={onCompleteProfile}
          className="complete-profile__action-btn"
          disabled={isFormInvalid}
        />
        {isLaunchedOnAppInit && (
          <ButtonOutline
            labelStyle={{ color: colors.neutralBlueColor }}
            containerStyle={{
              borderColor: colors.neutralBlueColor,
              borderWidth: 1,
              backgroundColor: colors.transparentColor,
            }}
            label={"Log Out"}
            onClick={SportsBookSignInBIF.logout}
          />
        )}
      </div>
    );
  },
);

CompleteProfile.displayName = "CompleteProfile";
export default CompleteProfile;
