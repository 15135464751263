import BankWire from "assets/img/withdrawalMethods/bankWire.png";
import CashApp from "assets/img/withdrawalMethods/cashApp.png";
import Skrill from "assets/img/withdrawalMethods/skrill.png";
import BitCoin from "assets/img/withdrawalMethods/bitcoin.png";
import Visa from "assets/img/withdrawalMethods/visa.png";
import { TWithdrawProviders } from "src/types";

export const WITHDRAWAL_METHODS = [
  {
    value: "mazooma",
    label: "Instant Bank Transfer",
    imageSource: BankWire,
  },
  {
    value: "cashApp",
    label: "Cash App",
    imageSource: CashApp,
  },
  {
    value: "skrill",
    label: "Skrill",
    imageSource: Skrill,
  },
  {
    value: "bitcoin",
    label: "Bitcoin",
    imageSource: BitCoin,
  },
  {
    value: "bankWire",
    label: "Standard Bank Transfer",
    imageSource: BankWire,
  },
  {
    value: "fiservBank",
    label: "Bank Transfer",
    imageSource: BankWire,
  },
  {
    value: "fiservCard",
    label: "Visa Debit Card",
    imageSource: Visa,
  },
] as const;

export const REGISTER_BTN_LABEL = {
  mazooma: "Register a new bank account",
  fiservBank: "New Bank Transfer",
  fiservCard: "New Debit Card",
};

export const SEPARATOR_LABEL = {
  mazooma: "Select a bank account",
  fiservBank: "Select Payment Method",
  fiservCard: "Select Payment Method",
};

export const getWithdrawalText = (provider: TWithdrawProviders) => {
  if (provider === "fiservBank") {
    return "Please allow 1-3 business days for your request to be processed. It may take additional 0-2 days to receive the amount.";
  }
  if (provider === "fiservCard") {
    return "Please allow 1-3 business days for your request to be processed. You will receive the amount instantly once your request is processed by Fliff.";
  }
  return "Please allow 1-3 business days for your request to be processed. If your bank is part of the Real-Time Payments (RTP) network, you will receive the amount instantly once your request is processed by Fliff. Otherwise, it may take 0-2 additional days.";
};
